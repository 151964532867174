import Component from '@ember/component';
import { computed } from '@ember/object';
import { htmlSafe } from '@ember/template';
import { inject as service } from '@ember/service';

export default Component.extend({
  // SETUP

  classNameBindings: [
    'dynamicHeight::image-card-fixed-height',
    'overrideCursor:cursor-pointer',
  ],

  classNames: ['image-card-collection'],

  session: service(),

  // INTERNAL

  isLoaded: false,

  // EXTERNAL

  dynamicHeight: true,

  color: 'default',

  image: null,

  index: null,

  link: null,

  badge: null,

  students: 0,

  teachers: [],

  isCourse: false,

  showEditions: null,

  overlay: false,

  searchValue: null,

  prefetchOnHover: null,

  prefetched: false,

  titleSize: 'text-quarternary',

  effects: true,

  disabled: false,

  overrideCursor: false,

  accessibility: false,

  onComplete() {},

  onSuccess() {},

  onError() {},

  onClick() {},

  click() {
    this.get('onClick')();
  },

  // INTERNAL

  tiltX: 16,

  tiltY: 16,

  _imgElement: null,

  _containerElem: null,

  _tilting: false,

  // COMPUTED

  url: computed('imageSource', function () {
    const url = this.get('imageSource');

    if (url) {
      return url && htmlSafe(`background-image: url(${url});`);
    } else {
      return htmlSafe(`background-color: ${this.get('backgroundColor')};`);
    }
  }),

  imageSource: computed('image', function () {
    return this.get('image');
  }),

  overlayClass: computed('image', 'overlay', function () {
    if (this.get('overlay')) {
      return 'card-image-overlay';
    } else if (!this.get('image')) {
      return 'card-image-default';
    } else {
      return '';
    }
  }),

  searchArr: computed('searchValue', function () {
    if (this.get('searchValue')) {
      return this.get('searchValue').split(' ');
    } else {
      return null;
    }
  }),

  // HOOKS

  init() {
    this._super(...arguments);

    if (this.get('effects')) {
      this.imgMouseEnter = this.imgMouseEnter.bind(this);
      this.imgMouseMove = this.imgMouseMove.bind(this);
      this.imgMouseLeave = this.imgMouseLeave.bind(this);
    }

    if (this.get('accessibility')) {
      this.imgFocusedEnter = this.imgFocusedEnter.bind(this);
    }
  },

  didInsertElement() {
    this._super(...arguments);

    const containerElem = this.element.firstElementChild;
    const imgElement = containerElem.querySelector('.card-image');

    this.set('_containerElem', containerElem);
    this.set('_imgElement', imgElement);

    containerElem.style.backgroundColor = this.get('backgroundColor');

    if (this.get('accessibility')) {
      containerElem.addEventListener('keydown', this.imgFocusedEnter);
      containerElem.setAttribute('tabindex', '0');
      containerElem.setAttribute('role', 'link');
      if (this.get('title')) {
        containerElem.setAttribute('aria-label', this.get('title'));
      }
    }

    if (this.get('effects')) {
      containerElem.addEventListener('mouseenter', this.imgMouseEnter);
      containerElem.addEventListener('mousemove', this.imgMouseMove);
      containerElem.addEventListener('mouseleave', this.imgMouseLeave);
    }
  },

  willDestroyElement() {
    this._super(...arguments);

    this.get('_containerElem').removeEventListener(
      'mouseenter',
      this.imgMouseEnter
    );
    this.get('_containerElem').removeEventListener(
      'mousemove',
      this.imgMouseMove
    );
    this.get('_containerElem').removeEventListener(
      'mouseleave',
      this.imgMouseLeave
    );
    this.get('_containerElem').removeEventListener(
      'keydown',
      this.imgFocusedEnter
    );
  },

  // EVENTS

  imgMouseEnter() {
    const containerElem = this.get('_containerElem');
    const imgElement = this.get('_imgElement');

    imgElement.style.setProperty('--transition', 'none');
    imgElement.style.setProperty('transition', 'none');
    imgElement.style.setProperty('--scale', '1.0');

    containerElem.classList.add('card-image-hover');
    if (!this.get('isDestroying') || !this.get('isDestroyed')) {
      this.set('_tilting', true);
    }

    // Prefetch image
    if (this.get('prefetchOnHover') && !this.get('prefetched')) {
      let prefetchImg = new Image();
      prefetchImg.src = this.get('prefetchOnHover');
      if (!this.get('isDestroying') || !this.get('isDestroyed')) {
        this.set('prefetched', true);
      }
    }
  },

  imgMouseMove(e) {
    window.requestAnimationFrame(() => this.updateTilt(e));
  },

  imgMouseLeave() {
    const containerElem = this.get('_containerElem');
    const imgElement = this.get('_imgElement');

    imgElement.style.setProperty('transition', 'all 200ms ease-in-out');
    containerElem.classList.remove('card-image-hover');

    if (!this.get('isDestroying') || !this.get('isDestroyed')) {
      this.set('_tilting', false);
    }
  },

  imgFocusedEnter(e) {
    if (e.key === 'Enter') {
      this.onClick();
    }
  },

  updateTilt(e) {
    const containerElem = this.get('_containerElem');
    const imgElement = this.get('_imgElement');

    if (imgElement && this.get('_tilting')) {
      let XAngle,
        YAngle = 0;

      YAngle =
        -(0.5 - e.offsetX / containerElem.offsetWidth) * this.get('tiltY');
      XAngle =
        (0.5 - e.offsetY / containerElem.offsetWidth) * this.get('tiltX');

      let glossy = 0.2 - (XAngle + 12) / 100;

      imgElement.style.setProperty('--xangle', `${XAngle}deg`);
      imgElement.style.setProperty('--yangle', `${YAngle}deg`);
      imgElement.style.setProperty('--overlay', glossy);
    }
  },
});
