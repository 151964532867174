import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.resource.isResolved}}\n\n  {{yield this.resource.value}}\n\n{{else if this.resource.isRejected}}\n\n  {{#if (has-block \"error\")}}\n    {{yield this.resource.error to=\"error\"}}\n  {{else}}\n\n    <XAlert data-test-wait-for-error-alert @variant=\"error\">\n\n      <p class=\"mb1\">\n        {{t \"components.waitFor.errorAlertMessage\"}}\n      </p>\n\n      <div class=\"flex justify-flex-end\">\n\n        <UiButton\n          data-test-wait-for-retry-button\n          @onClick={{this.resource.load}}\n        >\n          {{t \"components.waitFor.retryButtonLabel\"}}\n        </UiButton>\n\n      </div>\n    </XAlert>\n\n  {{/if}}\n\n{{else if this.resource.isPending}}\n\n  {{#if (has-block \"loading\")}}\n    {{yield to=\"loading\"}}\n  {{else}}\n\n    <DcLoader data-test-wait-for-loader />\n\n  {{/if}}\n\n{{/if}}", {"contents":"{{#if this.resource.isResolved}}\n\n  {{yield this.resource.value}}\n\n{{else if this.resource.isRejected}}\n\n  {{#if (has-block \"error\")}}\n    {{yield this.resource.error to=\"error\"}}\n  {{else}}\n\n    <XAlert data-test-wait-for-error-alert @variant=\"error\">\n\n      <p class=\"mb1\">\n        {{t \"components.waitFor.errorAlertMessage\"}}\n      </p>\n\n      <div class=\"flex justify-flex-end\">\n\n        <UiButton\n          data-test-wait-for-retry-button\n          @onClick={{this.resource.load}}\n        >\n          {{t \"components.waitFor.retryButtonLabel\"}}\n        </UiButton>\n\n      </div>\n    </XAlert>\n\n  {{/if}}\n\n{{else if this.resource.isPending}}\n\n  {{#if (has-block \"loading\")}}\n    {{yield to=\"loading\"}}\n  {{else}}\n\n    <DcLoader data-test-wait-for-loader />\n\n  {{/if}}\n\n{{/if}}","moduleName":"babel-app/components/wait-for/index.hbs","parseOptions":{"srcName":"babel-app/components/wait-for/index.hbs"}});
import { assert } from '@ember/debug';
import Component from '@glimmer/component';

import Resource from '../../utils/resource';

/*

import Resource from '../../utils/resource';

export interface WaitForComponentArgs {
  resource: Resource;
}

*/

export default class WaitForComponent extends Component {
  get resource() {
    const resource = this.args.resource;

    assert(
      `The @resource arg passed to <WaitFor> must be an instance of Resource. Got ${typeof resource}`,
      resource instanceof Resource
    );

    return resource;
  }
}
