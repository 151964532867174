import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default Route.extend({
  intl: service(),
  session: service(),

  setupController() {
    this._super(...arguments);

    this.controllerFor('master').setProperties({
      bookEntity: null,
      entity: null
    });
  },

  async model() {
    const user = this.session.user;

    if (!user) {
      return {};
    }

    const schools = await user.schools;
    const active_school = await user.school;

    return {
      user,
      schools,
      active_school
    }
  },

  // ACTIONS

  actions: {
    didTransition() {
      this.controllerFor('master').set('pageTitle', {
        title: this.get('intl').t('account.administration'),
        target: 'master.admin.books'
      });
    }
  }
});
