import Route from '@ember/routing/route';
import { service } from '@ember/service';
import config from 'babel-app/config/environment';

export default class ActivateRoute extends Route {
  @service notice;
  @service store;
  @service fakeOrders;

  async beforeModel() {
    await this.notice.getMessages();
  }

  async model({ link_hash }) {
    let user = null;
    let userSchools = [];

    try {
      user = await this.store.queryRecord('user', { me: true });
      userSchools = await user.schools;
    } catch {
      // User is not logged in
    }

    if (!config.test) {
      await this.fakeOrders.generate(user);
    }

    const order = await this.store.findRecord('order', link_hash);

    return {
      order,
      user,
      userSchools,
    };
  }
}
