// The entire path must exist for each file
const FILES = [
  { id: 1, path: '/assets/images/avatars/1.svg' },
  { id: 2, path: '/assets/images/avatars/2.svg' },
  { id: 3, path: '/assets/images/avatars/3.svg' },
  { id: 4, path: '/assets/images/avatars/4.svg' },
  { id: 5, path: '/assets/images/avatars/5.svg' },
  { id: 6, path: '/assets/images/avatars/6.svg' },
  { id: 7, path: '/assets/images/avatars/7.svg' },
  { id: 8, path: '/assets/images/avatars/8.svg' },
  { id: 9, path: '/assets/images/avatars/9.svg' },
  { id: 10, path: '/assets/images/avatars/10.svg' },
  { id: 11, path: '/assets/images/avatars/11.svg' },
  { id: 12, path: '/assets/images/avatars/12.svg' },
  { id: 13, path: '/assets/images/avatars/13.svg' },
  { id: 14, path: '/assets/images/avatars/14.svg' },
  { id: 15, path: '/assets/images/avatars/15.svg' },
  { id: 16, path: '/assets/images/avatars/16.svg' },
  { id: 17, path: '/assets/images/avatars/17.svg' },
  { id: 18, path: '/assets/images/avatars/18.svg' },
  { id: 19, path: '/assets/images/avatars/19.svg' }
];

function getAvatars() {
  return FILES;
}

function getPath(id) {
  const pathObj = FILES.findBy('id', id);
  return pathObj && pathObj.path;
}

export { getAvatars, getPath };
