import Component from '@ember/component';

export default Component.extend({
  tagName: '',

  index: null,

  missingLicense: null,

  isTeacher: false
});
