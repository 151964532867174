import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { all, resolve } from 'rsvp';

export default class MasterProfileArchiveIndexRoute extends Route {
  @service store;

  @service session;

  get type() {
    switch (this.routeName) {
      case 'master.profile.archive.notes.index':
        return 'note';
      case 'master.profile.archive.wordlists.index':
        return 'wordlist';
      case 'master.profile.archive.files.index':
        return 'file';
      case 'master.profile.archive.teacher-assignments.index':
        return 'teacher-assignment';
      default:
        return null;
    }
  }

  model() {
    if (!this.type) return null;

    let userContent;

    return this.store
      .query(this.type, {})
      .then((content) => {
        userContent = content.filterBy('userId', this.session.user.id);

        if (this.type === 'file') {
          userContent = userContent.filter((file) => {
            return file.type !== 'external';
          });
        }

        return all(
          userContent.map((item) =>
            all([item.books, item.contents]).catch(() => resolve())
          )
        );
      })
      .then(() => userContent);
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.setProperties({ bookId: null, search: '', page: 1 });
    }
  }
}
