import { A } from '@ember/array';
import Component from '@ember/component';
import { computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import config from 'babel-app/config/environment';
import appendQueryParams from 'babel-app/utils/append-query-params';
import { resolve } from 'rsvp';
import { trackEvent } from '../../../utils/matomo-events';

export default Component.extend({
  // SETUP

  tagName: '',

  session: service(),

  // PROPERTIES

  licenseModalOpen: false,

  license: null,

  product: alias('license.product'),

  school: null,

  searchValue: null,

  hideOptions: false,

  // COMPUTED

  image: alias('product.cover'),

  productIsDisabled: computed(
    'product.{disabledUntilPublished,released}',
    function() {
      if (!this.get('product.disabledUntilPublished')) {
        return false;
      }

      const released = this.get('product.released');
      const now = new Date();

      return released > now;
    }
  ),

  cardIsDisabled: computed(
    'productIsDisabled',
    'license.is_active',
    function() {
      if (this.get('productIsDisabled')) return true;
      return !this.get('license.is_active');
    }
  ),

  courses: alias('session.user.courses.[]'),

  productCourses: computed('courses.@each.products', 'product.[]', function() {
    const productId = this.get('product.id');
    const courses = this.get('courses') || [];

    const productCourses = A();

    courses.forEach((course) => {
      if (
        course
          .get('products')
          .map((product) => product.get('id'))
          .indexOf(productId) !== -1
      ) {
        productCourses.addObject(course);
      }
    });

    return productCourses;
  }),

  hasOptions: computed(
    'hideOptions',
    'product.{hasProductPage,books.length}',
    'productCourses.length', 
    function() {
      if (this.get('hideOptions')) {
        return false;
      } else if (this.get('productCourses.length') > 1) {
        return true;
      } else if (!this.get('product.hasProductPage') && this.get('product.books.length') > 1) {
        return true;
      }
      return false;
  }),

  hasPopover: computed(
    'productIsDisabled',
    function() {
      const productIsDisabled = this.get('productIsDisabled');

      if (productIsDisabled) {
        return false;
      }

      return true;
    }
  ),

  externalRedirect: (target, statisticsIsbn) => {
    target = appendQueryParams(target, { statisticsIsbn });
    return window.open(target, '_self');
  },

  hoursUntilMidnight: computed(function() {
    const midnight = new Date();
    midnight.setDate(midnight.getDate() + 1);
    midnight.setHours(0, 0, 0, 0);

    return Math.floor((midnight - new Date()) / 1000 / 60 / 60);
  }),

  // ACTIONS

  actions: {
    async openCourse(productProxy, course) {
      try {
        const product = await resolve(productProxy);
        const books = await resolve(product?.books);

        if (
          !product?.hasCoursePage
          && books?.length === 1
          && books?.firstObject?.type === 'books_external'
        ) {
          let target = product && books?.firstObject?.body?.external_url;

          if (target) {
            if (course?.id) {
              target = appendQueryParams(target, {
                activeCourse: course.id,
                statisticsIsbn: product.id,
              })
            }

            window.location = target;
          }
        } else if (course) {
          window.location = `${config.diginokUrl}/courses/${course.id}`;
        }
      } catch (err) {
        console.warn(err);
      }
    },

    openBook(book) {
      if (book.get('type') === 'books_external') {
        const target = book && book.get('body.external_url');
        const product = this.get('product');
        return this.externalRedirect(target, product.get('id'));
      }
      window.location = `${config.diginokUrl}/books/${book.get('id')}`;
    },

    createCourse() {
      trackEvent({ category: 'Kurs', action: 'Skapa kurs - Öppna från startsida' });
    }
  },
});
