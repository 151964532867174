import { helper as buildHelper } from '@ember/component/helper';

export function substr(params, options) {
  let str = params[0];

  if (!str) {
    return;
  }

  if (typeof str === 'string' || str instanceof String) {
    let len = 20;

    if (options.max) {
      len = options.max;
    }

    let out = str.substr(0, len);

    if (str.length > len) {
      out += ' ...';
    }

    return out;
  } else {
    return str;
  }
}

export default buildHelper(substr);
