import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import arg from 'compton/utils/arg';
import { v4 as uuid } from 'ember-uuid';
import { trackEvent } from '../../../utils/matomo-events';

export default class UserOptionsComponent extends Component {
  @service intl;
  @service router;

  @arg userType = null;

  @tracked tagName = 'div';
  @tracked id = uuid();
  @tracked isPopupIndexOpen = false;
  @tracked showAddUsers = false;
  @tracked showAddCsv = false;
  @tracked showAddStudents = null;
  @tracked list = null;
  @tracked modalOpen = false;
  
  actionTaken = false;

  get roleText() {
    return this.intl.t(`admin.${this.userType}`).toLowerCase();
  }

  @action
  toggleUserActionsMenu() {
    this.isPopupIndexOpen = true;
  }

  @action
  handleActionMenuButtonChange(value) {
    this.isPopupIndexOpen = false;
    this.showAddUsers = false;
    this.showAddCsv = false;
    this.showAddStudents = false;
    this.actionTaken = true;

    switch (value) {
      case 'inviteWithCode':
        this.router.transitionTo(`master.admin.${this.userType}.invite`);
        break;
      case 'import_csv':
        this.showAddCsv = true;
        break;
      case 'inviteWithEmail':
        this.showAddUsers = true;
        break;
      case 'createStudentsWithUsername':
        this.showAddStudents = true;
        break;
    }
  }

  /**
   * Closes the CSV modal.
   * @param {boolean} skipClosedTracking Not tracking the close event if true, since this is called even after a successful import.
   * @param {number} filesUploaded Number of times a CSV file was uploaded.
   */
  @action
  closeCsvModal(skipClosedTracking = false, filesUploaded = 0) {
    if (filesUploaded > 0) {
      trackEvent({
        category: 'Användare',
        action: `Bjud in ${ this.userType === 'teachers' ? 'lärare' : 'elev' } - Importera csv - Laddar upp fil`,
        value: filesUploaded,
      });
    }

    if (!skipClosedTracking) {
      trackEvent({
        category: 'Användare',
        action: `Bjud in ${this.userType === 'teachers' ? 'lärare' : 'elev'} - Importera csv - Avbryt`
      });
    }

    this.showAddCsv = false;
  }

  @action
  closeAddWithEmailModal() {
    trackEvent({
      category: 'Användare',
      action: `Bjud in ${this.userType === 'teachers' ? 'lärare' : 'elev'} - Via epost - Avbryt`
    });

    this.showAddUsers = false;
  }

  @action
  closeAddWithUsernameModal() {
    trackEvent({
      category: 'Användare',
      action: `Bjud in elev - Med användarnamn - Avbryt`
    });

    this.showAddStudents = false;
  }

  @action
  closeInvitationMenu() {
    if (!this.actionTaken) {
      trackEvent({
        category: 'Användare',
        action: `Bjud in ${this.userType === 'teachers' ? 'lärare' : 'elev'} - Stäng utan att välja`
      });
    }

    this.isPopupIndexOpen = false;
  }
}
