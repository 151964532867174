import { Promise } from 'rsvp';

function scrollToPromise({ top, behavior }) {
  window.scrollTo({
    top,
    behavior,
  });
  return waitForScrollEnd();
}

function waitForScrollEnd() {
  let lastChangedFrame = 0;
  let lastX = window.scrollX;
  let lastY = window.scrollY;

  return new Promise((resolve) => {
    function tick(frames) {
      if (frames >= 500 || frames - lastChangedFrame > 20) {
        return resolve();
      }
      if (window.scrollX != lastX || window.scrollY != lastY) {
        lastChangedFrame = frames;
        lastX = window.scrollX;
        lastY = window.scrollY;
      }
      window.requestAnimationFrame(tick.bind(null, frames + 1));
    }
    tick(0);
  });
}

function viewportTop() {
  const margin = parseInt(
    getComputedStyle(document.body).getPropertyValue('--global-margin')
  );
  const header = parseInt(
    getComputedStyle(document.body).getPropertyValue('--header-height')
  );

  let topbar = parseInt(
    document.getElementsByClassName('content-topbar')[0]?.offsetHeight
  );

  if (isNaN(topbar)) {
    topbar = parseInt(
      getComputedStyle(document.body).getPropertyValue('--topbar-height')
    );
  }

  return margin + header + topbar;
}

function viewportBottom() {
  let index = parseInt(
    document.getElementsByClassName('contents-float-nav')[0]?.offsetHeight
  );

  if (isNaN(index)) {
    index = 0;
  }

  return index;
}

function scrollTo(elem, behavior = 'smooth') {
  const target = {
    top: 0,
    behavior,
  };

  if (
    !elem.classList.contains('chapter-part') ||
    elem.previousElementSibling !== null
  ) {
    target.top = elemOffset(elem) - viewportTop();
  }

  return scrollToPromise(target);
}

function elemOffset(elem) {
  let offset = elem.offsetTop;
  let par = elem.offsetParent;

  while (par) {
    offset += par.offsetTop;
    par = par.offsetParent;
  }

  return offset;
}

export { elemOffset, scrollTo, viewportBottom, viewportTop, waitForScrollEnd };
