import { computed } from '@ember/object';
import DS from 'ember-data';

import Model from './model';
const { attr, hasMany, belongsTo } = DS;

export default Model.extend({
  // ATTRIBUTES

  name: attr('string'),

  type: attr('string'),

  provider: attr('string'),

  providerId: attr('string'),

  metadata: attr('object', {
    defaultValue() {
      return {};
    }
  }),

  numUsers: attr('number'),

  numStudents: attr('number'),

  numTeachers: attr('number'),

  schoolUnitCodes: attr({
    defaultValue() {
      return [];
    }
  }),

  // RELATIONS

  users: hasMany('user', { inverse: null }),

  customer: belongsTo('customer', { inverse: null }),

  groups: hasMany('group'),

  courses: hasMany('course'),

  licenses: hasMany('license'),

  agreements: hasMany('agreement'),

  available_products: hasMany('available_product', { inverse: null }),

  // COMPUTED

  hasAgreement: computed('available_products.isPending', function () {
    const availableProducts = this.get('available_products');

    if (availableProducts && !availableProducts.get('isPending')) {
      return availableProducts.any(
        (availableProduct) => availableProduct.get('has_agreement')
      );
    }

    return false;
  }),

  hasStudents: computed('numStudents', 'users', function () {
    if (this.numStudents > 0) {
      return true;
    }

    return this.users.then((users) =>
      users.some(({ role }) => role === 'student')
    );
  }),
});
