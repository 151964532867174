import {
  handleDirtyBelongsToRelationshipData,
  handleDirtyHasManyRelationshipData,
} from '../utils/handle-dirty-relationship-data';
import ApplicationAdapter from './application';

const DIRTY_DATA_RELATIONSHIPS = [
  'entity',
  'includedEntities',
  'includedTeacherAssignments',
  'includedWordlists',
];

export default ApplicationAdapter.extend({
  namespace: 'api/v1/meta',

  findHasMany(store, snapshot, url, relationship) {
    if (DIRTY_DATA_RELATIONSHIPS.includes(relationship?.meta?.name)) {
      return handleDirtyHasManyRelationshipData(
        store,
        url,
        relationship?.meta?.type
      );
    }

    return this._super(...arguments);
  },

  findBelongsTo(store, snapshot, url, relationship) {
    if (DIRTY_DATA_RELATIONSHIPS.includes(relationship?.meta?.name)) {
      return handleDirtyBelongsToRelationshipData(
        store,
        url,
        relationship?.meta?.type
      );
    }

    return this._super(...arguments);
  },
});
