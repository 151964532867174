import ApplicationAdapter from './application';

export default class extends ApplicationAdapter {

  urlForQuery(query) {
    if (query.school_id) {
      const { school_id } = query;
      return `${this.buildURL()}/schools/${school_id}/codes`;
    }
    return super.urlForQuery(...arguments);
  }

  urlForUpdateRecord(id, model, snapshot) {
    return `${this.buildURL()}/schools/${snapshot.attr('school_id')}/codes/${snapshot.id
      }`;
  }

  async query(store, type, query) {
    const response = await super.query(...arguments);

    if (query.role) {
      return response.filter(({ role }) => role == query.role);
    }
    return response;
  }

  updateRecord(store, type, snapshot) {
    const url = this.urlForUpdateRecord(
      snapshot.id,
      'school-access-code',
      snapshot
    );
    const payload = this.serialize(snapshot);
    payload.data.type = 'codes';
    return this.ajax(url, 'PUT', { data: payload });
  }

  urlForCreateRecord(model, snapshot) {
    return `${this.buildURL()}/schools/${snapshot.attr('school_id')}/codes/`;
  }

  createRecord(store, type, snapshot) {
    const url = this.urlForCreateRecord(type, snapshot);

    const payload = {
      data: {
        attributes: {
          school_id: snapshot.attr('school_id'),
          role: snapshot.attr('role'),
        },
        type: 'codes',
      },
    };
    return this.ajax(url, 'POST', { data: payload });
  }
}
