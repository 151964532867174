import { A } from '@ember/array';
import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
  // INTERNAL

  tagName: '',

  // EXTERNAL

  user: null,

  group: null,

  selectedGroups: null,

  selectedUsers: null,

  // COMPUTED

  isIndeterminate: computed(
    'selectedGroups.[]',
    'selectedUsers.[]',
    'group',
    'user',
    function() {
      const user = this.get('user');

      if (user) return false;

      const selectedGroups = this.get('selectedGroups');
      const group = this.get('group');

      if (!group || !selectedGroups) return false;
      if (selectedGroups.indexOf(group) !== -1) return false;

      const groupUsers = group.get('users.content').toArray();
      const selectedUsers = this.get('selectedUsers');

      if (!groupUsers || !selectedUsers) return false;

      return groupUsers.some((user) =>
        selectedUsers.indexOf(user) !== -1
      );
    }
  ),

  isSelected: computed(
    'selectedGroups.[]',
    'group',
    'selectedUsers.[]',
    'user',
    {
      get(key) {
        const selectedGroups = this.get('selectedGroups');
        const selectedUsers = this.get('selectedUsers');
        const group = this.get('group');
        const user = this.get('user');

        if (selectedGroups && group) {
          if (selectedGroups.indexOf(group) !== -1) {
            return true;
          }
        }

        if (selectedUsers && user) {
          if (selectedUsers.indexOf(user) !== -1) {
            return true;
          }
        }

        return false;
      },
      set(key, value) {
        const selectedGroups = this.get('selectedGroups');
        const selectedUsers = this.get('selectedUsers');
        const group = this.get('group');
        const user = this.get('user');

        if (user) {
          if (selectedUsers.indexOf(user) === -1) {
            if (group) {
              if (selectedGroups.indexOf(group) === -1) {
                selectedUsers.addObject(user);
              } else {
                group.get('users').then((users) => {
                  users.forEach((user) => {
                    if (user.role === 'student') {
                      selectedUsers.addObject(user);
                    }
                  });

                  selectedGroups.removeObject(group);
                  selectedUsers.removeObject(user);
                });
              }
            } else {
              selectedUsers.addObject(user);
            }
          } else {
            selectedUsers.removeObject(user);
          }
        } else {
          if (selectedGroups.indexOf(group) === -1) {
            group.get('users').then((users) => {
              users.forEach((user) => {
                if (user.role === 'student') {
                  selectedUsers.removeObject(user);
                }
              });

              selectedGroups.addObject(group);
            });
          } else {
            selectedGroups.removeObject(group);
          }
        }

        return value;
      }
    }
  )
});
