import Route from '@ember/routing/route';
import config from 'babel-app/config/environment';

export default class StatusRoute extends Route {
  tryParseJson(text) {
    try {
      return JSON.parse(text);
    } catch {
      return null;
    }
  }

  async model() {
    const services = {
      '🚪': { endpoint: `/api/health?status=1`, hasExpectedResult: (result) => result.healthy === true },
      '🤖': { endpoint: `${config.userEndpoint}/health?status=1`, hasExpectedResult: (result) => result.healthy === true },
      '📦': { endpoint: `https://orders.nok.se/system/info`, hasExpectedResult: (result) => result.environmentName === 'Production' },
    };

    const model = [];

    for (const [name, { endpoint, hasExpectedResult }] of Object.entries(services)) {
      try {
        const response = await fetch(endpoint);
        const responseBody = await response.text();
        const result = this.tryParseJson(responseBody);

        const ok = response.ok && result && hasExpectedResult(result);

        model.push({
          name,
          ok,
          status: response.status,
          error: !ok && { message: result ? JSON.stringify(result, null, 2) : responseBody },
        });
      } catch (error) {
        model.push({
          name,
          ok: false,
          status: null,
          error,
        });
      }
    }

    return model;
  }
}
