import Component from '@glimmer/component';

export default class IndexCircleComponent extends Component {
  /**
   * Arguments:
   * content (String)
   */

  /**
   * Styles:
   * Grey
   * Default
   */

  get className() {
    if (this.args.style === 'grey') {
      return 'index-circle-grey';
    } else {
      return 'index-circle-default';
    }
  }
}
