import Controller from '@ember/controller';
import EmberObject, { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { trackEvent } from '../../../utils/matomo-events';

export default class AdminStudentsController extends Controller {
  @service ajax;
  @service session;
  @service intl;
  @service store;
  @service confirmManager;
  @service snackbar;
  @service exportSchoolusers;

  @tracked pageMenuOpen = false;
  @tracked userId = null;
  @tracked tabValue = null;
  @tracked modalOpen = false;
  @tracked confirmRemoveUsersDialogOpen = false;
  @tracked confirmRemoveUsersConfirmInput = '';
  @tracked removingUsers = false;
  printing = false;

  texts = {
    searchHeading: 'admin.searchStudents',
    searchPlaceholder: 'routes.admin.search.students',
    groupSelectHeading: 'admin.classes',
    groupSelectAllOption: 'admin.allClasses',
    noUsersMessage: 'admin.noStudents',
    groupNumUsers: 'admin.classNumUsers',
    groupNoUsers: 'admin.classNoUsers',
    userNameHeading: 'admin.name',
    userEmailHeading: 'admin.eMail',
    userLastLoginHeading: 'admin.userLastLoginHeading',
    usersLabel: 'admin.numStudents',
  };

  confirm(message) {
    return this.confirmManager.confirm(message, { danger: true });
  }

  get usersHandledExternally() {
    return this.session.user.school.get('metadata')?.external_users ?? false;
  }

  get classes() {
    const groups = this.model.groups;
    const students = this.students;

    let shownUsers = [];
    let classes = [];

    if (groups) {
      classes = groups.filter(
        (x) => x.get('type') === 'class' && !x.get('metadata.classLessClass')
      );

      classes.forEach((group) => {
        const users = group.get('users.isFulfilled') ? group.get('users') : [];

        shownUsers = shownUsers.concat(
          users
            .filter((x) => x.get('role') === 'student')
            .map((x) => x.get('id'))
        );
      });

      shownUsers = shownUsers.filter(
        (val, idx, self) => self.indexOf(val) === idx
      );
    }

    if (students) {
      const classLessUsers = students.filter(
        (x) =>
          x.get('role') === 'student' && shownUsers.indexOf(x.get('id')) === -1
      );

      if (classLessUsers.length > 0) {
        const classLessClass = EmberObject.create({
          name: this.intl.t('admin.classNameMissing'),
          type: 'class',
          school: this.get('session.user.school'),
          num_students: classLessUsers.length,
          users: classLessUsers,
          metadata: {
            classLessClass: true,
          },
        });

        classes.addObject(classLessClass);
      }
    }

    return classes;
  }

  get students() {
    return this.model.users.filter((user) => user.isStudent);
  }

  get isConfirmRemoveButtonDisabled() {
    return (
      this.removingUsers ||
      this.confirmRemoveUsersConfirmInput.toLowerCase() !==
      this.intl.t('admin.removeStudentsConfirmText').toLowerCase()
    );
  }

  async _removeUsers(users) {
    const { school } = this.model;
    const size = 100;
    const chunks = Array.from({
      length: Math.ceil(users.length / size),
    }).map((_, index) => users.slice(index * size, index * size + size));

    try {
      await Promise.all(
        chunks.map((users) =>
          this.ajax.request(
            `/api/schools/${school.id}/remove-users`,
            true,
            {
              type: 'POST',
              data: { users },
            }
          )
        )
      );

      school.users.reload();

      this.snackbar.enqueue(
        this.intl.t('admin.removeUsersSucceeded', {
          count: users.length,
        }),
        { variant: 'success', autoDismiss: true }
      );
    } catch (err) {
      this.snackbar.enqueue(
        this.intl.t('admin.removeUsersFailed', {
          count: users.length,
        }),
        { variant: 'error', autoDismiss: false }
      );
    }
  }

  _reloadRelations(school, groups) {
    const promises = [];

    promises.push(this.model.users.reload());

    if (groups) {
      groups.forEach((group) => {
        if (group) {
          const groupUsers = group.get('users');
          promises.push(
            Promise.all([
              groupUsers.then?.((users) => users.reload()),
              ...group.get('users').map((user) => user.reload()),
            ]).then(() => {
              group.set(
                'num_students',
                group.get('users').filter((x) => x.get('role') === 'student')
                  .length
              );
            })
          );
        }
      });
    }

    if (school.hasMany('available_products').value() !== null) {
      school.get('available_products').then((available_products) => {
        available_products.forEach((available_product) => {
          if (available_product.hasMany('user_licenses').value() !== null) {
            promises.push(available_product.get('user_licenses').reload());
          }
        });
      });
    }

    return Promise.all(promises);
  }

  @action async addClass(name) {
    const { school, groups } = this.model;
    const existingGroup = groups.filterBy('name', name);

    if (existingGroup && existingGroup.length) {
      return existingGroup[0];
    }

    const group = await this.store
      .createRecord('group', {
        name,
        type: 'class',
        school,
        num_students: 0,
      })
      .save();

    groups.push(group);

    return group;
  }

  @action
  async delete(group, students) {
    try {
      await this.confirm(this.intl.t('admin.removeClassConfirm'));

      trackEvent({
        category: 'Användare',
        action: 'Klass - Ta bort klass - OK'
      });

      await this._removeUsers(students.map((x) => x.get('id')));
      group.destroyRecord();
    } catch {
      trackEvent({
        category: 'Användare',
        action: 'Klass - Ta bort klass - Avbryt'
      });
    }
  }

  @action
  async removeUsers() {
    if (this.removingUsers) return;
    if (
      this.intl.t('admin.removeStudentsConfirmText').toLowerCase() ===
      this.confirmRemoveUsersConfirmInput.toLowerCase()
    ) {
      this.removingUsers = true;

      const userIds = this.students.mapBy('id');
      await this._removeUsers(userIds);
      this.classes
        .filter((group) => !group.get('metadata.classLessClass'))
        .forEach((group) => group.destroyRecord());
    }
    this.removingUsers = false;
    this.closeConfirmDialog();
  }

  @action
  closeConfirmDialog() {
    this.confirmRemoveUsersConfirmInput = '';
    this.confirmRemoveUsersDialogOpen = false;
  }

  @action
  viewUser(userId) {
    this.userId = userId;
    this.tabValue = 'general';
    this.modalOpen = true;
  }

  @action
  async moveUsers(newGroup, users, oldGroups) {
    const { school } = this.model;
    await this.ajax.request(
      `/api/schools/${school.id}/move-users`,
      true,
      {
        type: 'POST',
        data: {
          users: users.map((x) => x.get('id')),
          class: newGroup.get('id'),
        },
      }
    );

    this._reloadRelations(school, [...oldGroups, newGroup]);
  }

  @action
  async addUsers(data, userType, groups) {
    const school = await this.session.user.school;
    const schoolId = school.get('id');
    if (!schoolId) {
      throw new Error('Missing school Id');
    }

    // import 100 items at a time
    const chunkSize = 100;
    const aggregatedResponse = {};
    for (
      let startIndex = 0;
      startIndex < data.length;
      startIndex += chunkSize
    ) {
      const response = await this.ajax.request(
        `/api/schools/${schoolId}/add-users`,
        true,
        {
          type: 'POST',
          data: {
            users: data.slice(startIndex, startIndex + chunkSize),
            userType: userType,
          },
        }
      );
      Object.assign(aggregatedResponse, response);
    }
    // reload this asynchronously, so the modal closes when the import is finished
    this._reloadRelations(school, groups);

    const { valid, invalid } = Object.entries(aggregatedResponse).reduce(
      (imports, [email, status]) => {
        imports[status === 'invalid_user_role' ? 'invalid' : 'valid'].push(
          email
        );
        return imports;
      },
      { valid: [], invalid: [] }
    );

    if (invalid.length > 5) {
      this.snackbar.enqueue(
        this.intl.t('admin.addUsers.multipleUsersFailed', {
          count: invalid.length,
        }),
        { variant: 'error', autoDismiss: false }
      );
    } else {
      invalid.forEach((email) => {
        this.snackbar.enqueue(
          this.intl.t('admin.addUsers.userFailed', { user: email }),
          { variant: 'error', autoDismiss: false }
        );
      });
    }

    if (valid.length > 5) {
      this.snackbar.enqueue(
        this.intl.t('admin.addUsers.multipleUsersSucceeded', {
          count: valid.length,
        }),
        { variant: 'success', autoDismiss: true }
      );
    } else {
      valid.forEach((email) => {
        this.snackbar.enqueue(
          this.intl.t('admin.addUsers.userSucceeded', {
            user: email,
          }),
          { variant: 'success', autoDismiss: true }
        );
      });
    }
  }

  @action async removeUser(user) {
    try {
      await this.confirm(this.intl.t('admin.removeUserWarning'));

      trackEvent({
        category: 'Användare',
        action: 'Elev - Ta bort elev - OK'
      });

      const groups = await user.get('groups');
      await this._removeUsers([user.id]);
      await this._reloadRelations(this.model.school, groups);
    } catch {
      trackEvent({
        category: 'Användare',
        action: 'Elev - Ta bort elev - Avbryt'
      });
    }
  }

  @action
  reloadRelations(school, groups) {
    return this._reloadRelations(school, groups);
  }

  @action
  openConfirmDialog() {
    this.pageMenuOpen = false;
    this.confirmRemoveUsersDialogOpen = true;

    trackEvent({
      category: 'Användare',
      action: 'Elever meny - Radera alla elever och klasser på skolan'
    });
  }

  @action
  exportUsersToExcel() {
    this.pageMenuOpen = false;

    trackEvent({
      category: 'Användare',
      action: 'Elever meny - Ladda ner användarlista'
    });

    return this.exportSchoolusers.export(this.model.school.id);
  }
}
