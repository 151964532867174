import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class SkolkodController extends Controller {
  @service router;
  @service session;
  @service ajax;
  @service intl;

  @tracked manualCode = '';
  @tracked errors = [];
  @tracked schoolName = null;
  @tracked schoolRole = null;
  @tracked loading = false;

  get modelCodeError() {
    return this.parseError(this.model.errorMessage);
  }

  #getCode(dryRun) {
    // Either we have the code as query param or we use the manually entered code
    const code = this.model.code ?? this.manualCode;

    return this.ajax.request(
      `/api/users/connect-user-school/${this.session.user.id}/${code
      }${dryRun ? '/dry-run' : ''}`,
      true,
      { type: 'POST' }
    );
  }

  parseError(error) {
    switch (error) {
      case 'The requested student code is inactive':
        return 'routes.connectToSchool.expiredCodeStudent';
      case 'The requested teacher code is inactive':
        return 'routes.connectToSchool.expiredCodeTeacher';
      case 'The user cannot change role from teacher to student':
        return 'routes.connectToSchool.teacherStudentCode';
      case 'The user cannot change role from student to teacher':
        return 'routes.connectToSchool.studentTeacherCode';
      case 'The user is already connected to the school':
        return 'routes.connectToSchool.alreadyConnected';
      case 'Too many attempts':
        return 'routes.connectToSchool.tooManyAttempts';
      default:
        return 'routes.connectToSchool.invalidCode';
    }
  }

  @action close() {
    this.accessCode = null;
    this.manualCode = '';
    this.errors.length = 0;
    this.schoolName = null;
    this.schoolRole = null;
    this.router.replaceWith('master.index');
  }

  @action
  async dryRunConnectToSchool() {
    this.schoolName = null;
    this.errors = [];
    this.manualCode = this.manualCode.trim();

    if (this.manualCode.length < 6) {
      return;
    }

    try {
      const { school, role } = await this.#getCode(true);

      this.schoolName = school;
      this.schoolRole = role;
    } catch (error) {
      if (error.responseJSON?.errors) {
        const errorMessage = error.responseJSON.errors[0].detail;
        this.errors = [this.intl.t(this.parseError(errorMessage))];
      }
    }
  }

  @action
  async connectToSchool() {
    this.loading = true;

    try {
      const { school } = await this.#getCode(false);

      this.session.user.active_school = school;
      await this.session.user.save();
      await this.session.refreshUserSession();

      window.location.replace('/');
    } catch (error) {
      if (error.responseJSON?.errors) {
        const errorMessage = error.responseJSON.errors[0].detail;
        this.errors = [this.intl.t(this.parseError(errorMessage))];
      }
    }

    this.loading = false;
  }
}
