import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default Route.extend({
  session: service(),

  setupController() {
    this._super(...arguments);

    this.controllerFor('master').setProperties({
      pageTitle: {
        label: 'myProfile',
        target: 'master.profile.archive.notes'
      }
    });
  },

  async model() {
    const user = this.session.user;

    if (!user) {
      return {};
    }

    const schools = await user.schools;
    const active_school = await user.school;

    return {
      user,
      schools,
      active_school
    }
  }
});
