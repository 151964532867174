import { A } from '@ember/array';
import Component from '@ember/component';
import { computed, get } from '@ember/object';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default Component.extend({
  // SETUP

  tagName: '',

  // SERVICES

  session: service(),

  // EXTERNAL

  course: null,

  onUsersToggle() {},

  // COMPUTED

  externalLicenses: alias('session.user.school.metadata.external_licenses'),

  showNotification: computed(
    'externalLicenses',
    'course.usersMissingLicenses.@each.numMissing',
    function() {
      if (this.get('externalLicenses')) return false;

      return (this.get('course.usersMissingLicenses') || A()).any(
        (obj) => get(obj, 'numMissing') > 0
      );
    }
  )
});
