import Component from '@ember/component';
import { inject as service } from '@ember/service';

export default Component.extend({
  tagName: '',
  // SERVICES

  ajax: service(),

  // EXTERNAL

  codes: null,

  validationMessage: '',

  // INTERNAL

  modalOpen: false,

  // LIFECYCLE

  // ACTIONS

  actions: {
    close() {
      this.set('modalOpen', false);
    },
  },
});
