import { computed } from '@ember/object';
import { alias, readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import DS from 'ember-data';
import { all, hash } from 'rsvp';

import Contentable from '../mixins/contentable';
import Userable from '../mixins/userable';
import Model from './model';

const { attr, hasMany } = DS;

export default Model.extend(Contentable, Userable, {
  // SETUP

  session: service(),

  intl: service(),

  store: service(),

  validations: {
    name: {
      presence: {
        message: function(_, __, record) {
          return record.get('intl').t('models.validations.presence');
        }
      }
    }
  },

  // RELATIONSHIPS

  words: hasMany('word'),

  // ATTRIBUTES

  name: attr('string'),

  language: attr('string'),

  type: attr('string', { defaultValue: 'glossaries' }),

  public: attr('boolean', { defaultValue: false }),

  // PROPERTIES

  title: alias('name'),

  isPublished: alias('public'),

  isBusy: false,

  isTransformed: false,

  categoryName: null,

  isReadOnly: computed('isTransformed', 'isOwner', function() {
    return this.get('isTransformed') || !this.get('isOwner');
  }),

  isQuizable: computed(
    'type',
    'words.@each.{explanation,translation}',
    function() {
      const { type, words } = this.getProperties('type', 'words');

      if (type === 'concepts' && words.any((word) => word.get('explanation'))) {
        return true;
      }

      if (
        type === 'glossaries' &&
        words.any((word) => word.get('translation'))
      ) {
        return true;
      }

      return false;
    }
  ),

  nameWithFallback: computed('name', 'intl.locale', function() {
    return (
      this.get('name') || this.get('intl').t('models.wordlist.nameWithFallback')
    );
  }),

  titleWithFallback: readOnly('nameWithFallback'),

  sortedWords: computed('words.[]', 'readOnly', function() {
    const words = this.get('words');

    if (this.get('readOnly')) return words;

    return words.sortBy('word');
  }),

  // METHODS

  async duplicate(content) {
    return all([content.get('book'), this.get('words')]).then(
      ([book, words]) => {
        return all(words.invoke('duplicate')).then((duplicatedWords) => {
          const language = book.body.language || 'sv_SE';

          const wordlist = this.get('store').createRecord('wordlist', {
            ...this.duplicateAttributes(),
            user: this.get('session.user'),
            books: [book],
            contents: [content],
            words: duplicatedWords,
            language,
          });

          duplicatedWords.setEach('wordlist', wordlist);

          if (this.get('session.user.isTeacher')) {
            wordlist.set('public', false);
          }

          wordlist.set('isBusy', true);

          return wordlist.save().then(() => {
            return all(duplicatedWords.invoke('save')).then(() => {
              return wordlist.get('words').then((relation) => {
                return relation.reload().then(() => {
                  wordlist.set('isBusy', false);

                  return wordlist;
                });
              });
            });
          });
        });
      }
    );
  }
});
