import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { writeCookie } from 'babel-app/utils/handle-cookies';

export default Component.extend({
  cookies: service(),

  router: service(),

  showMessage: true,

  cookiesNotAccepted: false,

  init() {
    this._super(...arguments);

    if (this.get('router').get('currentRouteName') === 'nodes') {
      this.set('showMessage', false);
    } else {
      this.set('showMessage', true);
    }
  },

  actions: {
    acceptCookies() {
      writeCookie(this.cookies, `cookies_allowed`, 'yes');
      this.set('cookiesNotAccepted', false);
    }
  }
});
