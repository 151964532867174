import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class GenerateStudentPasswordsComponent extends Component {
  @tracked commonPassword = null;
  @tracked sameForAll = false;

  @action
  setCommonPassword(cpw) {
    this.commonPassword = cpw?.replace(/\s/g, '');
  }

  @action toggleSameForAll() {
    this.sameForAll = !this.sameForAll;
  }
}
