import { inject as service } from '@ember/service';
import ApplicationSerializer from 'babel-app/serializers/application';
import generateFallbackTitle from 'babel-app/utils/generate-fallback-title';

const INTERACTIVES_TYPE = 'interactives';
const LEGACY_INTERACTIVE_TEMPLATES = ['diagnosis', 'practice'];

export default class EntitySerializer extends ApplicationSerializer {
  @service intl;

  normalize(typeClass, hash) {
    if (
      hash?.attributes?.type === INTERACTIVES_TYPE &&
      LEGACY_INTERACTIVE_TEMPLATES.includes(hash?.attributes?.template)
    ) {
      hash.attributes.template = 'interactive';
    }

    const entity = super.normalize(typeClass, hash);
    const attrs = entity?.data?.attributes;

    if (attrs && (!attrs.title || attrs.title === '')) {
      entity.data.attributes.title = generateFallbackTitle(
        attrs.type,
        this.intl
      );
    }

    return entity;
  }
}
