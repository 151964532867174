export default {
  "skolon": {
    "name": "Skolon",
    "uri": "https://skolon.com/",
    "userCanNotSetPassword": true,
    "userCanNotEnterName": true,
    "userCanNotAddLicenses": false,
    "userCanNotAddLoginProviders": true
  },
  "itslearning": {
    "name": "Itslearning",
    "uri": null,
    "userCanNotSetPassword": true,
    "userCanNotEnterName": true,
    "userCanNotAddLicenses": false,
    "userCanNotAddLoginProviders": true
  },
  "canvas": {
    "name": "Canvas",
    "uri": null,
    "userCanNotSetPassword": true,
    "userCanNotEnterName": true,
    "userCanNotAddLicenses": false,
    "userCanNotAddLoginProviders": true
  },
  "skolfederation": {
    "name": "Skolfederation",
    "uri": null,
    "userCanNotSetPassword": true,
    "userCanNotEnterName": true,
    "userCanNotAddLicenses": false,
    "userCanNotAddLoginProviders": true
  },
  "hermods": {
    "name": "Hermods",
    "uri": "https://www.hermods.se/",
    "userCanNotSetPassword": false,
    "userCanNotEnterName": false,
    "userCanNotAddLicenses": false,
    "userCanNotAddLoginProviders": false
  }
};