import { get } from '@ember/object';

import ApplicationAdapter from './application';

export default ApplicationAdapter.extend({
  adapterOptionsQuery: 'adapterOptions.query',
  coalesceFindRequests: false,

  urlForFindRecord(id, modelName, snapshot) {
    const url = this._super(...arguments);

    const query = get(snapshot, this.adapterOptionsQuery);

    const urlSplit = url.split('user-licenses');

    return `${urlSplit[0]}schools/${query.school_id}/user-licenses${urlSplit[1]}`;
  },

  urlForFindMany(ids, modelName, snapshots) {
    const url = this._super(...arguments);

    const query = get(snapshots[0], this.adapterOptionsQuery);

    const urlSplit = url.split('user-licenses');

    return `${urlSplit[0]}schools/${query.school_id}/user-licenses${urlSplit[1]}`;
  },
});
