import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default Route.extend({
  // SETUP

  router: service(),

  // HOOKS

  redirect() {
    this.router.replaceWith('master.profile.settings');
  },
});
