import { action } from '@ember/object';
import { cancel, later } from '@ember/runloop';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import config from 'babel-app/config/environment';
import { singularize } from 'ember-inflector';
import { resolve } from 'rsvp';
import { trackEvent } from '../../../utils/matomo-events';

export default class AddUsersComponent extends Component {
  @service ajax;
  @service session;
  @service intl;
  @service emailValidator;

  @tracked findingEmails = false;
  @tracked findTO = null;
  @tracked emails = null;
  @tracked checkedEmails = null;
  @tracked users = null;
  @tracked numberOfInvalidEmails = 0;
  @tracked numberOfAlreadyAddedTeacherEmails = 0;
  @tracked numberOfAlreadyAddedStudentEmails = 0;
  @tracked addUsersFailed = false;
  @tracked addingUsers = false;
  @tracked list = null;
  @tracked isPopupIndexOpen = false;
  @tracked menuIsOpen = false;
  @tracked loadingCreateClass = false;
  @tracked creatingClass = false;
  @tracked createClassErrors = [];
  @tracked createVal = '';
  @tracked searchClass = '';
  @tracked oldClasses = [];
  @tracked selectedClass = null;

  get selectableClasses() {
    const classes = this.args.classes ?? [];
    const searchClass = this.searchClass;
    const searchClassLower = searchClass && searchClass.toLowerCase();

    const sortedClasses = classes
      .filter((x) => !x.get('metadata.classLessClass'))
      .sortBy('name')
      .map((x) => {
        return {
          label: x.get('name'),
          value: x,
        };
      });

    sortedClasses.unshift({
      label: this.intl.t('admin.withoutClass'),
      value: null,
    });

    const selectableClasses = sortedClasses.filter((x) => {
      return (
        !searchClass ||
        (x.label || x.placeholder).toLowerCase().includes(searchClassLower)
      );
    });

    if (selectableClasses.length === 0) {
      return [
        {
          label: this.intl.t('admin.noClassSearchResults'),
          type: 'heading',
        },
      ];
    }

    return selectableClasses;
  }

  @action
  async createClass() {
    const name = this.createVal;
    const trimmedName = name && name.trim();

    if (!trimmedName || trimmedName.length === 0) {
      this.createClassErrors = [this.intl.t('admin.emptyClassName')];
      return;
    } else {
      this.createClassErrors = [];
    }

    this.loadingCreateClass = true;

    const school = await this.session.user.school;
    const existingGroup = school.get('groups').filterBy('name', trimmedName);

    if (existingGroup && existingGroup.length) {
      this.createClassErrors = [this.intl.t('admin.classNameExists')];
      this.loadingCreateClass = false;
      return;
    } else {
      this.createClassErrors = [];
    }

    const classGroup = await this.args.addClass(trimmedName);
    this.selectedClass = classGroup;
    this.creatingClass = false;
    this.loadingCreateClass = false;
    this.menuIsOpen = false;
  }

  @action
  async addressesAdded() {
    const val = this.emails;

    if (val === this.checkedEmails) {
      return;
    }

    this.findingEmails = true;
    this.users = null;
    this.numberOfInvalidEmails = 0;
    this.numberOfAlreadyAddedTeacherEmails = 0;
    this.numberOfAlreadyAddedStudentEmails = 0;

    this.addUsersFailed = false;

    if (this.findTO) {
      cancel(this.findTO);
    }

    this.findTO = later(
      this,
      async () => {
        let emails = [];

        let invalidEmails = 0;
        let alreadyAddedStudentEmails = 0;
        let alreadyAddedTeacherEmails = 0;

        if (val) {
          // split on comma, trim whitespace and remove any empty strings
          const matches = val
            .split(/[\s|;|,]/)
            .map((x) => x.trim())
            .filter((x) => x !== '');

          // we can settle for a very simple email validation here since we will
          // use an API to validate very carefully, this is just to eliminate the
          // most garbage so we dont spam the API with that
          const simpleEmailRegex = /.+@.+\..{2,}/;

          emails = matches
            .filter((x) => simpleEmailRegex.test(x))
            .map((x) => {
              return { address: x };
            });

          invalidEmails = matches.length - emails.length;
        }

        if (emails.length === 0) {
          this.users = null;
          this.numberOfInvalidEmails = invalidEmails;
          this.numberOfAlreadyAddedTeacherEmails = 0;
          this.numberOfAlreadyAddedStudentEmails = 0;
          this.findingEmails = false;
          this.checkedEmails = val;
        } else {
          const emailResult = this.emailValidator.validate(emails);

          try {
            const schoolUsers = this.session.get('user.school.users');
            const validEmails = emailResult
              .filter((response) => response.result === 'valid')
              .map((response) => response.normalized_address);

            invalidEmails += emails.length - validEmails.length;

            const userType = singularize(this.args.userType);

            let usersToAdd = validEmails;

            // cant add existing teacher emails if adding students to school
            if (userType === 'student') {
              const existingTeacherEmails = schoolUsers
                .filter((x) => x.get('role') === 'teacher')
                .map((x) => x.get('username').toLowerCase());

              usersToAdd = validEmails.filter(
                (email) => !existingTeacherEmails.includes(email)
              );

              alreadyAddedTeacherEmails +=
                validEmails.length - usersToAdd.length;
            } else {
              const existingStudentEmails = schoolUsers
                .filter((x) => x.get('role') === 'student')
                .map((x) => x.get('username').toLowerCase());

              usersToAdd = validEmails.filter(
                (email) => !existingStudentEmails.includes(email)
              );

              alreadyAddedStudentEmails +=
                validEmails.length - usersToAdd.length;
            }

            // if adding students we also need to check the emails globally
            // for teachers, do this with this endpoint (/users/check-if-teachers)
            const promises = {
              student: resolve({ existingStudents: [] }),
              teacher: resolve({ existingTeachers: [] }),
            };

            if (userType === 'teacher') {
              // Check if students
              promises.student = this.ajax.request(
                `/api/users/check-if-students`,
                true,
                {
                  data: {
                    emails: usersToAdd,
                  },
                  method: 'post',
                }
              );
            } else {
              // Check if teachers
              promises.teacher = this.ajax.request(
                `/api/users/check-if-teachers`,
                true,
                {
                  data: {
                    emails: usersToAdd,
                  },
                  method: 'post',
                }
              );
            }

            const [{ existingTeachers }, { existingStudents }] =
              await Promise.all([promises.teacher, promises.student]);

            alreadyAddedTeacherEmails += existingTeachers.length;
            alreadyAddedStudentEmails += existingStudents.length;

            const validUsers = usersToAdd.filter(
              (x) => ![...existingTeachers, ...existingStudents].includes(x)
            );

            // if we have some valid users to add, check their old classes so we can update
            // their out of date relations
            if (validUsers.length > 0) {
              const existingUsers = schoolUsers.map((x) =>
                x.get('username').toLowerCase()
              );

              const validExistingUsers = validUsers.filter((x) =>
                existingUsers.includes(x)
              );

              if (validExistingUsers.length > 0) {
                const classes = this.args.classes.filter(
                  (x) => !x.get('metadata.classLessClass')
                );

                const oldClasses = validExistingUsers
                  .map((x) =>
                    classes.find((y) =>
                      y
                        .get('users')
                        .find((z) => z.get('username').toLowerCase() === x)
                    )
                  )
                  .filter((x) => !!x);

                const uniqOldClasses = [...new Set(oldClasses)];

                this.oldClasses = uniqOldClasses;
              }
            }

            this.users = validUsers;
            this.numberOfInvalidEmails = invalidEmails;
            this.numberOfAlreadyAddedTeacherEmails = alreadyAddedTeacherEmails;
            this.numberOfAlreadyAddedStudentEmails = alreadyAddedStudentEmails;
            this.findingEmails = false;
            this.checkedEmails = val;
          } catch (err) {
            this.addUsersFailed = true;
            this.findingEmails = false;
          }
        }
      },
      1500
    );
  }

  @action
  async addUsers() {
    if (!this.users) {
      return;
    }

    this.addingUsers = true;

    const group = this.selectedClass;
    const users = this.users.map((x) => {
      return { username: x, class: group ? group.get('id') : null };
    });
    const userType = singularize(this.args.userType);

    const oldClasses = this.oldClasses;
    const relationshipsToUpdate = oldClasses.concat(group ? [group] : []);

    await this.args.addUsers(users, userType, relationshipsToUpdate);

    trackEvent({
      category: 'Användare',
      action: `Bjud in ${ this.args.userType === 'teachers' ? 'lärare' : 'elev' } - Via epost - Slutför`,
      name: group ? 'Med klass' : 'Utan klass',
      value: users.length
    });

    this.users = null;
    this.emails = null;
    this.checkedEmails = null;
    this.addingUsers = false;

    this.args.close();
  }
}
