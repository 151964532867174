import { assert } from '@ember/debug';

export default function pagedArray({ content, page = 1, perPage = 1 }) {
  assert('content must be an array', Array.isArray(content));

  page = parseInt(page);
  perPage = parseInt(perPage);

  assert('Page must be an integer', !isNaN(page));
  assert('Per page must be an integer', !isNaN(perPage));

  const start = (page - 1) * perPage;
  const end = start + perPage;
  const items = content.slice(start, end);

  items.page = page;
  items.perPage = perPage;
  items.totalPages = Math.ceil(content.length / perPage);

  return items;
}
