import { tracked } from '@glimmer/tracking';

export default class Part {
  constructor(component) {
    if (component) {
      for (const [key, value] of Object.entries(component)) {
        this[key] = value;
      }
    }
  }

  @tracked mediaForced = false;
}
