import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class TeachersRoute extends Route {
  @service session;
  @service store;

  queryParams = {
    search: {
      refreshModel: false
    }
  };

  async model() {
    const school = await this.session.user.school;
    const schoolUsers = await school.users;

    return {
      users: schoolUsers,
      school,
    };
  }
}
