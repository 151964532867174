import Component from '@ember/component';

export default Component.extend({
  classNames: ['maintenance-message'],

  model: null,

  modalOpen: true,

  didInsertElement() {
    this._super(...arguments);

    const elem = this.get('element');

    if (elem) {
      document.documentElement.style.setProperty(
        '--top-message-height',
        elem.offsetHeight + 'px'
      );
    }
  },

  willDestroyElement() {
    this._super(...arguments);

    document.documentElement.style.setProperty('--top-message-height', '0px');
  }
});
