import Component from '@glimmer/component';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

const DEBOUNCE_TIME = 500;

export default class ProfileSettingsName extends Component{
  @service session;
  @service intl;

  debounceTimeout;

  @tracked errors = [];

  @tracked firstName = this.session.user.firstname;
  @tracked lastName = this.session.user.lastname;

  async saveUser() {
    if (this.firstName.length === 0) {
      this.errors = [this.intl.t('components.profileSettingsName.missingFirstName')];
      return;
    }

    this.session.user.firstname = this.firstName;
    this.session.user.lastname = this.lastName;

    await this.session.user.save();
    document.querySelector('nok-account')?.update({ firstname: this.firstName, lastname: this.lastName })
  }

  @action updatedUser() {
    clearTimeout(this.debounceTimeout);
    this.errors = [];
    this.debounceTimeout = setTimeout(() => this.saveUser(), DEBOUNCE_TIME);
  }
}
