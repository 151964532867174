import Component from '@ember/component';
import { computed, get } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import scrollParentElement from 'compton/utils/scroll-parent-element';
import { didCancel, task, timeout } from 'ember-concurrency';

function buildQueryParams(params) {
  const filter = {};

  if (params.stages.length > 0) {
    filter.stages = params.stages;
  }

  if (params.subjects.length > 0) {
    filter.subjects = params.subjects;
  }

  if (!params.extra) {
    filter.extramaterial = false;
  }

  if (isPresent(params.search)) {
    filter.title = { contains: params.search };
  }

  if (isPresent(params.teachers_only)) {
    filter.teachers_only = params.teachers_only;
  }

  if (params.trials) {
    filter.for_trial = true;
  }

  return {
    filter,
    page: { size: 20, number: params.page }
  };
}

export default Component.extend({
  // SETUP

  classNames: ['product-querier'],

  store: service(),

  session: service(),

  // PROPERTIES

  products: null,

  trials: false,

  hideExtramaterials: false,

  subjects: readOnly('products.meta.subjects'),

  stages: readOnly('products.meta.stages'),

  subjectsSelectItems: computed('subjects.@each', function() {
    return this.get('subjects')
      .sort()
      .map((subject) => {
        return {
          value: subject,
          label: subject
        };
      });
  }),

  stagesSelectItems: computed('stages.@each', function() {
    return this.get('stages')
      .sort()
      .map((stage) => {
        return {
          value: stage,
          label: stage
        };
      });
  }),

  hasQuery: computed('query', function() {
    const query = this.get('query');

    return (
      get(query, 'subjects.length') > 0 ||
      get(query, 'stages.length') > 0 ||
      isPresent(query.search) ||
      query.extra === true ||
      isPresent(query.teachers_only)
    );
  }),

  // TASKS

  query: async function (newParams = {}, options = {}) {
    if (options.debounce) {
      await timeout(500);
    }

    const products = await this.get('store').query(
      'product',
      buildQueryParams(this._mergeParams(newParams))
    );

    this.set('products', products);

    if (options.scrollToTop) {
      this._scrollToTop();
    }

    await products.getEach('image');
  },

  // HOOKS

  init() {
    this._super(...arguments);

    const params = {
      stages: [],
      subjects: [],
      extra: false,
      page: 1
    };

    if (!this.get('session.user.isTeacher')) {
      params.teachers_only = false;
    }

    if (this.get('trials')) {
      params.trials = true;
    }

    this.set('params', params);

    this._query();
  },

  // ACTIONS

  actions: {
    handleSubjectsSelectChange(subjects) {
      return this._query(
        { subjects, page: 1 },
        { debounce: true, scrollToTop: true }
      );
    },

    handleStagesSelectChange(stages) {
      return this._query(
        { stages, page: 1 },
        { debounce: true, scrollToTop: true }
      );
    },

    handleSearchFormSubmit(search) {
      return this._query({ search, page: 1 }, { scrollToTop: true });
    },

    handleSearchInputClear() {
      return this._query({ search: '', page: 1 }, { scrollToTop: true });
    },

    handlePaginationChange(page) {
      return this._query({ page }, { scrollToTop: true });
    },

    handleExtraCheckboxChange(extra) {
      return this._query({ extra, page: 1 }, { scrollToTop: true });
    }
  },

  // PRIVATE

  _query(params, options) {
    return this.query(params, options)
      .catch((error) => {
        // NOTE This is needed for ember concurrency's task cancelation.
        // http://ember-concurrency.com/docs/task-cancelation-help
        if (!didCancel(error)) {
          throw error;
        }
      });
  },

  _mergeParams(params) {
    return {
      ...this.get('params'),
      ...params
    };
  },

  _scrollToTop() {
    const parentScrollElement = scrollParentElement(this.get('element'));

    if (parentScrollElement) {
      parentScrollElement.scrollTo({ top: 0 });
    }
  }
});
