import { helper as buildHelper } from '@ember/component/helper';

export function indexOf(params /*, hash*/) {
  var value = params[0];
  var array = params[1];

  if (!array) {
    return -1;
  }

  return array.indexOf(value);
}

export default buildHelper(indexOf);
