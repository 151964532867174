import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';
import { camelize } from '@ember/string';

const SV_SE = 'sv_SE';
const EN_GB = 'en_GB';
const ES_ES = 'es_ES';
const DE_DE = 'de_DE';
const FR_FR = 'fr_FR';
const IT_IT = 'it_IT';

const LANGUAGES = [SV_SE, EN_GB, ES_ES, DE_DE, FR_FR, IT_IT];

export default class LanguageItems extends Helper {
  // SETUP

  @service intl;

  // HOOKS

  compute() {
    return LANGUAGES.map((language) => {
      return {
        value: language,
        label: this.intl.t(
          ['helpers', 'languageItems', camelize(language)].join('.')
        ),
      };
    });
  }
}
