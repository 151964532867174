import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class MasterProfileArchiveIndexController extends Controller {
  @service router;

  queryParams = [{ bookId: 'book' }, 'search', 'page'];

  @tracked bookId = null;

  @tracked search = '';

  @tracked page = 1;

  @tracked model;

  get query() {
    return {
      bookId: this.bookId,
      search: this.search,
      page: this.page,
    };
  }

  @action handleAdditionCollatorQuery(queryParams) {
    if (queryParams.bookId !== undefined) {
      queryParams.book = queryParams.bookId;
      delete queryParams.bookId;
    }

    return this.router.transitionTo({ queryParams });
  }
}
