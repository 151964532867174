import OIDCRESTAdapter from 'ember-simple-auth-oidc/adapters/oidc-rest-adapter';

export default class OrderAdapter extends OIDCRESTAdapter {
  namespace = 'api/orders';

  pathForType() {
    return 'Activation';
  }

  updateRecord(store, type, snapshot) {
    const url = this.urlForUpdateRecord(snapshot.id, 'order', snapshot);

    // The REST adapter is using PUT for updates, but orders are using PATCH
    return this.ajax(url, 'PATCH', { data: this.serialize(snapshot) });
  }
}
