import { isArray } from '@ember/array';

export default function(val1, val2) {
  if (typeof val1 === 'string') {
    return val1 === val2;
  }

  if (isArray(val1)) {
    return (
      isArray(val2) &&
      val1.length === val2.length &&
      val1.every((item, index) => item === val2[index])
    );
  }

  return false;
};
