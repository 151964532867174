import Component from '@ember/component';

export default Component.extend({
  classNames: ['user-list-user-table'],

  // EXTERNAL

  group: null,

  users: null,

  searchArr: null,

  selectableComponent: null,

  viewUser: null,

  txt: null,

  licenseWarningComponent: null,

  // HOOKS

  init() {
    this._super(...arguments);

    if (!this.get('txt')) {
      this.set('txt', {});
    }
  },

  // LIFECYCLE

  didInsertElement() {
    this._super(...arguments);

    if (this.get('selectableComponent')) {
      const titles = this.get('element').querySelectorAll('.js-user-title');

      titles.forEach((title) => {
        title.onclick = () => {
          const checkbox = title.parentNode.querySelectorAll(
            '.js-user-selectable input[type="checkbox"]'
          )[0];
          const event = new Event('change');

          checkbox.checked = !checkbox.checked;
          checkbox.dispatchEvent(event);
        };
      });
    }
  },

  actions: {
    showUser(id, role) {
      if (!this.viewUser) {
        return;
      }

      this.viewUser(id, role === 'student' ? 'general' : 'password');
    }
  },
});
