import Controller from '@ember/controller';
import { service } from '@ember/service';
import { action } from '@ember/object';

export default class OnboardingNameController extends Controller {
  @service router;
  @service session;

  image = '/assets/images/onboarding/name.svg';
  get canContinue() {
    return this.session.user?.firstname?.length > 0;
  }

  @action
  async onNext() {
    await this.session.user.save();

    return this.router.replaceWith(this.model.nextRoute);
  }

  @action
  async selectAvatar(avatar) {
    this.session.user.avatar = avatar?.value ?? null;
    this.session.user.avatar_img = null
  }
}
