class MissingContentError extends Error {
  /**
   * The constructor takes the same arguments as the standard JavaScript
   * Error but with an extra "redirectTarget" argument last.
   */

  constructor() {
    const args = Array.from(arguments);
    const redirectTarget = args.pop();

    super(...args);

    this.redirectTarget = redirectTarget;
  }

  redirectTarget;
}

export { MissingContentError };
