import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { load } from 'ember-async-data';
import generateId from 'compton/utils/generate-id';
import { resolve } from 'rsvp';

export default class SchoolSelectComponent extends Component {
  /**
   * Arguments:
   * showAdmin (bool)
   * inline (bool)
   * showUnderline (bool)
   */

  @service session;
  @service router;

  @tracked connectToSchoolModalOpen = false;
  @tracked popoverOpen = false;
  @tracked loadingNewSchool = null;

  get hasSchool() {
    return this.args.schools.length === 1;
  }

  get hasMultipleSchools() {
    return this.args.schools.length > 1;
  }

  get classNames() {
    const classes = ['school-select'];

    if (this.args.inline) {
      classes.push('flex', 'flex-center');
    }

    return classes.join(' ');
  }

  get schoolSelectNameClassNames() {
    const classes = ['school-select-name'];

    if (this.args.showUnderline) {
      classes.push('text-underline');
    }

    if (this.args.inline) {
      classes.push('text-small', 'text-bold');
    } else {
      classes.push('text-tertiary');
    }

    return classes.join(' ');
  }

  get multipleSchoolsButtonClassNames() {
    if (this.args.inline) {
      return 'flex-center flex text-color-inverted';
    } else {
      return 'flex flex-center';
    }
  }

  get adminLinkClassNames() {
    const classes = [];

    if (this.args.inline) {
      classes.push(
        'text-small',
        'text-color-inverted',
        'ml1',
        'school-select-admin'
      );
    } else {
      classes.push('mt1');
    }

    if (this.args.showUnderline) {
      classes.push('text-underline');
    }

    return classes.join(' ');
  }

  get popoverButtonId() {
    return generateId('popover-button');
  }

  get activeSchoolName() {
    return this.loadingNewSchool || this.args.active_school.name;
  }

  @action changeSchool(schoolId) {
    this.popoverOpen = false;
    
    const school = this.args.schools.find((school) => school.id === schoolId);
    
    if (school) {
      // just so we display the correct name while page is being reloaded
      this.loadingNewSchool = school.name

      const user = this.session.user;

      user.school = school;
      user.active_school = schoolId;

      return user
        .save()
        .then(() => window.location.replace('/'));
    }
  }

  @action openPopover() {
    this.popoverOpen = true;
  }

  @action closePopover() {
    this.popoverOpen = false;
  }

  @action openConnectToSchoolModal() {
    if (this.session.user.role === 'teacher') {
      this.router.transitionTo('master.index.anslut');
    } else {
      this.router.transitionTo('master.index.skolkod');
    }
  }
}
