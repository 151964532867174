import Component from '@ember/component';
import { computed } from '@ember/object';
import { alias, oneWay } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import pagedArray from 'babel-app/utils/paged-array';
import scrollParentElement from 'compton/utils/scroll-parent-element';

export default Component.extend({
  // SETUP

  classNames: ['product-collator'],

  store: service(),

  intl: service(),

  // PARAMS

  licenses: null,

  query: null,

  scrollToTopOnQuery: false,

  onQuery() {},

  // PROPERTIES

  subjectSelectItems: computed('intl.locale', 'subjects.@each', function() {
    const items = [];

    this.get('subjects')
      .sort()
      .forEach((subject) => {
        items.addObject({
          value: subject,
          label: subject
        });
      });

    return items;
  }),

  stageSelectItems: computed('intl.locale', 'stages.@each', function() {
    const items = [];

    this.get('stages')
      .sort()
      .map((stage) => {
        items.addObject({
          value: stage,
          label: stage
        });
      });

    return items;
  }),

  perPage: 30,

  subject: oneWay('query.subject'),

  stage: oneWay('query.stage'),

  search: oneWay('query.search'),

  page: oneWay('query.page'),

  extra: oneWay('query.extra'),

  // HOOKS

  showExtraCheckbox: computed('products.@each.isExtraMaterial', function () {
    return this.get('products').filter((product) => product.get('isExtraMaterial')).length > 0;
  }),

  hasQuery: computed('query', function() {
    const query = this.get('query');

    return (
      query.subject.get('length') > 0 ||
      query.stage.get('length') > 0 ||
      isPresent(query.search) ||
      isPresent(query.extra)
    );
  }),

  filtredProducts: computed(
    'products.@each.{subjects,stages,title}',
    'query',
    function() {
      const { products, query } = this.getProperties('products', 'query');

      let filtredProducts = products;

      if (!query.extra) {
        filtredProducts = filtredProducts.filter((product) => !product.get('isExtraMaterial'));
      }

      if (isPresent(query.subject)) {
        filtredProducts = filtredProducts.filter((product) => {
          const subjects = product.get('subjects');

          return subjects && query.subject.any((x) => subjects.includes(x));
        });
      }

      if (isPresent(query.stage)) {
        filtredProducts = filtredProducts.filter((product) => {
          const stages = product.get('stages');

          return stages && query.stage.any((x) => stages.includes(x));
        });
      }

      if (isPresent(query.search)) {
        filtredProducts = filtredProducts.filter((product) => {
          // TODO Compare more attributes?

          const title = product.get('title');

          const search = query.search.toLowerCase();

          let result = false;

          if (title) {
            result = title.toLowerCase().includes(search);
          }

          return result;
        });
      }

      return filtredProducts.sortBy('title');
    }
  ),

  pagedProducts: computed(
    'filtredProducts.[]',
    'query.page',
    'perPage',
    function () {
      return pagedArray({
        content: this.filtredProducts,
        page: this.query?.page,
        perPage: this.perPage,
      });
    }
  ),

  stages: computed('products.@each.stages', function() {
    return this.get('products')
      .compact()
      .mapBy('stages')
      .flat()
      .compact()
      .uniq();
  }),

  subjects: computed('products.@each.subjects', function() {
    return this.get('products')
      .compact()
      .mapBy('subjects')
      .flat()
      .compact()
      .uniq();
  }),

  // ACTIONS

  actions: {
    handleSubjectSelectChange(subject) {
      this._query({ subject, page: 1 });
    },

    handleStageSelectChange(stage) {
      this._query({ stage, page: 1 });
    },

    handleExtraCheckboxChange(extra) {
      this._query({ extra, page: 1 });
    },

    handleSearchFormSubmit(search) {
      return this._query({ search, page: 1 });
    },

    handleSearchInputClear() {
      return this._query({ search: '', page: 1 });
    },

    handlePaginationChange(page) {
      this._query({ page });
    }
  },

  // PRIVATE

  _query(query) {
    if (this.get('scrollToTopOnQuery')) {
      const parentScrollElement = scrollParentElement(this.get('element'));

      if (parentScrollElement) {
        parentScrollElement.scrollTo({ top: 0 });
      }
    }
    this.get('onQuery')({ ...this.get('query'), ...query });
  }
});
