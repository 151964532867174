import { A } from '@ember/array';
import Component from '@ember/component';
import { computed } from '@ember/object';
import { alias, mapBy, readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import integrations from 'babel-app/rules/integrations';
import ConfirmSupport from 'compton/mixins/confirm-support';
import { flatten } from 'compton/utils/array';
import DS from 'ember-data';
import { all } from 'rsvp';

const { PromiseArray } = DS;

function createPromiseArray(promise) {
  return PromiseArray.create({ promise });
}

export default Component.extend(ConfirmSupport, {
  // SERVICES

  session: service(),

  store: service(),

  intl: service(),

  // PROPERTIES

  courseName: null,

  selectedGroups: null,

  classes: null,

  userGroups: null,

  txt: null,

  modalOpen: false,

  groupSearch: null,

  usersMissingLicenses: null,

  availableProducts: null,

  products: null,

  course: null,

  openLicenseModal() {},

  saveCourse() {},

  assignLicenses() {},

  // COMPUTED

  school: readOnly('session.user.school'),

  externalUsers: alias('session.user.school.metadata.external_users'),

  serviceProvider: computed('session.user.school.provider', function () {
    const provider = this.get('session.user.school.provider');

    if (provider && integrations[provider]) {
      return integrations[provider];
    }

    return null;
  }),

  filteredUserGroups: computed('userGroups.[]', 'groupSearch', function () {
    const userGroups = this.get('userGroups');
    const groupSearch = this.get('groupSearch');

    if (groupSearch) {
      return userGroups.filter(
        (x) =>
          (x.get('name') || '')
            .toLowerCase()
            .includes(this.get('groupSearch').toLowerCase())
      );
    }

    return userGroups;
  }),

  selectedClasses: computed('selectedGroups.[]', {
    get() {
      return this.get('selectedGroups').filter(
        (x) => x.get('type') === 'class' || x.get('type') === 'user_group'
      );
    },
    set(key, value) {
      const courseGroup = this.get('courseGroup');

      if (courseGroup) {
        this.set('selectedGroups', value.concat(courseGroup));
      } else {
        this.set('selectedGroups', value);
      }

      return value;
    },
  }),

  courseGroup: computed('selectedGroups.[]', {
    get() {
      const groups = this.get('selectedGroups').filter(
        (x) => x.get('type') === 'group'
      );

      if (groups.length > 0) {
        return groups[0];
      }

      return null;
    },
    set(key, value) {
      let selectedGroups = this.get('selectedClasses');

      if (value) {
        selectedGroups = selectedGroups.concat(value);
      }

      this.set('selectedGroups', selectedGroups);

      return value;
    },
  }),

  showCourseGroup: computed('courseGroup.isDeleted', function () {
    const courseGroup = this.get('courseGroup');
    return courseGroup && !courseGroup.get('isDeleted');
  }),

  selectedStudents: computed('courseGroup.students.[]', {
    get() {
      const courseGroup = this.get('courseGroup');

      if (courseGroup) {
        return courseGroup.get('users');
      }

      return [];
    },
    set(key, value) {
      const courseGroup = this.get('courseGroup');

      if (courseGroup && !courseGroup.get('isDeleted')) {
        if (value.length > 0) {
          courseGroup.set('users', value);
          courseGroup.set('num_students', value.length);
        } else {
          courseGroup.deleteRecord();
        }
      } else if (value.length > 0) {
        const group = this.get('store').createRecord('group', {
          name: this.get('courseName'),
          school: this.get('session.user.school'),
          type: 'group',
          users: value,
          num_students: value.length,
        });

        this.set('courseGroup', group);
      }

      return value;
    },
  }),

  selectedGroupUsers: mapBy('selectedGroups', 'users'),

  students: computed('selectedGroupUsers.@each.role', function () {
    const promise = all(this.get('selectedGroupUsers')).then((groupUsers) =>
      flatten(groupUsers.invoke('toArray')).uniq().filterBy('role', 'student')
    );

    return createPromiseArray(promise);
  }),

  numStudents: readOnly('students.length'),

  hasOnlyInternalProducts: computed('products.@each.isInternal', function () {
    return (this.get('products') || A()).every((product) => {
      return product.get('isInternal');
    });
  }),

  hasSelectedProducts: computed('products.@each.length', function () {
    const products = this.get('products');

    return products && products.length > 0;
  }),

  // RUNLOOP

  init() {
    this._super(...arguments);

    if (!this.get('txt')) {
      this.set('txt', {
        groupSelectHeading: 'courses.groups',
        groupSelectAllOption: 'courses.allGroups',
        noUsersMessage: 'courses.noStudents',
        groupNumUsers: 'courses.groupNumUsers',
        groupNoUsers: 'courses.groupNoUsers',
        userNameHeading: 'courses.name',
        userEmailHeading: 'courses.eMail',
        usersLabel: 'courses.numStudents',
      });
    }

    if (!this.get('selectedGroups')) {
      this.set('selectedGroups', []);
    }

    if (!this.get('classes')) {
      this.set('classes', []);
    }

    if (!this.get('userGroups')) {
      this.set('userGroups', []);
    }
  },

  // ACTIONS

  actions: {
    openModal() {
      this.set('modalSelectedStudents', this.get('selectedStudents').slice());
      this.set('modalSelectedClasses', this.get('selectedClasses').slice());

      this.set('modalOpen', true);
    },

    closeModal() {
      this.set('modalOpen', false);
    },

    removeUser(user) {
      return this.confirm(this.get('intl').t('courses.removeStudentConfirm'), {
        danger: true,
      }).then(() => {
        return this.get('courseGroup.users').then((selectedStudents) => {
          selectedStudents.removeObject(user);

          this.set('selectedStudents', selectedStudents);

          return this.saveCourse({ groupsChanged: true });
        });
      });
    },

    removeGroup(group) {
      return this.confirm(this.get('intl').t('courses.removeGroupConfirm'), {
        danger: true,
      }).then(() => {
        this.get('selectedGroups').removeObject(group);

        return this.saveCourse({ groupsChanged: true });
      });
    },

    async saveStudents() {
      this.set('selectedStudents', this.modalSelectedStudents.slice())
      this.set('selectedClasses', this.modalSelectedClasses.slice())
      await this.saveCourse({ groupsChanged: true })
      this.send('closeModal')
    },
  },
});
