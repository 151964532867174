import Helper from '@ember/component/helper';
import { get } from '@ember/object';
import { inject as service } from '@ember/service';
import { camelize } from '@ember/string';

const BLANK = 'blank';
const IMAGE = 'image';
const VIDEO = 'video';
const AUDIO = 'audio';
const DOCUMENT = 'document';

const TYPE = [IMAGE, VIDEO, AUDIO, DOCUMENT];

export default class FileTypeItems extends Helper {
  // SETUP

  @service intl;

  // HOOKS

  compute([], { includeBlank = false }) {
    const intl = this.intl;

    const path = 'helpers.fileTypeItems';

    const typeOptions = TYPE.map((type) => {
      return {
        value: type,
        label: intl.t([path, camelize(type)].join('.')),
      };
    });

    if (includeBlank) {
      typeOptions.unshiftObject({
        value: null,
        label: intl.t([path, BLANK].join('.')),
      });
    }

    return typeOptions;
  }
}
