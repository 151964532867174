import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class PageContentMenu extends Component {
  classNames = ['page-nav'];

  @service session;

  @tracked navOpen = false;

  @action openNav() {
    this.navOpen = true;
  }

  @action closeNav() {
    this.navOpen = false;
  }
}
