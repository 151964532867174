import Component from '@ember/component';
import ConfirmSupport from 'compton/mixins/confirm-support';

export default Component.extend(ConfirmSupport, {
  // EXTERNAL PROPERTIES

  roleName: null,

  provider: null,
});
