import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { trackEvent } from '../../../../utils/matomo-events';

export default class AvailableLicensesModalHeader extends Component {
  get availableProduct() {
    return this.args.availableProduct;
  }

  get externalLicenses() {
    return this.args.externalLicenses;
  }

  get school() {
    return this.args.school;
  }

  get numberOfLicenses() {
    return this.args.availableProduct?.total ?? 0;
  }

  get assignableLicenses() {
    const unassigned = this.availableProduct?.unassigned ?? 0;
    const recyclable = this.availableProduct?.recycled ?? 0;

    return unassigned + recyclable;
  }

  @tracked isLoading = true;
  @tracked hasAgreement = false;
  @tracked hasDemoAgreement = false;
  @tracked currentAgreement = null;
  @tracked agreementEndDate = false;
  @tracked licenseInfoModalOpen = false;

  constructor() {
    super(...arguments);

    if (this.args.agreement) {
      this.args.agreement.then((agreement) => {
        const currentLicenseAgreement = agreement
          ?.filter((curr) => !curr.is_demo)
          .sort((x, y) => (x.active_until < y.active_until ? 1 : -1))
          .get('firstObject');

        const currentDemoAgreement = agreement
          ?.filter((curr) => curr.is_demo)
          .sort((x, y) => (x.active_until < y.active_until ? 1 : -1))
          .get('firstObject');

        this.hasAgreement = !!currentLicenseAgreement;
        this.agreementEndDate = currentLicenseAgreement?.active_until;
        this.hasDemoAgreement = !!currentDemoAgreement;
        this.currentAgreement = currentLicenseAgreement;

        this.isLoading = false;
      });
    } else {
      this.isLoading = false;
    }
  }

  onClose = () => { };

  @action close() {
    if (this.onClose) {
      this.onClose();
    }
  }

  @action openLicenseInfoModal() {
    this.licenseInfoModalOpen = true;
    trackEvent({ category: 'Hantera licenser', action: 'Hantera licenser - Klicka på info-i' });
  }
}
