import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
  // INTERNAL

  tagName: '',

  // EXTERNAL

  user: null,

  group: null,

  selectedGroups: null,

  selectedUsers: null,

  changed: null,

  // COMPUTED

  isSelected: computed('selectedUsers.[]', 'user', {
    get() {
      const selectedUsers = this.get('selectedUsers');
      const user = this.get('user');

      if (selectedUsers && user) {
        if (selectedUsers.indexOf(user) !== -1) {
          return true;
        }
      }

      return false;
    },
    set(key, value) {
      const selectedUsers = this.get('selectedUsers');
      const user = this.get('user');

      if (user) {
        if (selectedUsers.indexOf(user) === -1) {
          selectedUsers.addObject(user);
        } else {
          selectedUsers.removeObject(user);
        }
      }

      this.get('changed')(selectedUsers);

      return value;
    }
  })
});
