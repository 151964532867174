import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class AnslutRoute extends Route {
  @service session;
  @service router;
  @service schoolApplication;

  async beforeModel() {
    const user = this.session.user;

    if (user?.role === 'student') {
      this.router.transitionTo('master.index.skolkod');
    }
  }

  async model() {
    if (!this.session.user) {
      return {};
    }

    const username = this.session.user.username;
    const blockedDomain = this.schoolApplication.getBlockedDomain(username);

    return {
      username,
      emailBlocked: blockedDomain,
    };
  }
}
