import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import config from '../../../../config/environment';

export default class SchoolCodeModal extends Component {
  @service ajax;
  @service session;

  @tracked page = 0;

  @tracked prevCode;

  @tracked usersAndCodes = [];

  codeUsage = {};

  hasMore = false;

  @tracked isLoading = false;

  constructor() {
    super(...arguments);
    this.showMore();
  }

  @action async showMore() {
    this.isLoading = true;

    const { school } = this.session.user;

    try {
      const { users, hasMore } = await this.ajax.request(
        `/api/schools/${school.get('id')}/code_history/${this.args.codeType}/${this.page + 1}/50`,
        true,
        { type: 'GET' }
      );

      this.page += 1;

      users.forEach((user) => {
        (this.codeUsage[user.code] ??= {
          code: user.code,
          users: [],
          uses: this.args.codes.find(({ code }) => code == user.code)?.uses,
        }).users.push(user);
      });

      this.usersAndCodes = Object.values(this.codeUsage);
      this.hasMore = hasMore;
    } catch (errors) {
      console.log(errors);
    } finally {
      this.isLoading = false;
    }
  }
}
