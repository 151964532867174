import { A, isArray } from '@ember/array';
import { camelizeKeys, underscoreKeys } from 'compton/utils/object';
import DS from 'ember-data';

import emberObjectToNativeObject from '../utils/ember-object-to-native-object';
import nativeObjectToEmberObject from '../utils/native-object-to-ember-object';

const { Transform } = DS;

export default Transform.extend({
  deserialize(serialized) {
    if (isArray(serialized)) {
      return serialized.map((x) => nativeObjectToEmberObject(camelizeKeys(x)));
    } else {
      return A();
    }
  },

  serialize(deserialized) {
    if (isArray(deserialized)) {
      return deserialized.map((x) =>
        underscoreKeys(emberObjectToNativeObject(x))
      );
    } else {
      return A();
    }
  }
});
