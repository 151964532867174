import Service from '@ember/service';
import { inject as service } from '@ember/service';

import config from '../config/environment';

export default Service.extend({
  ajax: service(),

  session: service(),

  logPageView(model, book, parent, metadata) {
    const data = {};

    data.user_id = this.get('session.user.id');

    if (model && model.get('id')) {
      data.entity_id = model.get('id');
      data.title = model.get('title');
      data.type = model.get('type');
    }

    if (book && book.get('id')) {
      data.book_id = book.get('id');
      data.book_title = book.get('title');
    }

    if (parent && parent.get('id')) {
      data.parent_id = parent.get('id');
      data.parent_title = parent.get('title');
    }

    if (metadata) {
      data.metadata = metadata;
    }

    this.get('ajax').request(
      config.endpoint + '/api/v1/meta/page-views/',
      true,
      {
        type: 'POST',
        data: data
      }
    );
  }
});
