import Service, { inject as service } from '@ember/service';
import { isPlainObject } from 'ember-fetch/types';
import { serializeQueryParams } from 'ember-fetch/utils/serialize-query-params';
import fetch from 'fetch';
import { reject } from 'rsvp';

export default class FetchService extends Service {
  @service session;

  async request(url = null, auth = false, options = {}) {
    if (!url) return;

    if (auth) {
      // When authentication is needed we get the necessary headers from the session service.
      await this.session.refreshAuthentication.perform();

      if (!options.headers) options.headers = {};

      for (const [key, value] of Object.entries(this.session.headers)) {
        options.headers[key] = value;
      }
    }

    if (options.type) {
      // Type === method for backwards compatibility with the ajax service
      options.method = options.type;
      delete options.type;
    }

    if (options.data) {
      // GET and HEAD requests should pass data in query string. Other requests should
      // send it in the request body.
      if (options.method === 'GET' || options.method === 'HEAD') {
        if (Object.keys(options.data).length) {
          const queryParamDelimiter = url.indexOf('?') > -1 ? '&' : '?';
          url += `${queryParamDelimiter}${serializeQueryParams(options.data)}`;
        }
      } else {
        if (isPlainObject(options.data)) {
          if (!options.headers) options.headers = {};
          options.headers['Content-Type'] =
            'application/x-www-form-urlencoded; charset=UTF-8';
          options.body = serializeQueryParams(options.data);
        } else {
          options.body = options.data;
        }
      }

      delete options.data;
    }

    let response, data;

    try {
      response = await fetch(url, options);

      if (response.headers.map['content-type'].startsWith('application/json')) {
        data = await response.json();
      } else {
        data = await response.text();
      }
    } catch (err) {
      return reject(err);
    }

    if (!response.ok) {
      return reject(data?.errors?.[0]);
    }

    return data;
  }
}
