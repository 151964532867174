import { attr, belongsTo, hasMany } from '@ember-data/model';

import Model from './model';

export default class extends Model {
  @attr('string') type;

  @attr('only-date') active_from;

  @attr('only-date') active_until;

  @attr('date') created;

  @attr('date') updated;

  @attr('boolean') is_demo;

  @attr() metadata;

  // RELATIONSHIPS

  @hasMany('license', { inverse: null }) licenses;

  @belongsTo('school') school;

  @belongsTo('product') product;
}
