import { helper as buildHelper } from '@ember/component/helper';
import { htmlSafe } from '@ember/template';

import library from '../utils/library';

export function format(params) {
  /*
    FORMATTER Helper
    Wraps the text in tags after appearance
  */

  var text = params[0].text;

  if (params[0].appearances) {
    for (var i = 0; i < params[0].appearances.length; i++) {
      if (params[0].appearances) {
        var tag = library.styleToTag(params[0].appearances[i]);
        if (tag) {
          text = '<' + tag + '>' + text + '</' + tag + '>';
        }
      }
    }
  }
  return htmlSafe(text);
}

export default buildHelper(format);
