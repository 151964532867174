import { action } from '@ember/object';
import Component from '@glimmer/component';
import { trackEvent } from '../../../../utils/matomo-events';

export default class extends Component {
  get assignedLicenses() {
    return (
      this.args.availableProduct.total -
      this.args.availableProduct.unassigned -
      this.args.availableProduct.recycled
    );
  }

  get agreement() {
    return this.args.availableProduct?.agreement;
  }

  @action close() {
    if (this.args.onClose) {
      this.args.onClose();
    }
  }

  @action readMore() {
    trackEvent({ category: 'Hantera licenser', action: 'Hantera licenser - Klick på info-i - Läs mer om licenser på vår supportsida' });
  }
}
