import Service from '@ember/service';

export default class EmailValidatorService extends Service {
  validate(emails) {
    if (!emails?.length) {
      return [];
    }

    const userRegexp = new RegExp(/^[a-zA-Z0-9+_~.-]+$/);
    const hostRegexp = new RegExp(/^([a-zA-Z0-9-]+\.)+[a-zA-Z]+$/);
    const dotsRegexp = new RegExp(/(^[.])|([.]{2})|([.]$)/);

    return emails.map(({ address }) => {
      const segments = address?.split('@') || [];
      let isValid = false;

      if (segments.length === 2) {
        isValid = !dotsRegexp.test(segments[0]) && userRegexp.test(segments[0]) && hostRegexp.test(segments[1]);
      }

      return {
        address: address,
        normalized_address: address?.toLowerCase(),
        disposable: false,
        result: isValid ? 'valid' : 'invalid',
        result_detail: isValid
          ? 'The email address is valid.'
          : 'The email format is invalid.',
      };
    });
  }
}