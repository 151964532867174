import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import QRCode from 'qrcode';
import { trackEvent } from '../../../../utils/matomo-events';

export default class AdminStudentsInviteController extends Controller {
  @service store;
  @service router;

  @action
  async generateCode() {
    if (this.model.codes?.some((code) => code.active)) {
      trackEvent({
        category: 'Användare',
        action: 'Bjud in elev - Med länk eller skolkod - Förnya'
      });
    } else {
      trackEvent({
        category: 'Användare',
        action: 'Bjud in elev - Med länk eller skolkod - Aktivera'
      });
    }

    this.isMenuOpen = false;
    await this.store.createRecord('school-access-code', {
      role: 'student',
      school_id: this.model.school.id,
    })
      .save();

    await this.model.codes.update();
  }

  @action
  async disableCode(accessCode) {
    if (!accessCode.active) {
      return;
    }

    trackEvent({
      category: 'Användare',
      action: 'Bjud in elev - Med länk eller skolkod - Inaktivera'
    });

    accessCode.active = false;
    await accessCode.save();

    await this.model.codes.update();
  }

  @action
  async close() {
    if (!this.model.codes?.some((code) => code.active)) {
      trackEvent({
        category: 'Användare',
        action: 'Bjud in elev - Med länk eller skolkod - Stäng utan att aktivera'
      });
    }

    this.router.transitionTo('master.admin.students');
  }

  @action
  async copyToClipboard() {
    trackEvent({
      category: 'Användare',
      action: 'Bjud in elev - Med länk eller skolkod - Kopiera länk'
    });

    await navigator.clipboard.writeText(this.inviteLink);
    this.snackbar.enqueue(this.intl.t('components.admin.schoolCode.linkCopied'), {
      variant: 'success',
      autoDismiss: true,
    })
  }

  @action
  async createQrCode() {
    trackEvent({
      category: 'Användare',
      action: 'Bjud in elev - Med länk eller skolkod - Visa qr-kod'
    });

    this.qrCode = await QRCode.toDataURL(this.inviteLink, { scale: 50 });
  }

  @action showHistoryModal() {
    trackEvent({
      category: 'Användare',
      action: 'Bjud in elev - Med länk eller skolkod - Se historik'
    });

    this.historyModalOpen = true;
  }
}
