import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import config from 'babel-app/config/environment';
import appendQueryParams from 'babel-app/utils/append-query-params';
import ConfirmSupport from 'compton/mixins/confirm-support';
import moment from 'moment';
import { resolve } from 'rsvp';

export default Component.extend(ConfirmSupport, {
  router: service(),

  session: service(),

  intl: service(),

  tagName: '',

  course: null,

  index: null,

  searchValue: null,

  onClick() { },

  startDate: computed('course.isComing', function () {
    if (this.get('course.isComing')) {
      return moment(this.get('course.start_date')).format('YYYY-MM-DD');
    } else {
      return null;
    }
  }),

  images: computed('course.products', function () {
    const products = this.get('course.products');

    return products
      .map((product) => product.get('cover.imageURL'))
      .filter((x) => x);
  }),

  hasOptions: computed(
    'course.{hasExternalProducts,products.length}',
    function () {
      const course = this.get('course');

      if (
        course &&
        course.get('hasExternalProducts') &&
        course.get('products.length') > 1
      ) {
        return true;
      }

      return false;
    }
  ),

  externalTarget: computed(
    'course.hasExternalProducts',
    'hasOptions',
    function () {
      if (this.get('course.hasExternalProducts') && !this.get('hasOptions')) {
        return true;
      }

      return false;
    }
  ),

  actions: {
    handleClick() {
      return this.send(
        'openProduct',
        this.get('course.products.firstObject')
      );
    },

    openCourseSettings() {
      return this.get('router').transitionTo(
        'master.course-settings',
        this.get('course.id')
      );
    },

    archiveCourse() {
      return this.confirm(this.get('intl').t('courses.archiveConfirm'), {
        danger: true,
        okLabel: this.get('intl').t('courses.archiveConfirmAction')
      }).then(() => {
        const course = this.get('course');

        course.set('active', false);

        return course.save();
      });
    },

    openProduct(product) {
      return resolve(product?.books).then((books) => {
        if (
          !product?.hasCoursePage
          && books?.length === 1
          && books?.firstObject?.type === 'books_external'
        ) {
          let target = product && books?.firstObject?.body?.external_url;

          if (target) {
            if (this.course?.id) {
              target = appendQueryParams(target, {
                activeCourse: this.course.id,
                statisticsIsbn: product.id,
              })
            }

            window.location = target;
          }
        } else {
          window.location = `${config.diginokUrl}/courses/${this.course.id}`;
        }
      });
    }
  }
});
