import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { storageFor } from 'ember-local-storage';
import moment from 'moment';

export default class ApplicationController extends Controller {
  // SETUP

  constructor() {
    super(...arguments);

    this.languageChanged();
  }

  @service intl;

  @service session;

  @storageFor('read-setting') settings;

  // PROPERTIES

  @tracked provider;

  @tracked idp;

  @tracked isLoading = false;

  @tracked error;

  // ACTIONS

  @action languageChanged() {
    const currentLocale = (this.intl.locale || [])[0];
    const userLocale = this.session.user?.language;
    const locale = userLocale || 'sv';

    if (locale !== currentLocale) {
      this.intl.locale = locale;
      moment.locale(locale);
    }
  }
}
