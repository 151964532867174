import { computed } from '@ember/object';
import { notEmpty, readOnly, alias } from '@ember/object/computed';
import DS from 'ember-data';
import moment from 'moment';

import Model from './model';

const { attr, belongsTo, hasMany } = DS;

export default Model.extend({
  // RELATIONSHIPS

  user: belongsTo('user'),

  product: belongsTo('product'),

  school: belongsTo('school'),

  agreement: belongsTo('agreement'),

  // ATTRIBUTES

  isbn: attr('string'),

  type: attr('string'),

  agreed_num_users: attr('number'),

  active_from: attr('only-date'),

  active_until: attr('only-date'),

  subscription_id: attr('string'),

  subscription_renewal_date: attr('only-date'),

  is_demo: attr('boolean'),

  unlimited: attr('boolean'),

  is_expired: attr('boolean'),

  extended: attr('boolean'),

  demo_used: attr('boolean'),

  provider: attr('string'),

  days_left: attr('number'),

  is_new: attr('boolean', {
    defaultValue() {
      return false;
    },
  }),

  // PROPERTIES

  is_external: computed('provider', function() {
    return this.get('provider') ? true : false;
  }),

  is_private: computed('school', function() {
    return this.belongsTo('school').id() ? false : true;
  }),

  activeFrom: readOnly('active_from'),

  activeUntil: readOnly('active_until'),

  isDemo: readOnly('is_demo'),

  agreedNumberOfUsers: readOnly('agreed_num_users'),

  numberOfUsesLeft: computed(
    'unlimited',
    'agreedNumberOfUsers',
    'userIds.length',
    function() {
      if (this.get('unlimited')) {
        return Infinity;
      }

      return Math.max(
        this.get('agreedNumberOfUsers') - this.get('userIds.length'),
        0
      );
    }
  ),

  // NOTE The "type" attribute is not reliable as it returns a empty string
  // when it's not "shared".
  kind: computed('type', function() {
    if (this.get('type') === 'shared') {
      return 'shared';
    }

    return 'personal';
  }),

  is_active: computed('activeFrom', 'activeUntil', function() {
    return moment().isBetween(
      this.get('activeFrom'),
      this.get('activeUntil'),
      'day',
      '[]'
    );
  }),

  isActive: readOnly('is_active'),

  isExpired: readOnly('is_expired'),

  isExhausted: computed('unlimited', 'numberOfUsesLeft', function() {
    if (this.get('unlimited')) {
      return false;
    }

    return this.get('numberOfUsesLeft') === 0;
  }),

  daysLeft: alias('days_left'),

  userIds: computed('users.@each', function() {
    return this.hasMany('users').ids();
  }),

  hasUsers: notEmpty('userIds'),

  hasUser: notEmpty('user.id'),

  subscriptionIsActive: computed(
    'subscription_id',
    'subscription_renewal_date',
    'isExpired',
    function () {
      const subscriptionId = this.get('subscription_id');
      const subscriptionRenewalDate = this.get('subscription_renewal_date');
      const isExpired = this.get('isExpired');

      return (
        !isExpired &&
        subscriptionId &&
        subscriptionRenewalDate &&
        moment(subscriptionRenewalDate, 'day').isSameOrAfter(moment(), 'day')
      );
    }
  ),

  subscriptionIsEnded: computed(
    'subscription_id',
    'subscription_renewal_date',
    'isExpired',
    function () {
      const subscriptionId = this.get('subscription_id');
      const subscriptionRenewalDate = this.get('subscription_renewal_date');
      const isExpired = this.get('isExpired');

      return !isExpired && subscriptionId && !subscriptionRenewalDate;
    }
  ),

  subscriptionHasPendingPayment: computed(
    'subscription_id',
    'subscription_renewal_date',
    'isExpired',
    function () {
      const subscriptionId = this.get('subscription_id');
      const subscriptionRenewalDate = this.get('subscription_renewal_date');
      const isExpired = this.get('isExpired');

      return (
        !isExpired &&
        subscriptionId &&
        subscriptionRenewalDate &&
        moment(subscriptionRenewalDate, 'day').isBefore(moment(), 'day')
      );
    }
  ),

  shortDate: computed('active_until', function() {
    const oneMonthFromNow = new Date();
    oneMonthFromNow.setMonth(oneMonthFromNow.getMonth() + 1);

    return this.active_until < oneMonthFromNow;
  }),

  isNew: computed('active_from', function () {
    const licenseDate = new Date(this.active_from);
    const currentDate = new Date();
    
    return licenseDate?.setHours(0, 0, 0, 0) === currentDate.setHours(0, 0, 0, 0);
  }),
});
