import { A } from '@ember/array';
import Component from '@ember/component';
import { computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import config from 'babel-app/config/environment';
import moment from 'moment';
import { trackEvent } from '../../../../utils/matomo-events';

export default Component.extend({
  session: service(),
  ajax: service(),
  intl: service(),
  confirmManager: service(),
  snackbar: service(),

  license: null,
  licenseModalOpen: null,
  product: alias('license.product'),

  expiredSubscription: computed(
    'license.{is_expired,subscription_id}',
    function () {
    const licenseExpired = this.get('license.is_expired');
    const subscriptionId = this.get('license.subscription_id');

    return licenseExpired && subscriptionId;
  }),

  availableProducts: alias('session.user.school.available_products.[]'),

  renewLicense: computed(
    'availableProducts.@each.{has_agreement}',
    'session.user.{isTeacher,isStudent}',
    'product.id',
    function() {
      const user = this.get('session.user');
      const isbn = this.get('product.id');
      const availableProducts = this.get('availableProducts');

      let product;

      if (!availableProducts) return product;

      if (user.get('isStudent')) {
        product = availableProducts.find(
          (product) =>
            product.get('isbn') === isbn && product.get('has_agreement')
        );
      } else if (user.get('isTeacher')) {
        product = availableProducts.find(
          (product) =>
            product.get('isbn') === isbn &&
            (product.get('has_agreement') || product.get('unassigned') > 0)
        );
      }

      return product;
    }
  ),

  hasBuyLink: computed('license.{is_active,is_demo}', function() {
    const license = this.get('license');

    if (!license) return false;
    if (license.get('is_active')) return false;

    return true;
  }),

  hasRenew: computed('hasBuyLink', 'renewLicense.[]', function() {
    if (!this.get('hasBuyLink')) return false;

    if (!this.get('renewLicense')) return false;

    return true;
  }),

  actions: {
    async removeFromHomeScreen() {
      if (this.license.isDemo && !this.license.isExpired) {
        await this.confirmManager
          .confirm(this.intl.t('overview.confirmRemoveTrial'), {
            danger: true,
            okLabel: this.intl.t('overview.removeFromHomescreen'),
          })
      }

      const shouldShowRecycleInformation = !this.license.isDemo && !this.license.isExpired && !this.product.get('isExtraMaterial');

      await this.license.destroyRecord()

      trackEvent({ category: 'Produkt', action: 'Startsida - Ta bort' });

      if (shouldShowRecycleInformation) {
        this.snackbar.enqueue(
          this.intl.t('overview.removeFromHomescreenSuccess'),
          { variant: 'success', autoDismiss: true, autoDismissDelay: 5000 }
        )
      }
    },

    renewLicense() {
      const user = this.get('session.user');

      if (!user) return;

      const userId = user.get('id');
      const school = user.get('school');

      if (!school) return;

      const schoolId = school.get('id');
      const isbn = this.get('product.id');
      const availableProduct = (this.get('availableProducts') || A()).findBy(
        'isbn',
        isbn
      );

      return this.get('ajax')
        .request(
          `/api/licenses/assign?isbn=${isbn}&user_id=${userId}${
            schoolId ? `&school_id=${schoolId}` : ''
          }`,
          true,
          { type: 'POST' }
        )
        .then((license) => {
          if (availableProduct) {
            return availableProduct.licensesUpdated(license);
          } else {
            return user.hasMany('licenses').reload();
          }
        });
    },

    openProductInfoPage(triggeredBy) {
      trackEvent({ category: 'Produkt', action: `Startsida - ${ triggeredBy === 'buy' ? 'Köp' : 'Om produkten'}` });
      window.open(`https://nok.se/redirect/products/${this.product.get('id')}`);
    },

    openLicenseModal() {
      trackEvent({ category: 'Produkt', action: 'Startsida - Hantera licenser' });
      this.set('licenseModalOpen', true);
    },

    cancelSubscription() {
      const date = moment(this.get('license.active_until')).format(
        'YYYY-MM-DD'
      );
      return this.get('confirmManager')
        .confirm(
          this.get('intl').t('overview.subscription.cancelModal.text', {
            date,
          }),
          {
            title: this.get('intl').t(
              'overview.subscription.cancelModal.title'
            ),
            danger: true,
            okLabel: this.get('intl').t(
              'overview.subscription.cancelModal.okButton'
            ),
          }
        )
        .then(() => {
          const licenseId = this.get('license.id');

          return this.get('ajax')
            .request(
              `/api/licenses/${licenseId}/cancel-subscription`,
              true,
              { type: 'POST', data: {} }
            )
            .then(() => {
              this.get('snackbar').enqueue(
                this.get('intl').t(
                  'overview.subscription.cancelModal.cancelSuccess'
                ),
                { variant: 'success', autoDismiss: true }
              );
            })
            .catch(() => {
              this.get('snackbar').enqueue(
                this.get('intl').t(
                  'overview.subscription.cancelModal.cancelFailed'
                ),
                { variant: 'error', autoDismiss: false }
              );
            });
        })
        .then(() => this.get('session.user').hasMany('licenses').reload())
        .catch(() => {});
    },
  }
});
