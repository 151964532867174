import { action } from '@ember/object';
import Component from '@glimmer/component';
import arg from 'compton/utils/arg';

export default class UserExternalLinkDialog extends Component {
  // ARGS

  @arg onClose;

  @action handleContinueButtonClick() {
    if (this.args?.url) {
      window.open(this.args.url);
    }
    this.close();
  }

  close() {
    this.onClose?.();
  }
}
