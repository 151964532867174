import config from 'babel-app/config/environment';
import { all } from 'rsvp';

export default function(ajax, type, user) {
  return all([
    ajax.request(`${config.endpoint}/api/v1/meta/${type}/books`, true, {
      type: 'GET'
    }),
    user.get('books')
  ]).then(([metaBooks, userBooks]) => {
    const books = [];

    if (metaBooks) {
      userBooks.forEach((book) => {
        if (metaBooks.indexOf(book.get('id')) !== -1) {
          books.push(book);
        }
      });
    }

    return books;
  });
}
