import { attr, belongsTo, hasMany } from '@ember-data/model';
import { action, get } from '@ember/object';
import { inject as service } from '@ember/service';
import Userable from '../mixins/userable';
import ActivityItemModel from './activity-item';

export default class PostModel extends ActivityItemModel.extend(Userable) {
  // SETUP

  @service session;

  static STATE = {
    draft: 'DRAFT',
    published: 'PUBLISHED',
    deleted: 'DELETED',
  };

  // RELATIONSHIPS

  @belongsTo('course') course;

  @hasMany('entity') entities;

  @hasMany('file') files;

  @hasMany('user', { inverse: null }) receivers;

  // ATTRIBUTES

  @attr('boolean') hasReceivers;

  @attr('string') postContent;

  @attr('boolean') allowComments;

  @attr('string', {
    defaultValue() {
      return PostModel.STATE.draft;
    },
  })
  state; // DRAFT, PUBLISHED or DELETED

  @attr('only-date') startDate;

  // PROPERTIES

  isPost = true; // ducktyping

  get isPublished() {
    return this.state === PostModel.STATE.published;
  }

  get isDraft() {
    return this.state === PostModel.STATE.draft;
  }

  get isAccessible() {
    const user = this.session?.user;

    if (user.isTeacher) {
      return true;
    }

    if (this.hasReceivers) {
      return this.receivers.includes(user);
    }

    return true;
  }

  get hasMissingEntities() {
    return get(this, 'entities.meta.num_not_found');
  }

  // METHODS

  @action async populateFromActivity() {
    const activity = await this.activity;

    if (activity) {
      this.startDate = activity.startDate;
    }
  }
}
