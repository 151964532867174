/**
 * Prevents line breaks before and after inline elements
 * by wrapping them with a white-space: nowrap element
 **/

function addNoWrapElem(wrap, node, elem, nodeIsBefore) {
  if (node && node.nodeType === 3) {
    const regexp = nodeIsBefore ? /(.*)\s+$/ : /^\s+(.*)/;

    if (!regexp.test(node.textContent)) {
      let index, text, textNode;

      if (!wrap) {
        wrap = document.createElement('span');
        wrap.classList.add('white-space-no-wrap');

        elem.parentNode.insertBefore(wrap, elem);
        wrap.appendChild(elem);
      }

      if (nodeIsBefore) {
        index = node.textContent.lastIndexOf(' ');
      } else {
        index = node.textContent.indexOf(' ');
      }

      if (index === -1) {
        text = node.textContent;
        node.parentNode.removeChild(node);
      } else if (nodeIsBefore) {
        text = node.textContent.substr(index + 1);
        node.textContent = node.textContent.substr(0, index + 1);
      } else {
        text = node.textContent.substr(0, index);
        node.textContent = node.textContent.substr(index);
      }

      textNode = document.createTextNode(text);

      if (nodeIsBefore) {
        wrap.insertBefore(textNode, elem);
      } else {
        wrap.appendChild(textNode);
      }
    }
  }

  return wrap;
}

export default function(elem) {
  const parent = elem.parentNode;

  if (parent) {
    const prev = parent.previousSibling;
    const next = parent.nextSibling;

    let wrap = addNoWrapElem(null, prev, parent, true);
    addNoWrapElem(wrap, next, parent, false);
  }
}
