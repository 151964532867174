import { inject as service } from '@ember/service';
import config from 'babel-app/config/environment';

import ApplicationAdapter from './application';

export default class UserAdapter extends ApplicationAdapter {
  @service('ajax') ajaxService;

  queryRecord(modelName, query, options) {
    if (options.me === true) {
      return this.ajaxService.request(`/api/users/me`, true, {
        type: 'GET',
      });
    } else {
      return super.queryRecord(...arguments);
    }
  }

  findHasMany(store, snapshot, url, relationship) {
    if (relationship.name == 'courses') {
      // rewrite course url
      url = url.replace(`${config.endpoint}/api/v1/meta`, '/api');
    }

    return super.findHasMany(store, snapshot, url, relationship);
  }
}
