import Service from '@ember/service';

export default class FootnotesService extends Service {
  indexes = {};

  footnotes = [];

  createIndexContext(sections) {
    const footnotes = (sections || [])
      .filterBy('footnotes')
      .mapBy('footnotes')
      .flat();

    footnotes.forEach((footnote, index) => {
      this.indexes[footnote.id] = index;
      this.footnotes.push(footnote);
    });

    return footnotes;
  }

  getIndex(id) {
    return this.indexes[id] + 1;
  }

  getFootnote(id) {
    return this.footnotes.findBy('id', id);
  }
}
