function storeInObject(sessionKey, key, value) {
  let dir = window.sessionStorage.getItem(sessionKey);
  dir = JSON.parse(dir);
  if (!dir || typeof dir !== 'object') {
    dir = {};
  }
  dir[key] = value;
  window.sessionStorage.setItem(sessionKey, JSON.stringify(dir));
}

function getValueFromObject(sessionKey, key) {
  let dir = window.sessionStorage.getItem(sessionKey);
  try {
    dir = dir ? JSON.parse(dir) : {};
    return dir?.[key];
  } catch (e) {
    return null;
  }
}

function removeObject(sessionKey) {
  window.sessionStorage.removeItem(sessionKey);
}

export { storeInObject, getValueFromObject, removeObject };
