import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class ChangeSchoolRoute extends Route {
  @service session
  @service router

  async beforeModel() {

    const { user } = this.session
    const { school_id: schoolId } = this.paramsFor(this.routeName)

    const newSchool = (await user.schools).find(school => school.id == schoolId)

    if (newSchool) {
      user.school = newSchool
      user.active_school = schoolId
      await user.save()
      await this.session.refreshUserSession()
      return location.replace('/')
    }
    this.router.replaceWith('master.index')
  }

  model() { }
}
