export default {
  /* Translate localName to style */
  tagToStyle(tag) {
    switch (tag) {
      case 'b':
        return 'bold';

      case 'i':
        return 'italic';

      case 'u':
        return 'underline';

      default:
        return '';
    }
  },

  styleToTag(style) {
    switch (style) {
      case 'h1':
        return 'h1';

      case 'h2':
        return 'h2';

      case 'bold':
        return 'b';

      case 'italic':
        return 'i';

      case 'underline':
        return 'u';

      default:
        return '';
    }
  }
};
