import Component from '@ember/component';
import { computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default Component.extend({
  router: service(),

  tagName: '',

  course: null,

  missingLicenseProduct: null,

  index: null,

  availableProducts: null,

  userMissingLicenses: null,

  openLicenseModal() {},

  product: alias('missingLicenseProduct.product'),

  userMissingLicense: computed(
    'userMissingLicenses.@each.product',
    'product.id',
    function() {
      const productId = this.get('product.id');
      const userMissingLicenses = this.get('userMissingLicenses');

      if (productId && userMissingLicenses) {
        return userMissingLicenses.findBy('product.id', productId);
      }

      return null;
    }
  ),

  availableProduct: computed(
    'availableProducts.@each.isbn',
    'product.id',
    function() {
      const productId = this.get('product.id');
      const availableProducts = this.get('availableProducts');

      if (productId && availableProducts) {
        return availableProducts.findBy('isbn', productId);
      }

      return null;
    }
  ),

  canAssignAll: computed(
    'product.free',
    'availableProduct.unassigned',
    'missingLicenseProduct.numMissing',
    function() {
      if (this.get('product.free')) return true;

      if (this.get('availableProduct.has_agreement')) return true;

      const unassigned = this.get('availableProduct.unassigned') || 0;
      const numUsers = this.get('missingLicenseProduct.numMissing') || 0;

      return unassigned >= numUsers;
    }
  ),

  actions: {
    openLicenseModal() {
      return this.openLicenseModal(this.get('product'));
    },

    openCourseSettings() {
      return this.get('router').transitionTo(
        'master.course-settings',
        this.get('course.id')
      );
    },

    assignLicenses() {
      const course = this.get('course');
      const availableProduct = this.get('availableProduct');
      const assignLicenses = course.assignLicenses.bind(course);

      return assignLicenses(availableProduct);
    }
  }
});
