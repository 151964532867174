import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import config from 'babel-app/config/environment';

export default Controller.extend({
  router: service(),
  session: service(),

  image: '/assets/images/onboarding/terms.svg',
  canContinue: false,

  actions: {
    onApprove(value) {
      const user = this.get('session.user');

      if (value) {
        user.set('approved_terms', config.termsVersion);
      } else {
        user.set('approved_terms', null);
      }

      this.set('canContinue', value);
    },

    onNext() {
      const user = this.get('session.user');

      return user.save().then(() => {
        return this.router.replaceWith(this.model.nextRoute);
      });
    },
  },
});
