import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { all } from 'rsvp';
import { trackEvent } from '../../../utils/matomo-events';

export default Component.extend({
  // SERVICES

  store: service(),

  snackbar: service(),

  session: service(),

  intl: service(),

  // SETUP

  tagName: '',

  // EXTERNAL PROPERTIES

  user: null,

  externalUsers: false,

  // INTERNAL PROPERTIES

  classes: null,

  initialClass: null,

  selectedClass: null,

  isLoading: true,

  // LIFECYCLE

  didReceiveAttrs() {
    this._super(...arguments);

    this.get('getUserClass').perform();
  },

  // COMPUTED

  userChanged: computed(
    'user.dirtyType',
    'initialClass',
    'selectedClass',
    function() {
      if (this.get('user.dirtyType')) {
        return true;
      }

      if (this.get('initialClass') !== this.get('selectedClass')) {
        return true;
      }

      return false;
    }
  ),

  // TASKS

  getUserClass: task(function*() {
    const user = this.get('user');

    if (!user) {
      return;
    }

    this.set('isLoading', true);

    const school = yield this.get('session.user.school');

    const classes = yield school.get('groups').then((groups) => {
      return groups
        .filter(
          (x) => x.get('type') === 'class' && !x.get('metadata.classLessClass')
        )
        .sortBy('name')
        .map((x) => {
          return {
            label: x.get('name'),
            value: x.get('id')
          };
        });
    });

    if (this.get('externalUsers')) {
      classes.forEach((x) => x.set('disabled', true));
    }

    this.set('classes', classes);

    const selectedClass = yield user.get('groups').then((groups) => {
      if (groups) {
        const classes = groups.filter(
          (x) =>
            x.get('type') === 'class' &&
            x.belongsTo('school').id() === school.get('id')
        );

        if (classes.length > 0) {
          return classes[0].get('id');
        }
      }

      return null;
    });

    this.set('initialClass', selectedClass);
    this.set('selectedClass', selectedClass);

    this.set('isLoading', false);
  }).drop(),

  // ACTIONS

  actions: {
    restoreUser() {
      const user = this.get('user');

      if (user) {
        this.set('selectedClass', this.get('initialClass'));

        trackEvent({ category: 'Användare', action: 'Elev - Allmänt - Återställ' });

        return user.rollbackAttributes();
      }
    },

    saveUser() {
      const promises = [];
      const user = this.get('user');
      const store = this.get('store');
      const initialClass = this.get('initialClass');
      const selectedClass = this.get('selectedClass');
      const groups = user.get('groups');

      if (initialClass !== selectedClass) {
        promises.push(
          store.findRecord('group', selectedClass).then((group) => {
            groups.addObject(group);

            return group.get('users').then((users) => {
              users.addObject(user);
              return this._saveAndUpdateGroup(group);
            });
          })
        );

        if (initialClass) {
          promises.push(
            store.findRecord('group', initialClass).then((group) => {
              groups.removeObject(group);

              return group.get('users').then((users) => {
                users.removeObject(user);
                return this._saveAndUpdateGroup(group);
              });
            })
          );
        }
      }

      return all(promises)
        .then(() => {
          return user.save().then(() => {
            trackEvent({ category: 'Användare', action: 'Elev - Allmänt - Spara' });
            this.set('initialClass', selectedClass);

            this.get('snackbar').enqueue(
              this.get('intl').t('components.userModal.saveSuccess'),
              {
                variant: 'success',
                dismissible: false,
                autoDismiss: true
              }
            );
          });
        })
        .catch(() => {
          this.get('snackbar').enqueue(
            this.get('intl').t('components.userModal.saveFailed'),
            {
              variant: 'error',
              dismissible: false,
              autoDismiss: true
            }
          );
        });
    },

    changeClass() {
      trackEvent({ category: 'Användare', action: 'Elev - Allmänt - Byt klass' });
    }
  },

  _saveAndUpdateGroup(group) {
    return group.save().then(() => {
      return group
        .get('users')
        .reload()
        .then(() => {
          group.set(
            'num_students',
            group.get('users').filter((x) => x.get('role') === 'student').length
          );
        });
    });
  }
});
