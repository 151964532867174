import DS from 'ember-data';

import Model from './model';

const { attr, hasMany } = DS;

export default Model.extend({
  // ATTRIBUTES

  name: attr('string'),

  sort: attr('number'),

  entities: hasMany('entity')
});
