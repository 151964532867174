import { attr, hasMany } from '@ember-data/model';
import { cached } from '@glimmer/tracking';

import extractFootnotesFromEditorValue from '../utils/extract-footnotes-from-editor-value';
import Model from './model';

function extractFootnotesFromComponents(components) {
  let notes = [];

  return components
    .map((item) => {
      switch (item.component) {
        case 'paragraph':
        case 'heading':
        case 'image':
        case 'video':
        case 'audio':
        case 'canvas':
        case 'interactive_image':
          return extractFootnotesFromEditorValue(item.value);
        case 'list':
        case 'gallery':
          return item.value
            ?.map((x) => extractFootnotesFromEditorValue(x.value))
            .flat();
        case 'table':
          item.rows?.forEach((row) => {
            row.columns?.forEach((column) => {
              notes.push(...extractFootnotesFromEditorValue(column?.value));
            });
          });

          notes.push(...extractFootnotesFromEditorValue(item.caption));

          return notes;
        default:
          break;
      }
    })
    .flat()
    .compact();
}

export default class EditorModel extends Model {
  // RELATIONSHIPS

  @hasMany({ inverse: null }) materials;

  // ATTRIBUTES

  @attr() rows;

  @attr() metadata;

  // PROPERTIES

  get components() {
    return this.rows
      ?.sortBy('index')
      ?.mapBy('columns')
      ?.flat()
      ?.sortBy('index')
      ?.mapBy('components')
      ?.flat();
  }

  @cached get footnotes() {
    return extractFootnotesFromComponents(this.components);
  }
}
