import Route from '@ember/routing/route';
import {
  ForbiddenError,
  NotFoundError,
  ServerError,
} from '@ember-data/adapter/error';

export default class ErrorRoute extends Route {
  setupController(controller, error) {
    super.setupController(...arguments);

    let title = 'pageTitle.error';

    if (error instanceof NotFoundError) {
      error.type = 'NotFound';
      title = 'pageTitle.notFound';
    } else if (error instanceof ForbiddenError) {
      error.type = 'Forbidden';
      title = 'forbidden.heading';
    } else if (error instanceof ServerError) {
      error.type = 'Server';
      title = 'error.heading';
    }

    this.controllerFor('master').setProperties({
      pageTitle: {
        label: title,
        target: 'master',
      },
    });
  }
}
