import EmberRouter from '@ember/routing/router';
import config from 'babel-app/config/environment';

class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.rootURL;
}

Router.map(function () {
  this.route('login');
  this.route('status');

  this.route('logout');

  this.route('onboarding', function () {
    this.route('terms');
    this.route('name');
    this.route('guide');
  });

  this.route('activate', { path: '/activate' }, function () {
    this.route('list', { path: '/list' });
    this.route('item', { path: '/:link_hash' });
  });

  this.route('course-key', { path: 'nyckel' }, function () {
    this.route('with-code', { path: ':course_code' });
  });

  this.route('master', { path: '/' }, function () {
    this.route('index', { path: '/' }, function () {
      this.route('add-products');
      this.route('activate', { path: '/activate' });
      this.route('anslut', { path: '/anslut' });
      this.route('ansok', { path: '/anslut/ansok' });
      this.route('skolkod', { path: '/skolkod' }, function() {
        this.route('with-code', { path: ':school_code' });
      });
      this.route('test-products', { path: '/test-products' });
    });

    this.route('courses', function () {
      this.route('new');
    });

    this.route('course-settings', { path: 'course-settings/:course_id' });

    this.route('change-school', { path: 'change-school/:school_id' });

    this.route('profile', function () {
      this.route('settings');
    });

    this.route('admin', function () {
      this.route('books');

      this.route('teachers', function () {
        this.route('invite');
      });

      this.route('students', function () {
        this.route('invite');
      });

      this.route('courses');
    });
  });

  this.route('404', { path: '*url' });
});

export default Router;
