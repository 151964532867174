const WHITELISTED_ROUTE_NAMES = [
  'master.index',
  'master.courses.new',
  'master.courses.course.overview',
  'master.courses.course.books',
  'master.courses.course.posts',
  'master.courses.course.todos',
  'master.courses.course.my-results',
  'master.courses.course.audit.index',
  'master.courses.course.audit.overview',
  'master.courses.course.audit.inbox',
  'master.courses.course.audit.log',
  'master.courses.course.audit.comments',
  'master.courses.course.audit.todos.index',
  'master.courses.course.audit.todos.todo',
  'master.course-settings',
  'master.todos',
  'master.products.index',
  'master.products.my-results',
  'master.profile.settings',
  'master.admin.books',
  'master.admin.teachers',
  'master.admin.students',
  'master.admin.courses',
  'master.books',
  'master.areas',
  'master.archives.index',
  'master.archives.folders.index',
  'master.archives.folders.materials',
  'master.interactives.index',
  'master.materials',
];

export default function shouldReloadOnUpdate(routeName) {
  if (WHITELISTED_ROUTE_NAMES.includes(routeName)) return true;
  return false;
}
