import { get } from '@ember/object';
import { isNone } from '@ember/utils';
import config from 'babel-app/config/environment';

import ApplicationAdapter from './application';

export default class Adapter extends ApplicationAdapter {
  host = config.endpoint;
  namespace = 'api/v1/content';

  urlForFindRecord(id, modelName, snapshot) {
    const url = super.urlForFindRecord(...arguments);
    const adapterOptionsInclude = 'adapterOptions.include';
    const include = get(snapshot, adapterOptionsInclude) || [];

    if (isNone(snapshot) || include.length === 0) {
      return url;
    }

    return `${url}${url.indexOf('?') > -1 ? '&' : '?'}include=${include.join(
      ','
    )}`;
  }
}
