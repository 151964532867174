import Service, { inject as service } from '@ember/service';
import $ from 'jquery';
import { Promise } from 'rsvp';

export default class AjaxService extends Service {
  @service session;

  /**
   * Extend jQuery ajax functionality with auth and progress event listener
   * @method request
   * @param  {string} url - URL to where the request should be sent
   * @param  {boolean} auth - Wheater the request should authorize to our API
   * @param  {object} options - Standard jQuery ajax options
   * @return {promise} Ajax promise
   */
  async request(url, auth, options) {
    const ajax = $.ajax.bind($);
    const self = this;

    if (typeof url === 'object') {
      options = url;
      url = undefined;
    }

    options = options || {};

    let xmlHttpReq;

    if (!options.xhr) {
      xmlHttpReq = $.ajaxSettings.xhr();
    } else {
      xmlHttpReq = options.xhr();
    }

    if (auth) {
      await this.session.refreshAuthentication.perform();

      options.beforeSend = (xhr) => {
        const headers = self.session.headers;

        if (headers) {
          for (const [key, value] of Object.entries(headers)) {
            xhr.setRequestHeader(key, value);
          }
        }
      };
    }

    if (options.onProgress) {
      xmlHttpReq.upload.onprogress = options.onProgress;
      delete options.onProgress;
    }

    options.xhr = () => {
      return xmlHttpReq;
    };

    // NOTE Make sure we return a 'real' promise.
    return new Promise((resolve, reject) => {
      ajax(url, options).then(resolve, reject);
    });
  }
}
