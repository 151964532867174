import OIDCAuthenticationRoute from 'ember-simple-auth-oidc/routes/oidc-authentication';
import getAbsoluteUrl from 'ember-simple-auth-oidc/utils/absolute-url';
import {
  generateCodeVerifier,
  generatePkceChallenge,
} from 'ember-simple-auth-oidc/utils/pkce';
import { v4 } from 'uuid';

export default class LoginRoute extends OIDCAuthenticationRoute {
  /**
   * Overwrites beforeModel
   * This overwrite handles the case where transition.from is empty,
   * which is not handled in ember-simple-auth-oidc.
   */
  beforeModel(transition) {
    let target = 'master';

    if (transition.from) {
      target = transition.from.name;
    }

    const returnUrl = transition.to.queryParams.returnUrl;

    if (returnUrl) {
      this.session.set('data.nextURL', returnUrl);
    }

    this.session.prohibitAuthentication(target);

    // PKCE Verifier has to be set in session, because we redirect to keycloak and back
    if (this.config.enablePkce) {
      let pkceCodeVerifier = this.session.data.pkceCodeVerifier;

      if (!pkceCodeVerifier) {
        pkceCodeVerifier = generateCodeVerifier(96);
        this.session.set('data.pkceCodeVerifier', pkceCodeVerifier);
      }
    }
  }

  /**
   * Overwrites afterModel to check if queryParams.error from oidc-provider on prompt=none
   */
  async afterModel(_, transition) {
    const queryParams = transition.to
      ? transition.to.queryParams
      : transition.queryParams;

    // errors when prompt=none fails, https://openid.net/specs/openid-connect-core-1_0.html#AuthError
    if (
      queryParams?.error === 'login_required' ||
      queryParams?.error === 'consent_required' ||
      queryParams?.error === 'interaction_required'
    ) {
      // if error from prompt=none check if we have a savedUrl, when savedUrl let this be handled by ember-simple-auth-oidc
      // otherwise redirect to landingpage
      const savedUrl = this.session.get('data.nextURL');
      if (!savedUrl) return location.replace('/');
    }

    return super.afterModel(...arguments);
  }

  /**
   * Overwrites _handleRedirectRequest
   * The code is unchanged with the exception of the key "prompt"
   * being added to "search" and the "savedUrl" being checked
   * before setting nextUrl.
   * Additionally, it checks if the attemptedTransition should
   * should take the user to the create account page rather than
   * the log in form.
   */
  _handleRedirectRequest(queryParams) {
    const state = v4();

    // Store state to session data
    this.session.set('data.state', state);

    /**
     * Store the `nextURL` in the localstorage so when the user returns after
     * the login he can be sent to the initial destination.
     */
    const url = this.session.attemptedTransition?.intent?.url;

    const path = this.session.attemptedTransition?.to.name

    const savedUrl = this.session.get('data.nextURL');

    if ((url && url !== '/') || !savedUrl) {
      this.session.set('data.nextURL', url);
    }

    const alternativeUrls = {
      'master.index.skolkod.with-code': () => {
        const returnTo = new URL('/login', location.origin)
        returnTo.searchParams.set('returnUrl', url)
        return `/create-account?${new URLSearchParams({ 'return-to': returnTo, 'schoolcode': true })}`
      }
    };

    const alternativeUrl = alternativeUrls[path]

    if (alternativeUrl) {

      return this._redirectToUrl(
        `${getAbsoluteUrl(this.config.host)}${alternativeUrl()}`
      )
    }

    // forward `login_hint` query param if present
    const key = this.config.loginHintName || 'login_hint';

    let search = [
      `client_id=${this.config.clientId}`,
      `redirect_uri=${this.redirectUri}`,
      `response_type=code`,
      `state=${state}`,
      `scope=${this.config.scope}`,
      queryParams[key] ? `${key}=${queryParams[key]}` : null,
    ];

    if (this.config.enablePkce) {
      const pkceChallenge = generatePkceChallenge(
        this.session.data.pkceCodeVerifier
      );
      search.push(`code_challenge=${pkceChallenge}`);
      search.push('code_challenge_method=S256');
    }

    search = search.filter(Boolean).join('&');

    this._redirectToUrl(
      `${getAbsoluteUrl(this.config.host)}${this.config.authEndpoint}?${search}`
    );
  }
}
