import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import AuthenticatedRoute from 'babel-app/routes/authenticated-route';

export default class MasterRoute extends AuthenticatedRoute {
  @service cookies;

  @service pusher;

  @service router;

  @service intl;

  @service ajax;

  @service session;

  async beforeModel(transition) {
    const url = new URL(window.location.href);
    const emptyPath = url.pathname === '/' && url.search === '';

    // Only the base route shows the landing page, all others will trigger a login.
    const transitionTo = emptyPath ? 'master.index' : 'login';

    const authenticated = await this.session.requireAuthentication(transition, transitionTo);

    if (authenticated) {
      await this.enableUser({
        // Only the landing page should be shown without the user being logged in
        stayOnPageIfNotLoggedIn: emptyPath
      });

      // Activate preview mode (for superadmins)
      if (transition.to && transition.to.queryParams.preview) {
        this.session.inPreviewMode = true;
      }
    }
  }

  setupController(controller) {
    super.setupController(...arguments);

    if (this.cookies.read('cookies_allowed') !== 'yes') {
      controller.cookiesNotAccepted = true;
    }
  }

  @action didTransition() {
    this.controller.drawerIsOpen = false;
  }
}
