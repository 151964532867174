import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class StatusController extends Controller {
  @tracked details;

  @action showDetails(error) {
    this.details = [error?.message, error?.stack].filter(Boolean);
  }
}
