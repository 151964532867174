import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class TestProductsRoute extends Route {
  @service router;
  @service ajax;
  @service session;
  @service store;
  @service intl;

  async model({ isbn }) {
    await this.session.load();
    const parentModel = await this.modelFor('master.index');
    const currentUser = this.session.user;

    const schoolApplications = await currentUser.get(
      'activeSchoolApplications'
    );

    const isValidUser = currentUser.role === 'teacher' || schoolApplications.length;

    if (!isbn || !isValidUser) {
      if (!isValidUser) {
        this.session.set('data.tryProductsMessage', true);
      }

      return this.router.transitionTo('master', {
        queryParams: { isbn: null },
      });
    }

    const isbns = isbn.split(',').filter((x) => x);
    let products = [];

    if (isbns.length > 1) {
      products = await this.store.query('product', {
        filter: { id: isbns },
      });
    } else {
      products = [await this.store.findRecord('product', isbns[0])];
      await products[0]?.cover;
    }

    const createResult = await Promise.all(
      products.map(
        async (product) => {
          const existingLicense = parentModel.user.licenses
            .filterBy('is_demo', true)
            .filterBy('is_expired', false)
            .findBy('isbn', product.id);

          try {
            if (!existingLicense) {
              await this._createTrialLicense(product, currentUser, parentModel.user.licenses);
            }

            return {
              status: 'success',
              product,
              license: parentModel.user.licenses
                .filterBy('is_demo', true)
                .filterBy('is_expired', false)
                .findBy('isbn', product.id)
            };
          }
          catch (err) {
            return {
              status: 'fail',
              product,
              license: existingLicense
            };
          }
        }
      )
    );

    if (isbns.length !== 1) {
      return this.router.transitionTo('master', { queryParams: { isbn: null } });
    }

    return {
      available: (createResult[0].license && !createResult[0].license.is_expired),
      createdLicense: createResult[0]
    }
  }

  async _createTrialLicense(product, user, licenses) {
    const schoolId = user.get('school.id');
    const schoolQuery = schoolId ? `&school_id=${schoolId}` : '';

    const license = await this.ajax.request(
      `/api/licenses/trial?isbn=${product.id}&user_id=${user.id}${schoolQuery}`,
      true,
      { type: 'POST' }
    );

    if (license?.data?.id) {
      const loadedLicense = await this.store.findRecord(
        'license',
        license.data.id
      );

      loadedLicense.is_new = true;
    }

    await licenses.reload();
  }
}
