import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
  // INJECT SERVICES

  session: service(),

  intl: service(),

  // COMPUTED

  locales: computed('intl.locales', function() {
    const intl = this.get('intl');
    return this.get('intl.locales').map((loc) => {
      return { value: loc, label: intl.t('language-select.language.' + loc) };
    });
  }),

  actions: {
    selectLocale(locale) {
      this.set('session.user.language', locale);
      this.get('session.user').save();
    }
  }
});
