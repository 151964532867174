import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { trackEvent } from '../../../utils/matomo-events';

export default class AdminTeachersController extends Controller {
  @service ajax;
  @service session;
  @service store;
  @service intl;
  @service snackbar;
  @service confirmManager;
  @service exportSchoolusers;
  @tracked userId = null;
  @tracked tabValue = null;
  @tracked modalOpen = false;
  @tracked pageMenuOpen = false;

  texts = {
    searchHeading: 'admin.searchTeachers',
    searchPlaceholder: 'routes.admin.search.teachers',
    noUsersMessage: 'admin.noTeachers',
    userNameHeading: 'admin.name',
    userEmailHeading: 'admin.eMail',
    userLastLoginHeading: 'admin.userLastLoginHeading',
    usersLabel: 'admin.numTeachers',
  };

  confirm(message) {
    return this.confirmManager.confirm(message, { danger: true });
  }

  get usersHandledExternally() {
    return Boolean(this.model.school.metadata?.external_users);
  }

  get users() {
    return this.model.users.filter((user) => user.isTeacher);
  }

  @action
  viewUser(userId) {
    this.userId = userId;
    this.modalOpen = true;
    this.tabValue = 'password';
  }

  @action
  async addUsers(data) {
    const { school, users } = this.model;

    // import 100 items at a time
    const chunkSize = 100;
    const aggregatedResponse = {};
    for (
      let startIndex = 0;
      startIndex < data.length;
      startIndex += chunkSize
    ) {
      const response = await this.ajax.request(
        `/api/schools/${school.id}/add-users`,
        true,
        {
          type: 'POST',
          data: {
            users: data.slice(startIndex, startIndex + chunkSize),
            userType: 'teacher',
          },
        }
      );
      Object.assign(aggregatedResponse, response);
    }
    // reload this asynchronously, so the modal closes when the import is finished
    users.reload();

    const { valid, invalid } = Object.entries(aggregatedResponse).reduce(
      (imports, [email, status]) => {
        imports[status === 'invalid_user_role' ? 'invalid' : 'valid'].push(
          email
        );
        return imports;
      },
      { valid: [], invalid: [] }
    );

    if (invalid.length > 5) {
      this.snackbar.enqueue(
        this.intl.t('admin.addUsers.multipleUsersFailed', {
          count: invalid.length,
        }),
        { variant: 'error', autoDismiss: false }
      );
    } else {
      invalid.forEach((email) => {
        this.snackbar.enqueue(
          this.intl.t('admin.addUsers.userFailed', { user: email }),
          { variant: 'error', autoDismiss: false }
        );
      });
    }

    if (valid.length > 5) {
      this.snackbar.enqueue(
        this.intl.t('admin.addUsers.multipleUsersSucceeded', {
          count: valid.length,
        }),
        { variant: 'success', autoDismiss: true }
      );
    } else {
      valid.forEach((email) => {
        this.snackbar.enqueue(
          this.intl.t('admin.addUsers.userSucceeded', {
            user: email,
          }),
          { variant: 'success', autoDismiss: true }
        );
      });
    }
  }

  @action
  async removeUser(user) {
    const { school, users } = this.model;

    try {
      await this.confirm(this.intl.t('admin.removeUserWarning'));

      trackEvent({
        category: 'Användare',
        action: 'Lärare - Ta bort användaren - OK'
      });

      try {
        await this.ajax.request(
          `/api/schools/${school.id}/remove-users`,
          true,
          { type: 'POST', data: { users: [user.id] } }
        );

        // Sync ember-data state
        users.removeObject(user);

        this.snackbar.enqueue(
          this.intl.t('admin.removeUsersSucceeded', {
            count: 1,
          }),
          { variant: 'success', autoDismiss: true }
        );
      } catch (error) {
        this.snackbar.enqueue(
          this.intl.t('admin.removeUsersFailed', {
            count: 1,
          }),
          { variant: 'error', autoDismiss: false }
        );
      }
    } catch {
      trackEvent({
        category: 'Användare',
        action: 'Lärare - Ta bort användaren - Avbryt'
      });
    }
  }

  @action
  exportUsersToExcel() {
    this.pageMenuOpen = false;

    trackEvent({
      category: 'Användare',
      action: 'Lärare - Ladda ner användarlista'
    });

    return this.exportSchoolusers.export(this.model.school.id);
  }
}
