import { getOwner } from '@ember/application';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { getConfig } from 'ember-simple-auth-oidc/config';
import getAbsoluteUrl from 'ember-simple-auth-oidc/utils/absolute-url';
import { generatePkceChallenge } from 'ember-simple-auth-oidc/utils/pkce';

export default class ProfileSettingsClass extends Component {
  @service session;
  @service intl;
  @service confirmManager;

  get googleService() {
    return {
      label: this.intl.t('profile.settingsPage.sso.link.google'),
      serviceName: 'google',
      model: this.args.logins.findBy('service', 'Google'),
      icon: '/assets/images/sso/google.svg',
    };
  }

  get microsoftService() {
    return {
      label: this.intl.t('profile.settingsPage.sso.link.microsoft'),
      serviceName: 'microsoft',
      model: this.args.logins.findBy('service', 'microsoft'),
      icon: '/assets/images/sso/microsoft.svg',
    };
  }

  @action connect(service) {
    const { host, clientId, authEndpoint, scope, profileSettingsUri, enablePkce } =
      getConfig(getOwner(this));

    /**
     * Store the `nextURL` in the localstorage so when the user returns after
     * the login he can be sent to the initial destination.
     */
    this.session.set(
      'data.nextURL',
      this.session.get('attemptedTransition.intent.url')
    );

    let search = [
      `client_id=${clientId}`,
      `redirect_uri=${profileSettingsUri}`,
      `response_type=code`,
      `scope=${scope}`,
      'prompt=login',
      `acr_values=idp:${service}`,
    ];

    if (enablePkce) {
      const pkceChallenge = generatePkceChallenge(
        this.session.data.pkceCodeVerifier
      );
      search.push(`code_challenge=${pkceChallenge}`);
      search.push('code_challenge_method=S256');
    }

    search = search.filter(Boolean).join('&');

    location.href = `${getAbsoluteUrl(host)}${authEndpoint}?${search}`;
  }

  @action disconnect(model) {
    return this.confirmManager
      .confirm({
        message: this.intl.t('profile.settingsPage.sso.disconnectAlertMessage'),
        danger: true,
      })
      .then(() => {
        const user = this.session.user;

        user.get('logins').removeObject(model);

        return user.save();
      });
  }
}
