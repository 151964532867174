import Controller from '@ember/controller';
import { inject as service } from '@ember/service';

export default Controller.extend({
  router: service(),
  store: service(),
  session: service(),

  image: '/assets/images/onboarding/guide.svg',
  canContinue: true,

  actions: {
    async onNext() {
      const user = this.get('session.user');

      const newReadNotice = this.store.createRecord('read-notice', {
        notice_key: 'has_seen_teacher_guide',
      });

      user.readNotices.addObject(newReadNotice);

      await newReadNotice.save();

      return this.router.replaceWith(this.model.nextRoute);
    },
  },
});
