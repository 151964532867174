import Component from '@glimmer/component';
import config from 'babel-app/config/environment';
import appendQueryParams from 'babel-app/utils/append-query-params';

export default class ProductLink extends Component {
  get isExternal() {
    const product = this.args.product;
    return (
      !product?.get('hasCoursePage') &&
      !product?.get('hasProductPage') &&
      product?.get('books.length') === 1 &&
      product.get('books.firstObject.type') === 'books_external'
    );
  }

  get diginokUrl() {
    let route = '/';

    switch (this.args.target) {
      case 'master.courses.course.overview':
        route = 'courses';
        break;
      case 'master.products.index':
        route = 'products';
        break;
      default:
        console.log(`TODO: Handle route: ${this.args.target}`);
        break;
    }

    const id = this.args.targetId;
    const url = new URL(`${route}/${id}`, config.diginokUrl);

    if (this.args.product) {
      url.searchParams.set('statisticsIsbn', this.args.product.get('id'));
    }

    return url;
  }

  get externalUrl() {
    const product = this.args.product;
    const targetId = this.args.targetId;

    if (!product?.get('hasProductPage') && product?.get('books.length') === 1) {
      const book = product?.get('books.firstObject');
      let target = book?.get('body.external_url');
      if (!target) {
        return '';
      }

      const query = { statisticsIsbn: product.get('id') };

      if (targetId && this.args.course) {
        query.activeCourse = this.args.course.id;
      }

      return appendQueryParams(target, query);
    } else {
      return '';
    }
  }
}
