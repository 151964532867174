import { A } from '@ember/array';
import Route from '@ember/routing/route';
import ConfirmSupport from 'compton/mixins/confirm-support';
import { all, hash } from 'rsvp';
import { inject as service } from '@ember/service';

export default class CourseSettings extends Route {
  @service session;

  @service store;

  setupController() {
    super.setupController(...arguments);

    this.controllerFor('master').setProperties({
      pageTitle: {
        label: 'courses.editingCourse',
        target: 'master.course-settings',
      },
      bookEntity: null,
      entity: null,
    });
  }

  async model({ course_id }) {
    const { user } = this.session;
    const school = await user.get('school');
    const fetchResult = await Promise.all([
      this.store.findRecord('course', course_id),
      user.get('licenses'),
      school.get('available_products'),
      school.get('groups'),
      school.get('users'),
      this.store.query('product', {
        filter: {
          extramaterial: true,
        },
      }),
    ]);
    const [
      course,
      userLicenses,
      availableProducts,
      schoolGroups,
      schoolUsers,
      extraMaterials,
    ] = fetchResult;

    const products = A(extraMaterials.toArray());

    const [loadedProducts, courseProducts] = await Promise.all([
      Promise.all([
        ...availableProducts.getEach('product'),
        ...userLicenses.filterBy('isExpired', false).getEach('product'),
      ]),
      course.get('products'),
      // preload
      course.get('books').catch(() => { /* unpublished products won't be able to load here, which makes the page crash. Since we don't care about them, we just ignore all errors. */ }),
      course.get('usersMissingLicenses'),
    ]);

    const addedISBNs = new Set(extraMaterials.getEach('id'));

    [loadedProducts, courseProducts].forEach((productsCollection) => {
      products.addObjects(
        productsCollection.filter((p) => p && !addedISBNs.has(p.get('id')))
      );
    });

    await all(products.map((product) => all([product.image, product.cover])));

    const userGroups = schoolGroups
      .filterBy('type', 'user_group')
      .sortBy('name');

    await all(schoolGroups.map((group) => group.users));

    return hash({
      course,
      products: products.sortBy('title'),
      availableProducts,
      classes: schoolGroups.filterBy('type', 'class').sortBy('name'),
      userGroups,
      teachers: schoolUsers.filterBy('role', 'teacher'),
      students: schoolUsers.filterBy('role', 'student'),
    });
  }
}
