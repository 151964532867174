import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
  // SETUP

  classNames: ['file-thumbnail'],

  // PARAMS

  file: null,

  iconName: computed('file.type', function() {
    switch (this.get('file.type')) {
      case 'video':
        return 'play-square';
      case 'audio':
        return 'audio';
      default:
        return 'content';
    }
  })
});
