import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { trackEvent } from '../../../../utils/matomo-events';

export default class GenerateStudentNamesComponent extends Component {
  @service intl;

  @tracked userNameErrors = [];
  @tracked userNamePrefix = this.args.userNamePrefix;
  @tracked numOfUsers = this.args.numOfUsers;
  @tracked numberError = [];

  @action
  numberChange() {
    if (this.numOfUsers > this.args.maxUsers) {
      this.numberError = [
        this.intl.t('admin.generatePopup.tooManyUsers', {
          number: this.maxUsers,
        }),
      ];
    } else {
      this.numberError = [];
    }
  }

  @action
  prefixChange(prefix) {
    this.userNameErrors = [];
    this.userNamePrefix = prefix.replace(/\s/g, '');
  }

  @action
  generate() {
    if (
      this.numberError.length === 0 &&
      this.userNameErrors?.length === 0 &&
      this.userNamePrefix &&
      this.args.generateUserNames
    ) {
      trackEvent({
        category: 'Användare',
        action: 'Bjud in elev - Med användarnamn - Genererar användarnamn'
      });

      this.args.generateUserNames(this.numOfUsers, this.userNamePrefix);
    }
  }
}
