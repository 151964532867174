import { not } from '@ember/object/computed';
import Service from '@ember/service';

export default Service.extend({
  online: false,
  offline: not('online'),

  init() {
    this._super(...arguments);

    this.set('online', navigator.onLine);

    var _this = this;
    var updateStatus = function () {
      _this.set('online', navigator.onLine);
    };

    window.addEventListener('online', updateStatus);
    window.addEventListener('offline', updateStatus);
  },
});
