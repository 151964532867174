import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import config from 'babel-app/config/environment';
import { MissingContentError } from 'babel-app/utils/errors';
import {
  generateCodeVerifier,
  generatePkceChallenge,
} from 'ember-simple-auth-oidc/utils/pkce';
import uuid from 'uuid';

export default class ApplicationRoute extends Route {
  @service router;

  @service session;

  @service pusher;

  @service appUpdate;

  constructor() {
    super(...arguments);
  }

  async beforeModel(transition) {
    await this.session.setup();

    this.appUpdate.initialize();
    this.pusher.subscribe();

    if (this.session) {
      this.session.on('authenticationSucceeded', this.sessionAuthenticated);
    }

    if (transition.to && transition.to.queryParams.provider) {
      let idp = null;

      // LEGACY: Needed until drop for v1 link support
      let arbetsuppgift = null;

      let url = window.location.pathname;

      if (transition.to.queryParams.url) {
        url = transition.to.queryParams.url;
      }

      if (transition.to.queryParams.idp) {
        idp = transition.to.queryParams.idp;
      }

      if (transition.to.queryParams.arbetsuppgift) {
        arbetsuppgift = transition.to.queryParams.arbetsuppgift;
        url = `${url}?arbetsuppgift=${arbetsuppgift}`;
      }

      const isLoggedIn = this.session.isAuthenticated;

      /*
      // TODO: Make this work to make the page load faster after login.
      // Kunskapsskolan redirects to the old Urls, which mean they will get an extra unnecessary redirect after logging in

      // Before: /contents/{content_id}/sections/{section_id}
      // After: /contents/{content_id}?section={section_id}
      const contentsRegex = new RegExp('\/?contents\/([A-Za-z-0-9]*)\/sections\/([A-Za-z-0-9]*)\/?$');

      // Before: /contents/{content_id}/sections/{section_id}/workspace/exercises/{exercise_id}
      // After: /contents/{content_id}/assignments/{exercise_id/}?section={section_id}
      const contentsSectionsRegex = new RegExp('\/?contents\/([A-Za-z-0-9]*)\/sections\/([A-Za-z-0-9]*)\/workspace\/exercises\/([A-Za-z-0-9]*)\/?$');

      if (contentsRegex.test(url)) {
        console.log('TODO: Fix contents');
      } else if (contentsSectionsRegex.test(url)) {
        console.log('TODO: Fix contents with section');
      }
      */

      const queryParams = { ...transition.to.queryParams };
      delete queryParams.provider;
      delete queryParams.idp;

      const queryString = Object.keys(queryParams).map(
        (x) => `${x}=${queryParams[x]}`
      );

      url += `?${queryString}`;

      if (isLoggedIn) {
        window.location = url;
      } else if (transition.to.queryParams.provider?.length && !idp) {
        transition.abort();

        const state = uuid.v4();
        this.session.set('data.state', state);
        if (url != '/?') {
          this.session.set('data.nextURL', url);
        }

        const searchParams = new URLSearchParams({
          client_id: config['ember-simple-auth-oidc'].clientId,
          redirect_uri: `${location.protocol}//${location.host}/login`,
          response_type: 'code',
          state,
          scope: 'openid profile offline_access',
          prompt: 'consent',
          acr_values: `idp:${transition.to.queryParams.provider}`,
        });

        if (config['ember-simple-auth-oidc'].enablePkce) {
          let verifier = this.session.data.pkceCodeVerifier;

          if (!verifier) {
            verifier = generateCodeVerifier(96);
            this.session.set('data.pkceCodeVerifier', verifier);
          }

          searchParams.append(
            'code_challenge',
            generatePkceChallenge(verifier)
          );
          searchParams.append('code_challenge_method', 'S256');
        }

        const authHost = config['ember-simple-auth-oidc'].host;

        window.location = `${`${authHost}/auth`}?${searchParams}`;
      } else {
        transition.abort();

        this.session.set('data.nextURL', url);

        window.location = `${config['ember-simple-auth-oidc'].host}/signin-skolfederation?idp=${idp}&url=${url}`;
      }
    }

    if (this.session.isAuthenticated) {
      return;
    }

    // Make sure we get back to the try products page after logging in since that requires a special create account flow
    if (transition.to?.queryParams.isbn && transition.intent.url?.startsWith('/test-products')) {
      const url = new URL(location);
      const savedUrl = this.session.get('data.nextURL');

      if (url.pathname !== '/' || !savedUrl) {
        this.session.set('data.nextURL', url.pathname + url.search);
      }
      const authUrl = new URL(
        `${config['ember-simple-auth-oidc'].host}/create-account`
      );

      authUrl.searchParams.append('test_products', true);

      transition.to.queryParams.isbn
        .split(',')
        .filter((x) => x)
        .forEach((isbn) => {
          authUrl.searchParams.append('test_product', isbn);
        });

      transition.abort();
      window.location = authUrl.toString();
    }
  }

  @action loading(transition) {
    // eslint-disable-next-line ember/no-controller-access-in-routes
    const controller = this.controllerFor('application');

    controller.isLoading = true;

    transition.promise.finally(() => {
      controller.isLoading = false;
    });

    return true;
  }

  @action didTransition() {
    // Unregister all service-workers
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (let registration of registrations) {
          registration.unregister();
        }
      });
    }
  }

  @action error(err) {
    if (err instanceof MissingContentError) {
      if (err.redirectTarget) {
        this.router.transitionTo(err.redirectTarget);
        return false;
      }
    }

    return true;
  }

  sessionAuthenticated() {
    if (this.session?.data?.nextURL) {
      const expires = this.session?.data?.nextURLExpires;

      if (expires) {
        const date = new Date();
        const time = date.getTime();

        if (time > expires) {
          this.session.data.nextURL = null;
        }
      }
    }
  }
}
