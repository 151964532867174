import { computed } from '@ember/object';
import Mixin from '@ember/object/mixin';

export default Mixin.create({
  // SETUP

  queryParams: [{ bookId: 'book' }, 'search', 'page'],

  // PROPERTIES

  bookId: null,

  search: '',

  page: 1,

  query: computed('bookId', 'search', 'page', function () {
    return this.getProperties('bookId', 'search', 'page');
  }),
});
