import DS from 'ember-data';
import { computed } from '@ember/object';

import Model from './model';

const { attr, belongsTo, hasMany } = DS;

export default Model.extend({
  isbn: attr('string'),
  active_until: attr('date'),
  assignee: attr('string'),
  is_demo: attr('boolean')
});
