import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';

export default Component.extend({
  // SETUP

  session: service(),

  router: service(),

  store: service(),

  // PROPERTIES

  schools: alias('session.user.schools.[]'),

  connectToSchoolModalOpen: false,

  // ACTIONS

  actions: {
    openConnectToSchoolModal() {
      if (this.session.user.role === 'student') {
        return this.router.transitionTo('master.index.skolkod');
      }

      return this.router.transitionTo('master.index.anslut');
    }
  }
});
