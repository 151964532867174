import Component from '@ember/component';
import { inject as service } from '@ember/service';

export default Component.extend({
  session: service(),

  classNames: ['flex', 'profile-settings-sso-item'],

  service: null,

  onConnect() {},

  onDisconnect() {},

  actions: {
    connect() {
      this.get('onConnect')(this.get('service.serviceName'));
    },
    disconnect() {
      this.get('onDisconnect')(this.get('service.model'));
    }
  }
});
