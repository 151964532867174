import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { trackEvent } from '../../../utils/matomo-events';

export default class AddProductsController extends Controller {
  queryParams = [
    'trial',
    'page',
    'search',
    'stages',
    'subjects',
    'extramaterial',
  ];

  @tracked trial = false;
  @tracked page = 1;
  @tracked search = '';
  @tracked subjects = [];
  @tracked stages = [];
  @tracked extramaterial = true;

  @action
  clearState(tab, includeSearch = false) {
    this.trial = tab === 'try_for_free';
    this.page = 1;
    this.extramaterial = true;

    const currentTab = tab === 'products' ? 'Produkter och extramaterial' : 'Prova gratis';
    trackEvent({ category: 'Produkt', action: `Lägg till produkt - ${currentTab} - Byt flik` });

    if (includeSearch) {
      this.search = this.searchInput = '';
      this.subjects = [];
      this.stages = [];
    }
  }

  @service router;
  @service intl;
  @service ajax;
  @service snackbar;

  // temp search input value that doesnt update the url on the fly
  @tracked searchInput = this.search;

  // action to handle custom case, see comment above for searchInput
  @action
  performTextSearch() {
    const typeOfProduct = this.trial ? 'Prova gratis' : 'Produkter och extramaterial';
    trackEvent({ category: 'Produkt', action: `Lägg till produkt - ${typeOfProduct} - Sök` });
    this.search = this.searchInput || '';
  }

  @tracked processingProduct = null;
  @tracked loading = false;

  get showExtramaterials() {
    return this.trial ? false : true;
  }

  get tabs() {
    const tabs = [
      {
        value: 'products',
        label: this.intl.t('routes.addProducts.navItems.products'),
      },
    ];

    if (
      (!this.model.school ||
        this.model.school.get('metadata')?.trials_disabled !== true) &&
      this.model.user.isTeacher
    ) {
      tabs.push({
        value: 'try_for_free',
        label: this.intl.t('routes.addProducts.navItems.tryForFree'),
      });
    }

    return tabs;
  }

  get externalLicenses() {
    return this.model.school?.get('metadata')?.external_licenses;
  }

  @action
  onClose() {
    const typeOfProduct = this.trial ? 'Prova gratis' : 'Produkter och extramaterial';
    trackEvent({ category: 'Produkt', action: `Lägg till produkt - ${typeOfProduct} - Stäng` });

    this.clearState('products');
    this.router.replaceWith('master.index');
  }

  @action
  async addLicense(product, availableProduct) {
    this.loading = true;
    this.processingProduct = product.id;

    try {
      const searchParams = new URLSearchParams({
        isbn: product.id,
        user_id: this.model.user.id,
      });

      if (!this.trial) {
        searchParams.append('assign_reusable', true);
      }

      if (this.model.school) {
        searchParams.append('school_id', this.model.school.id);
      }

      const license = await this.ajax.request(
        `/api/licenses/${
          this.trial && !product.get('isExtraMaterial') ? 'trial' : 'assign'
        }?${searchParams}`,
        true,
        { type: 'POST' }
      );

      if (this.model.user.isTeacher && availableProduct) {
        await availableProduct.licensesUpdated(license);
      } else {
        const licenses = await this.model.user.hasMany('licenses').reload();

        licenses.forEach((license) => {
          if (license.isbn === product.id) license.is_new = true;
        });
      }

      const typeOfProduct = this.trial ? 'Prova gratis' : 'Produkter och extramaterial';
      trackEvent({ category: 'Produkt', action: `Lägg till produkt - ${typeOfProduct} - Lägg till` });

      this.snackbar.enqueue(this.intl.t('routes.addProducts.addedLicense'), {
        variant: 'success',
        autoDismiss: true,
      });
    } catch (err) {
      if (err.responseJSON?.errors[0]?.detail === 'L029 Permission denied') {
        this.snackbar.enqueue(
          this.intl.t('routes.addProducts.usedTrialLicense'),
          {
            variant: 'error',
            autoDismiss: false,
          }
        );
      } else {
        this.snackbar.enqueue(
          this.intl.t('routes.addProducts.unabledToCreateLicense'),
          {
            variant: 'error',
            autoDismiss: false,
          }
        );
      }
    } finally {
      this.clearState('products');
      this.router.replaceWith('master.index');

      this.loading = false;
      this.processingProduct = null;
    }
  }
}
