import { isPresent, typeOf } from '@ember/utils';

import himalaya from './himalaya';

function nodeIsFootnote(node) {
  if (node.tagName === 'span') {
    return node.attributes.any(
      (attribute) =>
        attribute.key === 'data-type' && attribute.value === 'footnote'
    );
  }
  return false;
}

function extractFootnotesFromNodes(nodes) {
  const footnotes = [];

  nodes.forEach((node) => {
    if (nodeIsFootnote(node)) {
      const value = node.attributes.findBy('key', 'data-value')?.value;
      const id = node.attributes.findBy('key', 'data-id')?.value;

      if (value && id) {
        footnotes.addObject({
          id,
          value,
        });
      }
    }
    if (node.children) {
      footnotes.addObjects(extractFootnotesFromNodes(node.children));
    }
  });

  return footnotes;
}

export default function extractFootnotesFromEditorValue(value) {
  if (isPresent(value)) {
    if (typeOf(value) === 'string') {
      value = himalaya.parse(value);
    }

    return extractFootnotesFromNodes(value);
  }
  return [];
}
