import { computed } from '@ember/object';
import DS from 'ember-data';

import Model from './model';

const { attr, hasMany, belongsTo } = DS;

export default Model.extend({
  name: attr('string'),

  type: attr('string'),

  created: attr('date'),

  updated: attr('date'),

  metadata: attr({
    defaultValue() {
      return {};
    }
  }),

  num_students: attr('number'),

  // RELATIONS

  users: hasMany('user', { inverse: null }),

  school: belongsTo('school'),

  userIds: computed('users.@each', function() {
    return this.hasMany('users').ids();
  })
});
