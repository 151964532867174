import Component from '@ember/component';
import EmberObject, { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import licenseInfoHelper from 'babel-app/utils/license-info';

export default Component.extend({
  // SETUP

  intl: service(),

  tagName: '',

  // EXTERNAL PROPERTIES

  user: null,

  license: null,

  externalLicenses: false,

  body: null,

  openLicenseModal() {},

  // COMPUTED PROPERTIES

  licenseInfo: computed('license.[]', function() {
    const license = this.get('license');
    const licenseObj = EmberObject.create({});

    licenseObj.setProperties({
      license_id: license.get('id'),
      is_external: license.get('is_external'),
      is_expired: license.get('is_expired'),
      is_demo: license.get('is_demo'),
      active_until: license.get('active_until'),
      extended: license.get('extended'),
      is_private: license.get('is_private'),
      isbn: license.get('isbn')
    });

    return licenseInfoHelper(licenseObj, this.get('intl'));
  })
});
