import Mixin from '@ember/object/mixin';
import { inject as service } from '@ember/service';

// eslint-disable-next-line ember/no-new-mixins
export default Mixin.create({
  ajax: service(),

  pageviews: service(),

  afterModel(model, transition) {
    this._super(...arguments);

    const section = model.children.findBy(
      'id',
      transition.to?.queryParams?.section
    );

    let entity = section || model.model;

    if (!model || !model.bookEntity || !model.model) {
      console.error('Page view could not be registred.');
    }

    let parent = null;

    if (model.model.get('type') !== 'books') {
      parent = model.model.parent;
    }

    this.pageviews.logPageView(entity, model.bookEntity, parent);
  },
});
