import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

export default class TestProductsController extends Controller {
  queryParams = [{ isbn: 'products' }];

  @service router;

  @action
  goToStartpage() {
    this.router.transitionTo('master.index', { queryParams: { isbn: null } });
  }
}
