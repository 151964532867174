import AuthenticatedRoute from 'babel-app/routes/authenticated-route';
import { service } from '@ember/service';

export default class OnboardingRoute extends AuthenticatedRoute {
  @service session;
  @service store;
  @service intl;

  async beforeModel() {
    await this.session.load();
    this.intl.setLocale(this.session.user?.language ?? 'sv')
  }

  async model() {
    return {
      nextRoute: 'master.index',
      done: true
    }
  }
}