import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class ProductsOverviewPopoverContent extends Component {
  get active() {
    return this.args.active || 'title';
  }

  @action
  onSort(sortingProperty) {
    if (this.args.onSort) {
      this.args.onSort(sortingProperty);
    }
  }
}
