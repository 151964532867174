import Store from '@ember-data/store';

export default class CustomStore extends Store {
  createRecord() {
    const model = super.createRecord(...arguments);
    const attrs = model?.constructor?.attributes;

    if (attrs) {
      attrs.forEach((attr) => {
        const key = attr?.name;

        if (key && model[key]) {
          // self assign model key to trigger some internal ember-data stuff
          // that solves the defaultValue bugs described in DINO-2210
          // eslint-disable-next-line no-self-assign
          model[key] = model[key];
        }
      });
    }

    return model;
  }
}
