import { A } from '@ember/array';
import { get, set } from '@ember/object';
import { all, reject } from 'rsvp';

export default function(entityId, session, ajax, endpoint, transition) {
  transition.abort();

  return all([
    ajax.request(
      `${endpoint}/api/v1/content/entities/${entityId}/products`,
      true,
      { type: 'GET' }
    ),
    session.get('user.licenses')
  ]).then(([entityProducts, userLicenses]) => {
    // First check if the user's licenses should cover the entity

    const entityISBNs =
      entityProducts &&
      entityProducts.data &&
      entityProducts.data.map((x) => x.id);
    const licensedISBNs =
      userLicenses &&
      userLicenses
        .filter((x) => x.get('isActive') && !x.get('isExpired'))
        .map((x) => x.get('isbn'));

    let licenseExists = false;

    if (entityISBNs && licensedISBNs) {
      entityISBNs.forEach((isbn) => {
        if (licensedISBNs.indexOf(isbn) !== -1) {
          licenseExists = true;
        }
      });
    }

    const resolve = () => {
      // save the entityId in the session to prevent eternal loops
      // in weird edge cases, see NODI-1309

      const failedEntities = get(session, 'failedFindLicenseEntities') || A();

      if (failedEntities.includes(entityId)) {
        return reject();
      }

      failedEntities.push(entityId);

      set(session, 'failedFindLicenseEntities', failedEntities);

      return session.refreshUserLicenses().then(() => {
        const location = get(transition, 'intent.url');

        if (!location) {
          return transition.retry();
        }

        return window.location = location;
      });
    };

    if (licenseExists) {
      // The user should already have access, refresh book access and retry
      return resolve();
    }

    // Attempt to find a license to connect the user to automatically
    const userId = session.get('user.id');

    return ajax
      .request(
        `/api/users/${userId}/find-license`,
        true,
        {
          type: 'GET',
          data: { entityId }
        }
      )
      .then((payload) => {
        if (payload && payload.data && payload.data.id) {
          return resolve();
        }

        return reject();
      });
  });
}
