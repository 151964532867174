import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class ActivateRoute extends Route {
  @service fakeOrders;

  async model() {
    return {
      orders: this.fakeOrders.orders,
    };
  }
}
