export default {
  "archives": [],
  "areas": [],
  "assignments": [],
  "books": [],
  "books_external": [],
  "collections": [],
  "contents": [],
  "exercises": [],
  "folders": [],
  "glossaries": [],
  "interactives": [],
  "materials": [],
  "root": [],
  "sections": [],
  "studies": []
};