import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
  session: service(),

  logins: null,

  hasDigilarLogin: computed('logins.[]', function() {
    const logins = this.get('logins');

    if (logins) {
      const services = logins.mapBy('service');
      return services.indexOf('digilar') !== -1;
    }

    return false;
  }),

  init() {
    this._super(...arguments);

    this.set('logins', []);

    this.get('session.user.logins')
      .then((logins) => {
        this.set('logins', logins);
      });
  }
});
