import { observer } from '@ember/object';
import Service from '@ember/service';
import { inject as service } from '@ember/service';
import { clearCookie } from 'babel-app/utils/handle-cookies';
import { resolve } from 'rsvp';

export default Service.extend({
  maintenanceMessage: null,

  topMessage: null,

  shownSnacks: null,

  snacks: null,

  cookies: service(),

  pusher: service(),

  store: service(),

  session: service(),

  userChanged: observer('session.user', function () {
    this.getMessages();
  }),

  addListener() {
    this.get('pusher').on('notice', (record, event) => {
      if (event !== 'willDelete') {
        if (event === 'didDelete') {
          this.get('store').unloadRecord(record);
        }
        this.getMessages();
      }
    });
  },

  async getMessages() {
    if (!this.get('isRunning')) {
      this.set('isRunning', true);

      try {
        const user = this.get('session.user');

        let messages;

        if (user) {
          const licenses = await user.get('licenses');

          const isbns = licenses.map((license) => license.get('isbn'));

          const nestedNotices = await this.getNoticesBatchedByIsbn(
            isbns,
            (isbnBatch) => this.get('store').query('notice', {
              platform: 'nokportalen',
              role: user.get('role'),
              isbns: isbnBatch?.join(',')
            })
          );

          messages = this.removeDuplicateNotices(nestedNotices);
        } else {
          messages = await this.get('store').query('notice', { platform: 'nokportalen' });
        }

        const openMessages = this.filterMessages(messages);

        this.showMessages(openMessages);

        this.set('isRunning', false);
      } catch (error) {
        this.set('isRunning', false);
      }
    }
  },

  showMessages(messages) {
    if (messages) {
      if (messages.length === 0) {
        this.set('maintenanceMessage', null);
        this.set('topMessage', null);
        this.set('snacks', null);
        return resolve();
      }

      const maintenanceMessages = messages.filter(
        (x) => x.get('type') === 'maintenance'
      );

      const topMessages = messages.filter(
        (x) => x.get('type') === 'top'
      );

      const snacks = messages.filter((x) => x.get('type') === 'snack');

      if (maintenanceMessages.length > 0) {
        this.set('maintenanceMessage', maintenanceMessages[0]);
      } else {
        this.set('maintenanceMessage', null);
      }

      if (topMessages.length > 0) {
        this.set('topMessage', topMessages[0]);
      } else {
        this.set('topMessage', null);
      }

      if (snacks.length > 0) {
        this.set('snacks', snacks);
      } else {
        this.set('snacks', null);
      }
    }
  },

  filterMessages(messages) {
    const cookies = this.cookies.read();

    if (cookies) {
      // Remove cookies set for messages that are no longer active
      for (const name in cookies) {
        if (name.startsWith('nokportalen-closedNotice_')) {
          const id = name.replace('nokportalen-closedNotice_', '');

          if (!messages.find((message) => message.id === id)) {
            clearCookie(this.cookies, name);
          }
        }
      }
    }

    return messages.filter((message) => {
      if (this.cookies.read(`nokportalen-closedNotice_${message.id}`)) {
        return false;
      }

      if (message.get('isDeleted')) {
        return false;
      }

      return true;
    });
  },

  async getNoticesBatchedByIsbn(isbns, callback) {
    const batchSize = 200;

    const batchedIsbns = 
      isbns.length === 0
        ? [null]
        : Array
          .from({ length: Math.ceil(isbns.length / batchSize) })
          .map((_, index) => isbns.slice(index * batchSize, (index + 1) * batchSize));

    return await Promise.all(batchedIsbns.map(callback));
  },

  removeDuplicateNotices(nestedNotices) {
    const uniqueNoticeIds = new Set();

    return nestedNotices
      .flatMap((nestedArray) => 
        nestedArray.filter(
          (message) => {
            if (uniqueNoticeIds.has(message.get('id'))) return false;

            uniqueNoticeIds.add(message.get('id'));
            return true;
          }
        )
      )
  }
});
