import { attr, belongsTo } from '@ember-data/model';

import Model from './model';

export default class extends Model {
  @attr('string') school_name;

  @attr('string') school_code;

  @attr('boolean') is_active;

  @attr('boolean') is_accepted;

  @attr('string') updated_by;

  @attr('boolean') try_products;

  @attr() isbns;

  @attr('date') created;

  @attr('date') updated;

  @belongsTo('user') user;

  @belongsTo('school') school;
}
