import { attr, belongsTo } from '@ember-data/model';
import Model from './model';

export default class OrderItemModel extends Model {
  @attr('string') isbn;
  @attr('string') title;

  @attr('date') publishDate;
  @attr('date') willExpireAt;
  @attr('date') expired;

  @attr('number') quantity;
  @attr('number') licenseDays;

  @belongsTo('product') product;

  get isPublished() {
    if (!this.publishDate) {
      return false;
    }

    return new Date(this.publishDate) <= new Date();
  }
}
