import { helper } from '@ember/component/helper';
import moment from 'moment';

export function isAfter([date, days]) {
  if (date) {
    if (!days) return moment(date).isAfter(moment());
    return moment(date).isAfter(moment().add(days, 'days'));
  }

  return null;
}

export default helper(isAfter);
