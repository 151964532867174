import { attr, hasMany, belongsTo } from '@ember-data/model';
import Model from './model';

export default class OrderModel extends Model {
  @attr('string') source;
  @attr('string') orderReference;
  @attr('string') attention;
  @attr('string') schoolName;
  @attr('string') schoolId;
  @attr('string') alternativeDeliveryPoint;
  @attr('string') activatedByUser;
  @attr('string') activatedByClient;

  @attr('boolean') isPrivatePurchase;
  @attr('boolean') isActivationCode;

  @attr('date') created;
  @attr('date') activated;

  @belongsTo('user') user;
  @belongsTo('school') school;

  @hasMany('order-item', { async: false }) items;

  get isOld() {
    return this.items.some(item => item.willExpireAt);
  }

  get numberOfLicenses() {
    return this.items.reduce((sum, item) => sum + item.quantity, 0);
  }

  get isExpired() {
    return this.items.every(item => item.expired);
  }

  get hasUnpublishedProducts() {
    return this.items.some(item => !item.isPublished);
  }

  get onlyUnpublishedProducts() {
    return this.items.every(item => !item.isPublished);
  }
}
