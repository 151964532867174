import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class AuthenticatedRoute extends Route {
  @service intl;

  @service notice;

  @service pusher;

  @service session;

  async beforeModel(transition) {
    const authenticated = await this.session.requireAuthentication(
      transition,
      'login'
    );

    if (authenticated) {
      this.enableUser();
    }
  }

  async enableUser({stayOnPageIfNotLoggedIn = false} = {}) {
    await this.session.load(stayOnPageIfNotLoggedIn);

    this.intl.setLocale(this.session.user?.language ?? 'sv');
    this.pusher.subscribe();
    this.notice.addListener();
    this.notice.getMessages();
  }
}
