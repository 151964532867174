import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import config from 'babel-app/config/environment';

export default Component.extend({
  // SETUP

  classNames: ['login-content'],

  session: service(),

  ajaxService: service('ajax'),

  userAgent: service(),

  // PROPERTIES

  username: null,

  password: null,

  token: null,

  error: null,

  // ACTIONS

  actions: {
    loginAndConnect() {
      const { username, password } = this.getProperties('username', 'password');

      if (!username || !password) {
        return;
      }

      let lowerUsername = username.toLowerCase();

      this.get('ajaxService')
        .request(`${config.endpoint}/api/v1/oauth/sso-connect`, false, {
          type: 'POST',
          data: {
            username: lowerUsername,
            password: password,
            token: this.get('token'),
            client_id: config.clientId,
            client_secret: config.clientSecret
          }
        })
        .then((data) => {
          this.get('session')
            .authenticate('authenticator:sso', data.refresh_token)
            .catch((reason) => {
              this.set('error', reason.errors);
            });
        })
        .catch((err) => {
          if (err.responseJSON && err.responseJSON.errors[0]) {
            this.set('error', err.responseJSON.errors[0].code);
          } else {
            this.set('error', 'unknown_error');
          }
        });
    }
  }
});
