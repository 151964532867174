import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { load } from 'ember-async-data';
import { resolve } from 'rsvp';

export default class UserListGroupComponent extends Component {
  @service intl;

  @tracked isExpanded = false;

  @cached get groupUsersProxy() {
    return load(resolve(this.args.group?.users), this);
  }

  get groupUsers() {
    return this.groupUsersProxy.isResolved ? this.groupUsersProxy.value : [];
  }

  get students() {
    const groupMatches =
      !this.args.search ||
      (this.args.group?.name ?? '').toLowerCase().includes(this.args.search);

    return this.groupUsers
      .filter(
        (user) =>
          user['role'] === 'student' &&
          (!this.args.search ||
            groupMatches ||
            this.args.userFilter(user, this.args.search))
      )
      .sortBy('lastname', 'firstname', 'username');
  }

  get groupName() {
    if (this.args.modifiedGroupNames && this.args.group?.type === 'group') {
      return this.intl.t('courses.selectedStudentsGroupName');
    }

    return this.args.group?.name;
  }

  get expanded() {
    if (this.args.hideAccordion || this.args.expandAccordion) {
      return true;
    }

    return this.isExpanded;
  }

  @action toggleExpanded() {
    this.isExpanded = !this.isExpanded;
  }
}
