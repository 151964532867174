import ApplicationSerializer from './application';

export default class SchoolApplicationSerializer extends ApplicationSerializer {
  serialize() {
    const json = super.serialize(...arguments);

    delete json.data.attributes.created;

    return json;
  }
}
