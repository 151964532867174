// https://stackoverflow.com/a/62198235
export function numberToBase26(number, tail = '') {
  if (number <= 26) {
    return `${String.fromCharCode(number + 64)}${tail}`.toLowerCase();
  }

  const remainder = number % 26 || 26;
  const division = Math.trunc(number / 26) - (remainder === 26 ? 1 : 0);

  return numberToBase26(
    division,
    `${String.fromCharCode(remainder + 64)}${tail}`
  );
}
