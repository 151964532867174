/**
 * @typedef {Object} Event
 * @property {'Användare' | 'Kurs' | 'Produkt' | 'Support'} category Händelsekategori
 * @property {string} action Åtgärd för händelse - tydlig beskrivning
 * @property {string=} name Händelsens namn - förklaring och exempel
 * @property {string=} value Händelsens värde - förklaring och exempel
 */

/**
 * Track an event in Matomo
 * @param {Event} event
 * @example
 * **När användaren slutför en inbjudan via a-post där hen har valt klass och skickar med 30 elever**
 * ```javascript
 * trackEvent('Användare', 'Bjud in elev - Via e-post - Slutför', 'Med klass', 30);
 * ```
 */
export function trackEvent({ category, action, name, value }) {
  if (!category || !action) {
    console.error('Missing category or action for tracking event', { category, action });

    return;
  }

  // Make sure window._paq exists
  window._paq ??= [];

  window._paq.push(['trackEvent', category, action, name, value?.toString()]);
}