import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import getToday from 'babel-app/utils/get-today';

export default Component.extend({
  classNames: ['course-main-component'],

  session: service(),

  intl: service(),

  course: null,

  licensesReloaded: false,

  activeProduct: null,

  modalOpen: false,

  didReceiveAttrs() {
    this._super(...arguments);

    if (
      this.get('missingLicenses.length') > 0 &&
      this.licensesReloaded === false
    ) {
      this.session.refreshUserLicenses().then(() => {
        this.set('licensesReloaded', true);
      });
    }
  },

  missingLicenses: computed(
    'session.user.licenses.[]',
    'course.products.[]',
    function () {
      let missingLicenses = [];

      const licenses = this.get('session.user.licenses');
      const courseProducts = this.get('course.products');

      if (licenses && courseProducts) {
        const activeLicenseISBNs = licenses
          .filter((x) => x.get('isActive'))
          .map((x) => x.get('isbn'));
        const licenseISBNs = licenses.map((x) => x.get('isbn'));

        courseProducts.forEach((product) => {
          if (activeLicenseISBNs.indexOf(product.id) === -1) {
            const index = licenseISBNs.indexOf(product.id);
            const license = index !== -1 ? licenses.objectAt(index) : null;

            missingLicenses.push({
              product: product,
              license: license,
            });
          }
        });
      }

      return missingLicenses;
    }
  ),

  showNoLicenseMessage: computed(
    'licensesReloaded',
    'missingLicenses.length',
    function () {
      const licensesReloaded = this.licensesReloaded;
      const missingLicenses = this.get('missingLicenses.length') > 0;

      if (licensesReloaded && missingLicenses) return true;

      return false;
    }
  ),

  hasCourseWarnings: computed(
    'session.user.{isTeacher,school.metadata.external_licenses}',
    'course.{active,usersMissingLicenses.[]}',
    function () {
      if (!this.get('session.user.isTeacher')) return false;
      if (this.get('session.user.school.metadata.external_licenses'))
        return false;
      if (!this.get('course.active')) return true;
      return this.get('course.usersMissingLicenses.length') > 0;
    }
  ),

  courseHasGroups: computed('course.groups.[]', function () {
    if (this.get('course.groups.length') > 0) return true;
    return false;
  }),

  courseHasBooks: computed('course.books.[]', function () {
    if (this.get('course.books.length') > 0) return true;
    return false;
  }),

  courseMenuItems: computed(
    'intl.locale',
    'courseHasBooks',
    'session.user.isTeacher',
    function () {
      const menu_items = ['overview', 'books', 'posts', 'todo', 'myResults'];
      const menu_teacher_items = ['audit', 'settings'];

      const menu_icons = {
        overview: 'home',
        books: 'book',
        posts: 'notes',
        todo: 'mission',
        myResults: 'log',
        settings: 'settings',
        audit: 'results',
      };

      const menu_routes = {
        overview: 'master.courses.course.overview',
        books: 'master.courses.course.books',
        posts: 'master.courses.course.posts',
        todo: 'master.courses.course.todos',
        myResults: 'master.courses.course.my-results',
        settings: 'master.courses.course.settings',
        audit: 'master.courses.course.audit.index',
      };

      const require_books = ['myResults', 'audit'];

      let items = [];

      for (let i = 0; i < menu_items.length; i++) {
        items.addObject({
          title: this.intl.t('courses.' + menu_items[i]),
          icon: menu_icons[menu_items[i]],
          route: menu_routes[menu_items[i]],
          disabled:
            require_books.includes(menu_items[i]) && !this.courseHasBooks,
        });
      }

      if (this.session.user.isTeacher) {
        for (let i = 0; i < menu_teacher_items.length; i++) {
          items.addObject({
            title: this.intl.t('courses.' + menu_teacher_items[i]),
            icon: menu_icons[menu_teacher_items[i]],
            route: menu_routes[menu_teacher_items[i]],
            disabled:
              require_books.includes(menu_teacher_items[i]) &&
              !this.courseHasBooks,
          });
        }
      }

      return items;
    }
  ),

  actions: {
    saveCourse() {
      return this.course.save();
    },

    restore(course) {
      course.set('active', true);
      return course.save();
    },

    openLicenseModal(product) {
      this.set('activeProduct', product);
      this.set('modalOpen', true);
    },
  },
});
