import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import arg from 'compton/utils/arg';

export default class AddStudentsRowComponent extends Component {
  @service intl;

  @tracked index = this.args.index || 0;

  @arg user;

  @tracked popoverOpen = false;
  @tracked usernameErrorOpen = false;
  @tracked passwordErrorOpen = false;
  @tracked passwordPlaceholder = null;
  @tracked usernamePlaceholder = null;

  #onlyOneSpace(s) {
    return s.replace(/\s+/g, ' ');
  }

  #removeSpaces(s) {
    return s.replace(/\s/g, '');
  }

  #resetErrors(field) {
    if (field === 'username') {
      this.user.usernameError = null;
    }

    if (field === 'password') {
      this.user.passwordError = null;
    }
  }

  @action
  setInputValue(e) {
    const field = e.target.name;
    const value = e.target.value;
    const start = e.target.selectionStart;
    const length = Number(value.trim().length);

    this.#resetErrors(field);

    // stop spaces at start of string
    if (e.which === 32 && start === 0) return false;

    // allow one space at the end
    if (e.which === 32 && start === length + 1) return false;

    const trimmedValue = this.#onlyOneSpace(value);

    this.args.checkRowFields(trimmedValue);
    this.user[field] = trimmedValue;
  }

  @action
  setUserValue(e) {
    const field = e.target.name;
    const value = this.#removeSpaces(e.target.value);

    this.#resetErrors(field);

    if (e.which === 32) return false;

    this.args.checkRowFields(value);
    this.user[field] = value;
  }

  @action
  clickNumberButton() {
    this.popoverOpen = !this.popoverOpen;
  }

  @action
  popoverClosed() {
    this.popoverOpen = false;
  }

  @action
  remove() {
    this.popoverOpen = false;
    this.args.removeRow(this.index);
  }

  @action
  setUsernamePlaceholder() {
    this.usernamePlaceholder = this.intl.t(
      'admin.generatePopup.usernamePlaceholder'
    );
  }

  @action
  unsetUsernamePlaceholder() {
    this.usernamePlaceholder = null;
  }

  @action
  openUsernameError() {
    this.usernameErrorOpen = true;
  }

  @action
  usernamePopoverClosed() {
    this.usernameErrorOpen = false;
  }

  @action
  openPasswordError() {
    this.passwordErrorOpen = true;
  }

  @action
  passwordPopoverClosed() {
    this.passwordErrorOpen = false;
  }

  @action
  setPasswordPlaceholder() {
    this.passwordPlaceholder = this.intl.t(
      'admin.generatePopup.passwordPlaceholder'
    );
  }

  @action
  unsetPasswordPlaceholder() {
    this.passwordPlaceholder = null;
  }
}
