import Component from '@ember/component';
import { inject as service } from '@ember/service';

import config from '../../config/environment';

export default Component.extend({
  // INJECT SERVICES

  session: service(),

  ajax: service(),

  intl: service(),

  // PROPERTIES

  currentPasswordErrors: null,

  newPasswordErrors: null,

  confirmPasswordErrors: null,

  currentPassword: null,

  newPassword: null,

  confirmedPassword: null,

  passwordError: null,

  message: null,

  actions: {
    changeCurrentPassword() {
      this.set('currentPasswordErrors', null);
    },

    changeNewPassword() {
      this.set('newPasswordErrors', null);
    },

    changeConfirmedPassword() {
      this.set('confirmPasswordErrors', null);
    },

    updatePassword() {
      let errors = false;

      if (!this.get('currentPassword')) {
        this.set('currentPasswordErrors', [
          this.get('intl').t('choosePassword.errors.wrongPassword')
        ]);
        errors = true;
      }

      if (!this.get('newPassword') || this.get('newPassword.length') < 8) {
        this.set('newPasswordErrors', [
          this.get('intl').t('choosePassword.errors.passwordToShort')
        ]);
        errors = true;
      }

      if (
        !this.get('confirmedPassword') ||
        this.get('newPassword') !== this.get('confirmedPassword')
      ) {
        this.set('confirmPasswordErrors', [
          this.get('intl').t('choosePassword.errors.passwordsNotMatch')
        ]);
        errors = true;
      }

      if (errors) return;

      this.get('ajax')
        .request(
          `/api/account/password/update`,
          true,
          {
            type: 'PATCH',
            data: {
              oldPassword: this.get('currentPassword'),
              newPassword: this.get('newPassword')
            }
          }
        )
        .then(() => {
          this.set(
            'message',
            this.get('intl').t('choosePassword.passwordChanged')
          );

          this.set('currentPassword', null);
          this.set('newPassword', null);
          this.set('confirmedPassword', null);
        })
        .catch((e) => {
          if (e.responseJSON.errors[0] && e.responseJSON.errors[0].code) {
            this.set('currentPasswordErrors', [
              this.get('intl').t(
                'choosePassword.errors.' + e.responseJSON.errors[0].code
              )
            ]);
          }
        });
    }
  }
});
