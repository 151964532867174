import { computed } from '@ember/object';
import Mixin from '@ember/object/mixin';
import { dasherize } from '@ember/string';
import { typeOf } from '@ember/utils';

export default Mixin.create({
  // SETUP

  classNameBindings: ['settingClasses'],
  attributeBindings: ['data-id', 'data-language'],

  // PROPERTIES

  settingClasses: computed(
    'part.settings',
    'componentCssClassName',
    function () {
      const settingClasses = [];

      const { settings } = this.get('part');

      if (settings != null) {
        for (const k in settings) {
          const v = settings[k];

          if (v) {
            if (typeOf(v) !== 'number') {
              const settingClass = dasherize(typeOf(v) === 'boolean' ? k : v);
              settingClasses.addObject(settingClass);
            }
          }
        }
      }

      return settingClasses
        .map((settingClass) => {
          return [this.get('componentCssClassName'), settingClass].join('--');
        })
        .join(' ');
    }
  ),
});
