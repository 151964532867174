import sanitizeHtml from 'ember-cli-sanitize-html';

// We basically allow all tags and attributes that are
// safe from an XSS point of view.

const allowedTags = [
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'div',
  'figcaption',
  'figure',
  'hr',
  'li',
  'ol',
  'p',
  'ul',
  'b',
  'br',
  'em',
  'i',
  'q',
  's',
  'small',
  'span',
  'strong',
  'sub',
  'sup',
  'u',
  'wbr',
  'del',
  'ins',
  'caption',
  'col',
  'colgroup',
  'table',
  'tbody',
  'td',
  'tfoot',
  'th',
  'thead',
  'tr',
  'strike',
  'a',
];

const allowedAttributes = {
  '*': [
    'aria-*',
    'class',
    'data-*',
    'dir',
    'id',
    'lang',
    'spellcheck',
    'tabindex',
    'title',
    'translate',
  ],
  li: ['value', 'type'],
  ol: ['reversed', 'start', 'type'],
  ul: ['type'],
  col: ['span'],
  colgroup: ['span'],
  td: ['colspan', 'rowspan'],
  th: ['abbr', 'colspan', 'rowspan', 'scope'],
  a: ['href', 'target'],
};

export default function (markup) {
  return sanitizeHtml(markup, { allowedTags, allowedAttributes });
}
