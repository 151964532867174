import { getProperties } from '@ember/object';
import { typeOf } from '@ember/utils';

const BLACKLISTED_KEYS = [
  'isInstance',
  'isDestroyed',
  'isDestroying',
  'concatenatedProperties',
  'mergedProperties'
];

function emberObjectToNativeObject(target) {
  let result;

  switch (typeOf(target)) {
    case 'instance':
      result = {};

      for (const k in getProperties(target, Object.keys(target))) {
        if (target.hasOwnProperty(k) && !BLACKLISTED_KEYS.includes(k)) {
          result[k] = emberObjectToNativeObject(target[k]);
        }
      }

      break;

    case 'object':
      result = {};

      for (const k in target) {
        if (target.hasOwnProperty(k)) {
          result[k] = emberObjectToNativeObject(target[k]);
        }
      }

      break;

    case 'array':
      result = target.map((x) => emberObjectToNativeObject(x));
      break;

    default:
      result = target;
  }

  return result;
}

export default emberObjectToNativeObject;
