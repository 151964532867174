import Service from '@ember/service';

export default class SchoolApplicationService extends Service {
  /**
   * Get the blocked domain from the users username. If the email is not on the block list null is returned.
   *
   * @param {string} username The users username
   * @returns {string|null} The domain that is blocked or null.
   */
  getBlockedDomain(username = '') {
    // Block know private email providers and student email patterns
    const domainMatcher = /@(((gmail|ymail|hotmail|yahoo|outlook)\.com)|(student|elevmail|elev)(\.[a-z0-9]+)*)/i;

    const blockedDomain = username.match(domainMatcher) ?? [];

    return blockedDomain[0] ?? null;
  }
}
