import { A } from '@ember/array';
import Component from '@ember/component';
import { computed } from '@ember/object';
import PromiseProxyMixin from '@ember/object/promise-proxy-mixin';
import ObjectProxy from '@ember/object/proxy';
import { inject as service } from '@ember/service';
import licenseInfoHelper from 'babel-app/utils/license-info';
import { resolve } from 'rsvp';

const ObjectPromiseProxy = ObjectProxy.extend(PromiseProxyMixin);

export default Component.extend({
  intl: service(),

  // EXTERNAL

  product: null,

  user: null,

  licenses: null,

  availableProducts: null,

  openLicenseModal() {},

  // COMPUTED

  hasTrialLicenses: computed(
    'externalLicenses',
    'missingLicenseProduct.numMissing',
    'availableProducts.[]',
    'product.[]',
    function () {
      const productId = this.get('product.id');
      const availableProducts = this.get('availableProducts');

      if (productId && availableProducts) {
        const products = availableProducts.findBy('isbn', productId);

        if (!products) {
          return false;
        }

        return !!products.get('demo');
      }

      return false;
    }
  ),

  userLicense: computed(
    'availableProducts.[]',
    'product.id',
    'user.id',
    function() {
      const productId = this.get('product.id');
      const userId = this.get('user.id');
      const availableProducts = this.get('availableProducts');

      const availableProduct = (availableProducts || A()).findBy(
        'isbn',
        productId
      );

      const intl = this.get('intl');

      const promise = availableProducts
        ? availableProduct.get('user_licenses')
        : resolve();

      return ObjectPromiseProxy.create({
        promise: promise.then((userLicenses) => {
          const license = (userLicenses || A()).findBy('user_id', userId);

          if (license) {
            const licenseInfo = licenseInfoHelper(license, intl, true);

            license.set('text', licenseInfo.text);
            license.set('class', licenseInfo.class);
            license.set('showExtension', licenseInfo.extended);
          }

          return license;
        })
      });
    }
  )
});
