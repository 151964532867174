import shuffle from 'compton/utils/array-shuffle';

export default function (array, sortOption) {
  switch (sortOption) {
    case 'inverse':
      return array.sortBy('sort').reverse();
    case 'random':
      return shuffle(array.toArray());
    case 'name_desc':
      return array.sortBy('name').reverse();
    case 'name_asc':
      return array.sortBy('name');
    case 'title_desc':
      return array.sortBy('title').reverse();
    case 'title_asc':
      return array.sortBy('title');
    case 'date_desc':
      return array.sortBy('created').reverse();
    case 'date_asc':
      return array.sortBy('created');
    default:
      return array;
  }
}
