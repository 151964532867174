import Component from '@glimmer/component';
import { service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import config from 'babel-app/config/environment';
import { trackEvent } from '../../../../utils/matomo-events';

export default class RecycleModalComponent extends Component {
  @service store;
  @service ajax;

  @tracked isLoading = false;

  @tracked selectedLicenseGroup = null;

  @tracked selectedLicenseGroupItems = [];

  @action
  getRecyclableLicenses(_, [recyclableLicenses]) {
    const licenseGroups = Object.keys(recyclableLicenses);

    if (licenseGroups.length === 1) {
      this.selectedLicenseGroup = licenseGroups[0];
      this.selectedLicenseGroupItems =
        recyclableLicenses[this.selectedLicenseGroup];
    }
  }

  @action select(licenseGroup) {
    this.selectedLicenseGroup = licenseGroup;
    this.selectedLicenseGroupItems =
      this.args.availableProduct.recyclableLicenses[licenseGroup];
  }

  get numberOfRecyclableLicenseGroups() {
    return Object.keys(this.args.availableProduct.recyclableLicenses).length;
  }

  get numberOfLicensesToAssign() {
    if (!this.selectedLicenseGroup) {
      return 0;
    }

    const { users } = this.args;
    const licenses = this.selectedLicenseGroupItems;

    return Math.min(users.length, licenses.length);
  }

  get numberOfRemainingToAssign() {
    return this.args.users.length - this.numberOfLicensesToAssign;
  }

  @action
  async recycle() {
    this.isLoading = true;
    const { users, availableProduct } = this.args;

    const licenseGroup = this.selectedLicenseGroupItems;

    trackEvent({ category: 'Hantera licenser', action: 'Hantera licenser - Tilldela - Återanvänd startad licens', name: new Date(this.selectedLicenseGroup).toISOString().substring(0, 10) });

    const usersWithLicensesToAssign = users.slice(
      0,
      this.selectedLicenseGroupItems.length
    );

    try {
      const licenses = await this.ajax.request(
        `/api/licenses/reassign`,
        true,
        {
          type: 'POST',
          data: JSON.stringify(
            usersWithLicensesToAssign.map(({ user_id }, index) => {
              return { license_id: licenseGroup[index].id, user_id };
            })
          ),
          headers: { 'Content-Type': 'application/vnd.api+json' },
        }
      );

      await availableProduct.licensesUpdated(licenses);
      if (
        users.length === licenses.data.length ||
        availableProduct.recycled === 0
      ) {
        this.args.onClose();
      } else {
        const licenseGroups = Object.keys(availableProduct.recyclableLicenses);

        this.selectedLicenseGroup = licenseGroups.length === 1 ? licenseGroups[0] : null;
        this.selectedLicenseGroupItems = this.selectedLicenseGroup
          ? availableProduct.recyclableLicenses[this.selectedLicenseGroup]
          : [];
      }
    } catch (error) {
      this.error = error.message;
    } finally {
      this.isLoading = false;
    }
  }
}
