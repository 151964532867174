// Note: Code taken from https://stackoverflow.com/questions/28310414/what-is-the-ember-implementation-of-setinterval-and-clearinterval
import EmberObject from '@ember/object';
import { cancel, later } from '@ember/runloop';

var Poller = EmberObject.extend({
  _interval: 1000,
  _currentlyExecutedFunction: null,

  start: function(context, pollingFunction) {
    this.set(
      '_currentlyExecutedFunction',
      this._schedule(context, pollingFunction, [].slice.call(arguments, 2))
    );
  },

  stop: function() {
    cancel(this.get('_currentlyExecutedFunction'));
  },

  _schedule: function(context, func, args) {
    return later(
      this,
      function() {
        this.set(
          '_currentlyExecutedFunction',
          this._schedule(context, func, args)
        );
        func.apply(context, args);
      },
      this.get('_interval')
    );
  },

  setInterval: function(interval) {
    this.set('_interval', interval);
  }
});

export default Poller;
