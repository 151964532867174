/**
 * Utility for get element(s)
 *
 * @method getElement
 * @param {HTMLElement|String} element Element or selector for element(s)
 * @param {HTMLElement|String} [parentElement=document] If element is a String, search for element(s) in parentElement. parentElement can be a selector.
 * @param {Boolean} [multipleElements=false] Only available if element is a String. If true, return all matching elements. If false, only return first found element.
 * @returns {(HTMLElement|NodeList)} Element or, if multipleElements is true, NodeList with all matching elements.
 * @namespace utils
 *
 * @example
 * import getElement from 'babel-app/utils/get-element';
 *
 * getElement(this.element);
 * getElement('.find-me', this.element, true);
 */

const isBoolean = (value) => {
  return typeof value === 'boolean';
};

const isElement = (value) => {
  return typeof value === 'object' && value instanceof HTMLElement;
};

const isString = (value) => {
  return typeof value === 'string' && value !== '';
};

export default function(...params) {
  let element,
    elements,
    parentElement = document,
    multipleElements = false;

  // set values from params
  for (let index = params.length - 1; index >= 0; index--) {
    const value = params[index];

    if (index === 0 && isElement(value)) {
      element = value;
    } else if (index === 0 && isString(value) && multipleElements) {
      elements = parentElement.querySelectorAll(value);
    } else if (index === 0 && isString(value) && !multipleElements) {
      element = parentElement.querySelector(value);
    } else if (isElement(value)) {
      parentElement = value;
    } else if (isString(value)) {
      parentElement = document.querySelector(value);
    } else if (isBoolean(value)) {
      multipleElements = value;
    }
  }

  return element || elements || null;
}
