import EmberObject from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class FilePresenterComponent extends Component {
  @tracked imageViewerIsOpen = false;

  get imageObj() {
    return EmberObject.create({
      source: this.args.model?.url,
    });
  }
}
