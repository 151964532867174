function getAnswersForExercise(exercise, answers) {
  if (!exercise || !answers) return null;

  const exerciseKey = exercise.get('isTeacherAssignment')
    ? 'teacherAssignment.id'
    : 'id';

  const id = exercise.get(exerciseKey);

  if (!id) return null;

  const answerKey = exercise.get('isTeacherAssignment')
    ? 'teacherAssignment.id'
    : 'node_id';

  const answersForExercise = answers.filter((answer) => answer.get(answerKey) === id);

  if (answersForExercise.length === 0) return null;

  return answersForExercise;
}

function getOriginalAnswer(exercise, answers) {
  const answersForExercise = getAnswersForExercise(exercise, answers);

  if (!answersForExercise) return null;

  const originalAnswers = answersForExercise.filter(
    (answer) => !answer.belongsTo('originalAnswer').id()
  );

  if (originalAnswers.length === 0) return null;

  return originalAnswers.shift();
}

function getLatestVersion(exercise, answers) {
  const answersForExercise = getAnswersForExercise(exercise, answers);

  if (!answersForExercise) return null;

  const versions = answersForExercise.filter((answer) =>
    answer.belongsTo('originalAnswer').id()
  );

  if (versions.length > 0) {
    return versions
      .sortBy('version')
      .reverse()
      .shift();
  }

  return getOriginalAnswer(exercise, answers);
}

export { getAnswersForExercise, getOriginalAnswer, getLatestVersion };
