import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import config from 'babel-app/config/environment';
import { trackEvent } from '../../../../utils/matomo-events';

export default Component.extend({
  store: service(),
  ajax: service(),

  tagName: '',

  isLoading: false,

  row: false,
  item: false,
  school: false,
  availableProduct: false,
  searchArr: false,
  externalLicenses: false,
  actionsDisabled: false,

  agreement: null,
  userId: false,
  userModalOpen: false,

  removeModalOpen: false,

  recycleModalOpen: false,

  itemPopover: false,

  removeExtension: false,

  activeAgreement: computed('agreement.[]', function () {
    const agreement = this.get('agreement');

    if (!agreement?.length) {
      return false;
    }

    const agreements = agreement
      .filter((curr) => !curr.is_demo)
      .sort((x, y) => (x.active_until < y.active_until ? 1 : -1));

    if (agreements.length) {
      return agreements.get('firstObject');
    }

    return null;
  }),

  hasDemoAgreement: computed('agreement', function () {
    const agreement = this.get('agreement');

    if (!agreement?.length) {
      return false;
    }

    return !!agreement.filter((curr) => curr.is_demo).length;
  }),

  demoAgreement: computed('agreement', function () {
    const agreement = this.get('agreement');

    if (!agreement?.length) {
      return false;
    }

    const demoAgreements = agreement
      .filter((curr) => curr.is_demo)
      .sort((x, y) => (x.active_until < y.active_until ? 1 : -1));

    if (demoAgreements.length) {
      return demoAgreements.get('firstObject');
    }

    return null;
  }),

  canAddDemoLicenses: computed(
    'availableProduct.product.for_trial',
    'school.metadata.trials_disabled',
    function () {
      if (this.get('school.metadata.trials_disabled')) {
        return false;
      }

      if (this.get('availableProduct.product.for_trial') !== true) return false;
      return true;
    }
  ),

  canAssignLicense: computed(
    'availableProduct.unassigned',
    'availableProduct.recycled',
    'availableProduct.has_agreement',
    'availableProduct.product',
    function () {
      const availableProduct = this.get('availableProduct');

      return (
        availableProduct.unassigned > 0 ||
        availableProduct.recycled > 0 ||
        availableProduct.product.get('isExtraMaterial') ||
        (availableProduct.has_agreement &&
          !availableProduct.agreement.get('is_demo'))
      );
    }
  ),

  init() {
    this._super(...arguments);

    const item = this.get('item');

    if (item && item.get('isbn').startsWith('EX')) {
      item.set('isExtraMaterial', true);
    }
  },

  actions: {
    async assign(trial) {
      this.set('isLoading', true);
      this.set('itemPopover', false);

      const item = this.get('item');
      const userId = item.get('user_id');
      const availableProduct = this.get('availableProduct');

      let schoolQuery = '';
      let endPoint = 'assign';

      if (trial) {
        endPoint = 'trial';
        const schoolId = item.get('school_id');
        if (schoolId) {
          schoolQuery = `&school_id=${schoolId}`;
        }
      }

      try {
        const license = await this.get('ajax').request(
          `/api/licenses/${endPoint}?isbn=${availableProduct.isbn}&user_id=${userId}${schoolQuery}`,
          true,
          { type: 'POST' }
        );

        const isExtraMaterial = availableProduct.product.get('isExtraMaterial');
        const isForTrial = trial;

        if (availableProduct.product.get('isExtraMaterial')) {
          trackEvent({ category: 'Hantera licenser', action: 'Hantera licenser - Tilldela extramaterial' });
        } else if (trial) {
          trackEvent({ category: 'Hantera licenser', action: 'Hantera licenser - Tilldela testlicens' });
        } else {
          trackEvent({ category: 'Hantera licenser', action: 'Hantera licenser - Tilldela ny licens' });
        }

        await availableProduct.licensesUpdated(license);
      } catch (error) {
        this.set('error', error.message);
      } finally {
        this.set('isLoading', false);
      }
    },

    async removeExtramaterial() {
      this.set('isLoading', true);

      const item = this.get('item');
      const licenseId = item.get('license_id');
      const userId = item.get('user_id');
      const availableProduct = this.get('availableProduct');

      try {
        const license = await this.get('ajax').request(
          `/api/licenses/${licenseId}/retract`,
          true,
          { type: 'POST' }
        );
        await availableProduct.licensesUpdated(license, userId);
      } catch (error) {
        this.set('error', error.message);
      } finally {
        this.set('isLoading', false);
      }
    },

    recycle() {
      this.set('itemPopover', false);
      this.get('onRecycleLicense')(this.get('item'));
    },
    async retractLicense() {
      this.set('removeModalOpen', false);
      this.set('isLoading', true);

      trackEvent({ category: 'Hantera licenser', action: 'Hantera licenser - Dra tillbaka' });

      const item = this.get('item');
      const extension = this.get('extension') || item.extended;
      const availableProduct = this.get('availableProduct');

      const licenseId = extension ? item.extended_id : item.license_id;

      try {
        const license = await this.get('ajax').request(
          `/api/licenses/${licenseId}/retract`,
          true,
          { type: 'POST' }
        );

        await availableProduct.licensesUpdated(license, item.user_id);
      } catch (error) {
        this.set('error', error.message);
      } finally {
        this.set('isLoading', false);
      }
    },

    remove() {
      if (this.get('availableProduct.product.isExtraMaterial')) {
        this.send('removeExtramaterial');
      } else {
        this.set('removeModalOpen', true);
      }
    },

    removeExtension() {
      this.set('removeExtension', true);
      this.set('removeModalOpen', true);
    },

    showUser(userId) {
      this.set('userId', userId);
      this.set('userModalOpen', true);
    },

    openItemPopover() {
      trackEvent({ category: 'Hantera licenser', action: 'Hantera licenser - Tilldela' });
      this.set('itemPopover', true);
    },

    closeRemoveModal() {
      this.set('removeExtension', false);
      this.set('removeModalOpen', false);
    },

    closeRecycleModal() {
      this.set('recycleModalOpen', false);
    },
  },
});
