import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class AddProductsRoute extends Route {
  @service session;
  @service store;

  queryParams = {
    trial: { refreshModel: true, replace: true },
    page: { refreshModel: true, replace: true },
    search: { refreshModel: true, replace: true },
    subjects: { refreshModel: true, replace: true },
    stages: { refreshModel: true, replace: true },
    extramaterial: { refreshModel: true, replace: true },
  };

  page_size = 30;

  extramaterials = null;

  async model(params, transition) {
    const initState = transition.from?.name !== 'master.index.add-products';

    const user = this.session.user;

    const school = await user.school;

    let agreements, licenses;

    if (initState) {
      licenses = await user.get('licenses').reload();
      agreements = await this.store.findAll('agreement', { reload: true });
    } else {
      licenses = await user.get('licenses');
      agreements = await this.store.findAll('agreement');
    }

    let availableProducts;

    let products;
    let totalPages;
    let subjects;
    let stages;

    if (params.trial) {
      products = await this.store.query('product', {
        filter: {
          for_trial: true,
          extramaterial: false,
          title: params.search ? { contains: params.search } : undefined,
          subjects: params.subjects.length > 0 ? params.subjects : undefined,
          stages: params.stages.length > 0 ? params.stages : undefined,
        },
        page: {
          size: this.page_size,
          number: params.page,
        },
      });

      totalPages = products.meta.totalPages;
      subjects = products.meta.subjects.sort((a, b) => a.localeCompare(b), 'sv');
      stages = products.meta.stages.sort((a, b) => a.localeCompare(b), 'sv');
    } else {
      if (!this.extramaterials) {
        this.extramaterials = await this.store.query('product', {
          filter: {
            extramaterial: true,
          },
        });
      }

      if (!school || school?.get('metadata')?.external_licenses === true) {
        products = this.extramaterials;
      } else {
        if (initState) {
          availableProducts = await school.get('available_products').reload();
        } else {
          availableProducts = await school.get('available_products');
        }

        const availableProductsProducts = await Promise.all(
          availableProducts
            .filter(
              (availableProduct) =>
                user.isTeacher ||
                (availableProduct.has_agreement &&
                  !availableProduct.agreement.get('is_demo'))
            )
            .mapBy('product')
        );

        products = availableProductsProducts
          .addObjects(this.extramaterials)
          .uniqBy('id');
      }

      subjects = products.mapBy('subjects').flat().compact().uniq().sort();
      stages = products.mapBy('stages').flat().compact().uniq().sort();

      products = products
        .filter((product) => {
          if (product.get('teachersOnly') && !user.isTeacher) return false;

          if (!params.extramaterial && product.get('isExtraMaterial'))
            return false;

          if (params.subjects?.length > 0) {
            if (
              !product.subjects ||
              !params.subjects.some((x) => product.subjects.includes(x))
            )
              return false;
          }

          if (params.stages?.length > 0) {
            if (
              !product.stages ||
              !params.stages.some((x) => product.stages.includes(x))
            )
              return false;
          }

          if (params.search?.trim() !== '') {
            return product
              .get('title')
              .toLowerCase()
              .includes(params.search.toLowerCase());
          }

          return true;
        })
        .sortBy('title');

      totalPages = Math.ceil(products.length / this.page_size);

      products = products.slice(
        (params.page - 1) * this.page_size,
        params.page * this.page_size
      );
    }

    return {
      selectedTab: params.trial ? 'try_for_free' : 'products',
      user,
      school,
      products,
      subjects,
      stages,
      totalPages,
      availableProducts,
      licenses,
      agreements,
    };
  }

  resetController(controller, isExiting, transition) {
    super.resetController(...arguments);

    if (isExiting) {
        controller.clearState(1, true);
    }
}
}
