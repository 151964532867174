import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
export default class AdminBooksRoute extends Route {
  @service session;
  @service store;

  queryParams = {
    order: {
      refreshModel: false
    }
  };

  async model({ order = null }) {
    const school = await this.session.user.school;

    let availableProducts = [];
    let orderDetails = null;

    if (school) {
      availableProducts = await school.get('available_products');

      await Promise.all(
        availableProducts.map((availableProduct) =>
          Promise.all([
            availableProduct.get('product').then((product) => {
              return product.get('cover');
            }),
            availableProducts.get('agreement'),
          ])
        )
      );
    }

    if (order) {
      try {
        const orderRecord = await this.store.findRecord('order', order);

        if (school.id === orderRecord.schoolId) {
          orderDetails = orderRecord
        }
      }
      catch (error) {
        this.transitionTo({ queryParams: {order: null} });
      }
    }

    return {
      school,
      availableProducts,
      orderDetails,
    };
  }
}
