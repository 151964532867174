import { computed } from '@ember/object';
import DS from 'ember-data';

import { inject as service } from '@ember/service';

import Contentable from '../mixins/contentable';
import Userable from '../mixins/userable';
import Model from './model';

const { attr } = DS;

export default Model.extend(Contentable, Userable, {
  // SETUP

  intl: service(),

  validations: {
    title: {
      presence: {
        message: function(_, __, record) {
          return record.get('intl').t('models.validations.presence');
        }
      }
    }
  },

  // ATTRIBUTES

  title: attr('string'),

  content: attr('string'),

  // PROPERTIES

  titleWithFallback: computed('title', 'intl.locale', function() {
    return (
      this.get('title') || this.get('intl').t('models.note.nameWithFallback')
    );
  })
});
