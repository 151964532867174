import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { trackEvent } from '../../../utils/matomo-events';

import config from '../../../config/environment';

export default Component.extend({
  // SERVICES

  ajax: service(),

  snackbar: service(),

  intl: service(),

  // SETUP

  tagName: '',

  // EXTERNAL PROPERTIES

  user: null,

  externalUsers: false,

  // INTERNAL PROPERTIES

  showPassword: null,

  newPassword: null,

  setPasswordSuccess: false,

  setPasswordFailed: false,

  changePasswordSuccess: false,

  // COMPUTED

  showResetPassword: computed('user.username', function () {
    return this.get('user.username').includes('@');
  }),

  noDigilarLogin: computed('user.logins.isPending', function () {
    const logins = this.get('user.logins');

    if (logins) {
      const services = logins.mapBy('service');
      return services.indexOf('digilar') === -1;
    }

    return false;
  }),

  passwordErrors: computed('newPassword', {
    get() {
      return !this.get('newPassword') || this.get('newPassword.length') >= 8
        ? false
        : [this.get('intl').t('components.userModal.passwordWarning')];
    },
    set(key, val) {
      return val;
    },
  }),

  // ACTIONS

  actions: {
    resetPassword() {
      const email = this.get('user.username');

      if (email) {
        return this.get('ajax')
          .request(
            `${config['ember-simple-auth-oidc'].host}/forgot/json?return_uri=${
              window.location.origin + '/login'
            }`,
            false,
            {
              type: 'POST',
              data: {
                email: email,
              },
            }
          )
          .then((res) => {
            if (res.success) {
              trackEvent({ category: 'Användare', action: `${ this.user.role === 'teacher' ? 'Lärare' : 'Elev' } - Lösenord - Återställ lösenord` });
              this.set('changePasswordSuccess', true);
            } else {
              this.set('changePasswordFailed', true);
            }
          })
          .catch(() => {
            this.set('changePasswordFailed', true);
          });
      }
    },

    resetPasswordStates() {
      this.set('setPasswordSuccess', false);
      this.set('setPasswordFailed', false);
      this.set('changePasswordSuccess', false);
      this.set('changePasswordFailed', false);
    },

    setPassword() {
      const newPassword = this.get('newPassword');
      const userId = this.get('user.id');

      if (newPassword && userId) {
        return this.get('ajax')
          .request(
            `/api/account/password/set`,
            true,
            {
              type: 'POST',
              data: {
                userId: userId,
                password: newPassword,
              },
            }
          )
          .then(() => {
            trackEvent({ category: 'Användare', action: `${ this.user.role === 'teacher' ? 'Lärare' : 'Elev' } - Lösenord - Ändra` });
            this.set('setPasswordSuccess', true);
            this.set('newPassword', null);
          })
          .catch(() => {
            this.set('setPasswordFailed', true);
          });
      }
    },
  },
});
