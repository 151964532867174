export default {
  // Return current origin url e.g. https://example.com
  getCurrentOrigin: function() {
    let origin = window.location.origin;

    if (!origin) {
      origin =
        window.location.protocol +
        '//' +
        window.location.hostname +
        (window.location.port ? ':' + window.location.port : '');
    }

    return origin;
  },

  // Check if url match current domain
  urlIsSameOrigin: function(url) {
    if (url.startsWith(this.getCurrentOrigin())) {
      return true;
    } else {
      return false;
    }
  },

  isValidURL: function (url) {
    const expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
    const urlRegex = new RegExp(expression);
    return typeof url === 'string' && url?.match(urlRegex);
  },

  addHttp: function (url) {
    return !/^https?:\/\//i.test(url) ? `http://${url}` : url;
  },
};
