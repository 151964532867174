import { action } from '@ember/object';
import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { resolve } from 'rsvp';

export default class NavigationHelperService extends Service {
  @service router;

  @tracked primaryContent;

  @tracked secondaryContent;

  @tracked returnUrl;

  get inActiveDiagnosis() {
    return (
      this.router.currentRouteName === 'master.interactives.diagnoses.show'
    );
  }

  updateNavigationHelper(transition) {
    if (
      !this.isTransitioningFromContentsToInteractives(transition) &&
      !this.isTransitioningFromInteractivesToContents(transition)
    ) {
      this.resetNavigationHelper();
    }
  }

  resetNavigationHelper() {
    this.returnUrl = null;
    this.primaryContent = null;
    this.secondaryContent = null;
  }

  setNavigationHelper(model) {
    if (this.primaryContent) {
      if (this.primaryContent.id === model.id) {
        this.secondaryContent = null;
      } else {
        this.secondaryContent = model;
      }
    } else {
      this.primaryContent = model;
    }
  }

  setReturnUrl(transition, currentUrl) {
    if (!this.returnUrl) {
      if (transition.from && transition.urlMethod) {
        this.returnUrl = currentUrl;
      } else {
        this.returnUrl = null;
      }
    }
  }

  @action closeContent() {
    if (
      this.returnUrl &&
      !this.returnUrl.includes('nodes') &&
      this.returnUrl != this.router.currentURL
    ) {
      return this.router.transitionTo(this.returnUrl);
    }

    if (this.primaryContent) {
      return resolve(this.primaryContent.parent).then((parent) =>
        this.router.transitionTo('master.areas', parent.id)
      );
    }

    return this.router.transitionTo('master');
  }

  @action returnToPrimary() {
    if (this.primaryContent && this.secondaryContent) {
      return this.router
        .transitionTo(
          `master.${this.primaryContent.type}`,
          this.primaryContent.id
        )
        .then(() => {
          this.secondaryContent = null;
        })
        .catch(() => {});
    }
  }
}
