import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { trackEvent } from '../../../utils/matomo-events';

export default class AdminBooksController extends Controller {
  @service session;

  queryParams = ['page', 'order'];
  pageSize = 20;

  @tracked pageMenuOpen = false;
  @tracked modalOpen = false;
  @tracked availableProduct = false;
  @tracked orderDetails = this.model.orderDetails;
  @tracked filteredProducts = this.products;
  @tracked order = null;
  @tracked page = 1;
  @tracked searchValue = null;
  @tracked searchSegments = [];
  @tracked assignLicenseTo = null;

  get totalPages() {
    return Math.ceil(this.filteredProducts.length / this.pageSize);
  }

  get paginatedFilteredProducts() {
    return this.filteredProducts.slice((this.page - 1) * this.pageSize, this.page * this.pageSize);
  }

  get isLti() {
    return ['itslearning', 'canvas'].includes(this.model.school.provider);
  }

  get schoolUnitCode() {
    return this.model.school.schoolUnitCodes?.[0] ?? '';
  }

  get licensesHandledExternally() {
    return this.model.school.metadata?.external_licenses ?? false;
  }

  get products() {
    if (this.orderDetails) {
      const orderItems = this.orderDetails.items.map(item => item.isbn);

      return this.model.availableProducts.filter(availableProduct => orderItems.includes(availableProduct.get('isbn')));
    }

    return this.model.availableProducts;
  }

  get orderDate() {
    if (!this.model.orderDetails) {
      return '';
    }

    const date = new Date(this.model.orderDetails.activated);

    return date.toLocaleDateString('sv-SE');
  }

  get buyOrManageLicensesLink() {
    const provider = this.session.user.school.get('provider');
    const externalLicenses = this.session.user.school.get(
      'metadata.external_licenses'
    );
    return provider && externalLicenses
      ? 'https://skolon.com'
      : 'https://nok.se';
  }

  get buyOrManageLicensesLinkText() {
    const provider = this.session.user.school.get('provider');
    const externalLicenses = this.session.user.school.get(
      'metadata.external_licenses'
    );
    return provider && externalLicenses ? 'Skolon' : 'nok.se';
  }

  @action
  openLicense(availableProduct, assignToMe = false) {
    this.assignLicenseTo = assignToMe ? this.session.user.username : null;
    this.availableProduct = availableProduct;
    this.modalOpen = true;
  }

  async downloadLicenses() {
    const school = this.model.school;

    const response = await fetch(`/api/schools/${school.id}/licenses-excel`, {
      headers: {
        ...this.session.headers,
        accept:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
    });

    const blob = await response.blob();

    const xlsx = new Blob([blob], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
    });

    const fileUrl = URL.createObjectURL(xlsx);

    const link = document.createElement('a');

    link.setAttribute('href', fileUrl);
    link.setAttribute(
      'download',
      `Licenser ${new Date().toLocaleDateString('SV-se')} ${school.name}.xlsx`
    );

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(fileUrl);
  }

  @action
  async downloadLicensesToFile() {
    trackEvent({ category: 'Hantera licenser', action: 'Klickar trepunktsmeny - Laddar ner lista' });
    this.pageMenuOpen = false;

    if (!window.showSaveFilePicker) {
      return this.downloadLicenses();
    }

    const school = this.model.school;

    // eslint-disable-next-line no-undef
    const fileHandle = await showSaveFilePicker({
      suggestedName: `Licenser ${new Date().toLocaleDateString('SV-se')} ${
        school.name
      }.xlsx`,
      types: [
        {
          accept: {
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
              ['.xlsx'],
          },
        },
      ],
    });

    const fileDestination = await fileHandle.createWritable();

    const response = await fetch(`/api/schools/${school.id}/licenses-excel`, {
      headers: {
        ...this.session.headers,
        accept:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
    });

    response.body.pipeTo(fileDestination);
  }

  @action
  search() {
    if (this.searchValue) {
      this.searchSegments = this.searchValue.split(' ');
      this.filteredProducts = this.products
        .filter(availableProduct => availableProduct.get('product.title')?.toLowerCase()?.includes(this.searchValue))
        .sort((a, b) => {
          let productTitleA = a.get('product.title')?.toLowerCase();
          let productTitleB = b.get('product.title')?.toLowerCase();

          if (productTitleA < productTitleB) return -1;
          if (productTitleA > productTitleB) return 1;
          return 0;
        });
    }
    else {
      this.searchSegments = [];
      this.filteredProducts = this.products;
    }

    trackEvent({ category: 'Hantera licenser', action: 'Sök produkt', value: this.filteredProducts.length });
  }

  @action
  clearOrderFilter() {
    this.orderDetails = null;
    this.order = null;
    this.search();
  }

  @action
  handlePaginationChange(page) {
    trackEvent({ category: 'Hantera licenser', action: 'Bläddrar till nästa sida' });
    this.page = page;
  }

  @action
  openPageMenu() {
    trackEvent({ category: 'Hantera licenser', action: 'Klickar trepunktsmeny - Öppnar' });
    this.pageMenuOpen = true;
  }

  @action
  closePageMenu() {
    trackEvent({ category: 'Hantera licenser', action: 'Klickar trepunktsmeny - Stänger' });
    this.pageMenuOpen = false;
  }
}
