import Component from '@ember/component';

export default Component.extend({
  // SETUP

  classNames: ['container-xs', 'mx-1', 'license-warning-popover'],

  // EXTERNAL

  products: null,

  user: null,

  licenses: null,

  availableProducts: null,

  openLicenseModal() {}
});
