import Service, { service } from '@ember/service';
import config from 'babel-app/config/environment';

export default class ExportSchoolUsersService extends Service {
  @service session;
  @service store;

  filename(schoolName) {
    return `Användare ${new Date().toLocaleDateString('sv-SE')} ${
      schoolName
    }.xlsx`;
  }

  get contentType() {
    return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  }

  #downloadExcelFile(schoolId) {
    return fetch(`/api/schools/${schoolId}/users-excel`, {
      headers: {
        ...this.session.headers,
        accept: this.contentType,
      },
    });
  }

  async #downloadUsers(school) {
    const response = await this.#downloadExcelFile(school.id);
    const blob = await response.blob();

    const xlsx = new Blob([blob], {
      type: this.contentType,
    });

    const fileUrl = URL.createObjectURL(xlsx);

    const link = document.createElement('a');
    link.setAttribute('href', fileUrl);
    link.setAttribute('download', this.filename(school.name));

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(fileUrl);
  }

  async #downloadUsersWithFilepicker(school) {
    const fileHandle = await showSaveFilePicker({
      suggestedName: this.filename(school.name),
      types: [
        {
          accept: {
            [this.contentType]: ['.xlsx'],
          },
        },
      ],
    });

    const fileDestination = await fileHandle.createWritable();
    const response = await this.#downloadExcelFile(school.id);
    response.body.pipeTo(fileDestination);
  }

  async export(schoolId) {
    const school = await this.store.findRecord('school', schoolId);

    return window.showSaveFilePicker
      ? this.#downloadUsersWithFilepicker(school)
      : this.#downloadUsers(school);
  }
}
