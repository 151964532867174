import { handleReceiversRelationship } from '../utils/handle-dirty-relationship-data';
import ApplicationAdapter from './application';

export default ApplicationAdapter.extend({
  namespace: 'api/v1/meta',

  findHasMany(store, snapshot, url, relationship) {
    if (relationship?.meta?.name === 'receivers') {
      return handleReceiversRelationship(
        store,
        snapshot,
        url,
        relationship?.meta?.type
      );
    }

    return this._super(...arguments);
  },
});
