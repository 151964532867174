import { action } from '@ember/object';
import EmberObject from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class FilesPreviewerItemComponent extends Component {
  @tracked imageViewerIsOpen = false;
  @tracked mediaViewerIsOpen = false;
  @tracked externalLinkDialogIsOpen = false;

  get titleName() {
    return this.args.file?.name;
  }

  get imageObj() {
    return EmberObject.create({
      source: this.args.file?.url,
    });
  }

  get iconName() {
    let type;
    switch (this.args.file?.type) {
      case 'video':
        return 'play-square';
      case 'audio':
        return 'audio';
      case 'external':
        type = this.args.file?.externalType;
        if (type === 'google-docs') {
          return 'google';
        }
        if (type === 'office365') {
          return 'office';
        }
        return 'link';
      default:
        return 'content';
    }
  }

  get downloadable() {
    return !['external'].includes(this.args.file?.type);
  }

  @action closeImageViewer() {
    this.imageViewerIsOpen = false;
  }

  @action closeMediaViewer() {
    this.mediaViewerIsOpen = false;
  }

  @action handleRemove() {
    this.args.onRemove?.(this.args.file);
  }

  @action handleExternalLink() {
    if (['google-docs', 'office365'].includes(this.args.file.externalType)) {
      window.open(this.args.file.externalUrl, '_blank');
    } else {
      this.externalLinkDialogIsOpen = true;
    }
  }
}
