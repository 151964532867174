export default {
  xl: '(min-width: 1600px)',
  lg: '(min-width: 1200px)',
  md: '(min-width: 992px)',

  // TODO Fix these:

  sm: '(max-width: 768px)',
  xs: '(max-width: 480px)',
  mediumOrLess: '(max-width: 991px)',
};
