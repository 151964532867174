import ApplicationSerializer from './application';

const METADATA_ATTRIBUTES = ['included_entity_settings'];

export default ApplicationSerializer.extend({
  normalize(_, hash) {
    const attributes = hash.attributes;

    const metadata = attributes.metadata;

    if (metadata) {
      METADATA_ATTRIBUTES.forEach((k) => {
        attributes[k] = attributes.metadata[k];
      });

      delete attributes.metadata;
    }

    return this._super(...arguments);
  },

  serialize() {
    const json = this._super(...arguments);
    const attributes = json.data.attributes;

    const metadata = {};

    METADATA_ATTRIBUTES.forEach((k) => {
      metadata[k] = attributes[k];
      delete attributes[k];
    });

    attributes.metadata = metadata;

    return json;
  }
});
