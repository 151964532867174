import Component from '@glimmer/component';
import { htmlSafe } from '@ember/template';
import { service } from '@ember/service';

export default class AvatarPickerAvatarComponent extends Component {
  @service session;

  get user() {
    if (this.args.avatar) {
      return {
        avatar: this.args.avatar.value
      }
    }

    return {
      initials: this.session.user.initials,
    };
  }
}
