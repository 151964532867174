import { addListener, removeListener, sendEvent } from '@ember/object/events';
import { run } from '@ember/runloop';
import Service, { inject as service } from '@ember/service';
import { classify } from '@ember/string';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import PusherBatchAuthorizer from 'pusher-js-auth';
import { all, reject, resolve } from 'rsvp';

import config from '../config/environment';
import Pusher from '../utils/pusher';

const EVENTS = {
  create: 'Create',
  update: 'Update',
  destroy: 'Destroy',
  logout: 'Logout',
};

export default class PusherService extends Service {
  @service fetch;

  @service session;

  @service store;

  @tracked pusher;

  initPusher(callback) {
    if (this.pusher) {
      if (this.pusher.connection.state === 'connected') {
        // Pusher is already initialized and connected.
        // We should continue and update the channels.
        return callback();
      } else {
        // Pusher is initialized but not connected.
        // This means another instance is waiting for
        // the "connected" event. We can abort here as
        // the other instance will update the channels
        // when connection succeeds.
        return;
      }
    }

    this.pusher = new Pusher(config.pusherKey, {
      cluster: config.pusherCluster,
      forceTLS: true,
      authorizer: (channel, options) => {
        return {
          authorize: async (socketId, callback) => {
            await this.session.refreshAuthentication.perform();
            options.auth.headers = this.session.headers;
            const authorizer = new PusherBatchAuthorizer(channel, options);
            return authorizer.authorize(socketId, callback);
          },
        };
      },
      authEndpoint: '/api/pusher/auth',
      auth: { headers: this.session.headers },
    });

    // Wait for "connected" event before updating channels.
    // We can ignore other events as pusher retries automatically.
    this.pusher.connection.bind('connected', callback);
  }

  on(event, callback) {
    addListener(this, event, callback);
  }

  off(event, callback) {
    removeListener(this, event, callback);
  }

  trigger() {
    const args = Array.prototype.slice.call(arguments);
    const event = args.shift();

    sendEvent(this, event, args);
  }

  subscribe() {
    this.initPusher(() => this.updateSubscriptions.perform());
  }

  async getChannels() {
    const user = this.session?.user;
    const channels = ['global'];

    if (user) {
      channels.push(['private', 'user', user.id].join('-'));

      let courses, schools;

      try {
        [courses, schools] = await all([user.courses, user.schools]);
      } catch (err) {
        //console.log(err)
      }

      if (courses) {
        const { isTeacher, isStudent } = user;

        try {
          await all(
            courses.map(async (course) => {
              channels.push(['private', 'course', course.id].join('-'));

              if (isTeacher || isStudent) {
                let teachers, users;

                try {
                  [teachers, users] = await all([
                    course.teachers,
                    course.users,
                  ]);
                } catch (err) {
                  //console.log(err)
                }

                const teacherIds = (teachers || []).map((x) => x.id);

                if (isTeacher) {
                  const studentIds = (users || [])
                    .map((x) => x.id)
                    .filter((x) => !teacherIds.includes(x.id));

                  studentIds.forEach((studentId) => {
                    channels.push(['private', 'student', studentId].join('-'));
                  });
                }

                teacherIds.forEach((teacherId) => {
                  channels.push(['private', 'teacher', teacherId].join('-'));
                });
              }
            })
          );
        } catch (err) {
          //console.log(err)
        }
      }

      if (schools) {
        schools.forEach((school) => {
          channels.push(['private', 'school', school.id].join('-'));
        });
      }
    }

    return channels.filter((x, idx, self) => self.indexOf(x) === idx);
  }

  @task({ enqueue: true }) *updateSubscriptions() {
    const channels = yield this.getChannels();
    const pusherChannels = Object.keys(this.pusher.channels.channels);

    const channelsToAdd = (channels || []).filter(
      (channel) => !(pusherChannels || []).includes(channel)
    );

    yield all(
      channelsToAdd.map((channelName) =>
        this.subscribeToChannel(channelName).catch(() => {
          //console.log(err, channelName)
        })
      )
    );

    const channelsToRemove = (pusherChannels || []).filter(
      (channel) => !(channels || []).includes(channel)
    );

    channelsToRemove.map((channelName) =>
      this.unsubscribeFromChannel(channelName)
    );
  }

  subscribeToChannel(channelName) {
    return new Promise((resolve, reject) => {
      const channel = this.pusher.subscribe(channelName);

      channel.bind('pusher:subscription_succeeded', resolve);
      channel.bind('pusher:subscription_error', reject);

      channel.bind_global((event, data) =>
        this._handleEvent(event, data, channel)
      );
    });
  }

  unsubscribeFromChannel(channelName) {
    const channel = this.pusher.channel(channelName);

    channel.unbind_global();

    this.pusher.unsubscribe(channelName);
  }

  _handleEvent(event, data, channel) {
    run(async () => {
      if (data && data.type) {
        this.trigger('didReceive' + classify(data.type), data);
      }

      if (data && data.type === 'course') {
        const user = this.session.user;

        return resolve(user.courses)
          .then((courses) => courses.reload())
          .then(() => this.subscribe());
      }

      if (data && data.type === 'answer') {
        const answer = this.store.peekRecord('answer', data.id);

        if (answer) {
          const url = `${config.endpoint}/api/v1/meta/answers/${data.id}`;

          return this.fetch.request(url, true).then((response) => {
            if (
              !['started', 'not-started'].includes(answer.status) &&
              response?.data?.attributes?.status
            ) {
              answer.status = response.data.attributes.status;
            }
          });
        }

        return resolve();
      }

      if (data && data.type === 'maintenance-mode') {
        return this.session.user.reload();
      }

      if (data && data.type === 'license') {
        if (channel?.name?.startsWith('private-user-')) {
          return this.session.refreshUserLicenses();
        } else {
          if (data.user === this.session.user.id) {
            return resolve();
          }

          const pushedLicense = data.data;

          return resolve(this.session.user.school)
            .then((school) => {
              const relation = school && school.hasMany('available_products');

              if (!relation || relation.value() === null) {
                return reject();
              }

              return resolve(school.available_products);
            })
            .then((available_products) => {
              const available_product =
                available_products &&
                available_products.findBy('isbn', pushedLicense.isbn);

              if (!available_product) {
                return reject();
              }

              available_product.licensesHaveChanged = true;

              this.store.peekAll('course').forEach((course) => {
                if (!course.active) return;

                if (
                  !course.hasMany('products').ids().includes(pushedLicense.isbn)
                ) {
                  return;
                }

                if (course.hasMany('missingLicenseProducts').value() === null) {
                  return;
                }

                course.licensesHaveChanged = true;
              });
            })
            .catch(() => {});
        }
      }

      if (data && data.type === 'school') {
        if (channel?.name?.startsWith('private-school-')) {
          return resolve(this.session.user.school).then((school) => {
            return school.users.reload();
          });
        }
      }

      if (event === EVENTS.create) {
        const record = this.store.peekRecord(data.type, data.id);

        if (!record) {
          try {
            const newRecord = await this.store.findRecord(data.type, data.id);

            this.trigger(data.type, newRecord, 'create');
          } catch (error) {
            // console.log(error);
          }
        }
      } else if (event === EVENTS.update) {
        let record = this.store.peekRecord(data.type, data.id);

        if (record && !record.isSaving) {
          try {
            await record.reload();
            this.trigger(data.type, record, 'update');
          } catch (error) {
            // NOTE If we cannot reload the record, we can assume it's no longer
            // available and should thus be unloaded from the store.
            this.trigger(data.type, record, 'willDelete');
            record.unloadRecord();
          }
        } else {
          try {
            record = await this.store.findRecord(data.type, data.id);
            this.trigger(data.type, record, 'create');
          } catch (error) {
            // console.log(error);
          }
        }
      } else if (event === EVENTS.destroy) {
        const record = this.store.peekRecord(data.type, data.id);

        if (record && !record.isDeleted) {
          this.trigger(data.type, record, 'willDelete');
          record.deleteRecord();
          this.trigger(data.type, record, 'didDelete');
        }
      } else if (event === EVENTS.logout) {
        this.trigger('logout', data);
      }
    });
  }
}
