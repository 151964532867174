import Component from '@glimmer/component';

export default class AddStudentsPrintComponent extends Component {
  get userCount() {
    return this.args.users?.length ?? 0;
  }

  get usersWithClassName() {
    const classes = this.args.classes;

    return this.args.users.map((user) => {
      const classGroup = classes.findBy('id', user.class);

      if (classGroup) {
        user.className = classGroup.get('name');
      }

      return user;
    });
  }
}
