import DS from 'ember-data';

import Model from './model';

const { attr, belongsTo } = DS;

export default Model.extend({
  // ATTRIBUTES

  title: attr('string'),

  activity: belongsTo('activity', { inverse: 'activityItem' })
});
