import Component from '@ember/component';
import ConfirmSupport from 'compton/mixins/confirm-support';

export default Component.extend(ConfirmSupport, {
  tagName: '',

  // EXTERNAL PROPERTIES

  roleName: null,

  provider: null,

  // INTERNAL PROPERTIES

  popupOpen: false,
});
