import Component from '@ember/component';
import { computed } from '@ember/object';
import { htmlSafe } from '@ember/template';

export default Component.extend({
  classNames: ['onboarding-template'],

  // EXTERNAL PROPERTIES

  image: null,

  hasFooter: true,

  done: false,

  canContinue: true,

  onNext() {},

  login: false,

  onLogin() {},

  // COMPUTED PROPERTIES

  imageStyle: computed('image', function () {
    const url = this.get('image');
    return url && htmlSafe(`background-image: url(${url})`);
  }),
});
