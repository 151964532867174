import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { trackEvent } from '../../../../utils/matomo-events';

export default class AdminTeachersInviteRoute extends Route {
  @service store;
  @service session;

  beforeModel() {
    trackEvent({
      category: 'Användare',
      action: 'Bjud in lärare - Med länk eller skolkod - Öppna'
    });
  }

  async model(params) {
    const school = await this.session.user.school;

    const accessCodes = await this.store.query('school-access-code', {
      school_id: school.id,
      role: 'teacher',
    });

    return {
      codes: accessCodes,
      school,
    };
  }
}
