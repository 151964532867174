import EmberObject, { set } from '@ember/object';
import { typeOf } from '@ember/utils';

function objectToEmberObject(target) {
  let result;

  switch (typeOf(target)) {
    case 'object':
      result = EmberObject.create();

      for (const k in target) {
        if (target.hasOwnProperty(k)) {
          const v = target[k];
          set(result, k, objectToEmberObject(v));
        }
      }

      break;

    case 'array':
      result = target.map((x) => objectToEmberObject(x));
      break;

    default:
      result = target;
  }

  return result;
}

export default objectToEmberObject;
