import Component from '@ember/component';
import { computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { validateEmail } from 'babel-app/helpers/validate-email';
import { task, timeout } from 'ember-concurrency';

const DEBOUNCE_TIME = 500;

export default Component.extend({
  // INJECT SERVICES

  session: service(),

  intl: service(),

  // PROPERTIES

  email: alias('session.user.email'),

  // COMPUTED

  emailError: computed('email', function () {
    if (!this.get('email') || validateEmail(this.get('email'))) {
      return;
    } else {
      return [this.intl.t('profile.errors.invalidEmail')];
    }
  }),

  // TASKS

  saveUser: task(function* () {
    yield this.get('session.user').save();
  }).keepLatest(),

  updatedUser: task(function* () {
    yield timeout(DEBOUNCE_TIME);
    this.get('saveUser').perform();
  }).restartable(),
});
