import Component from '@ember/component';

export default Component.extend({
  // SETUP

  tagName: '',

  // PARAMS

  title: null,

  fullscreen: false,

  size: null,

  closeOnRouteChange: null,

  onClose: null
});
