import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import DS from 'ember-data';
import { resolve } from 'rsvp';

import Model from './model';

const { attr, belongsTo } = DS;

export default Model.extend({
  // SETUP

  intl: service(),

  store: service(),

  validations: {
    word: {
      presence: {
        message: function(_, __, record) {
          return record.get('intl').t('models.validations.presence');
        }
      }
    }
  },

  // RELATIONSHIPS

  wordlist: belongsTo('wordlist'),

  // ATTRIBUTES

  word: attr('string'),

  explanation: attr('string'),

  translation: attr('string'),

  wordClass: attr('string'),

  examples: attr('string'),

  wordConstruction: attr('string'),

  sort: attr('number'),

  // PROPERTIES

  readOnly: false,

  // METHODS

  duplicate() {
    return resolve(
      this.get('store').createRecord('word', {
        ...this.duplicateAttributes()
      })
    );
  },

  // LEGACY

  word_class: alias('wordClass'),
  word_construction: alias('wordConstruction')
});
