import { A } from '@ember/array';
import Component from '@ember/component';
import { computed } from '@ember/object';
import { alias, not } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default Component.extend({
  session: service(),

  // SETUP

  tagName: '',

  // EXTERNAL

  user: null,

  usersMissingLicenses: null,

  availableProducts: null,

  products: null,

  openLicenseModal() {},

  showText: true,

  // INTERNAL

  popoverOpen: false,

  // COMPUTED

  externalLicenses: alias('session.user.school.metadata.external_licenses'),

  showMissingLicenses: not('externalLicenses'),

  isMissingLicenses: computed(
    'usersMissingLicenses.@each.items',
    'user.[]',
    function() {
      const userId = this.get('user.id');

      return (this.get('usersMissingLicenses') || A()).any(
        (missingLicenseProduct) =>
          missingLicenseProduct
            .get('items')
            .filterBy('id', userId)
            .filterBy('status', 'expired').length > 0
      );
    }
  ),

  // ACTIONS

  actions: {
    openLicenseModal() {
      this.set('popoverOpen', false);

      return this.openLicenseModal(...arguments);
    }
  }
});
