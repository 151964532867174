import { helper as buildHelper } from '@ember/component/helper';

/**
 *  @desc Can move to delta in array
 *  @param array, object, delta
 *  @return bool
 **/

export function canAdvance(params) {
  var index, length, array, object, delta;
  array = params[0];
  object = params[1];
  delta = params[2];

  length = array.get('length');
  index = array.indexOf(object) + delta;

  if (index < 0 || index > length - 1) {
    return false;
  }

  return true;
}

export default buildHelper(canAdvance);
