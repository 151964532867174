import { helper } from '@ember/component/helper';
import moment from 'moment';

export function datetime([datetime]) {
  if (datetime) {
    return moment(datetime).format('YYYY-MM-DD HH:mm');
  }

  return null;
}

export default helper(datetime);
