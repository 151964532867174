import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import config from 'babel-app/config/environment';
import { getOwner } from '@ember/application';
import { getConfig } from 'ember-simple-auth-oidc/config';
import jwtDecode from 'ember-cli-jwt-decode';
import { storageFor } from 'ember-local-storage';
import ESASession from 'ember-simple-auth-oidc/services/session';
import { resolve } from 'rsvp';
import getAbsoluteUrl from 'ember-simple-auth-oidc/utils/absolute-url';
import {
  generateCodeVerifier,
  generatePkceChallenge,
} from 'ember-simple-auth-oidc/utils/pkce';
import { v4 } from 'uuid';

export default class SessionService extends ESASession {
  @service imbox;

  @service store;

  @service router;

  @service pusher;

  @service ajax;

  @storageFor('session-states') states;

  @tracked inPreviewMode = false;

  @tracked lastLoggedProduct = null;

  get inUndercover() {
    return this.states?.inUndercover;
  }

  get accessToken() {
    return this.session?.content?.authenticated?.access_token;
  }

  get clientUserRole() {
    return jwtDecode(this.accessToken)?.role;
  }

  get isUser() {
    return this.clientUserRole === 'user';
  }

  get isAdmin() {
    return this.clientUserRole === 'admin';
  }

  get isSuperAdmin() {
    return this.clientUserRole === 'superadmin';
  }

  constructor() {
    super(...arguments);

    this.pusher.on('logout', (logoutEvent) => {
      const idToken = this.data.authenticated.id_token;

      if (idToken) {
        // if the session sent by logoutEvent is the same as idToken session
        // we should logout this session
        if (jwtDecode(idToken).sid === logoutEvent.id) {
          // Invalidate the ember-simple-auth session
          this.invalidate();

          const authenticator = this.session._lookupAuthenticator(
            this.session.authenticator
          );

          // Trigger a single logout on the authorization server
          return authenticator.singleLogout(idToken);
        }
      } else {
        this.router.transitionTo('/login');
      }
    });
  }

  refreshUserSession() {
    return this.ajax.request(
      `/api/users/refresh-userdata`,
      true,
      {
        type: 'GET',
      }
    );
  }

  refreshUserLicenses() {
    return this.ajax
      .request('/api/users/refresh-licenses', true, {})
      .then((data) =>
        resolve(this.user.licenses)
          .then((licenses) =>
            licenses
              .reload()
              .then(() => licenses.invoke('notifyPropertyChange', 'userIds'))
          )
          .then(() => data)
      );
  }

  load(stayOnPage = false) {
    return this.store
      .queryRecord('user', { me: true })
      .then((user) => {
        return Promise.all([
          user,
          user.get('readNotices')
        ])
      })
      .then(([user, readNotices]) => {
        if (!this.user) {
          this.user = user;
        }

        if (user) {
          const hasSeenTeacherGuide = readNotices.find(
            (notice) => notice.notice_key === 'has_seen_teacher_guide'
          );

          if (
            user.isTeacher &&
            user.approved_terms !== config.termsVersion
          ) {
            this.router.replaceWith('onboarding.terms');
          } else if (!user.firstname) {
            this.router.replaceWith('onboarding.name');
          } else if (user.isTeacher && !hasSeenTeacherGuide) {
            this.router.replaceWith('onboarding.guide');
          }
        }

        return user;
      })
      .then((user) => {
        user.get('school').then((school) => {
          this.imbox.load(user, school);
        })

        return user;
      })
      .catch((err) => {
        if (err.status === 503) {
          const errorDetail = err.responseJSON?.errors?.[0]?.detail;

          if (errorDetail === 'Maintenance mode') {
            const source = encodeURIComponent(window.location.href);
            const health = encodeURIComponent(`${config.endpoint}/health`);
            const target = `${config.maintenanceHost}/?source=${source}&health=${health}`;

            window.location.href = target;
            return;
          }
        }

        this.invalidate();

        if (!stayOnPage) this.triggerPromptNone();
      });
  }

  triggerPromptNone() {
    const state = v4();

    // Store state to session data
    this.set('data.state', state);

    /**
     * Store the `nextURL` in the localstorage so when the user returns after
     * the login he can be sent back to error destination.
     */
    const nextUrl = window.location.pathname === '/login' ? '/' : window.location.pathname;
    this.set('data.nextURL', nextUrl);

    const esaConfig = getConfig(getOwner(this));

    const { protocol, host } = location;

    let search = [
      `client_id=${esaConfig.clientId}`,
      `redirect_uri=${protocol}//${host}/login`, // cant use this.redirectUri because it will point to whatever route path we are on
      `response_type=code`,
      `state=${state}`,
      `scope=${esaConfig.scope}`,
      `prompt=none`,
    ];

    if (esaConfig.enablePkce) {
      const pkceChallenge = generatePkceChallenge(
        this.getPkceVerifier()
      );
      search.push(`code_challenge=${pkceChallenge}`);
      search.push('code_challenge_method=S256');
    }

    search = search.filter(Boolean).join('&');

    location.replace(
      `${getAbsoluteUrl(esaConfig.host)}${esaConfig.authEndpoint}?${search}`
    );
  }

  getPkceVerifier() {
    let verifier = this.data.pkceCodeVerifier;

    if (!verifier) {
      verifier = generateCodeVerifier(96);
      this.set('data.pkceCodeVerifier', verifier);
    }

    return verifier;
  }

  singleLogout() {
    const idToken = this.data.authenticated.id_token;

    // Invalidate the ember-simple-auth session
    this.invalidate();

    if (idToken) {
      // Trigger a single logout on the authorization server
      const authenticator = this.session._lookupAuthenticator(
        this.session.authenticator
      );

      return authenticator.singleLogout(idToken);
    } else {
      // No id_token found which means we can not use post_logout_redirect_uri
      return location.replace(
        `${config['ember-simple-auth-oidc'].host}/session/end`
      );
    }
  }

  invalidateSession() {
    return this.invalidate();
  }

  restore() {
    return this.session.restore();
  }
}
