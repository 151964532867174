import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import config from 'babel-app/config/environment';
import { v4 as uuid } from 'ember-uuid';
import { trackEvent } from '../../../utils/matomo-events';

const getBaseUser = () => ({
  username: '',
  usernameError: false,
  password: '',
  passwordError: false,
  firstname: '',
  lastname: '',
  skip: false,
});

export default class AddStudentsComponent extends Component {
  @service store;
  @service intl;
  @service session;
  @service ajax;
  @service('confirmManager') confirm;

  @tracked userNamePopupOpen = false;
  @tracked passwordPopupOpen = false;
  @tracked userNamePrefix = null;
  @tracked prefixTaken = false;
  @tracked generatedUserNames = [];
  @tracked generatedPasswords = [];
  @tracked generatedFirstNames = [];
  @tracked generatedLastNames = [];
  @tracked generatedClasses = [];
  @tracked userNameErrors = [];
  @tracked passwordErrors = [];
  @tracked sameForAll = false;
  @tracked addingNames = false;
  @tracked addingPasswords = false;
  @tracked createdUnsavedUsers = null;
  @tracked hasGeneratedRows = false;
  @tracked allFieldsEmpty = null;
  @tracked validationErrors = null;
  @tracked list = null;
  @tracked usersToCreate = Array.from({ length: 5 }).map(getBaseUser);
  @tracked numOfUsers = 5;
  @tracked maxUsers = 500;
  @tracked usersToPrint = [];
  @tracked userNameErrors = [];
  @tracked print = false;
  @tracked printing = this.args.printing;
  @tracked allFieldsEmpty = true;
  @tracked columns = [
    {
      text: 'admin.createAccountsUserName',
      icon: null,
      tooltip: null,
      id: uuid(),
      colspan: '',
    },
    {
      text: 'admin.createAccountsPassWord',
      icon: null,
      tooltip: null,
      id: uuid(),
      colspan: '',
    },
    {
      text: 'admin.createAccountsFirstName',
      icon: 'info-circle',
      tooltip: 'admin.firstNameTooltip',
      id: uuid(),
      colspan: '',
    },
    {
      text: 'admin.createAccountsLastName',
      icon: 'info-circle',
      tooltip: 'admin.lastNameTooltip',
      id: uuid(),
      colspan: '',
    },
    {
      text: 'admin.createAccountsClass',
      icon: 'info-circle',
      tooltip: 'admin.classTooltip',
      type: 'class',
      id: uuid(),
      colspan: '2',
    },
  ];

  #addRows(numOfRows = 5, remove = false) {
    const existingUsersToCreate = this.usersToCreate;

    if (remove) {
      const newUsersToCreate = existingUsersToCreate.slice(0);
      newUsersToCreate.splice(parseInt(numOfRows, 10));
      this.usersToCreate = newUsersToCreate;
    } else {
      if (existingUsersToCreate.length + numOfRows > this.maxUsers) {
        return;
      }

      const moreUsersToCreate = Array.from({ length: numOfRows }).map(
        getBaseUser
      );

      this.usersToCreate = existingUsersToCreate.concat(moreUsersToCreate);
    }

    this.numOfUsers = this.usersToCreate.length;
    this.hasGeneratedRows = true;
  }

  #addRowValues() {
    const usersToCreate = this.usersToCreate;

    const generatedUserNames = this.generatedUserNames;
    const generatedPasswords = this.generatedPasswords;

    const newUsersToCreate = usersToCreate.map((user, index) => {
      return {
        ...user,
        username: generatedUserNames[index] ?? user.username,
        usernameError: false,
        password: generatedPasswords[index] ?? user.password,
        passwordError: false,
      };
    });

    // store generated userdata in local state to be sent to print later
    this.usersToCreate = newUsersToCreate;
  }

  #removeSpaces(s) {
    return s.replace(/\s/g, '');
  }

  #mapUnusedRows(rows) {
    const mapped = rows.map((row) => {
      if (
        row.username.trim() === '' &&
        row.password.trim() === '' &&
        row.firstname.trim() === '' &&
        row.lastname.trim() === ''
      ) {
        row.skip = true;
      } else {
        row.skip = false;
      }
      return row;
    });
    return mapped;
  }

  #filterUnusedRows(rows) {
    const filtered = rows.filter(
      (row) =>
        row.username.trim() !== '' ||
        row.password.trim() !== '' ||
        row.firstname.trim() !== '' ||
        row.lastname.trim() !== ''
    );

    return filtered;
  }

  #checkRowFields() {
    const filtered = this.#filterUnusedRows(this.usersToCreate);
    const isEmpty = filtered.length === 0;
    this.allFieldsEmpty = isEmpty;
  }

  #resetGenerated() {
    if (this.numOfUsers === 0 && this.usersToCreate.length === 0) {
      this.generatedUsernames = [];
      this.generatedPasswords = [];
    }
  }

  constructor() {
    super(...arguments);

    this.selectClassPlaceholder = this.intl.t('admin.selectClass');
  }

  get selectableClasses() {
    const classes = this.args.classes;
    const noClassOption = [
      {
        label: this.intl.t('admin.generatePopup.noClassLabel'),
        value: null,
      },
    ];

    const sortedClasses = classes
      .filter((x) => !x.get('metadata.classLessClass'))
      .sortBy('name')
      .map((x) => {
        return {
          label: x.get('name'),
          value: x.get('id'),
        };
      });

    const selectableClasses = sortedClasses.filter((x) => {
      return x.label && x.label.toLowerCase();
    });

    return noClassOption.concat(selectableClasses);
  }

  get title() {
    if (this.print) {
      return this.intl.t('admin.addStudentsPrint.title');
    } else {
      return this.intl.t('admin.createStudentAccountsWithUserNamesTitle', {
        role: this.roleText,
      });
    }
  }

  @action
  createAccounts() {
    if (this.#filterUnusedRows(this.usersToCreate).length === 0) {
      return this.confirm.perform('Fyll i minst en rad', {
        danger: true,
        cancelLabel: null,
        okLabel: 'OK',
      });
    }

    const usersToCreate = this.#mapUnusedRows(this.usersToCreate);
    this.addingAccounts = true;

    return this.session.user.school.then((school) => {
      const schoolId = school.get('id');

      return this.ajax
        .request(`/api/schools/${schoolId}/add-users-with-validation`, true, {
          type: 'POST',
          data: {
            users: usersToCreate,
            userType: 'student',
          },
        })
        .then((result) => {
          if (result.error) {
            const copy = this.usersToCreate.slice();

            result.validation_errors.forEach((userErrors, userIndex) => {
              const user = copy[userIndex];

              let usernameError = false;
              let passwordError = false;

              if (userErrors.error === true) {
                userErrors.validation_errors.forEach((fieldErrors) => {
                  if (fieldErrors.field === 'username') {
                    usernameError = fieldErrors.error;
                  } else if (fieldErrors.field === 'password') {
                    passwordError = fieldErrors.error;
                  }
                });

                copy.splice(userIndex, 1, {
                  ...user,
                  usernameError,
                  passwordError,
                });
              }

              if (userErrors.error === false) {
                usernameError = false;
                passwordError = false;
                copy.splice(userIndex, 1, {
                  ...user,
                  usernameError,
                  passwordError,
                });
              }
            });

            this.usersToCreate = copy;
            this.addingAccounts = false;
          } else {
            trackEvent({ category: 'Användare', action: 'Bjud in elev - Med användarnamn - Skapa konton', value: usersToCreate.length });

            const affectedGroupIds = usersToCreate.map((user) => user.class);
            const uniqueAffectedGroupIds = [...new Set(affectedGroupIds)];

            const classes = this.args.classes;

            const affectedGroups = uniqueAffectedGroupIds.map((groupId) => {
              return classes.find(
                (classGroup) => classGroup.get('id') === groupId
              );
            });

            this.args.reloadRelations(school, affectedGroups).then(() => {
              this.addingAccounts = false;
              this.usersToPrint = this.#filterUnusedRows(this.usersToCreate);
              this.print = true;
            });
          }
        });
    });
  }

  @action
  addRows() {
    this.#addRows();
  }

  @action
  removeRow(index) {
    const existingUsersToCreate = this.usersToCreate;
    const numOfUsers = this.numOfUsers;

    // create a new array so we dont modify in place
    const clonedArray = existingUsersToCreate.slice();
    clonedArray.splice(index, 1);

    this.usersToCreate = clonedArray;
    this.numOfUsers = numOfUsers - 1;
    this.#checkRowFields();
    this.#resetGenerated();
  }

  @action
  generateUserNames(numOfUsers, userNamePrefix) {
    this.userNameErrors = [];
    this.addingNames = true;
    // this.resetErrors();
    this.ajax
      .request(
        `/api/users/check-if-prefix`,
        true,
        {
          data: { prefix: this.#removeSpaces(userNamePrefix) },
          method: 'post',
        }
      )
      .then((res) => {
        if (res.error) {
          this.userNameErrors = [
            this.intl.t(`admin.generatePopup.${res.error}`),
          ];
          this.addingNames = false;
          return;
        }

        if (!res) {
          const names = [];
          const prefix = this.#removeSpaces(userNamePrefix);
          for (let i = 0; numOfUsers > i; i++) {
            names.push(`${prefix}${i + 1}`);
          }

          this.generatedUserNames = names;

          // if new num of users is lower than users then we need to remove
          // rows so send the remove flag to #addRows
          if (numOfUsers < this.usersToCreate.length) {
            this.#addRows(numOfUsers, true);
          } else {
            this.#addRows(numOfUsers - this.usersToCreate.length);
          }

          this.#addRowValues();
          this.userNamePopupOpen = false;
        } else {
          this.userNameErrors = [
            this.intl.t('admin.generatePopup.prefixUsed', {
              prefix: this.#removeSpaces(userNamePrefix),
            }),
          ];
        }
      })
      .catch((error) => {
        const eObj = JSON.parse(error.responseText);
        const messages = eObj.errors.map((e) => e.detail);
        this.userNameErrors = messages;
      })
      .finally(() => {
        this.addingNames = false;
        this.#checkRowFields();
      });
  }

  @action
  generatePasswords(sameForAll, commonPassword) {
    this.passwordErrors = [];
    this.addingPasswords = true;

    trackEvent({
      category: 'Användare',
      action: 'Bjud in elev - Med användarnamn - Genererar lösenord'
    });

    if (sameForAll) {
      if (!commonPassword || commonPassword.length < 8) {
        this.passwordErrors = [
          this.intl.t(`admin.generatePopup.enterEightChars`),
        ];
        this.addingPasswords = false;
        return;
      }

      const passwords = new Array(Number(this.numOfUsers)).fill(commonPassword);

      this.generatedPasswords = passwords;
      this.#addRowValues();
      this.#checkRowFields();
      this.addingPasswords = false;
      this.passwordPopupOpen = false;
    } else {
      this.addingPasswords = true;
      this.ajax
        .request(
          `/api/users/generate-passwords`,
          true,
          {
            data: { number_of_passwords: this.numOfUsers },
            method: 'post',
          }
        )
        .then((passwords) => {
          this.generatedPasswords = passwords;
          this.#addRowValues();
          this.passwordPopupOpen = false;
        })
        .catch((error) => {
          const eObj = JSON.parse(error.responseText);
          const messages = eObj.errors.map((e) => e.detail);
          this.passwordErrors = messages;
        })
        .finally(() => {
          this.#checkRowFields();
          this.addingPasswords = false;
        });
    }

    this.#checkRowFields();
  }

  @action
  doPrint() {
    trackEvent({
      category: 'Användare',
      action: 'Bjud in elev - Med användarnamn - Skriv ut eller spara'
    });

    this.printing = true;
    window.document.body.classList.add('printing-add-students');
    window.print();
  }

  @action
  close() {
    if (this.print) {
      return this.confirm
        .perform(this.intl.t('admin.addStudentsPrint.confirm'), {
          danger: false,
          okLabel: this.intl.t('admin.addStudentsPrint.confirmButton'),
        })
        .then(() => {
          this.printing = false;
          window.document.body.classList.remove('printing-add-students');
          this.args.close();
        });
    }

    this.args.close();
  }

  @action
  checkRowFields(value) {
    this.#checkRowFields(value);
  }

  @action togglePasswordPopup() {
    this.passwordPopupOpen = !this.passwordPopupOpen;
  }

  @action toggleUserNamePopup() {
    this.userNamePopupOpen = !this.userNamePopupOpen;
  }

  @action setUsers(users) {
    this.usersToCreate = [...users];
  }
}
