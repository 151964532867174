import { inject as service } from '@ember/service';
import config from 'babel-app/config/environment';
import { chunk } from 'compton/utils/array';
import OIDCJSONAPIAdapter from 'ember-simple-auth-oidc/adapters/oidc-json-api-adapter';
import { v4 as uuid } from 'ember-uuid';

export default class ApplicationAdapter extends OIDCJSONAPIAdapter {
  @service session;

  coalesceFindRequests = true;

  namespace = 'api';

  get headers() {
    const headers = this.session?.headers;

    if (headers && this.session?.inPreviewMode) {
      headers['x-preview'] = true;
    }

    return headers;
  }

  handleResponse(status, info, errors) {
    if (status === 503) {
      const errorDetail = errors?.errors?.[0]?.detail;

      if (errorDetail === 'Maintenance mode') {
        const source = encodeURIComponent(window.location.href);
        const health = encodeURIComponent(`${config.endpoint}/health`);
        const target = `${config.maintenanceHost}/?source=${source}&health=${health}`;

        window.location.href = target;
        return;
      }
    }

    return super.handleResponse(...arguments);
  }

  generateIdForRecord() {
    return uuid();
  }

  groupRecordsForFindMany(_, snapshots) {
    return chunk(snapshots, 20);
  }

  shouldBackgroundReloadRecord() {
    return false; // Can not be true for sections, causes reload of sections that breaks speaker highlighting
  }

  shouldBackgroundReloadAll() {
    return false; // Can not be true for sections, causes reload of sections that breaks speaker highlighting
  }

  createRecord(store, type, snapshot) {
    const data = {};
    const serializer = store.serializerFor(type.modelName);

    serializer.serializeIntoHash(data, type, snapshot, { includeId: true });

    const id = snapshot.id;
    let url = this.buildURL(type.modelName, id, snapshot, 'createRecord');

    if (snapshot.adapterOptions && snapshot.adapterOptions.action) {
      url += '?action=' + snapshot.adapterOptions.action;
    }

    return this.ajax(url, 'POST', { data: data }).then((payload) => {
      if (
        snapshot.adapterOptions &&
        snapshot.adapterOptions.ignoreResponsePayload
      ) {
        return null;
      }
      return payload;
    });
  }

  updateRecord(store, type, snapshot) {
    const data = {};
    const serializer = store.serializerFor(type.modelName);

    serializer.serializeIntoHash(data, type, snapshot, { includeId: true });

    const id = snapshot.id;
    let url = this.buildURL(type.modelName, id, snapshot, 'updateRecord');

    if (snapshot.adapterOptions && snapshot.adapterOptions.action) {
      url += '?action=' + snapshot.adapterOptions.action;
    }

    return this.ajax(url, 'PATCH', { data: data }).then((payload) => {
      if (
        snapshot.adapterOptions &&
        snapshot.adapterOptions.ignoreResponsePayload
      ) {
        return null;
      }
      return payload;
    });
  }
}
