import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class AdminStudentsRoute extends Route {
  @service session;
  @service store;

  queryParams = {
    search: {
      refreshModel: false
    }
  };

  async model() {
    const school = await this.session.user.school;
    const [users, groups] = await Promise.all([
      school.users,
      school.groups.then(async (groups) => {
        await Promise.all(groups.map((group) => group.users));
        return groups;
      }),
    ]);

    return {
      users,
      groups,
      school,
    };
  }
}
