import Component from '@glimmer/component';
import { action } from '@ember/object';
import { trackEvent } from '../../../utils/matomo-events';

export default class FilterHelperComponent extends Component {
  get typeOfListing() {
    return this.args.trial ? 'Prova gratis' : 'Produkter och extramaterial';
  }

  @action updateSubject() {
    trackEvent({
      category: 'Produkt',
      action: `Lägg till produkt - ${this.typeOfListing} - Välj kategorier`,
      value: this.args.subject.length
    });
  }

  @action updateStage() {
    trackEvent({
      category: 'Produkt',
      action: `Lägg till produkt - ${this.typeOfListing} - Välj ålder`,
      value: this.args.stage.length
    });
  }

  @action toggleExtraMaterial() {
    trackEvent({
      category: 'Produkt',
      action: `Lägg till produkt - ${this.typeOfListing} - ${this.args.extramaterial ? 'Visa' : 'Dölj'} extramaterial`
    });
  }
}

