import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { camelize } from '@ember/string';

const PRODUCTS = 'products';
const TRY_FOR_FREE = 'try_for_free';

export default Component.extend({
  // SETUP

  session: service(),

  intl: service(),

  tagName: '',

  // PARAMS

  onPick() {},

  onUnpick() {},

  onClose() {},

  onSave() {},

  products: null,

  availableProducts: null,

  pickedProducts: null,

  range: 'large',

  school: null,

  navItems: computed(
    'intl.locale',
    'session.user.{isTeacher,isTestUser}',
    'school.{hasAgreement,metadata.trials_disabled,metadata.external_licenses}',
    function () {
      const user = this.get('session.user');

      const t = (k) => {
        return this.get('intl').t(
          [
            'components',
            'productPickerModal',
            'navItems',
            camelize(k),
          ].join('.')
        );
      };

      const navItems = [];
      const school = this.get('school');
      const trialsAvailable =
        !school || school.get('metadata.trials_disabled') !== true;

      navItems.addObject({
        value: PRODUCTS,
        label: t(PRODUCTS),
      });

      if (
        trialsAvailable &&
        (user.get('isTeacher') || user.get('isTestUser'))
      ) {
        navItems.addObject({
          value: TRY_FOR_FREE,
          label: t(TRY_FOR_FREE),
        });
      }

      return navItems;
    }
  ),

  _section: null,

  section: computed('navItems.firstObject.value', {
    get() {
      return this.get('_section') || this.get('navItems.firstObject.value');
    },
    set(_, value) {
      this.set('_section', value);
      return value;
    },
  }),

  // HOOKS

  init() {
    this._super(...arguments);

    this.set('query', {
      subject: [],
      stage: [],
      search: '',
      page: 1,
      extra: true
    });
  },

  // ACTIONS

  actions: {
    handleProductCollatorQuery(query) {
      this.set('query', query);
    }
  }
});
