import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { all, hash } from 'rsvp';

export default Route.extend({
  store: service(),

  model() {
    const store = this.get('store');

    return hash({
      teacherAssignments: store
        .findAll('teacher-assignment')
        .then((teacherAssignments) =>
          all(
            teacherAssignments.map((teacherAssignment) =>
              all([
                teacherAssignment.get('books'),
                teacherAssignment.get('contents')
              ])
            )
          ).then(() => teacherAssignments)
        ),
      wordlists: store
        .findAll('wordlist')
        .then((wordlists) =>
          all(
            wordlists.map((wordlist) =>
              all([wordlist.get('books'), wordlist.get('contents')])
            )
          ).then(() => wordlists)
        ),
    });
  }
});
