import { helper as buildHelper } from '@ember/component/helper';

export function slug(params /*, hash*/) {
  if (params[0]) {
    return params[0]
      .toLowerCase()
      .replace('?', '')
      .replace(/ /g, '-')
      .replace(/å/g, 'a')
      .replace(/ä/g, 'a')
      .replace(/ö/g, 'o');
  } else {
    return null;
  }
}

export default buildHelper(slug);
