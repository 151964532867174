import config from 'babel-app/config/environment';

async function logOpenedProduct(model, ajax, session) {
  try {
    const product = await model.product;

    if (product && product.id !== session.lastLoggedProduct) {
      await ajax.request(
        `/api/statistics`,
        true,
        {
          type: 'POST',
          data: { isbn: product.id },
        }
      );

      session.lastLoggedProduct = product.id;
    }
  } catch (err) {
    // console.log('Could not log opened product', err);
  }
}

export { logOpenedProduct };
