const copyToClipboard = function(str) {
  if (navigator.clipboard) return navigator.clipboard.writeText(str);

  const element = document.createElement('textarea');
  element.value = str;
  element.contentEditable = true;
  element.readOnly = true;

  document.body.appendChild(element);

  const range = document.createRange();
  range.selectNodeContents(element);

  const selection = window.getSelection();
  selection.removeAllRanges();
  selection.addRange(range);

  element.setSelectionRange(0, 999999);

  document.execCommand('copy');

  document.body.removeChild(element);

  return Promise.resolve();
};

export default copyToClipboard;
