import ApplicationAdapter from './application';

export default class AvailableProductsAdapter extends ApplicationAdapter {
  coalesceFindRequests = false;

  shouldBackgroundReloadRecord() {
    return true;
  }

  urlForFindRecord(id, modelName, snapshot) {
    const url = super.urlForFindRecord(...arguments);

    const schoolId = snapshot.attr('school_id');
    const urlSplit = url.split('available-products/');
    const endPoint = urlSplit[0];
    const availableProductId = urlSplit[1];

    return `${endPoint}schools/${schoolId}/available-products/${availableProductId}`;
  }
}
