import EmberObject from '@ember/object';
import DS from 'ember-data';

import Model from './model';

const { attr, hasMany, belongsTo } = DS;

export default Model.extend({
  title: attr('string'),

  description: attr('string'),

  sort: attr('number'),

  metadata: attr('object', {
    defaultValue() {
      return EmberObject.create();
    },
  }),

  type: attr('string'),

  todo: belongsTo('todo', { inverse: null }),

  entity: belongsTo('entity'),

  entities: hasMany('entity'),
});
