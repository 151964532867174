import Helper from '@ember/component/helper';
import moment from 'moment';

export default class DaysToMonthsHelper extends Helper {
  compute([days]) {
    const duration = moment.duration(days ?? 365, 'days');

    return Math.round(duration.asMonths());
  }
}
