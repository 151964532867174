import DS from 'ember-data';

import Model from './model';

const { attr, belongsTo, hasMany } = DS;

export default Model.extend({
  // ATTRIBUTES

  type: attr('string'),

  comment: attr('string'),

  public: attr('boolean'),

  version: attr('number'),

  metadata: attr('object', {
    defaultValue() {
      return {};
    }
  }),

  answer: belongsTo('answer'),

  user: belongsTo('user', {
    async: true,
    inverse: null
  }),

  assignment: belongsTo('entity')
});
