import {
  handleDirtyHasManyRelationshipData,
  handleReceiversRelationship,
} from '../utils/handle-dirty-relationship-data';
import ApplicationAdapter from './application';

const DIRTY_DATA_RELATIONSHIPS = ['entities'];

export default ApplicationAdapter.extend({
  namespace: 'api/v1/meta',

  shouldBackgroundReloadRecord() {
    return true;
  },

  shouldBackgroundReloadAll() {
    return true;
  },

  findHasMany(store, snapshot, url, relationship) {
    if (DIRTY_DATA_RELATIONSHIPS.includes(relationship?.meta?.name)) {
      return handleDirtyHasManyRelationshipData(
        store,
        url,
        relationship?.meta?.type
      );
    } else if (relationship?.meta?.name === 'receivers') {
      return handleReceiversRelationship(
        store,
        snapshot,
        url,
        relationship?.meta?.type
      );
    }

    return this._super(...arguments);
  },
});
