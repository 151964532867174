import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { trackEvent } from '../../utils/matomo-events';

export default Component.extend({
  // SETUP

  session: service(),

  classNames: [
    'grid-cell-3',
    'grid-cell-md-4',
    'grid-cell-sm-6',
    'grid-cell-xs-12',
    'mb0',
    'p0',
    'course-product-card',
  ],

  classNameBindings: ['showOptions:options--active'],

  // SERVICES

  router: service(),

  // EXTERNAL

  searchValue: null,

  course: null,

  image: null,

  images: null,

  students: 0,

  teachers: [],

  isCourse: false,

  title: null,

  courseIcon: false,

  dynamicHeight: true,

  disabled: false,

  hasPopover: true,

  courses: null,

  product: null,

  renderAsLink: true,

  target: null,

  targetId: null,

  hasOptions: false,

  product: null,

  onClick() {},

  // INTERNAL

  showOptions: false,

  popoverOpen: false,

  // COMPUTED

  isClickable: computed('hasOptions', 'disabled', 'showOptions', function () {
    if (this.disabled) return false;
    if (this.showOptions) return false;
    return true;
  }),

  isLinked: computed('hasOptions', 'isClickable', function () {
    return !this.hasOptions && this.isClickable;
  }),

  // EVENTS

  closeOptions(evt) {
    if (this.showOptions && !this.popoverOpen) {
      let node = evt.target;

      while (node) {
        if (
          node.classList &&
          node.classList.contains('course-product-card-options')
        ) {
          return;
        }

        node = node.parentNode;
      }

      document.removeEventListener('touchstart', this.closeOptions);
      document.removeEventListener('mousedown', this.closeOptions);

      this.set('showOptions', false);
    }
  },

  // LIFECYCLE

  init() {
    this._super(...arguments);
    this.closeOptions = this.closeOptions.bind(this);
  },

  didDestroyElement() {
    this._super(...arguments);

    document.removeEventListener('touchstart', this.closeOptions);
    document.removeEventListener('mousedown', this.closeOptions);
  },

  // ACTIONS

  actions: {
    openPopover(e) {
      e.preventDefault();
      e.stopPropagation();

      trackEvent({ category: 'Hantera licenser', action: 'Öppna produkt' });
      this.set('popoverOpen', true);
    },

    handleClick(isSettingsClick, event) {
      if (isSettingsClick === true && event) {
        event.stopPropagation();
        event.preventDefault();
        return this.router.transitionTo('master.course-settings', this.targetId)
      }

      if (this.disabled) {
        return;
      }

      if (this.hasOptions) {
        document.addEventListener('touchstart', this.closeOptions);
        document.addEventListener('mousedown', this.closeOptions);

        return this.set('showOptions', true);
      }

      return this.onClick();
    },
  },
});
