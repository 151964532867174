import Helper from '@ember/component/helper';

export default class SortBy extends Helper {
  // HOOKS

  compute([arr, ...properties], { reverse = false }) {
    this._setup(arr, ...properties);

    const sortedArr = arr.sortBy(...properties);

    if (reverse) {
      return sortedArr.reverse();
    }

    return sortedArr;
  }

  willDestroyElement() {
    super.willDestroyElement(...arguments);

    if (this._teardown) {
      this._teardown();
    }
  }

  // PRIVATE

  _setup(array, ...properties) {
    if (this._teardown) {
      this._teardown();
    }

    const path = '@each.{' + properties.join(',') + '}';

    array.addObserver(path, this, this.recompute);

    this._teardown = () => {
      array.removeObserver(path, this, this.recompute);
    };
  }
}
