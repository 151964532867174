import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class SkolkodRoute extends Route {
  @service session;
  @service ajax;

  async model() {
    const { school_code: accessCode } = this.paramsFor('master.index.skolkod.with-code');

    if (!accessCode) {
      return {};
    }

    const userId = this.session.user.id;
    const url = `/api/users/connect-user-school/${userId}/${accessCode}/dry-run`;

    try {
      const result = await this.ajax.request(url, true, { type: 'POST' });

      return {
        code: accessCode,
        schoolName: result.school,
        role: result.role,
        username: this.session.user.username,
      };
    } catch (error) {
      return {
        code: accessCode,
        errorMessage: error.responseJSON.errors?.[0]?.detail,
      };
    }
  }
}
