import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { trackEvent } from '../../../../utils/matomo-events';

export default class AddToClassComponent extends Component {
  @service intl;
  @service session;

  @tracked popupOpen = false;
  @tracked selectClassPlaceholder = null;
  @tracked selectedClass = null;
  @tracked classOption = 'existing';
  @tracked addingClass = false;
  @tracked createClassErrors = [];
  @tracked classOptions = [
    {
      label: this.intl.t('admin.generatePopup.addToExistingClassLabel'),
      value: 'existing',
    },
    {
      label: this.intl.t('admin.generatePopup.addToNewClassLabel'),
      value: 'new',
    },
  ];

  @action
  async setClass() {
    trackEvent({
      category: 'Användare',
      action: 'Bjud in elev - Med användarnamn - Ange klass för alla'
    });

    this.addingClass = true;
    const classOption = this.classOption;

    if (classOption === 'existing') {
      const selectedClass = this.selectedClass;
      const users = this.args.users;

      const newUsers = users.map((user) => {
        return {
          ...user,
          class: selectedClass,
        };
      });

      this.args.setUsers(newUsers);
      this.popupOpen = false;
      this.addingClass = false;
    } else if (classOption === 'new') {
      const name = this.className;
      const trimmedName = name && name.trim();

      if (!trimmedName || trimmedName.length === 0) {
        this.createClassErrors = [this.intl.t('admin.emptyClassName')];
        this.addingClass = false;
        return;
      } else {
        this.createClassErrors = [];
      }

      const school = await this.session.user.school;
      const existingGroup = school.get('groups').filterBy('name', trimmedName);

      if (existingGroup && existingGroup.length) {
        this.createClassErrors = [this.intl.t('admin.classNameExists')];
        this.addingClass = false;
        return;
      } else {
        this.createClassErrors = [];
      }

      this.args.addClass(trimmedName).then((classGroup) => {
        const users = this.args.users;

        const newUsers = users.map((user) => {
          return {
            ...user,
            class: classGroup.get('id'),
          };
        });

        this.args.setUsers(newUsers);
        this.popupOpen = false;
        this.addingClass = false;
      });
    }
  }
}
