import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class AvatarPickerComponent extends Component {
  @tracked filter = '';

  get avatars() {
    return this.args.avatars.filter(avatar => avatar.tags.some(tag => tag.includes(this.filter)));
  }

  @action
  selectAvatar(avatar) {
    this.args.onSelect(avatar);
    this.args.onClose();
  }
}
