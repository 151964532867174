import ApplicationSerializer from 'babel-app/serializers/application';
import sanitizeHTML from 'babel-app/utils/sanitize-html';

const METADATA_ATTRIBUTES = [
  'hide_solutions',
  'show_key',
  'show_description',
  'sorted_custom_content_ids',
];

export default ApplicationSerializer.extend({
  normalize(_, hash) {
    if (hash && hash.attributes) {
      const attributes = hash.attributes;
      const metadata = attributes.metadata;

      if (metadata) {
        METADATA_ATTRIBUTES.forEach((k) => {
          attributes[k] = attributes.metadata[k];
        });

        delete attributes.metadata;
      }

      const attrs = ['title', 'description'];

      attrs.forEach((name) => {
        if (hash.attributes[name]) {
          hash.attributes[name] = sanitizeHTML(hash.attributes[name]);
        }
      });
    }

    return this._super(...arguments);
  },

  serialize() {
    const json = this._super(...arguments);
    const attributes = json.data.attributes;

    const metadata = {};

    METADATA_ATTRIBUTES.forEach((k) => {
      metadata[k] = attributes[k];
      delete attributes[k];
    });

    attributes.metadata = metadata;

    return json;
  },
});
