import { helper } from '@ember/component/helper';
import moment from 'moment';

export function fromNowDays([date]) {
  if (date) {
    const format = 'YYYY-MM-DD';
    return moment.utc(date, format).diff(moment.utc().format(format), 'days');
  }

  return null;
}

export default helper(fromNowDays);
