import { all, Promise, resolve } from 'rsvp';

function handleDirtyHasManyRelationshipData(store, url, type) {
  return new Promise((resolve, reject) => {
    if (!type) reject();

    const relationUrl = new URL(url);
    const params = new URLSearchParams(relationUrl.search);
    const ids = params.getAll('id')?.filter((x) => x?.length > 0) || [];

    let notFound = 0;

    all(
      ids.map((id) =>
        store
          .findRecord(type, id)
          .then((item) => {
            const serialized = item.serialize();
            const data = serialized.data;
            data.id = id;
            return data;
          })
          .catch(() => {
            notFound++;
          })
      )
    )
      .then((records) =>
        resolve({
          data: records.filter((x) => x),
          meta: { num_not_found: notFound },
        })
      )
      .catch(reject);
  });
}

function handleDirtyBelongsToRelationshipData(store, url, type) {
  return new Promise((resolve, reject) => {
    const id = url.split('/').pop();

    if (!type || !id) reject();

    store
      .findRecord(type, id)
      .then((item) => {
        const serialized = item.serialize();
        const data = serialized.data;
        data.id = id;
        return data;
      })
      .then((record) => resolve({ data: record }))
      .catch(() => resolve({ data: null }));
  });
}

function handleReceiversRelationship(store, snapshot, url, type) {
  return all([
    resolve(snapshot?.record?.course).then((course) =>
      resolve(course?.users?.mapBy('id'))
    ),
    handleDirtyHasManyRelationshipData(store, url, type),
  ]).then(([courseUsers, receivers]) => {
    const validReceivers = {
      data: [],
      meta: { num_not_found: receivers?.meta?.num_not_found || 0 },
    };

    receivers?.data?.forEach((user) => {
      if (courseUsers?.includes(user?.id)) {
        validReceivers.data.push(user);
      } else {
        validReceivers.meta.num_not_found++;
      }
    });

    return validReceivers;
  });
}

export {
  handleDirtyHasManyRelationshipData,
  handleDirtyBelongsToRelationshipData,
  handleReceiversRelationship,
};
