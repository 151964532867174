import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<label\n  class={{class\n    \"file-picker-grid-item\"\n    (if this.isDisabled \"file-picker-grid-item--disabled\")\n  }}\n  ...attributes\n>\n\n  <FileThumbnail class=\"nb1\" @file={{@file}} />\n\n  <span class=\"flex justify-space-between\">\n\n    <span class=\"flex-one text-quarternary word-break-all\">\n\n      {{@file.titleWithFallback}}\n\n    </span>\n\n    <XCheckbox\n      class=\"flex-shrink\"\n      @checked={{this.isPicked}}\n      @disabled={{this.isDisabled}}\n      @twoWay={{false}}\n      @onChange={{fn (if this.isPicked @onUnpick @onPick) @file}}\n    />\n\n  </span>\n\n  <FileType @file={{@file}} />\n\n</label>", {"contents":"<label\n  class={{class\n    \"file-picker-grid-item\"\n    (if this.isDisabled \"file-picker-grid-item--disabled\")\n  }}\n  ...attributes\n>\n\n  <FileThumbnail class=\"nb1\" @file={{@file}} />\n\n  <span class=\"flex justify-space-between\">\n\n    <span class=\"flex-one text-quarternary word-break-all\">\n\n      {{@file.titleWithFallback}}\n\n    </span>\n\n    <XCheckbox\n      class=\"flex-shrink\"\n      @checked={{this.isPicked}}\n      @disabled={{this.isDisabled}}\n      @twoWay={{false}}\n      @onChange={{fn (if this.isPicked @onUnpick @onPick) @file}}\n    />\n\n  </span>\n\n  <FileType @file={{@file}} />\n\n</label>","moduleName":"babel-app/components/file-picker/grid/item/index.hbs","parseOptions":{"srcName":"babel-app/components/file-picker/grid/item/index.hbs"}});
import Component from '@glimmer/component';

/*

import FileModel from 'babel-app/models/file';

export interface FilePickerGridItemComponentArgs {
  file: FileModel;
  pickedFiles: FileModel[];
  disabledFiles: FileModel[];
  onPick(file: FileModel): void;
}

*/

export default class FilePickerGridItemComponent extends Component {
  get isPicked() {
    return this.args.pickedFiles.includes(this.args.file);
  }

  get isDisabled() {
    const disabledFiles = this.args.disabledFiles;

    return disabledFiles && disabledFiles.includes(this.args.file);
  }
}
