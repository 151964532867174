import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { trackEvent } from '../../../utils/matomo-events';

export default class CoursesRoute extends Route {

  @service store

  queryParams = {
    page: { refreshModel: true },
    search: { refreshModel: true },
    archivedPage: { refreshModel: true }
  }

  async model(params) {

    const query = { active: true, page: { number: params.page, size: 20 } }
    const archivedQuery = { active: false, page: { number: params.page, size: 20 } }
    if (params.search) {
      query.filter = archivedQuery.filter = { name: { contains: params.search } }
    }
    const [courses, archivedCourses] = await Promise.all([
      this.store.query('course', query),
      this.store.query('course', archivedQuery)
    ])

    if (params.search) {
      trackEvent({ category: 'Kurs', action: 'Sök kurser', value: courses.meta.total });
    }

    return {
      courses,
      page: courses.meta.page,
      totalPages: courses.meta.totalPages,

      archivedCourses,
      archivedPage: archivedCourses.meta.page,
      totalArchivedPages: archivedCourses.meta.totalPages
    }
  }

  // Restore the controller's search state on new searches
  setupController(controller, model) {
    super.setupController(controller, model)
    controller.searchVal = controller.search
  }
}
