import Component from '@ember/component';
import { computed } from '@ember/object';
import { sort } from '@ember/object/computed';
import { trackEvent } from '../../utils/matomo-events';

export default Component.extend({
  classNames: ['user-list-component'],

  classNameBindings: ['bordered:user-list-bordered'],

  bordered: false,

  users: null,

  groups: null,

  searchable: true,

  selectableComponent: null,

  groupOptionComponent: null,

  userOptionComponent: null,

  viewUser: null,

  showFutureDeletion: false,

  modifiedGroupNames: false,

  licenseWarningComponent: null,

  searchHintComponent: null,

  searchHintHelperComponent: null,

  hideAccordion: false,

  classLessSelectable: false,

  roleName: null,

  // INTERNAL

  searchVal: null,

  search: null,

  searchArr: computed('search', function () {
    return this.search?.length > 0 ? this.search.split(' ') : null;
  }),

  expandAccordions: computed('search', function () {
    return this.search?.length > 0;
  }),

  // HOOKS

  init() {
    this._super(...arguments);

    if (!this.txt) {
      this.set('txt', {
        searchHeading: null,
        searchPlaceholder: null,
        groupSelectHeading: null,
        groupSelectAllOption: null,
        noUsersMessage: null,
        groupNumUsers: null,
        groupNoUsers: null,
        userNameHeading: null,
        userEmailHeading: null,
        usersLabel: null,
      });
    }
  },

  // COMPUTED

  sortedUsers: computed('users.[]', function () {
    const users = this.users;

    if (!users) return null;

    return users.sortBy('lastname', 'firstname', 'username');
  }),

  filteredUsers: computed('sortedUsers.[]', 'search', function () {
    const search = this.search;
    const users = this.sortedUsers;

    if (search && users) {
      return users.filter((user) => {
        return this.userFilter(user, search);
      });
    }

    return users;
  }),

  numMatchingUsers: computed(
    'search',
    'filteredUsers.[]',
    'filteredGroups.@each.users',
    function () {
      const users = this.filteredUsers;

      if (this.search && users) {
        trackEvent({
          category: 'Användare',
          action: 'Sök lärare',
          value: users.length
        });
      }

      if (users) return users.length;

      const search = this.search;
      const groups = this.filteredGroups;

      let num = 0;

      const matchedIds = [];

      groups.forEach((group) => {
        const users = group
          .get('users')
          .filter((user) => {
            if (matchedIds.includes(user.id)) return false;

            if (!search) {
              matchedIds.push(user.id);
              return true;
            }

            if (user.get('role') === 'student') {
              const res = this.userFilter(user, search);
              if (res) matchedIds.push(user.id);
              return res;
            }

            return false;
          });

        num += users.length;
      });

      if (this.search) {
        trackEvent({
          category: 'Användare',
          action: 'Sök elev eller klass',
          value: num
        });
      }

      return num;
    }
  ),

  sortDefinition: Object.freeze(['metadata.classLessClass:desc', 'name:asc']),

  sortedGroups: sort('groups.[]', 'sortDefinition'),

  filteredGroups: computed('sortedGroups.[]', 'search', function () {
    const groups = this.sortedGroups;
    const search = this.search;

    if (search) {
      return groups.filter((group) => {
        if ((group.get('name') ?? '').toLowerCase().indexOf(search) !== -1) {
          return true;
        }

        const users = group.get('users').filter((user) => {
          if (user.get('role') === 'student') {
            return this.userFilter(user, search);
          }

          return false;
        });

        if (users.length > 0) {
          return true;
        }

        return false;
      });
    }

    return groups;
  }),

  // ACTIONS

  actions: {
    search() {
      let search = this.searchVal;

      if (search && search.length > 0) {
        search = search.trim().toLowerCase();

        this.set('search', search);
      } else {
        this.set('search', null);
      }
    },
  },

  userFilter(user, search) {
    if (
      user.get('lastname')?.toLowerCase().includes(search) ||
      user.get('firstname')?.toLowerCase().includes(search) ||
      user.get('username').toLowerCase().includes(search) ||
      user.get('email')?.toLowerCase().includes(search) ||
      user.get('showname').toLowerCase().includes(search) ||
      (typeof user.get('listname') === 'string' &&
        user.get('listname').toLowerCase().includes(search))
    ) {
      return true;
    }

    return false;
  },
});
