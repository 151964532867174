import Component from '@ember/component';
import { computed, get } from '@ember/object';
import { oneWay } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import config from 'babel-app/config/environment';

export default Component.extend({
  classNames: ['course-header'],

  // SERVICES

  intl: service(),

  router: service(),

  session: service(),

  ajax: service(),

  // EXTERNAL

  course: null,

  // INTERNAL

  modalOpen: false,

  // COMPUTED

  courseName: oneWay('course.name'),

  errors: computed('courseName', function () {
    const name = this.get('courseName');

    if (!name) {
      return [this.get('intl').t('courses.nameError')];
    }

    return false;
  }),

  imageUrl: computed('course.coverBackground', function () {
    let url = get(this, 'course.coverBackground');
    return url && htmlSafe(url);
  }),

  isSettings: computed('router.currentRouteName', function () {
    return this.get('router.currentRouteName') === 'master.course-settings';
  }),

  // ACTIONS

  actions: {
    closeModal() {
      this.set('courseName', this.get('course.name'));
      this.set('modalOpen', false);
    },

    saveName() {
      if (this.get('errors')) {
        return false;
      }

      this.set('course.name', this.get('courseName'));

      return this.get('course')
        .save()
        .then(() => this.set('modalOpen', false));
    },
  },
});
