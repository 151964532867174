import { getOwner } from '@ember/application';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { getConfig } from 'ember-simple-auth-oidc/config';

export default class LtiInfoLinkComponent extends Component {
  @service intl;

  modalOpen = false;

  get schoolUnitCode() {
    return this.args.schoolUnitCode ?? '';
  }

  get isbn() {
    return this.args.isbn ?? '';
  }

  get link() {
    return `${getConfig(getOwner(this)).host}/metadata/lti/cartridge.xml?isbn=${
      this.isbn
    }&school_unit_code=${this.schoolUnitCode}`;
  }
}
