import { helper } from '@ember/component/helper';

function gcd(a, b) {
  return b ? gcd(b, a % b) : a;
}

export function aspectRatio([width, height]) {
  const divisor = gcd(width, height);

  return `${width / divisor} / ${height / divisor}`;
}

export default helper(aspectRatio);
