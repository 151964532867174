import Controller from '@ember/controller';
import { service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import config from 'babel-app/config/environment'
import { trackEvent } from '../../../utils/matomo-events';

export default class NewCourseController extends Controller {
  queryParams = ['productId', { returnUrl: 'return-url' }];

  @service session;
  @service router;
  @service store;
  @service intl;
  @service snackbar;

  @tracked errors = null;
  @tracked courseName = null;
  @tracked returnUrl = null;

  get internalRedirectRoute() {
    return this.model.previousRoute ?? "master.index";
  }

  get externalRedirectUrl() {
    return this.returnUrl?.startsWith('/') ? `${config.diginokUrl}${this.returnUrl}` : null;
  }

  get numUsers() {
    let numUsers = 0;

    (this.model.course.groups ?? []).forEach((group) => {
      numUsers += group.users.length;
    });

    return numUsers;
  }

  get classes() {
    let shownUsers = [],
      classes = [];
    if (this.model.classes) {
      classes = this.model.classes.filter(({ type, metadata }) => type === 'class' && !metadata?.classLessClass);

      classes.forEach((group) => {
        const users = group.users ?? [];

        shownUsers = shownUsers.concat(
          users
            .filter(({ role }) => role === 'student')
            .map(({ id }) => id)
        );
      });

      shownUsers = shownUsers.filter(
        (val, idx, self) => self.indexOf(val) === idx
      );
    }

    const group = this.model.classLessClass;
    let classLessUsers = [];

    if (this.model.students) {
      classLessUsers = this.model.students.filter(({ role, id }) => role === 'student' && shownUsers.indexOf(id) === -1);

      this.model.classLessClass.users = classLessUsers;
      this.model.classLessClass.num_students = classLessUsers.length;
    }

    if (classLessUsers.length > 0) {
      classes.addObject(group);
    } else if (classes.indexOf(group) !== -1) {
      classes.removeObject(group);
    }

    return classes;
  }

  #scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  @action
  async save() {
    if (!this.model.course.name?.trim().length) {
      this.errors = [this.intl.t('courses.nameError')];
      return this.#scrollToTop();
    }

    const groups = this.model.course.groups.filter(({ type }) => type === 'group').filterBy('type', 'group');

    if (groups.length > 0) {
      await groups[0].save();
    }

    const course = await this.model.course.save();

    const products = course.products || [];

    await Promise.all(products.map((product) =>
      course.assignLicenses(product, { isTrial: product.isTrial, teachersOnly: !product.isTrial })
    ));

    await this.session.user.courses.reload();

    const previousLicenses = new Set(this.session.user.licenses.map((license) => license.isbn));

    await this.session.refreshUserLicenses();

    const newProductISBNS = new Set(
      products.map((product) => product.id)
        .filter(isbn => !previousLicenses.has(isbn))
    );

    this.session.user.licenses
      .filter((license) => newProductISBNS.has(license.isbn))
      .forEach((license) => { license.is_new = true });

    course.is_new = true;

    this.session.user.courses.pushObject(course);

    if (this.session.user.courses.length <= 1) {
      const user = this.session.user;
      user.license_filter = null;
      await user.save();
    }

    this.snackbar.enqueue('Ny kurs skapades', {
      variant: 'success',
      autoDismiss: true
    });

    trackEvent({ category: 'Kurs', action: 'Skapa kurs - Skapa', value: this.numUsers });

    if (this.externalRedirectUrl) {
      location.href = `${config.diginokUrl}/courses/${course.id}`;
    } else {
      await this.router.transitionTo(this.internalRedirectRoute, { queryParams: { new: true } });
    }

    this.#scrollToTop();
  }

  @action
  setCourseName() {
    if (this.errors) {
      this.errors = null;
    }
  }
}
