import AuthenticatedRoute from 'babel-app/routes/authenticated-route';

export default class NotFoundRoute extends AuthenticatedRoute {
  model() {
    return {
      label: 'pageTitle.notFound',
      target: 'master',
    };
  }
}
