import DS from 'ember-data';

import Model from './model';

const { attr } = DS;

export default Model.extend({
  tag_group: attr('string'),

  tags: attr() // array
});
