import { helper as buildHelper } from '@ember/component/helper';

export function splitBy(params /*, hash*/) {
  var string = params[0];
  var seperator = params[1];
  var index = params[2];

  if (index == null) {
    return string.split(seperator);
  }
  if (typeof string == 'string' && seperator && index != null) {
    return string.split(seperator)[index];
  }
}

export default buildHelper(splitBy);
