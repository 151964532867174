import { A } from '@ember/array';
import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import config from 'babel-app/config/environment';
import { trackEvent } from '../../../utils/matomo-events';

export default Component.extend({
  // SETUP

  classNames: ['admin-csv-table'],

  // SERVICES

  ajax: service(),

  intl: service(),

  emailValidator: service(),

  // EXTERNAL

  list: null,

  modal: null,

  userType: null,

  addingUsers: false,

  modalOpen: true,

  findingEmails: false,

  users: null,

  validUsers: [],

  headings: null,

  firstNameHeading: null,

  lastNameHeading: null,

  emailHeading: null,

  classHeading: null,

  selectCSV: null,

  validateUsersWithInvalidEmail: null,

  // INTERNAL

  heading: null,

  validDrop: false,

  buttons: null,

  targets: null,

  nameMode: false,

  headersLoaded: false,

  emailColumn: null,

  csvLoaded: computed('list', function () {
    return !!this.get('list');
  }),

  validationMessage: computed('findingEmails', 'list', function () {
    const items = this.get('list') || A();
    const filteredItems = items.filterBy('validationMessage');

    if (!filteredItems.length) {
      return null;
    }

    let errors = {};

    for (let item of filteredItems) {
      if (errors[item.get('validationMessage')]) {
        errors[item.get('validationMessage')].count++;
      } else {
        errors[item.get('validationMessage')] = { count: 1 };
      }
    }

    const messages = Object.keys(errors).map(error => this.get('intl').t(`admin.importCSVValidator${ error }ValidationMessage`, { count: errors[error].count }));

    let summary = null;

    if (messages.length === 1) {
      summary = messages[0];
    } else {
      summary = messages.slice(0, messages.length - 1).join(', ');

      summary += ` ${this.get('intl').t('admin.importCSVValidatorAnd')} ${messages.slice(messages.length - 1)}`;
    }

    return summary.length ? `${summary} ${this.get('intl').t('admin.importCSVValidatorCanNotBeImported')}.` : null;
  }),

  // ACTIONS

  actions: {
    dragStarted(heading) {
      this.set('heading', heading);
      this.set('validDrop', false);

      this._updateTargetPointerEvents('auto');
    },

    dragEnded() {
      this._updateTargetPointerEvents('none');
    },

    headingDropped(context, action) {
      if (!this.get('validDrop')) {
        return;
      }

      const heading = this.get('heading');
      const index = this.get('targets').indexOf(action.event.target);

      this.get('buttons').removeObject(heading);

      this._updateTargetPointerEvents('none');

      if (heading === this.get('emailHeading')) {
        this._checkAddresses(index);
      } else if (
        heading === this.get('firstNameHeading') ||
        heading === this.get('lastNameHeading')
      ) {
        this._updateNames(index);
      }
    },

    headingEntered(event) {
      const {
        firstNameHeading,
        lastNameHeading,
        headings,
        targets,
        heading
      } = this.getProperties(
        'firstNameHeading',
        'lastNameHeading',
        'headings',
        'targets',
        'heading'
      );

      const index = targets.indexOf(event.target);
      const savedHeading = headings[index];

      if (savedHeading) {
        if (
          (heading === firstNameHeading || heading === lastNameHeading) &&
          (savedHeading === firstNameHeading ||
            savedHeading === lastNameHeading)
        ) {
          headings.replace(index, 1, [
            { multiple: true, primary: savedHeading, secondary: heading }
          ]);
          this.set('validDrop', true);
        }
      } else {
        headings.replace(index, 1, [heading]);
        this.set('validDrop', true);
      }
    },

    headingLeft() {
      const heading = this.get('heading');

      if (this.get('buttons').indexOf(heading) === -1) {
        return;
      }

      this.set('validDrop', false);
      this._removeHeading(heading);
    },

    removeHeading(heading) {
      const index = this._removeHeading(heading);

      this.get('buttons').addObject(heading);

      if (heading === this.get('emailHeading')) {
        this.set('validUsers', []);
        this.set('findingEmails', false);

        for (let row of this.get('list')) {
          row.set('validationMessage', null);
        }
      } else if (
        heading === this.get('firstNameHeading') ||
        heading === this.get('lastNameHeading')
      ) {
        this._updateNames(index);
      }
    },

    openFilePicker() {
      const filePicker = this.get('selectCSV');
      return (
        filePicker &&
        filePicker(() => {
          this._loadHeaders();
        })
      );
    },

    trackDownloadExample() {
      trackEvent({
        category: 'Användare',
        action: `Bjud in ${ this.userType === 'teachers' ? 'lärare' : 'elev'} - Importera csv - Ladda ned exempel`
      });
    }
  },

  _updateTargetPointerEvents(val) {
    this.get('targets').forEach((target) => {
      target.style.pointerEvents = val;
    });
  },

  _checkAddresses(columnIndex) {
    this.set('validUsers', []);
    this.set('findingEmails', true);
    this.set('emailColumn', columnIndex);

    const rows = this.get('list');

    let emails = rows.map(row => ({ address: row.data[columnIndex] }));

    // Checking if e-mail address is used by a teacher on another school
    const getTeachers = this.get('userType') === 'teachers' ?
      Promise.resolve([]) :
      this.get('ajax').request(
        `/api/users/check-if-teachers`,
        true,
        {
          data: { emails: emails.map(x => x.address.toLowerCase()) },
          method: 'post'
        }
      )
      .then((data) => data.existingTeachers);

    // Checking if e-mail address is used by a student
    const getStudents = this.get('userType') === 'students' ?
    Promise.resolve([]) :
    this.get('ajax').request(
      `/api/users/check-if-students`,
      true,
      {
        data: { emails: emails.map(x => x.address.toLowerCase()) },
        method: 'post'
      }
    )
    .then((data) => data.existingStudents);

    // Check if e-mail is valid
    const emailResult = this.emailValidator
      .validate(emails)
      .filter((email) => email.result !== 'valid')
      .map((email) => email.normalized_address);

    Promise.all([getTeachers, getStudents,]).then(([teachers, students]) => {

      for (let row of rows) {
        const email = row.data[columnIndex].toLowerCase();

        if (teachers.includes(email)) {
          // Finns som lärare på annan skola
          row.set('validationMessage', 'Teacher');
        } else if (students.includes(email)) {
          // Finns som elev
          row.set('validationMessage', 'Student');
        } else if (emailResult.includes(email)) {
          // Ogiltig e-post
          row.set('validationMessage', 'InvalidEmail');
        } else {
          // Giltig
          this.get('validUsers').push(email);

          row.set('validationMessage', null);
        }
      }

      this.set('findingEmails', false);
    })
  },

  _removeHeading(heading) {
    const headings = this.get('headings');
    let index;

    headings.forEach((savedHeading, i) => {
      if (savedHeading) {
        if (savedHeading === heading) {
          headings.replace(i, 1, [null]);
          index = i;
          return;
        } else if (
          savedHeading.secondary &&
          savedHeading.secondary === heading
        ) {
          headings.replace(i, 1, [savedHeading.primary]);
          index = i;
          return;
        }
      }
    });

    return index;
  },

  _updateNames(columnIndex) {
    const savedHeading = this.get('headings')[columnIndex];
    const nameMode = this.get('nameMode');
    const firstNameHeading = this.get('firstNameHeading');

    if (savedHeading) {
      if (savedHeading.multiple && !nameMode) {
        this._loopUserRows(columnIndex, (column) => {
          const nameParts = column.textContent.split(' ');

          let first, last;

          if (savedHeading.primary === firstNameHeading) {
            first = nameParts.shift();
            last = nameParts.join(' ');
          } else {
            last = nameParts.pop();
            first = nameParts.join(' ');
          }

          column.innerHTML = '<em>' + first + '</em> ' + last;
        });

        this.set('nameMode', true);
      } else if (!savedHeading.multiple && nameMode) {
        this._loopUserRows(columnIndex, (column) => {
          column.innerHTML = column.textContent;
        });

        this.set('nameMode', false);
      }
    }
  },

  _loopUserRows(columnIndex, callback) {
    const rows = this.element.querySelectorAll('.js-body-row');
    const len = rows.length;

    let i;

    for (i = 0; i < len; i++) {
      callback(rows[i].children[columnIndex]);
    }
  },

  _loadHeaders() {
    this._super(...arguments);

    const targetElems = this.element.querySelectorAll(
      '.csv-draggable-object-target'
    );
    const targets = [];
    const num = targetElems.length;

    let i;

    for (i = 0; i < num; i++) {
      let target = targetElems[i];

      targets.push(target);

      target.style.width = target.parentNode.offsetWidth + 'px';
      target.style.left = target.parentNode.offsetLeft + 'px';
      target.style.pointerEvents = 'none';
    }

    this.set('targets', targets);

    this.set('firstNameHeading', this.get('intl').t('admin.firstName'));
    this.set('lastNameHeading', this.get('intl').t('admin.lastName'));
    this.set('emailHeading', `${this.get('intl').t('admin.eMail')}*`);
    this.set('classHeading', this.get('intl').t('admin.class'));

    const buttons = [
      this.get('emailHeading'),
      this.get('firstNameHeading'),
      this.get('lastNameHeading')
    ];

    if (this.get('userType') === 'students') {
      buttons.push(this.get('classHeading'));
    }

    this.set('buttons', buttons);

    const headings = new Array(num);

    headings.fill(null);

    this.set('headings', headings);
  },
});
