import Component from '@glimmer/component';
import { action } from '@ember/object';
import { trackEvent } from '../../../utils/matomo-events';

export default class AddProductComponent extends Component {
  get searchArr() {
    return this.args.search?.split(' ') || [];
  }

  get license() {
    return this.args.licenses
      ?.filterBy('product.id', this.args.product.id)
      .get('firstObject');
  }

  get availableProduct() {
    return this.args.availableProducts?.findBy(
      'product.id',
      this.args.product.id
    );
  }

  get agreement() {
    const agreement = this.args.agreements?.find(
      (agreement) => agreement.product.get('id') === this.args.product.id
    );

    if (agreement) return agreement;

    const globalAgreement = this.args.agreements?.find(
      (agreement) => !agreement.product.get('id')
    );

    if (globalAgreement) return globalAgreement;

    return null;
  }

  get canExtend() {
    return (
      this.license &&
      !this.license.extended &&
      ((this.agreement && !this.agreement.is_demo) ||
        this.availableProduct?.numberOfAssignableLicenses > 0)
    );
  }

  get canAdd() {
    return (
      this.args.product.isExtraMaterial ||
      (this.agreement && !this.agreement.is_demo) ||
      (this.availableProduct?.numberOfAssignableLicenses > 0 &&
        this.args.isTeacher)
    );
  }

  @action buyMore() {
    trackEvent({ category: 'Produkt', action: 'Lägg till produkt - Produkter och extramaterial - Köp' });
  }
}
