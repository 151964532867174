import Component from '@ember/component';
import { task, timeout } from 'ember-concurrency';

const DEBOUNCE_TIME = 500;

export default Component.extend({
  classNames: ['search-bar', 'flex'],

  searchValue: null,

  autofocus: false,

  onClear() {},

  onSearch() {},

  updateSearch: task(function*() {
    yield timeout(DEBOUNCE_TIME);

    this.get('onSearch')(this.get('searchValue'));
  }).drop(),

  actions: {
    clearSearch() {
      this.get('onClear')();
    }
  }
});
