import Route from '@ember/routing/route';
import { service } from '@ember/service';
import config from 'babel-app/config/environment';

export default class AnsokRoute extends Route {
  @service store;
  @service session;
  @service router;
  @service schoolApplication;

  async beforeModel() {
    const user = this.session.user;
    const blockedDomain = this.schoolApplication.getBlockedDomain(user?.username);

    if (user?.role === 'student') {
      this.router.transitionTo('master.index.skolkod');
    }
    else if (!config.allowSchoolApplication || blockedDomain) {
      this.router.transitionTo('master.index.anslut');
    }
  }

  async model() {
    const user = this.session.user;
    const schoolApplications = user.get('schoolApplications');

    return {
      schoolApplications
    };
  }
}
