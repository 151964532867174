import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
  // EXTERNAL

  hasTooltip: false,

  course: null,

  school: null,

  // COMPUTED

  checked: computed(
    'course.metadata.uses_licenses',
    'school.metadata.course_uses_licenses',
    {
      get() {
        if (this.get('course.metadata.uses_licenses') === false) {
          return false;
        }

        if (this.get('course.metadata.uses_licenses') === true) {
          return true;
        }

        if (this.get('school.metadata.course_uses_licenses') === false) {
          return false;
        }

        return true;
      },
      set(key, val) {
        this.set('course.metadata.uses_licenses', val);
        return val;
      }
    }
  )
});
