import { modifier } from 'ember-modifier';

function highlightTerm(term, text) {
  if (!term?.length) {
    return text;
  }

  // Escape regex chars
  term = term.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

  const regex = new RegExp(term, 'gi');
  return text.replace(regex, '<mark class="highlight">$&</mark>');
}

export default modifier(function highlightTerms(element, [terms]) {
  element.classList.add('highlight-terms');

  const highlightedSearch = highlightTerm(terms.join(' '), element.textContent);

  element.innerHTML = highlightedSearch;
});
