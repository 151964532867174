import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { trackEvent } from '../../../utils/matomo-events';

export default class MasterIndexActivateController extends Controller {
  @service router;
  @service store;
  @service intl;

  @tracked activationCode;
  @tracked codeErrors = null;

  reset() {
    this.activationCode = null;
    this.codeErrors = null;
  }

  @action
  closeModal() {
    this.reset();

    trackEvent({ category: 'Produkt', action: 'Lägg till produkt - Med aktiveringskod - Avbryt' });

    this.router.transitionTo('master.index');
  }

  @action
  activate() {
    const validCodeExpression = /N-[A-Z]{4}-[0-9A-F]{4}-[0-9A-F]{4}/;

    if (!this.activationCode?.match(validCodeExpression)) {
      return this.codeErrors = [
        this.get('intl').t('components.activationCodeModal.inputErrorMessage')
      ];
    }

    trackEvent({ category: 'Produkt', action: 'Lägg till produkt - Med aktiveringskod - Aktivera' });

    return this.router.transitionTo('activate.item', this.activationCode);
  }
}
