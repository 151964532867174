import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { trackEvent } from '../../../utils/matomo-events';

export default class AddClassComponent extends Component {
  @service snackbar;
  @service userAgent;
  @service intl;
  @service session;

  @tracked modalOpen = false;
  @tracked name = null;
  @tracked errors = [];
  @tracked loading = false;

  @action
  async addClass(name) {
    const trimmedName = name && name.trim();

    if (!trimmedName || trimmedName.length === 0) {
      this.errors = [this.intl.t('admin.emptyClassName')];
      return;
    } else {
      this.errors = [];
    }

    this.loading = true;

    const school = await this.session.get('user.school');

    const existingGroup = school.get('groups').filterBy('name', trimmedName);

    if (existingGroup && existingGroup.length) {
      this.errors = [this.intl.t('admin.classNameExists')];
      this.loading = false;
      return;
    } else {
      this.errors = [];
    }

    this.args.addClass(trimmedName).then(() => {
      this.modalOpen = false;
      this.name = null;
      this.loading = false;

      trackEvent({
        category: 'Användare',
        action: 'Klass - Skapa ny klass - Slutför'
      });

      this.snackbar.enqueue(
        this.intl.t('admin.addedClass', { name: trimmedName }),
        { variant: 'success', autoDismiss: true }
      );
    });
  }

  @action
  close() {
    trackEvent({
      category: 'Användare',
      action: 'Klass - Skapa ny klass - Avbryt'
    });

    this.name = null;
    this.errors = [];
    this.modalOpen = false;
  }
}
