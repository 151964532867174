import { attr } from '@ember-data/model';
import Model from 'babel-app/models/model';

export default class SchoolAccessCode extends Model {

  @attr('boolean') active;
  @attr('string') code;
  @attr('date') created;
  @attr('date') updated;
  @attr('date') expires;
  @attr('number') max_uses;
  @attr('number') uses;
  @attr('string') role;

  //TODO: could be a relation
  @attr('string') school_id;
}
