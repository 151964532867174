import { helper } from '@ember/component/helper';
import moment from 'moment';

export function date([date]) {
  if (date) {
    return moment(date).format('YYYY-MM-DD');
  }

  return null;
}

export default helper(date);
