import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { trackEvent } from '../../../utils/matomo-events';

const actions = {
  VIEW_USER: 'view_user',
  VIEW_PASSWORD: 'view_password',
  VIEW_LICENSES: 'view_licenses',
  VIEW_COURCES: 'view_courses',
  REMOVE_USER: 'remove_user'
};

export default Component.extend({
  intl: service(),
  session: service(),

  // SETUP

  classNames: ['inline-block', 'position-relative', 'text-left'],

  // PARAMS

  item: null,

  viewUser() {},

  removeUser() {},

  // PROPERTIES

  isPopupIndexOpen: false,

  actionMenuButtonItems: computed(
    'intl.locale',
    'item',
    'session.user',
    function() {
      const items = [];

      const { intl, item, session } = this.getProperties(
        'intl',
        'item',
        'session'
      );

      if (item.get('isStudent')) {
        items.addObject({
          value: actions.VIEW_USER,
          label: intl.t('components.userModal.viewStudent')
        });
      }

      items.addObject({
        value: actions.VIEW_PASSWORD,
        label: intl.t('components.userModal.viewPassword')
      });

      if (item !== session.get('user')) {
        items.addObject({ type: 'divider' });

        items.addObject({
          value: actions.REMOVE_USER,
          label: item.get('isStudent') ? intl.t('admin.removeStudent') : intl.t('admin.removeUser'),
          variant: 'error'
        });
      }

      return items;
    }
  ),

  // ACTIONS

  actions: {
    handleActionMenuButtonChange(value) {
      switch (value) {
        case actions.VIEW_USER:
          trackEvent({ category: 'Användare', action: `${this.item.role === 'teacher' ? 'Lärare' : 'Elev'} - ${this.item.role === 'teacher' ? 'Lärar' : 'Elev'}inställningar - Öppna` });
          this._viewUser('general');
          break;
        case actions.VIEW_PASSWORD:
          trackEvent({ category: 'Användare', action: `${this.item.role === 'teacher' ? 'Lärare' : 'Elev'} - Återställ lösenord - Öppna` });
          this._viewUser('password');
          break;
        case actions.VIEW_LICENSES:
          this._viewUser('licenses');
          break;
        case actions.VIEW_COURCES:
          this._viewUser('courses');
          break;
        case actions.REMOVE_USER:
          this.get('removeUser')(this.get('item'));
          break;
      }
    }
  },

  // PRIVATE

  _viewUser(value) {
    this.get('viewUser')(this.get('item.id'), value);
  }
});
