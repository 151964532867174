import Component from '@ember/component';
import { inject as service } from '@ember/service';
import copyToClipboard from 'babel-app/utils/copy-to-clipboard';
import { computed as overridable } from 'compton/utils/computed-override';

export default Component.extend({
  modalTitle: null,

  entity: null,

  fullLink: null,

  title: null,

  body: null,

  hideOptions: false,

  info: null,

  snackbar: service(),

  intl: service(),

  link: overridable(function () {
    return window.location.href;
  }),

  didReceiveAttrs() {
    this._super(...arguments);
    if (!this.get('fullLink')) {
      this.set(
        'title',
        this.get('intl').t('components.shareLinkModal.message.title', {
          title: this.get('entity.title'),
        })
      );

      this.set(
        'body',
        this.get('intl').t('components.shareLinkModal.message.body', {
          title: this.get('entity.title'),
        })
      );
    }
  },

  actions: {
    selectAll(input) {
      input.setSelectionRange(0, input.value.length);
    },
    handleCopyToClipboard(value) {
      copyToClipboard(value)
        .then(() => {
          this.get('snackbar').enqueue(
            this.get('intl').t('locationCopiedToClipboard'),
            {
              variant: 'success',
              autoDismiss: true,
            }
          );
        })
        .catch(() => {
          this.get('snackbar').enqueue(
            this.get('intl').t('locationFailedToCopyToClipboard'),
            {
              variant: 'error',
            }
          );
        });
    },
  },
});
