import Component from '@ember/component';

export default Component.extend({
  // SETUP

  classNames: ['py1'],

  // PROPERTIES

  group: null,

  selectedGroups: null,

  checked: false,

  init() {
    this._super(...arguments);

    this.checked = this.get('selectedGroups').indexOf(this.get('group')) !== -1;
  },

  // ACTIONS

  actions: {
    selectUserGroup() {
      const selected = this.get('selectedGroups');
      const group = this.get('group');

      if (selected.indexOf(group) === -1) {
        selected.addObject(group);
      } else {
        selected.removeObject(group);
      }

      this.set('selectedGroups', selected);
    }
  }
});
