export default function appendQueryParams(url, params) {
  if (params && typeof params === 'object' && !Array.isArray(params)) {
    const querystring =
      url.indexOf('?') > -1
        ? new URLSearchParams(new URL(url).search)
        : new URLSearchParams();

    for (const [key, value] of Object.entries(params)) {
      querystring.append(key, value);
    }
    url = `${url.substr(
      0,
      url.indexOf('?') > -1 ? url.indexOf('?') : url.length
    )}?${querystring.toString()}`;
  }
  return url;
}
