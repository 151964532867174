import { A } from '@ember/array';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import moment from 'moment';
import { hash } from 'rsvp';
import EmberObject from '@ember/object';

export default class CoursesNewRoute extends Route {
  queryParams = {
    productId: {
      refreshModel: true,
    },
  };

  @service session;
  @service store;
  @service intl;

  classLessClass = null;

  setupController(controller, post) {
    super.setupController(controller, post);

    this.controllerFor('master').set('pageTitle', {
      label: 'courses.createCourse',
      target: 'master.courses.new',
    });
  }

  async model({ productId }, transition) {
    const previousRoute = transition.from?.name;
    const user = this.session.user;
    const course = this.store.createRecord('course');
    const school = await user.get('school');

    const [
      userLicenses,
      availableProducts,
      schoolGroups,
      schoolUsers,
      extraMaterials,
      selectedProduct,
    ] = await Promise.all([
      user.get('licenses'),
      school.get('available_products'),
      school.get('groups').then(async (group) => { await group.users; return group; }),
      school.get('users'),
      this.store.query('product', {
        filter: {
          extramaterial: true,
        },
      }),
      productId
        ? this.store.findRecord('product', productId, { include: 'books' })
        : Promise.resolve(null),
    ]);

    const products = A(extraMaterials.toArray());

    const loadedProducts = await Promise.all([
      ...availableProducts.getEach('product'),
      ...userLicenses.filterBy('isExpired', false).getEach('product'),
    ]);

    products.addObjects(
      loadedProducts.filter(
        (product) =>
          product && !extraMaterials.mapBy('isbn').includes(product.get('id'))
      )
    );

    const userGroups = schoolGroups
      .filterBy('type', 'user_group')
      .sortBy('name');

    course.get('teachers').pushObject(user);

    const today = moment();

    course.set('start_date', today.toDate());
    course.set('end_date', today.year(today.year() + 1).toDate());

    if (selectedProduct) {
      course.get('products').addObject(selectedProduct);
      course.get('books').addObjects(await selectedProduct.get('books'));
    }

    this.classLessClass = EmberObject.create({
      name: this.intl.t('admin.classNameMissing'),
      type: 'class',
      school: this.session.user.school,
      num_students: 0,
      users: [],
      metadata: {
        classLessClass: true,
      },
    });

    return hash({
      previousRoute,
      course,
      products,
      availableProducts,
      classes: schoolGroups.filterBy('type', 'class').sortBy('name'),
      userGroups,
      teachers: schoolUsers.filterBy('role', 'teacher'),
      students: schoolUsers.filterBy('role', 'student'),
      users: schoolUsers,
      classLessClass: this.classLessClass,
    });
  }
}
