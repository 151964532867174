import Component from '@ember/component';
import { computed } from '@ember/object';
import { alias, or, sort } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default Component.extend({
  session: service(),

  classNames: ['b1', 'nb1', 'bradius', 'course-product-display', 'course-product-display-component'],

  product: null,

  availableProducts: null,

  selectedBooks: null,

  gridSize: null,

  usersMissingLicenses: null,

  canHandleLicenses: true,

  canRemove: true,

  isLinked: false,

  modalOpen: false,

  onRemoveProduct() {},

  openLicenseModal() {},

  assignLicenses() {},

  externalLicenses: alias('session.user.school.metadata.external_licenses'),

  books: alias('product.books'),

  hasActions: or('canHandleLicenses', 'canRemove'),

  availableProduct: computed('availableProducts.[]', 'product.[]', function() {
    const productId = this.get('product.id');
    const availableProducts = this.get('availableProducts');

    if (productId && availableProducts) {
      return availableProducts.findBy('isbn', productId);
    }

    return null;
  }),

  missingLicenseProduct: computed(
    'usersMissingLicenses.@each.product',
    'product.[]',
    function() {
      const productId = this.get('product.id');
      const missingLicenseProducts = this.get('usersMissingLicenses');

      if (productId && missingLicenseProducts) {
        return missingLicenseProducts.findBy('id', productId);
      }

      return null;
    }
  ),

  hasLicenseWarning: computed(
    'externalLicenses',
    'missingLicenseProduct.numMissing',
    function() {
      if (this.get('externalLicenses')) return false;
      return this.get('missingLicenseProduct.numMissing') > 0;
    }
  ),

  hasTrialLicenses: computed(
    'availableProducts.[]',
    'product.id',
    function () {
      const productId = this.get('product.id');
      const availableProducts = this.get('availableProducts');

      if (productId && availableProducts) {
        const products = availableProducts.findBy('isbn', productId);

        if (!products) {
          return false;
        }

        return !!products.get('demo');
      }

      return false;
    }
  ),

  actions: {
    openExternalProduct(product) {
      const target = product && product.get('externalUrl');

      if (target) {
        window.location = target;
      }
    }
  }
});
