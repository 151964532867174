import Controller from '@ember/controller';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default Controller.extend({

  // SETUP

  session: service(),


  router: service(),

  store: service(),

  // PROPERTIES

  loggedIn: alias('session.user')
});
