import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import config from 'babel-app/config/environment';

export default Component.extend({
  classNames: ['user-approve-terms'],

  ajax: service(),

  terms: null,

  approved: false,

  onApprove() {},

  init() {
    this._super(...arguments);

    this.get('ajax')
      .request(`/assets/terms/version-${config.termsVersion}.txt`, false, {
        type: 'GET'
      })
      .then((terms) => {
        if (this.isDestroyed) {
          return;
        }

        this.set('terms', htmlSafe(terms));
      });
  },

  actions: {
    approveClicked(value) {
      this.get('onApprove')(value);
    }
  }
});
