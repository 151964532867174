import Component from '@ember/component';
import { computed } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import integrations from 'babel-app/rules/integrations';
import ConfirmSupport from 'compton/mixins/confirm-support';

export default Component.extend(ConfirmSupport, {
  session: service(),

  intl: service(),

  // PROPERTIES

  selected: null,

  modalSelected: null,

  available: null,

  txt: null,

  modalOpen: false,

  usersMissingLicenses: null,

  availableProducts: null,

  products: null,

  course: null,

  openLicenseModal() {},

  saveCourse() {},

  assignLicenses() {},

  // COMPUTED

  school: readOnly('session.user.school'),

  users: computed('available.[]', function() {
    return this.get('available')
      .filter((x) => x['role'] === 'teacher')
      .sortBy('lastname', 'firstname', 'username');
  }),

  sortedSelected: computed('selected.[]', function() {
    return this.get('selected')
      .filter((x) => x['role'] === 'teacher')
      .sortBy('lastname', 'firstname', 'username');
  }),

  serviceProvider: computed('session.user.school.provider', function () {
    const provider = this.get('session.user.school.provider');

    if (provider && integrations[provider]) {
      return integrations[provider];
    }

    return null;
  }),

  // RUNLOOP

  init() {
    this._super(...arguments);

    if (!this.get('txt')) {
      this.set('txt', {
        searchHeading: 'courses.search',
        searchPlaceholder: 'courses.searchTeachers',
        noUsersMessage: 'courses.noTeachers',
        userNameHeading: 'courses.name',
        userEmailHeading: 'courses.eMail',
        usersLabel: 'courses.numTeachers'
      });
    }

    if (!this.get('selected')) {
      this.set('selected', []);
    }

    if (!this.get('available')) {
      this.set('available', []);
    }
  },

  // ACTIONS

  actions: {
    openModal() {
      this.set('modalSelected', this.get('selected').slice());
      this.set('modalOpen', true);
    },

    closeModal() {
      this.set('modalOpen', false);
    },

    remove(item) {
      return this.confirm(this.get('intl').t('courses.removeTeacherConfirm'), {
        danger: true
      }).then(() => {
        this.get('selected').removeObject(item);
        return this.saveCourse({ teachersChanged: true });
      });
    },

    async saveTeachers() {
      this.set('selected', this.get('modalSelected'))
      await this.saveCourse({ teachersChanged: true })
      this.send('closeModal')
    }
  }

});
