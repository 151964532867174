import { A } from '@ember/array';
import Component from '@ember/component';
import { computed } from '@ember/object';
import { sort } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import ConfirmSupport from 'compton/mixins/confirm-support';
import { resolve } from 'rsvp';

export default Component.extend(ConfirmSupport, {
  ajax: service(),

  session: service(),

  intl: service(),

  // PROPERTIES

  selectedProducts: null,

  selectedBooks: null,

  availableProducts: null,

  products: null,

  modalOpen: false,

  addedProducts: null,

  removedProducts: null,

  searchVal: null,

  search: null,

  searchArr: null,

  size: null,

  usersMissingLicenses: null,

  canHandleLicenses: true,

  school: null,

  course: null,

  saveCourse() {},

  assignLicenses() {},

  openLicenseModal() {},

  // COMPUTED

  productSorting: Object.freeze(['title:asc']),

  sortedProducts: sort('selectedProducts', 'productSorting'),

  gridSize: computed('size', function() {
    if (this.get('size') == 'small') {
      return 'col-6 col-xs-12';
    }

    return 'col-4 col-md-6 col-xs-12';
  }),

  pickedProducts: null,

  // RUNLOOP

  init() {
    this._super(...arguments);

    if (!this.get('selectedProducts')) {
      this.set('selectedProducts', A());
    }

    if (!this.get('selectedBooks')) {
      this.set('selectedBooks', A());
    }

    if (!this.get('availableProducts')) {
      this.set('availableProducts', A());
    }

    if (!this.get('products')) {
      this.set('products', A());
    }
  },

  // ACTIONS

  actions: {
    openModal() {
      this.set('pickedProducts', this.get('selectedProducts').slice());
      this.set('modalOpen', true);
    },

    closeModal() {
      this.set('modalOpen', false);
    },

    search() {
      let search = this.get('searchVal');

      if (search && search.length > 0) {
        search = search.trim().toLowerCase();

        this.set('search', search);
        this.set('searchArr', search.split(' '));
      } else {
        this.set('search', null);
        this.set('searchArr', null);
      }
    },

    removeDisplayedProduct(product) {
      const promise = this.get('canHandleLicenses')
        ? this.confirm(this.get('intl').t('courses.removeProductConfirm'), {
          danger: true
        })
        : resolve();

      return promise.then(() => {
        this.set('pickedProducts', this.get('selectedProducts').slice());
        this.get('pickedProducts').removeObject(product);

        return this._saveProducts();
      });
    },

    removeProduct(product) {
      this.get('pickedProducts').removeObject(product);
    },

    addProduct(product, isTrial) {
      product.isTrial = isTrial || false;

      this.get('pickedProducts').addObject(product);
    },

    saveProducts() {
      return this._saveProducts();
    }
  },

  // INTERNAL METHODS

  async _saveProducts() {
    const selectedProducts = this.get('selectedProducts');
    const pickedProducts = this.get('pickedProducts');
    const selectedBooks = this.get('selectedBooks');

    const addedProducts = pickedProducts.filter(
      (product) => !selectedProducts.includes(product)
    );

    selectedProducts.setObjects(pickedProducts);

    // All selected books must be part of a selected product
    selectedBooks.setObjects(
      selectedBooks.filter((book) =>
        selectedProducts.any((product) => product.books.includes(book))
      )
    );

    // Add the books of the new products in the course
    await Promise.all(addedProducts.map(async (product) => {
      const books = await product.get('books')
      books
        .filter((book) => !selectedBooks.includes(book))
        .forEach((book) => selectedBooks.addObject(book));
    }));

    await this.saveCourse({ booksChanged: true });

    // If there are demo licenses, we will create them here
    const trialProducts = addedProducts.filter((product) => product.isTrial);
    const productsToAddLicencesFor = addedProducts.filter(
      (product) => !product.isTrial
    );

    const schoolId = this.get('school.id');
    const course = this.get('course');

    if (!schoolId || !course || (trialProducts.length == 0 && productsToAddLicencesFor.length == 0)) {
      this.send('closeModal');
      return;
    }

    await Promise.all(
      trialProducts.map((product) => {
        delete product.isTrial;
        return course.assignLicenses(product, { isTrial: true });
      })
    );

    await Promise.all(
      productsToAddLicencesFor.map((product) =>
        course.assignLicenses(product, { teachersOnly: true })
      )
    );

    course.set('licensesHaveChanged', true);

    await this.get('school').get('available_products').reload();

    const previousLicenses = new Set(this.get('session.user.licenses')
      .map((license) => license.isbn));

    await this.get('session').refreshUserLicenses();

    const newProductISBNS = new Set(
      addedProducts.map((product) => product.id)
        .filter(isbn => !previousLicenses.has(isbn))
    );

    this.get('session.user.licenses')
      .filter((license) => newProductISBNS.has(license.isbn))
      .forEach((license) => { license.is_new = true });

    await course.reloadMissingLicenseProducts();
    this.send('closeModal');
  }
});
