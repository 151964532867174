import config from 'babel-app/config/environment';

import { handleDirtyHasManyRelationshipData } from '../utils/handle-dirty-relationship-data';
import ApplicationAdapter from './application';

const DIRTY_DATA_RELATIONSHIPS = ['books', 'contents'];

export default class Adapter extends ApplicationAdapter {
  host = config.endpoint;
  namespace = 'api/v1/meta';

  findHasMany(store, snapshot, url, relationship) {
    if (DIRTY_DATA_RELATIONSHIPS.includes(relationship?.meta?.name)) {
      return handleDirtyHasManyRelationshipData(
        store,
        url,
        relationship?.meta?.type
      );
    }

    return super.findHasMany(...arguments);
  }
}
