import { A } from '@ember/array';
import Component from '@ember/component';
import { computed } from '@ember/object';
import {
  createClassNameModifier,
  param
} from 'compton/utils/computed';
import { inject as service } from '@ember/service';

const classNameModifier = createClassNameModifier('product-picker');

export default Component.extend({
  store: service(),

  // SETUP

  classNames: ['product-picker'],

  classNameBindings: ['rangeClassName'],

  // PARAMS

  products: null,

  availableProducts: null,

  pickedProducts: null,

  licenses: null,

  range: param('string', { defaultValue: 'medium' }), // small, medium or large

  onPick: null,

  onUnpick: null,

  isTrial: false,

  // PROPERTIES

  rangeClassName: classNameModifier('range'),

  agreements: computed(function () {
    return this.get('store').query('agreement', {});
  }),

  hasExternalProducts: computed('pickedProducts.@each.isExternal', function() {
    return (this.get('pickedProducts') || A()).any((product) =>
      product.get('isExternal')
    );
  }),

  hasInternalProducts: computed('pickedProducts.@each.isInternal', function() {
    return (this.get('pickedProducts') || A()).any((product) =>
      product.get('isInternal')
    );
  })
});
