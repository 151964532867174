import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import config from 'babel-app/config/environment';

export default class AnslutController extends Controller {
  @service router;
  @service session;

  get isApplicationClosed() {
    return !config.allowSchoolApplication;
  }

  @action
  close() {
    this.router.replaceWith('master.index');
  }
}
