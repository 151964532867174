import Component from '@ember/component';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';

export default Component.extend({
  // SERVICES

  store: service(),

  session: service(),

  // SETUP

  tagName: '',

  // EXTERNAL

  userId: null,

  tabValue: null,

  // INTERNAL

  user: null,

  modalOpen: false,

  isLoading: true,

  // COMPUTED

  externalUsers: alias('session.user.school.metadata.external_users'),

  externalLicenses: alias('session.user.school.metadata.external_licenses'),

  // LIFECYCLE

  didReceiveAttrs() {
    this._super(...arguments);

    this.get('getUserInformation').perform();
  },

  // TASKS

  getUserInformation: task(function*() {
    if (!this.get('modalOpen')) {
      return;
    }

    const userId = this.get('userId');

    if (!userId) {
      return this.set('modalOpen', false);
    }

    this.set('isLoading', true);

    const user = yield this.get('store').findRecord('user', userId);

    this.set('user', user);
    this.set('isLoading', false);
  }).drop(),

  // ACTIONS

  actions: {
    close() {
      const user = this.get('user');

      if (user && user.get('dirtyType')) {
        user.rollbackAttributes();
      }

      this.set('modalOpen', false);
    }
  }
});
