import '@naturochkultur/lit-web-components';

import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

import config from 'babel-app/config/environment';
import { tracked } from '@glimmer/tracking';

export default class AnsokController extends Controller {
  @service router;
  @service session;
  @service intl;
  @service store;

  schoolSearchEndpoint = config.schoolSearchEndpoint;

  @tracked schoolInput = '';
  @tracked loading = false;
  @tracked manualSchoolInput = '';
  @tracked manualLocationInput = '';
  @tracked schoolRequestError = null;

  @tracked schoolApplication = null;
  @tracked schoolApplicationError = null;

  @tracked schoolName = null;
  schoolCode = null;

  #clear () {
    this.schoolInput = '';
    this.manualSchoolInput = '';
    this.manualLocationInput = '';
    this.schoolName = null;
    this.schoolLocation = null;
    this.schoolCode = null;
    this.schoolRequestError = null;
    this.schoolApplicationError = null;
  }

  @action
  close() {
    this.#clear();
    this.router.replaceWith('master.index');
  }

  @action
  async sendApplication() {
    this.schoolApplicationError = null;

    if (
      !(this.schoolName || this.manualSchoolInput) &&
      !(this.schoolCode || this.manualLocationInput)
    ) {
      this.schoolRequestError = this.intl.t('routes.anslut.application.validation');
      return;
    } else {
      this.schoolRequestError = null;
    }

    this.loading = true;

    const newSchoolApplication = {
      school_code: this.schoolCode,
      school_name: `${this.schoolName ?? this.manualSchoolInput}, ${this.schoolLocation ?? this.manualLocationInput}`,
    };

    if (this.schoolCode) {
      newSchoolApplication.school_name += ` - ${this.schoolCode}`;
    }

    try {
      await this.store.createRecord('school-application', newSchoolApplication).save();
    } catch (error) {
      this.schoolApplicationError = true;

      this.schoolApplication = this.model.schoolApplications.find((schoolApplication) => {
        const code = schoolApplication.get('school_name').split(' - ')[1];
        return code === this.schoolCode;
      });

      this.loading = false;
      return;
    }

    this.loading = false;
    await this.session.get('user.schoolApplications').reload();

    this.close();
  }

  @action
  handleSchoolSelection({ detail }) {
    const { name, municipality, code } = detail ?? {};

    this.schoolName = name;
    this.schoolLocation = municipality;
    this.schoolCode = code;
    this.schoolApplicationError = null;
  }

  @action
  clearSchoolSelection() {
    this.#clear();
  }
}
