import ApplicationSerializer from './application';

export default class extends ApplicationSerializer {
  normalizeArrayResponse(store, modelClass, data) {
    return {
      data: data.map(({ id, ...attributes }) => ({
        id,
        attributes,
        type: 'school-access-code',
      })),
    };
  }

  normalizeSingleResponse(store, primaryModelClass, payload) {
    const { id, ...attributes } = payload;
    return {
      data: {
        id,
        attributes,
        type: 'school-access-code',
      },
    };
  }
}
