import { A } from '@ember/array';
import ArrayProxy from '@ember/array/proxy';
import { computed } from '@ember/object';
import PromiseProxyMixin from '@ember/object/promise-proxy-mixin';
import { inject as service } from '@ember/service';
import config from 'babel-app/config/environment';
import imageSource from 'compton/utils/image-source';
import DS from 'ember-data';
import moment from 'moment';
import { all, reject, resolve } from 'rsvp';

import Model from './model';

const ArrayPromiseProxy = ArrayProxy.extend(PromiseProxyMixin);
const { attr, hasMany, belongsTo } = DS;

export default Model.extend({
  ajax: service(),

  session: service(),

  store: service(),

  name: attr('string'),

  cover: attr('string'),

  start_date: attr('only-date'),

  end_date: attr('only-date'),

  licenses_added: attr('boolean'),

  showKey: attr('boolean', {
    defaultValue() {
      return true;
    },
  }),

  showDiagnosis: attr('boolean', {
    defaultValue() {
      return true;
    },
  }),

  active: attr('boolean', {
    defaultValue() {
      return true;
    },
  }),

  language: attr('string'),

  cover_url: attr('string'),

  created: attr('date'),

  updated: attr('date'),

  metadata: attr({
    defaultValue() {
      return {};
    },
  }),

  // INTERNAL

  licensesHaveChanged: false,

  missingLicenseProductsIsReloading: false,

  // RELATIONS

  users: hasMany('user', { inverse: null }),

  teachers: hasMany('user', { inverse: null }),

  groups: hasMany('group', { inverse: null }),

  school: belongsTo('school'),

  products: hasMany('product', { inverse: null }),

  books: hasMany('entity', { inverse: null }),

  shortcuts: hasMany('entity', { inverse: null }),

  missingLicenseProducts: hasMany('missing-license-product'),

  is_new: attr('boolean', {
    defaultValue() {
      return false;
    },
  }),

  // PROPERTIES

  numUsers: computed('users.{[],isFulfilled}', function () {
    return (this.hasMany('users').ids() || []).length;
  }),

  hasExternalProducts: computed('products.@each.isExternal', function () {
    return (this.products || A()).any((product) => product.get('isExternal'));
  }),

  hasInternalProducts: computed('products.@each.isInternal', function () {
    return (this.products || A()).any((product) => product.get('isInternal'));
  }),

  usersMissingLicenses: computed(
    'missingLicenseProducts.{[],isFulfilled}',
    'session.user.isTeacher',
    'licensesHaveChanged',
    function () {
      let promise;

      if (!this.get('session.user.isTeacher')) {
        promise = resolve(A());
      } else if (
        this.licensesHaveChanged &&
        this.hasMany('missingLicenseProducts').value() !== null
      ) {
        promise = this.reloadMissingLicenseProducts();
      } else {
        promise = this.missingLicenseProducts;
      }

      return ArrayPromiseProxy.create({
        promise: promise.then((arr) => arr.sortBy('product.title')),
      });
    }
  ),

  students: computed('users.{[],isFulfilled}', 'teachers.{[],isFulfilled}', function () {
    const users = this.users || A();
    const teachers = this.teachers || A();

    return users.filter((user) => !teachers.includes(user));
  }),

  sortedBooks: computed('books.[]', 'products.[]', function () {
    const promise = all([this.books, this.products])
      .then(([books, products]) =>
        all(
          products
            .sortBy('title')
            .map((product) =>
              resolve(product.books).then((productBooks) =>
                productBooks.filter((book) => books.includes(book))
              )
            )
        )
      )
      .then((books) => books.flat().uniqBy('id'));

    return ArrayPromiseProxy.create({ promise });
  }),

  firstProductImage: computed('products.@each.image', function () {
    const products = this.products;
    const productsWithImage = products.filter(
      (product) =>
        product.get('metadata.background_type') !== 'color' &&
        product.get('image')
    );

    if (productsWithImage.length > 0) {
      return productsWithImage.get('firstObject.image');
    }

    return null;
  }),

  coverImage: computed(
    'cover',
    'cover_url',
    'firstProductImage.imageURL',
    function () {
      if (this.cover_url) {
        return (
          `${config.imgProcessingEndpoint}?${new URLSearchParams({url: this.cover_url, h: 480, w: 640, embed: true})}`
        );
      } else if (this.cover) {
        return this.cover;
      } else {
        return this.firstProductImage
          ? imageSource(this.get('firstProductImage.imageURL'), {
            preset: 'small',
          })
          : null;
      }
    }
  ),

  coverBackground: computed(
    'cover',
    'cover_url',
    'firstProductImage.imageURL',
    function () {
      if (this.cover_url) {
        return (
          `${config.imgProcessingEndpoint}?${new URLSearchParams({url: this.cover_url, h: 500, w: 1920, embed: true})}`
        );
      } else if (this.cover) {
        return this.cover;
      } else {
        return this.firstProductImage
          ? imageSource(this.get('firstProductImage.imageURL'), {
            preset: 'landscape',
          })
          : null;
      }
    }
  ),

  isComing: computed('start_date', 'end_date', function () {
    return moment().isBefore(moment(this.start_date).format('YYYY-MM-DD'));
  }),

  isNew: computed('created', function () {
    const courseDate = new Date(this.created);
    const currentDate = new Date();

    return courseDate?.setHours(0, 0, 0, 0) === currentDate.setHours(0, 0, 0, 0);
  }),

  // METHODS

  addNewActivity(attributes) {
    return this.store.createRecord('activity', {
      course: this,
      ...attributes,
    });
  },

  assignLicenses(
    availableProduct,
    { isTrial = false, teachersOnly = false } = {}
  ) {
    const id = availableProduct.get('id');
    const isbn = id.split('_')[0];
    const courseId = this.id;

    return this.ajax
      .request(
        `/api/courses/${courseId}/assign-licenses?isbn=${isbn}&is_demo=${isTrial}&teachers_only=${teachersOnly}`,
        true,
        { type: 'POST' }
      )
      .then((licenses) => {
        if (availableProduct?.licensesUpdated) {
          return availableProduct.licensesUpdated(licenses);
        }

        return Promise.resolve();
      })
      .catch(() => { });
  },

  save() {
    return this._super(...arguments).then((saved) => {
      if (!saved || !saved.get('licenses_added')) {
        return saved;
      }

      saved.set('licenses_added', false);

      const promises = [];

      if (this.hasMany('missingLicenseProducts').value() !== null) {
        promises.push(this.missingLicenseProducts.reload());
      }

      if (this.belongsTo('school') !== null) {
        promises.push(
          this.school
            .then((school) => {
              if (school.hasMany('available_products').value() !== null) {
                return all([this.products, school.get('available_products')]);
              }

              return reject();
            })
            .then(([products, available_products]) => {
              if (available_products) {
                const isbns = (products || A()).mapBy('id');

                return all(
                  available_products.map((available_product) => {
                    if (isbns.includes(available_product.get('isbn'))) {
                      return available_product.get('user_licenses').reload();
                    }

                    return resolve();
                  })
                );
              }
            })
            .catch(() => { })
        );
      }

      return all(promises).then(() => saved);
    });
  },

  reloadMissingLicenseProducts() {
    if (this.missingLicenseProductsIsReloading) {
      return this.missingLicenseProducts;
    }

    this.set('missingLicenseProductsIsReloading', true);

    return this.missingLicenseProducts
      .reload()
      .then((missingLicenseProducts) => {
        this.set('missingLicenseProductsIsReloading', false);
        this.set('licensesHaveChanged', false);

        return missingLicenseProducts;
      });
  },
});
