import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class ProductsCoursesOverview extends Component {
  @service session;
  @service intl;
  @service store;

  @tracked searchSet = false;
  @tracked searchValue = null;
  @tracked sortPopoverOpen = false;
  @tracked searchOpen = false;
  @tracked sortField = 'title';
  @tracked filterField = 'all';

  constructor() {
    super(...arguments);

    if (this.args.searchValue) {
      this.args.searchValue.then((searchValue) => {
        this.searchValue = searchValue;
        this.searchOpen = !!this.searchValue;
      });
    }

    if (this.licenseFilter) {
      this.filterField = this.licenseFilter;
    }
  }

  get licenseSorting() {
    return this.currentUser?.license_sorting;
  }

  get licenseFilter() {
    return this.currentUser?.license_filter;
  }

  get numberOfItems() {
    return this.licenses.length + this.courses.length;
  }

  get licenses() {
    return this.args.licenses || [];
  }

  get courses() {
    return this.args.courses || [];
  }

  get filteredLicenses() {
    const filteredLicenses = this.licenses
      .filter((license) => {
        if (!this.searchValue) {
          return true;
        }

        const product = license.get('product');
        const books = product.get('books');
        const bookNames = books
          ? books.getEach('title').join(',').toLowerCase()
          : '';
        const title = product.get('title') || '';
        const search = this.searchValue.toLowerCase();

        return (
          bookNames.includes(search) || title.toLowerCase().includes(search)
        );
      })
      .sort((x, y) => {
        if (this.licenseSorting === 'title') {
          return x.product.get('title')?.localeCompare(y.product.get('title'));
        }

        return x.updated > y.updated ? -1 : 1;
      });

    return filteredLicenses;
  }

  get filteredCourses() {
    return this.courses
      .filter((course) => {
        if (!this.searchValue) {
          return true;
        }

        const searchValue = this.searchValue.toLowerCase();

        if (
          course.get('products') &&
          course
            .get('products')
            .getEach('title')
            .join(',')
            .toLowerCase()
            .includes(searchValue)
        ) {
          return true;
        } else if (
          course.get('books') &&
          course
            .get('books')
            .getEach('title')
            .join(',')
            .toLowerCase()
            .includes(searchValue)
        ) {
          return true;
        } else if (
          course.name &&
          course.name.toLowerCase().includes(searchValue)
        ) {
          return true;
        } else {
          return false;
        }
      })
      .sort((x, y) => {
        if (this.licenseSorting === 'title') {
          return x.name?.localeCompare(y.name);
        }

        return x.created > y.created ? -1 : 1;
      });
  }

  get noResults() {
    return !this.filteredLicenses?.length && !this.filteredCourses?.length;
  }

  get externalLicenses() {
    return this.currentUser.school.get('metadata.external_licenses');
  }

  get trialsDisabled() {
    return this.currentUser.school.get('metadata.trials_disabled');
  }

  get currentUser() {
    return this.session.user;
  }

  get currentSchool() {
    return this.currentUser.school;
  }

  get addMessages() {
    const role = this.currentUser.hasSchool ? (this.currentUser.isStudent ? 'student' : 'teacher') : 'private';

    return {
      heading: this.intl.t(`overview.books.${role}.heading`),
      info: htmlSafe(this.intl.t(`overview.books.${role}.info`)),
    };
  }

  get hasReadNotice() {
    return this.args.readNotices.find(
      (notice) => notice.notice_key === 'skolon_handles_licenses_notice'
    );
  }

  get buyOrManageLicensesLink() {
    const provider = this.currentUser.school.get('provider');
    const externalLicenses = this.currentUser.school.get(
      'metadata.external_licenses'
    );
    return provider && externalLicenses
      ? 'https://skolon.com'
      : 'https://nok.se';
  }

  get buyOrManageLicensesLinkText() {
    const provider = this.currentUser.school.get('provider');
    const externalLicenses = this.currentUser.school.get(
      'metadata.external_licenses'
    );
    return provider && externalLicenses ? 'Skolon' : 'nok.se';
  }

  @action openExternalSite(url) {
    window.location = url;
  }

  @action search() {
    this.searchSet = true;
    this.isbn = null;
  }

  @action clearSearch() {
    this.searchSet = true;
    this.isbn = null;
    this.searchValue = null;
  }

  @action toggleSearch() {
    this.isbn = null;
    this.searchValue = null;
    this.searchOpen = !this.searchOpen;
  }

  @action toggleOrdering() {
    this.currentUser.set(
      'license_filter',
      this.licenseFilter === 'products' ? 'courses' : 'products'
    );

    this.currentUser.save();
  }

  @action toggleSorting() {
    this.currentUser.set(
      'license_sorting',
      this.licenseSorting === 'date' ? 'title' : 'date'
    );

    this.currentUser.save();
  }

  @action
  async addReadNotice() {
    const newReadNotice = this.store.createRecord('read-notice', {
      notice_key: 'skolon_handles_licenses_notice',
    });

    this.currentUser.readNotices.addObject(newReadNotice);

    await newReadNotice.save();
  }
}
