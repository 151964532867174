import { computed } from '@ember/object';
import Service from '@ember/service';
import { inject as service } from '@ember/service';

export default Service.extend({
  store: service(),

  loading: false,

  pageNumber: 1,

  perPage: 24,

  text: null,

  hits: null,

  invalidSearch: false,

  numHits: computed('hits.meta.total', function() {
    return this.get('hits.meta.total') || 0;
  }),

  numPages: computed('hits.meta.totalPages', function() {
    return this.get('hits.meta.totalPages') || 0;
  }),

  noHits: computed('hits', 'numHits', function() {
    if (this.get('hits') && this.get('numHits') === 0) return true;
    return false;
  }),

  doSearch(page) {
    const search = this.get('text');

    this.set('invalidSearch', false);

    if (!search || search.length < 3) {
      this.set('hits', null);

      if (search) {
        this.set('invalidSearch', true);
      }

      return;
    }

    this.set('loading', true);
    this.set('pageNumber', page ? page : 1);

    return this.get('store')
      .query('entity', {
        page: {
          size: this.get('perPage'),
          number: this.get('pageNumber')
        },
        search
      })
      .then((nodes) => {
        this.set('hits', nodes);
        this.set('loading', false);
      });
  }
});
