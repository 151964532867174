import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';


export default Component.extend({
  session: service(),

  course: null,

  showTrail: false,

  subtitleContents: computed(
    'session.user.isTeacher',
    'course.groups.@each.{type,num_students}',
    'course.teachers.@each.role',
    function() {
      if (this.get('session.user.isTeacher')) {
        return [];
      } else {
        const users = this.get('course.teachers');

        if (!users) return [];

        return users
          .filterBy('role', 'teacher')
          .mapBy('showname')
          .sort();
      }
    }
  ),

  subtitle: computed('subtitleContents.[]', function() {
    const contents = this.get('subtitleContents');

    if (contents.length > 4) {
      return contents.slice(0, 3).join(', ');
    } else {
      return contents.join(', ');
    }
  }),

  trail: computed('subtitleContents.[]', function() {
    const contents = this.get('subtitleContents');

    if (contents.length > 4) {
      return ', ' + contents.slice(3).join(', ');
    } else {
      return null;
    }
  }),

  actions: {
    showTrail(e) {
      e.stopPropagation();
      e.preventDefault();

      this.set('showTrail', true);
    }
  }
});
