import Component from '@ember/component';

export default Component.extend({
  // SETUP

  tagName: '',

  // EXTERNAL PROPERTIES

  done: false,

  canContinue: true,

  onNext() {},

  login: false,

  onLogin() {}
});
