import { computed } from '@ember/object';
import { filterBy, mapBy, notEmpty } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import DS from 'ember-data';

import Model from './model';
const { attr, hasMany, belongsTo } = DS;
import integrations from 'babel-app/rules/integrations';

export default Model.extend({
  intl: service(),

  // ATTRIBUTES

  username: attr('string'),

  firstname: attr('string'),

  lastname: attr('string'),

  avatar: attr('string'),

  avatar_img: attr('string'),

  provider: attr('string'),

  future_deletion_date: attr('date'),

  active_school: attr('string'),

  license_filter: attr('string'),

  license_sorting: attr('string'),

  approved_terms: attr('number'),

  language: attr('string'),

  role: attr('string', {
    defaultValue() {
      return 'test';
    },
  }),

  email: attr('string'),

  // RELATIONS

  teacherAssignments: hasMany('teacher-assignment'),

  wordlists: hasMany('wordlist'),

  notes: hasMany('note'),

  files: hasMany('file'),

  school: belongsTo('school', { inverse: null }),

  schools: hasMany('school', { inverse: null }),

  schoolApplications: hasMany('school-application', { inverse: null }),

  books: hasMany('entities', { inverse: null }),

  courses: hasMany('course'),

  licenses: hasMany('license', { inverse: null }),

  class: belongsTo('group'),

  groups: hasMany('group'),

  logins: hasMany('login'),

  roles: hasMany('role'),

  readNotices: hasMany('read-notice', { async: true }),

  // COMPUTED

  serviceProvider: computed('provider', function () {
    const provider = this.get('provider');

    if (provider && integrations[provider]) {
      return integrations[provider];
    }

    return null;
  }),

  isTeacher: computed('role', function () {
    return this.get('role') === 'teacher';
  }),

  isStudent: computed('role', function () {
    return this.get('role') === 'student';
  }),

  isTestUser: computed('role', function () {
    return this.get('role') === 'test';
  }),

  isPrivateUser: computed('role', function () {
    return this.get('role') === 'private';
  }),

  hasSchool: computed('school', function () {
    return this.belongsTo('school').id() ? true : false;
  }),

  isTeacherForSchool: computed('isTeacher', 'hasSchool', function () {
    return this.get('hasSchool') && this.get('isTeacher');
  }),

  isTeacherWithoutSchool: computed('isTeacher', 'hasSchool', function () {
    return !this.get('hasSchool') && this.get('isTeacher');
  }),

  isStudentWithoutSchool: computed('isStudent', 'hasSchool', function () {
    return !this.get('hasSchool') && this.get('isStudent');
  }),

  showname: computed('firstname', 'lastname', 'username', function () {
    if (this.firstname && this.lastname) {
      return `${this.firstname} ${this.lastname}`;
    } else if (this.firstname && !this.lastname) {
      return this.firstname;
    } else if (this.lastname && !this.firstname) {
      return this.lastname;
    } else {
      return this.username;
    }
  }),

  initials: computed('firstname', 'lastname', 'username', function () {
    let initials = '';

    if (this.firstname) {
      initials += this.firstname.substring(0, 1).toUpperCase();
    }

    if (this.lastname) {
      initials += this.lastname.substring(0, 1).toUpperCase();
    }

    if (initials.length === 0 && this.username) {
      initials += this.username.substring(0, 2).toUpperCase();
    }

    return initials;
  }),

  listname: computed('firstname', 'lastname', 'intl.locale', function () {
    let firstname = this.get('firstname');
    let lastname = this.get('lastname');

    if (!firstname) {
      firstname = '';
    }

    if (!lastname) {
      lastname = '';
    }

    if (firstname.trim() === '' && lastname.trim() === '') {
      return htmlSafe(
        '<em class="dimmed">' + this.get('intl').t('nameMissing') + '</em>'
      );
    }

    return firstname.trim() !== '' && lastname.trim() !== ''
      ? `${lastname}, ${firstname}`
      : `${lastname}${firstname}`;
  }),

  activeSchoolApplications: computed('schoolApplications', function () {
    return this.schoolApplications.then((schoolApplications) =>
      schoolApplications.filter((application) => application.is_active)
    );
  }),

  licenseProducts: mapBy('licenses', 'product'),

  externalLicenseProducts: filterBy('licenseProducts', 'isExternal'),

  hasExternalLicenseProducts: notEmpty('externalLicenseProducts'),

  internalLicenseProducts: filterBy('licenseProducts', 'isInternal'),

  hasInternalLicenseProducts: notEmpty('internalLicenseProducts'),

  lastLogin: computed('logins', function () {
    return this.logins.then((logins) => {
      return logins.reduce((result, login) => {
        if (login.last_login) {
          if (result && result.before && result.before(login.last_login)) {
            return result;
          } else {
            return login.last_login;
          }
        }

        return result;
      }, false);
    // just so we dont show a bad error message in views
    }).catch(() => {
      return false;
    })
  })
});
