import AuthenticatedRoute from 'babel-app/routes/authenticated-route';
import { service } from '@ember/service';

export default class OnboardingRoute extends AuthenticatedRoute {
  @service session;
  @service store;
  @service intl;

  async beforeModel() {
    await this.session.load();
    this.intl.setLocale(this.session.user?.language ?? 'sv')
  }

  async model() {
    const avatars = await this.store.findAll('avatar');
    const user = this.get('session.user');
    const readNotices = await user.readNotices;

    const hasSeenTeacherGuide = readNotices.find(
      (notice) => notice.notice_key === 'has_seen_teacher_guide'
    );

    let nextRoute = 'master.index';

    if (user?.isTeacher && !hasSeenTeacherGuide) {
      nextRoute = 'onboarding.guide';
    }

    const firstPart = nextRoute.split('.')[0];
  
    const done = firstPart !== 'onboarding';

    return {
      avatars,
      nextRoute,
      done
    }
  }
}