import { dasherize } from '@ember/string';
import { typeOf } from '@ember/utils';

export default function getSettingClassesForPart(className, part) {
  const settingClasses = [];
  const settings = part?.settings;

  if (settings) {
    for (const k in settings) {
      const v = settings[k];

      if (v) {
        if (typeOf(v) !== 'number') {
          const settingClass = dasherize(typeOf(v) === 'boolean' ? k : v);
          settingClasses.addObject(settingClass);
        }
      }
    }
  }

  return settingClasses
    .map((settingClass) => [className, settingClass].join('--'))
    .concat([className]);
}
