import Helper from '@ember/component/helper';
import { get } from '@ember/object';
import { inject as service } from '@ember/service';
import { camelize } from '@ember/string';

const GLOSSARIES = 'glossaries';
const CONCEPTS = 'concepts';

const TYPES = [GLOSSARIES, CONCEPTS];

export default class WordlistTypeItems extends Helper {
  // SETUP

  @service intl;

  // HOOKS

  compute() {
    return TYPES.map((type) => {
      return {
        value: type,
        label: this.intl.t(
          ['helpers', 'wordlistTypeItems', camelize(type)].join('.')
        ),
      };
    });
  }
}
