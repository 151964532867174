import Service, { service } from '@ember/service';

export default class FileDispatcherService extends Service {
  @service store;
  @service intl;
  @service snackbar;
  @service confirmManager;

  create(attributes = {}) {
    return this.store.createRecord('file', attributes);
  }

  performUpload(file, fileData) {
    const onResolve = () => {
      this.#success('upload');
    };

    const onReject = (xhr) => {
      // NOTE The request has been aborted.
      if (xhr.status === 0) {
        return;
      }

      this.#error('upload');
    };

    // NOTE Make sure that we return the orginal promise.
    const promise = file.performUpload(fileData);
    promise.then(onResolve, onReject);
    return promise;
  }

  abortUpload(file) {
    file.abortUpload();
  }

  save(file) {
    const onResolve = () => {
      this.#success('save');
    };

    const onReject = () => {
      this.#error('save');
    };

    // NOTE Make sure that we return the orginal promise.
    const promise = file.save();
    promise.then(onResolve, onReject);
    return promise;
  }

  delete(file, skipConfirm = false) {
    if (skipConfirm) {
      this.#deleteFile(file);
    } else {
      return this.#confirm('delete', { danger: true }).then(() => {
        this.#deleteFile(file);
      });
    }
  }

  deleteMany(files) {
    return this.#confirm(
      'deleteMany',
      {
        danger: true,
      },
      files.length
    ).then(async () => {
      try {
        const result = await Promise.all(
          files.map((file) => file.destroyRecord())
        );
        this.#success('deleteMany', { count: files.length });
        return result;
      } catch (error) {
        this.#error('delete');
        throw error;
      }
    });
  }

  #deleteFile(file) {
    const onResolve = () => {
      this.#success('delete');
    };

    const onReject = () => {
      this.#error('delete');
    };

    // NOTE Make sure that we return the orginal promise.
    const promise = file.destroyRecord();
    promise.then(onResolve, onReject);
    return promise;
  }

  #confirm(method, options, count = 1) {
    return this.confirmManager.perform(
      this.intl.t(`services.fileDispatcher.${method}.confirm`, {
        count,
      }),
      options
    );
  }

  #success(method, { count = 1 } = {}) {
    this.snackbar.enqueue(
      this.intl.t(`services.fileDispatcher.${method}.snackbar.success`, {
        count,
      }),
      {
        variant: 'success',
        autoDismiss: true,
      }
    );
  }

  #error(method, count = 1) {
    this.snackbar.enqueue(
      this.intl.t(`services.fileDispatcher.${method}.snackbar.error`, {
        count,
      }),
      {
        variant: 'error',
      }
    );
  }
}
