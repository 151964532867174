import JSONSerializer from '@ember-data/serializer/json';

export default class OrderSerializer extends JSONSerializer {
  normalizeResponse(store, primaryModelClass, payload, id, requestType) {
    if (requestType === 'findRecord' || requestType === 'updateRecord') {
      const normalized = this.normalize(primaryModelClass, payload);

      (normalized.included ??= []).push(
        ...payload.items.map((item) => ({
          attributes: item,
          id: item.id,
          type: 'order-item',
          relationships: {
            product: {
              data: {
                id: item.isbn,
                type: 'product',
              },
            },
          },
        }))
      );

      return normalized;
    }

    return {
      data: payload.map((item) => this.normalize(primaryModelClass, item).data),
    };
  }

  extractRelationships(modelClass, resourceHash) {
    const relationships = super.extractRelationships(...arguments);

    relationships.items = {
      data: resourceHash.items.map((item) => ({
        id: item.id,
        type: 'order-item',
      })),
    };

    if (resourceHash.activatedByUser) {
      relationships.user = {
        data: {
          id: resourceHash.activatedByUser,
          type: 'user',
        },
      };
    }

    return relationships;
  }

  serialize(snapshot, options) {
    return {
      userId: snapshot.record.get('user.id'),
      schoolId: snapshot.record.get('school.id'),
    };
  }
}
