import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';
import imageSource from 'compton/utils/image-source';

export default class PageHeaderComponent extends Component {
  /**
   * Arguments:
   * small (bool)
   * model (object)
   */

  get withLogo() {
    return this.args.model?.body?.use_logo ?? false;
  }

  get backgroundType() {
    if (this.args.model?.backgroundType === 'color') {
      return 'color';
    }

    if (this.args.model?.featuredImage) {
      return 'image';
    }

    return null;
  }

  get cssClasses() {
    const classes = ['page-header'];

    if (this.args.small) {
      classes.push('page-header--small');
    }

    if (this.withLogo) {
      classes.push('page-header--with-logo');
    }

    if (this.backgroundType === 'image') {
      classes.push('page-header--image');
    } else if (this.backgroundType === 'color') {
      classes.push('page-header--color');
    } else {
      classes.push('page-header--none');
    }

    return classes.join(' ');
  }

  get imageUrl() {
    if (!this.args.model?.featuredImage) return null;
    return imageSource(this.args.model.featuredImage, { preset: 'landscape' });
  }

  get backgroundColor() {
    const backgroundColor = this.args.model?.backgroundColor || '#000000';
    return htmlSafe(`background-color: ${backgroundColor}`);
  }

  get textColor() {
    const textColor = this.args.model?.textColor || '#ffffff';
    return htmlSafe(`color: ${textColor}`);
  }
}
