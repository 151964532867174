import Component from '@glimmer/component';
import config from 'babel-app/config/environment';

export default class LandingPageComponent extends Component {
  constructor() {
    super(...arguments);
  }

  get accountCreationLink() {
    const url = new URL('/create-account', config.accountEndpoint);

    return url.toString();
  }
}
