import { A } from '@ember/array';
import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { all } from 'rsvp';
import { trackEvent } from '../../../utils/matomo-events';

export default Component.extend({
  // SERVICES

  store: service(),

  session: service(),

  // EXTERNAL PROPERTIES

  externalLicenses: false,

  user: null,

  // INTERNAL PROPERTIES

  licenses: null,

  availableProducts: null,

  isLoading: true,

  activeProduct: null,

  modalOpen: false,

  // COMPUTED

  sortedLicenses: computed('licenses.[]', function() {
    return (this.get('licenses') || A()).sortBy('product.title');
  }),

  // LIFECYCLE

  didReceiveAttrs() {
    this._super(...arguments);

    this.get('getLicenses').perform();
  },

  // TASKS

  getLicenses: task(function*() {
    const user = this.get('user');

    if (!user) {
      return;
    }

    this.set('isLoading', true);

    const licenses = yield user.get('licenses');
    const school = yield this.get('session.user.school');
    const availableProducts = yield school.get('available_products');

    this.set('licenses', licenses);
    this.set('availableProducts', availableProducts);

    const promises = [];

    licenses.forEach((license) => {
      promises.push(license.get('product'));
    });

    availableProducts.forEach((license) => {
      promises.push(license.get('product'));
    });

    yield all(promises);

    this.set('isLoading', false);
  }).drop(),

  // ACTIONS

  actions: {
    openLicenseModal(product) {
      trackEvent({
        category: 'Användare', 
        action: `${this.user.role === 'teacher' ? 'Lärare' : 'Elev' } - Licenser - Hantera licenser`
      });

      this.set('activeProduct', product);
      this.set('modalOpen', true);
    }
  }
});
