import { helper } from '@ember/component/helper';
import { assert } from '@ember/debug';

import { numberToBase26 } from '../utils/number-to-base-26';

/*

export interface NumberToBase26HelperSignature {
  Args: {
    Positional: [number];
  };
  Return: string;
}

*/

export default helper(function _numberToBase26([number]) {
  assert(
    `The first argument passed to the number-to-base-26 helper must be a number. Got ${typeof number}.`,
    typeof number === 'number'
  );

  return numberToBase26(number);
});
