import { isArray } from '@ember/array';
import { all, reject } from 'rsvp';

export default function loadDataFromStore(type, id, store) {
  if (!id) return reject();

  if (isArray(id)) {
    const promises = id.map((x) => {
      if (!x) return reject();
      return store.findRecord(type, x);
    });

    return all(promises);
  }

  return store.findRecord(type, id);
}
