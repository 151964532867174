import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';

import licenseInfoHelper from '../utils/license-info';

export default class LicenseInfo extends Helper {
  @service intl;

  compute(_, props) {
    return licenseInfoHelper(props.license, this.intl)[props.parameter];
  }
}
