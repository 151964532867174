import Component from '@ember/component';
import { computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import ClassLessClass from 'babel-app/mixins/class-less-class';
import ConfirmSupport from 'compton/mixins/confirm-support';
import { computed as overridable } from 'compton/utils/computed-override';
import moment from 'moment';

export default Component.extend(ClassLessClass, ConfirmSupport, {
  // SERVICES

  session: service(),

  router: service(),

  store: service(),

  intl: service(),

  snackbar: service(),

  // EXTERNAL

  model: null,

  // PROPERTIES

  modalOpen: false,

  showTrial: false,

  activeProduct: null,

  activeUser: null,

  new: false,

  today: new Date().toLocaleDateString('SV-se'),

  // COMPUTED

  students: alias('model.students'),

  groups: alias('model.classes'),

  minStartDate: computed('model.course.start_date', function () {
    const today = moment();
    const startDate = moment(this.get('model.course.start_date'));
    const minDate = today.isAfter(startDate) ? startDate : today;
    return minDate.format('YYYY-MM-DD');
  }),

  maxDate: computed(function () {
    const maxDate = new Date()
    maxDate.setFullYear(maxDate.getFullYear() + 5, 11, 31)
    return maxDate.toLocaleDateString('SV-se')
  }),

  selectableCourse: overridable('model.course', function () {
    const courseId = this.get('model.course.id');

    return `course.${courseId}`;
  }),

  // LIFECYCLE

  init() {
    this._super(...arguments);

    this._createClassLessClass();
  },

  willDestroyElement() {
    this._super(...arguments);

    this._destroyClassLessClass();
  },

  // ACTIONS

  showPicker(event) {
    event.target.showPicker?.();
  },

  actions: {
    changeStartDate(event) {
      this.set('model.course.start_date', new Date(event.target.value));
      this.send('save');
    },

    changeEndDate(event) {
      this.set('model.course.end_date', new Date(event.target.value));
      this.send('save');
    },

    async save({ groupsChanged, booksChanged, teachersChanged } = {}) {
      if (groupsChanged) {
        const groups = this.get('model.course.groups').filterBy('type', 'group')
        if (groups.length > 0) {
          await groups[0].save()
        }
      }

      await this.get('model.course').save()
      await Promise.all([
        booksChanged && this.get('model.course.books'),
        booksChanged && this.get('model.course').reloadMissingLicenseProducts(),
        (booksChanged || groupsChanged || teachersChanged) && this.get('model.course.users').reload()
      ])
    },

    archive() {
      return this.confirm(this.get('intl').t('courses.archiveConfirm'), {
        danger: true,
        okLabel: this.get('intl').t('courses.archiveConfirmAction')
      }).then(() => {
        const course = this.get('model.course');

        course.set('active', false);

        return course.save().then(() => {
          return this.get('router').transitionTo('master.index');
        });
      });
    },

    restore() {
      const course = this.get('model.course');

      course.set('active', true);

      course.save().then(() => {
        this.get('session.user.courses').reload();
      });
    },

    openLicenseModal(product, user, showTrial) {
      if (showTrial) {
        Promise.resolve(showTrial).then((trial) => {
          this.set('showTrial', trial === true);
        })
      }
      this.set('activeProduct', product);
      this.set('activeUser', user);
      this.set('modalOpen', true);
    },

    assignLicenses(availableProduct) {

      return this.get('model.course').assignLicenses(availableProduct);
    },

    finished() {
      window.scrollTo({ top: 0 });
      if (this.get('new')) return this.get('router').transitionTo('master.index');
      history.back();
    }
  }
});
