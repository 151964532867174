import Component from '@glimmer/component';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import QRCode from 'qrcode';

export default class SchoolCodeComponent extends Component {
  @service snackbar;
  @service intl;

  @tracked qrCode = null;
  @tracked isMenuOpen = false;

  get accessCode() {
    return this.args.codes?.find((code) => code.active);
  }

  get inviteLink() {
    return `${location.origin}/skolkod/${this.accessCode.code}`;
  }

  @action
  async createQrCode() {
    this.qrCode = await QRCode.toDataURL(this.inviteLink, { scale: 50 });
  }

  @action
  async copyToClipboard() {
    await navigator.clipboard.writeText(this.inviteLink);

    this.snackbar.enqueue(this.intl.t('components.admin.schoolCode.linkCopied'), {
      variant: 'success',
      autoDismiss: true,
    })
  }

  @action
  generateCode() {
    this.isMenuOpen = false;

    return this.args.generateCode?.();
  }

  @action
  disableCode() {
    this.isMenuOpen = false;

    return this.args.disableCode?.(this.accessCode);
  }
}
