import DS from 'ember-data';
import { computed } from '@ember/object';

import Model from './model';

const { attr } = DS;

export default Model.extend({
  user_id: attr('string'),
  username: attr('string'),
  firstname: attr('string'),
  lastname: attr('string'),
  role: attr('string'),

  isbn: attr('string'),

  class_id: attr('string'),
  school_id: attr('string'),

  license_id: attr('string'),
  acquire_date: attr('date'),
  active_from: attr('date'),
  active_until: attr('date'),
  is_demo: attr('boolean'),

  is_private: attr('boolean'),
  has_agreement: attr('boolean'),
  is_external: attr('boolean'),
  is_expired: attr('boolean'),

  extended: attr('boolean'),
  extended_id: attr('string'),

  demo_used: attr('boolean'),

  text: attr('string'),
  class: attr('string'),
  showExtension: attr('boolean'),

  showname: computed('firstname', 'lastname', 'username', function() {
    if (this.firstname && this.lastname) {
      return `${this.firstname} ${this.lastname}`;
    } else if (this.firstname && !this.lastname) {
      return this.firstname;
    } else if (this.lastname && !this.firstname) {
      return this.lastname;
    } else {
      return this.username;
    }
  })
});
