import ApplicationAdapter from './application';

export default class LicenseAdapter extends ApplicationAdapter {

  shouldBackgroundReloadRecord() {
    return true;
  }

  shouldBackgroundReloadAll() {
    return true;
  }

  urlForDeleteRecord() {
    return `${super.urlForDeleteRecord(...arguments)}/remove`;
  }

  async deleteRecord(store, type, snapshot) {
    await super.deleteRecord(...arguments);

    const schoolId = snapshot.belongsTo('school', { id: true });
    if (schoolId) {
      const isbn = snapshot.attr('isbn');
      store.findRecord('available-product', `${isbn}_${schoolId}`);
    }
  }
}
