import DS from 'ember-data';

import Model from './model';

const { attr } = DS;

export default Model.extend({
  title: attr('string'),

  type: attr('string'),

  message: attr('string'),

  body: attr(),

  created: attr('date')
});
