import Component from '@ember/component';

export default Component.extend({
  // SETUP

  classNames: ['file-type', 'text-small'],

  // PARAMS

  file: null
});
