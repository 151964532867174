import { isArray } from '@ember/array';
import EmberObject from '@ember/object';

const clone = function(val) {
  if (!val || typeof val !== 'object') {
    return val;
  }

  if (isArray(val)) {
    return val.map((item) => clone(item));
  }

  return EmberObject.create(Object.assign({}, val));
};

export default clone;
