import Component from '@ember/component';
import { computed } from '@ember/object';
import { sort } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default Component.extend({
  session: service(),

  courses: null,

  searchValue: null,

  coursesSortDefinition: Object.freeze(['created:desc']),

  sortedCourses: sort('filteredCourses', 'coursesSortDefinition'),

  filteredCourses: computed('courses', 'searchValue', function() {
    if (this.get('searchValue')) {
      return this.get('courses').filter((course) => {
        const searchValue = this.get('searchValue').toLowerCase();

        if (
          course.get('products') &&
          course
            .get('products')
            .getEach('title')
            .join(',')
            .toLowerCase()
            .includes(searchValue)
        ) {
          return true;
        } else if (
          course.get('books') &&
          course
            .get('books')
            .getEach('title')
            .join(',')
            .toLowerCase()
            .includes(searchValue)
        ) {
          return true;
        } else if (
          course.get('name') &&
          course
            .get('name')
            .toLowerCase()
            .includes(searchValue)
        ) {
          return true;
        } else {
          return false;
        }
      });
    } else {
      return this.get('courses');
    }
  }),

  actions: {
    goToSupport() {
      window.open(
        'https://support.nok.se/article/4101',
        '_blank'
      );
    }
  }
});
