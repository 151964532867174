import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import { load } from 'ember-async-data';
import { resolve } from 'rsvp';

export default class GlobalBreadcrumb extends Component {
  get isContentRoute() {
    return ['contents', 'interactives'].includes(this.args.parent?.type);
  }

  @cached get areaProxy() {
    let promise = resolve();

    if (this.args.parent) {
      promise = resolve(this.args.parent.parent);
    }

    return load(promise, this);
  }

  get area() {
    return this.areaProxy.isResolved ? this.areaProxy.value : null;
  }

  get targetRoute() {
    return 'master.interactives';
  }
}
