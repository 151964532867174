import Component from '@glimmer/component';
import EmberObject from '@ember/object';

export default class UserAvatarAndNameComponent extends Component {
  get userWithMetadata() {
    const {
      firstname,
      lastname,
      avatar,
      avatar_img
    } = this.args.user;

    const user = EmberObject.create({
      initials: firstname || lastname ? `${firstname?.substring(0, 1) ?? ''}${lastname?.substring(0, 1) ?? ''}`.toUpperCase() : '-',
      avatar,
      metadata: {
        avatar: avatar ? avatar : -1,
        avatar_img
      }
    });

    return user;
  }
}
