
import Component from '@ember/component';
import { computed } from '@ember/object';
import { alias, equal } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default Component.extend({
  session: service(),

  classNames: ['product-picker-item'],

  classNameBindings: ['isPicked:product-picker-item--picked'],

  // PARAMS

  product: null,

  availableProducts: null,

  pickedProducts: null,

  hasExternalProducts: null,

  hasInternalProducts: null,

  licenses: null,

  isTrial: false,

  onPick: null,

  onUnpick: null,

  externalLicenses: alias('session.user.school.metadata.external_licenses'),

  teachersOnly: computed('product.teachersOnly', function() {
    return this.get('product.teachersOnly');
  }),

  freeProduct: alias('product.free'),

  availableProduct: computed(
    'availableProducts.@each.isbn',
    'product.id',
    'isTrial',
    function() {
      if (this.get('isTrial')) {
        return null;
      }

      return this.get('availableProducts').findBy(
        'isbn',
        this.get('product.id')
      );
    }
  ),

  demoAgreement: computed('agreements', 'product.id', function () {
    const agreements = this.get('agreements');
    const isbn = this.get('product.id');

    if (!agreements?.length) {
      return null;
    }

    const demoAgreementCampaigns = agreements.filter(
      (curr) => curr.is_demo && !curr.get('product.id')
    );

    if (demoAgreementCampaigns?.length) {
      return demoAgreementCampaigns[0];
    }

    const currentAgreement = agreements.filter(
      (curr) => curr.is_demo && curr.get('product.id') === isbn
    );

    if (currentAgreement?.length) {
      return currentAgreement[0];
    }

    return null;
  }),

  hasAgreement: alias('availableProduct.has_agreement'),

  numberOfLicensesLeft: alias('availableProduct.unassigned'),

  isExhausted: equal('numberOfLicensesLeft', 0),

  isPicked: computed('pickedProducts.@each', 'product', function() {
    return this.get('pickedProducts').includes(this.get('product'));
  }),

  actions: {}
});
