import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import ConfirmSupport from 'compton/mixins/confirm-support';
import { trackEvent } from '../../../../utils/matomo-events';

export default Component.extend(ConfirmSupport, {
  intl: service(),

  // EXTERNAL

  course: null,

  searchArr: null,

  row: null,

  // SETUP

  tagName: '',

  // COMPUTED

  teachers: computed('course.teachers.@each.showname', function() {
    return this.get('course.teachers')
      .map((x) => x.get('showname'))
      .join(', ');
  }),

  courseName: computed('course.name', function() {
    const name = this.get('course.name');

    if (!name || name === '') {
      return htmlSafe(
        '<em class="dimmed">' + this.get('intl').t('nameMissing') + '</em>'
      );
    }

    return name;
  }),

  // ACTIONS

  actions: {
    archive(course) {
      return this.confirm(this.get('intl').t('admin.archiveConfirm'), {
        danger: true
      }).then(() => {
        trackEvent({
          category: 'Kurs',
          action: 'Kurs - Arkivera'
        });
        course.set('active', false);
        return course.save();
      });
    },

    restore(course) {
      trackEvent({
        category: 'Kurs',
        action: 'Kurs - Återställ'
      });
      course.set('active', true);
      return course.save();
    },

    openCourseSettings() {
      trackEvent({
        category: 'Kurs',
        action: 'Kurs - Öppna'
      });
    }
  }
});
