import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class ActivateController extends Controller {
  @service session;
  @service router;
  @service snackbar;
  @service store;
  @service intl;

  @tracked orderIsLoading;

  get schoolOptions() {
    // Should not show schools if the order is private
    if (this.model.order.isPrivatePurchase) {
      return [];
    }

    return this.model.user.schools?.map((school) => ({
      value: school.id,
      label: school.name,
    }));
  }

  get deliveredTitle() {
    if (this.model.order.alternativeDeliveryPoint === 'skolon') {
      return this.intl.t('routes.activate.deliveredTitle.skolon');
    }

    if (this.model.order.schoolName) {
      return this.intl.t('routes.activate.deliveredTitle.schoolName', { schoolName: this.model.order.schoolName });
    }

    return this.intl.t('routes.activate.deliveredTitle.added');
  }

  get canBeActivated() {
    const { order, user } = this.model;

    if (!order || !user) {
      return false;
    }

    if (order.activated) {
      return false;
    }

    if (
      order.isExpired ||
      order.hasUnpublishedProducts
    ) {
      return false;
    }

    if ((user.isStudent || user.isPrivateUser) && !order.isPrivatePurchase) {
      return false;
    }

    return true;
  }

  get isTeacherAtOrderSchool() {
    if (!this.model.user || !this.model.order.schoolId) {
      return false;
    }

    if (!this.model.user.isTeacher) {
      return false;
    }

    const schools = this.model.userSchools;

    if (schools.some(({ id }) => id === this.model.order.schoolId)) {
      return true;
    }

    return false;
  }

  @action
  async changeSchool() {
    this.model.user.school = this.store.peekRecord('school', this.model.user.active_school);

    await this.model.user.save();
  }

  @action
  async activate() {
    this.orderIsLoading = true;

    try {
      this.model.order.user = this.model.user;

      const activeSchool = this.model.user.active_school;
      const school = activeSchool ? this.store.peekRecord('school', this.model.user.active_school) : null;

      if (school) {
        this.model.order.school = school;
      }

      await this.model.order.save();
    } catch (err){
      console.log(err);
      await this.snackbar.enqueue(this.intl.t('routes.activate.processFailed'), { variant: 'error', autoDismiss: false, });
    } finally {
      this.orderIsLoading = false;
    }
  }

  @action
  async goToSchoolAdmin() {
    if (this.model.user.active_school !== this.model.order.schoolId) {
      this.model.user.set('active_school', this.model.order.schoolId);

      await this.changeSchool();
    }

    return this.router.transitionTo('master.admin.books', { queryParams: { order: this.model.order.id }});
  }

  @action
  gotoLogin() {
    this.session.data.nextURL = this.router.currentURL;
    this.session.data.nextURLExpires = new Date().getTime() + 300000; // in five minutes

    return this.router.transitionTo('login');
  }
}
