import { A } from '@ember/array';
import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import ConfirmSupport from 'compton/mixins/confirm-support';
import showFilePicker from 'compton/utils/show-file-picker';
import { singularize } from 'ember-inflector';
import { v4 as uuid } from 'ember-uuid';
import EmberObject from '@ember/object';
import { trackEvent } from '../../../utils/matomo-events';

const csvMaxLength = 1000;

/*global Papa */

export default Component.extend(ConfirmSupport, {
  intl: service(),

  // EXTERNAL

  userType: null,

  addUsers() { },

  close() { },

  role: null,

  // INTERNAL

  tagName: 'div',

  id: uuid(),

  addingUsers: false,

  list: null,

  findingEmails: false,

  users: null,

  validUsers: [],

  headings: null,

  firstNameHeading: null,

  lastNameHeading: null,

  emailHeading: null,

  classHeading: null,

  isPopupIndexOpen: false,

  validateUsersWithInvalidEmail: null,

  filesUploaded: 0, // Number of times a file has been uploaded, only used for tracking

  canSubmit: computed('validUsers', 'findingEmails', 'list', function () {
    const list = (this.get('list') || A()).length;
    const validUsers = (this.get('validUsers') || A()).length;

    return validUsers && list === validUsers;
  }),

  init() {
    this._super(...arguments);

    this.set('users', null);
    this.set('addingUsers', false);
    this.set('validUsers', []);
    this.set('list', null);
  },

  // ACTIONS

  actions: {
    selectCSV(callback) {
      showFilePicker({
        accept: 'text/csv',
        onChange: (file) => {
          this.set('validateUsersWithInvalidEmail', null);

          Papa.parse(file, {
            header: false,
            complete: (result) => {
              this.set('filesUploaded', this.get('filesUploaded') + 1);

              if (result.data.length > csvMaxLength) {
                this.confirm(this.get('intl').t('admin.CSVFileTooLarge'), {
                  cancelLabel: null
                });
                return;
              }
              this.set(
                'list',
                result.data.map((data) =>
                  EmberObject.create({ data, validationMessage: null })
                )
              );
              this.set('csvModalOpen', true);

              if (callback) {
                // Hack: Make sure everything is loaded.
                // Even though we are in the complete callback and have set 'list' to the loaded list, everything is not yet completed.
                // If we execute the callback directly, the headers have not been loaded and we will not be able to select which properties to use.
                // By running the callback with setImmediate, we will make sure everything else is loaded since this puts the callback to the end of the event loop queue.
                setImmediate(callback);
              }
            },
            error: () => {
              this.confirm(this.get('intl').t('admin.invalidCSV'), {
                cancelLabel: null
              });
            },
            skipEmptyLines: true
          });
        }
      });
    },

    async addUsers() {
      const {
        headings,
        validUsers,
        list,
        firstNameHeading,
        lastNameHeading,
        emailHeading,
        classHeading
      } = this.getProperties(
        'headings',
        'validUsers',
        'list',
        'firstNameHeading',
        'lastNameHeading',
        'emailHeading',
        'classHeading'
      );

      let emailIndex = -1;
      let classIndex = -1;
      let firstNameIndex = -1;
      let lastNameIndex = -1;
      let mixedNameIndex = -1;

      headings.forEach((heading, index) => {
        if (heading === emailHeading) {
          emailIndex = index;
        } else if (heading === classHeading) {
          classIndex = index;
        } else if (heading === firstNameHeading) {
          firstNameIndex = index;
        } else if (heading === lastNameHeading) {
          lastNameIndex = index;
        } else if (heading && heading.multiple) {
          mixedNameIndex = index;
        }
      });

      if (emailIndex < 0) {
        return;
      }

      this.set('addingUsers', true);

      const classes = [];
      const classPromises = [];

      if (classIndex >= 0) {
        list.forEach((rowItem) => {
          const row = rowItem.data;
          const email = row[emailIndex];

          if (email) {
            const userIndex = validUsers.indexOf(email.toLowerCase());

            if (userIndex !== -1) {
              const group = row[classIndex];

              if (group && classes.indexOf(group) === -1) {
                classes.push(group);
                classPromises.push(this.get('addClass')(group));
              }
            }
          }
        });
      }

      const groups = await Promise.all(classPromises);

      const data = [];

      list.forEach((rowItem) => {
        const row = rowItem.data;
        const email = row[emailIndex];

        if (email) {
          const userIndex = validUsers.indexOf(!!email ? email.toLowerCase() : null);

          if (userIndex !== -1) {
            const user = {
              username: validUsers[userIndex]
            };

            if (classIndex >= 0) {
              const groupIndex = groups
                .map((x) => x['name'])
                .indexOf(row[classIndex]);

              if (groupIndex >= 0) {
                user.class = groups[groupIndex]['id'];
              }
            }

            if (firstNameIndex >= 0) {
              user.firstname = row[firstNameIndex];
            }

            if (lastNameIndex >= 0) {
              user.lastname = row[lastNameIndex];
            }

            if (mixedNameIndex >= 0) {
              const nameParts = row[mixedNameIndex].split(' ');
              const heading = headings[mixedNameIndex];

              if (heading.primary === firstNameHeading) {
                user.firstname = nameParts.shift();
                user.lastname = nameParts.join(' ');
              } else {
                user.firstname = nameParts.pop();
                user.lastname = nameParts.join(' ');
              }

              user.firstname = user.firstname.replace(/,/g, '');
              user.lastname = user.lastname.replace(/,/g, '');
            }

            data.push(user);
          }
        }
      });

      const eventName = this.userType === 'students' ? (headings.includes('Class') || headings.includes('Klass')) ? 'Med klass' : 'Utan klass' : null;

      try {
        trackEvent({
          category: 'Användare',
          action: `Bjud in ${ this.userType === 'teachers' ? 'lärare' : 'elev' } - Importera csv - Importera`,
          name: eventName,
          value: data.length,
        });

        await this.addUsers(data, singularize(this.userType), groups);

        this.set('users', null);
        this.set('csvModalOpen', false);
        this.close?.(true, this.filesUploaded);
      } catch (error) {
        this.confirm(this.intl.t('admin.csvImportFail'), {
          cancelLabel: null,
        });
      } finally {
        this.set('addingUsers', false);
      }
    },
  },
});
