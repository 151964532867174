import { computed } from '@ember/object';
import Mixin from '@ember/object/mixin';
import { inject as service } from '@ember/service';

export default Mixin.create({
  // SERVICES

  store: service(),

  session: service(),

  intl: service(),

  // PROPERTIES

  classLessClass: null,

  // COMPUTED

  classes: computed('groups.{[],@each.users}', 'students.[]', function () {
    const groups = this.get('groups');
    const students = this.get('students');

    let shownUsers = [],
      classes = [];

    if (groups) {
      classes = groups.filter(
        (x) => x.get('type') === 'class' && !x.get('metadata.classLessClass')
      );

      classes.forEach((group) => {
        const users = group.get('users.isFulfilled') ? group.get('users') : [];

        shownUsers = shownUsers.concat(
          users
            .filter((x) => x.get('role') === 'student')
            .map((x) => x.get('id'))
        );
      });

      shownUsers = shownUsers.filter(
        (val, idx, self) => self.indexOf(val) === idx
      );
    }

    const group = this.get('classLessClass');
    let classLessUsers = [];

    if (students) {
      classLessUsers = students.filter(
        (x) =>
          x.get('role') === 'student' && shownUsers.indexOf(x.get('id')) === -1
      );

      group.set('users', classLessUsers);
      group.set('num_students', classLessUsers.length);
    }

    if (classLessUsers.length > 0) {
      classes.addObject(group);
    } else if (classes.indexOf(group) !== -1) {
      classes.removeObject(group);
    }

    return classes;
  }),

  // METHODS

  _createClassLessClass() {
    const classLessClass = this.get('store').createRecord('group', {
      name: this.get('intl').t('admin.classNameMissing'),
      type: 'class',
      school: this.get('session.user.school'),
      num_students: 0,
      metadata: {
        classLessClass: true,
      },
    });

    this.set('classLessClass', classLessClass);
  },

  _destroyClassLessClass() {
    const classLessClass = this.get('classLessClass');

    if (classLessClass) {
      classLessClass.destroyRecord();
    }
  },
});
