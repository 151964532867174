import Controller from '@ember/controller';
import { action } from '@ember/object';
import { alias } from '@ember/object/computed';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { storageFor } from 'ember-local-storage';

export default class extends Controller {
  queryParams = ['isbn', 'refresh'];
  isbn = null;
  refresh = null;

  @service store;
  @service router;
  @service session;
  @service intl;
  @service confirmManager;

  @storageFor('ui-messages') uiMessages;

  @tracked transitioningToCreateCourse = false;
  @tracked searchVal = '';

  @alias('session.data.tryProductsMessage') tryProductsMessage;

  get hasClosedConnectToSchoolInfoMessage() {
    const notices = this.model.readNotices;

    return Boolean(notices.find(notice => notice.notice_key === 'connect_to_school_info_message'));
  }

  get isAlreadyOnSchool() {
    const userSchoolIds = this.model.user.hasMany('schools').ids();
    const applicationSchoolId = this.model.schoolApplication.belongsTo('school').id();

    if (!applicationSchoolId) {
      return false;
    }

    return userSchoolIds.includes(applicationSchoolId);
  }

  get searchValue() {
    if (!this.model.isbn) {
      return null;
    }

    return this.store.findRecord('product', this.model.isbn).then((product) => {
      return product ? product.get('title') : null;
    });
  }

  get deniedSchoolApplication() {
    if (!this.model.schoolApplication || this.model.schoolApplication.is_active) {
      return null;
    }

    const schoolApplication = this.model.schoolApplication;
    const sameUser = this.model.user.id === schoolApplication.updated_by;
    const isAccepted = schoolApplication.is_accepted;
    const messageClosed =
      this.uiMessages.get('schoolApplicationMessageClosed') ===
      schoolApplication.id;

    if (!isAccepted && !sameUser && !messageClosed && !this.isAlreadyOnSchool) {
      return schoolApplication;
    }

    return false;
  }

  @action
  closeSchoolApplicationMessage() {
    const schoolApplicationId = this.model.schoolApplication.id;
    this.uiMessages.set('schoolApplicationMessageClosed', schoolApplicationId);
  }

  @action
  async closeSchoolMessage() {
    const newReadNotice = this.store.createRecord('read-notice', {
      notice_key: 'connect_to_school_info_message',
    });

    this.session.user.readNotices.addObject(newReadNotice);

    await newReadNotice.save();
  }

  @action
  openConnectToSchoolModal() {
    this.router.transitionTo('master.index.anslut');
  }

  @action
  createCourse() {
    if (!this.transitioningToCreateCourse) {
      this.transitioningToCreateCourse = true;
      this.router.transitionTo('master.courses.new');
    }
  }

  @action
  async cancelApplication() {
    try {
      await this.confirmManager.confirm(
        this.intl.t('overview.cancelSchoolApplication'),
        {
          title: this.intl.t('overview.cancelSchoolApplicationConfirmTitle'),
          danger: true,
          okLabel: this.intl.t('overview.cancelSchoolApplicationConfirm'),
        }
      );

      const application = this.model.schoolApplication;
      application.deleteRecord();
      await application.save();
    } catch (error) {}
  }

  @action
  dismissSchoolApplicationAlert() {
    this.session.set('data.tryProductsMessage', null);
  }
}
