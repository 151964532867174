import StorageObject from 'ember-local-storage/session/object';

const Storage = StorageObject.extend();

// Uncomment if you would like to set initialState
Storage.reopenClass({
  initialState() {
    return {
      // ILT (Inläsningstjänst) can be disabled for the session if user is not authorized,
      // Note: Initial value is null until we have tried to authenticate user for ILT! After that it will be true/false.
      iltEnabled: null,

      // A JWT is required to fetch articles from ILT. The token will be fetched from Digilär's backend.
      iltJwt: null
    };
  }
});

export default Storage;
