import Component from '@ember/component';

export default Component.extend({
  classNames: ['sticky-update-notice'],

  availableProduct: null,

  actions: {
    updateAvailableProduct() {
      const availableProduct = this.get('availableProduct');

      if (availableProduct) {
        return availableProduct.fullReload();
      }
    }
  }
});
