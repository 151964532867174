export default {
  // MARK : - Convert hex to rgba
  convertHex: function(hex, opacity) {
    if (hex) {
      hex = hex.replace('#', '');
      var r = parseInt(hex.substring(0, 2), 16);
      var g = parseInt(hex.substring(2, 4), 16);
      var b = parseInt(hex.substring(4, 6), 16);
      return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity / 100 + ')';
    }

    return '';
  },

  LightenDarkenColor: function(col, amt) {
    var usePound = false;

    if (col[0] == '#') {
      col = col.slice(1);
      usePound = true;
    }

    var num = parseInt(col, 16);

    var r = (num >> 16) + amt;

    if (r > 255) r = 255;
    else if (r < 0) r = 0;

    var b = ((num >> 8) & 0x00ff) + amt;

    if (b > 255) b = 255;
    else if (b < 0) b = 0;

    var g = (num & 0x0000ff) + amt;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
  },

  shadeColor: function(color, percent) {
    var hex = color.replace(/#/gi, '');
    if (hex.length == 3) {
      hex = hex + hex;
    }

    var f = parseInt(hex, 16),
      t = percent < 0 ? 0 : 255,
      p = percent < 0 ? percent * -1 : percent,
      R = f >> 16,
      G = (f >> 8) & 0x00ff,
      B = f & 0x0000ff;
    return (
      '#' +
      (
        0x1000000 +
        (Math.round((t - R) * p) + R) * 0x10000 +
        (Math.round((t - G) * p) + G) * 0x100 +
        (Math.round((t - B) * p) + B)
      )
        .toString(16)
        .slice(1)
    );
  },

  /**
   *
   * Helper to change parts of query params to tailor transition params.
   * @param router, name, id
   * @return array with routerName + ids
   **/

  dynamicParams: function(router, name, id) {
    var params = router.targetState.routerJsState.params,
      routeName = router.currentRouteName,
      array;

    array = Object.entries(params)
      .map(function(obj) {
        // Change id at this position if name is equal the provided name
        if (obj.indexOf(name) > -1) {
          return id;
        }

        // Return route it
        return Object.values(obj[1]) ? Object.values(obj[1])[0] : null;
      })
      .filter(function(val) {
        // Remove undefined values
        return val ? true : false;
      });

    // Insert routeName as first object in array
    array.splice(0, 0, routeName);
    return array;
  }
};
