import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { trackEvent } from '../../../utils/matomo-events';

export default class CourseController extends Controller {
  @tracked searchVal = ''

  queryParams = [
    'page',
    'archivedPage',
    'search',
  ]

  @tracked search = null
  @tracked page = 1
  @tracked archivedPage = 1
  @tracked archivedToggle = false

  @action textSearch() {
    this.page = 1
    this.archivedPage = 1
    this.search = this.searchVal

    if (this.searchVal) {
      trackEvent({ category: 'Kurs', action: 'Sök kurser' });
    }
  }

  @action changePage(newPage) {
    this.page = newPage
  }

  get searchArray() {
    return this.search?.split(' ') ?? []
  }

  @action changeArchivedPage(newPage) {
    this.archivedPage = newPage
  }

  @action toggleArchived() {
    trackEvent({ category: 'Kurs', action: 'Visa arkiverade kurser' });

    this.archivedToggle = !this.archivedToggle;
  }

  @action createCourse() {
    trackEvent({ category: 'Kurs', action: 'Skapa kurs - Öppna från skoladministration' });
  }
}
