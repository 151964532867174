function getOptions() {
  const maxAge = 60 * 60 * 24 * 365; // one year in seconds
  const fullDomain = location.hostname;
  const domainParts = fullDomain.split('.');
  const numDomainParts = domainParts.length;
  const domain =
    numDomainParts > 2
      ? '.' +
        domainParts[numDomainParts - 2] +
        '.' +
        domainParts[numDomainParts - 1]
      : fullDomain;
  const path = '/';

  return { domain, path, maxAge };
}

function clearCookie(cookieService, name) {
  const { domain, path } = getOptions();
  cookieService.clear(name, { domain, path });
}

function writeCookie(cookieService, name, value) {
  cookieService.write(name, value, getOptions());
}

export { clearCookie, writeCookie };
