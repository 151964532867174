import { computed } from '@ember/object';
import DS from 'ember-data';
import moment from 'moment';

import Model from './model';

const { attr, belongsTo } = DS;

export default Model.extend({
  // ATTRIBUTES

  title: attr('string'),

  startDate: attr('only-date'),

  endDate: attr('only-date'),

  state: attr('string'),

  // RELATIONSHIPS

  todo: belongsTo('todo'),

  post: belongsTo('post'),

  user: belongsTo('user'),

  school: belongsTo('school'),

  activityItem: belongsTo('activityItem', { polymorphic: true }),

  course: belongsTo('course'),

  isComing: computed('startDate', function() {
    return moment().isBefore(this.get('startDate'));
  }),

  endDateAtEndOfDay: computed('endDate', function() {
    const endDate = this.get('endDate');

    if (endDate) {
      return moment(endDate)
        .add(1, 'd')
        .toDate();
    }

    return null;
  }),

  hasEnded: computed('endDateAtEndOfDay', function() {
    return moment().isAfter(this.get('endDateAtEndOfDay'));
  })
});
