import { helper as buildHelper } from '@ember/component/helper';

export function map(params /*, hash*/) {
  var array = params[0];
  var attr = params[1];
  if (!array) {
    return [];
  }
  return array.map((item) => {
    return item.get(attr);
  });
}

export default buildHelper(map);
