import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
  // SETUP

  classNames: ['mb1'],

  // EXTERNAL PROPERTIES

  teachers: null,

  // COMPUTED PROPERTIES

  text: computed('teachers.[]', function() {
    return this.get('teachers')
      .map((x) => x.get('showname'))
      .join(', ');
  })
});
