import { helper } from '@ember/component/helper';
import config from 'babel-app/config/environment';

function serialize(obj) {
  return Object.keys(obj)
    .map((k) => {
      return [encodeURIComponent(k), encodeURIComponent(obj[k])].join('=');
    })
    .join('&');
}

// FIXME Rename and cleanup this helper!

export function imageUrl([url], { width: w, height: h }) {
  return config.imgProcessingEndpoint + '?' + serialize({ url, w, h });
}

export default helper(imageUrl);
