import Component from '@glimmer/component';
import { service } from '@ember/service';
import fileToBase64 from 'babel-app/utils/file-to-base64';
import EmberObject, { action } from '@ember/object';

export default class ProfileSettingsAvatar extends Component {
  @service session;

  get userWithMetadata() {
    if (!this.session.user) return {};

    const {
      firstname,
      lastname,
      avatar,
      avatar_img
    } = this.session.user;

    const user = EmberObject.create({
      initials: `${firstname ? firstname.substring(0, 1) : ''}${lastname ? lastname.substring(0, 1) : ''}`.toUpperCase(),
      avatar,
      metadata: {
        avatar: avatar ? avatar : -1,
        avatar_img
      }
    });

    return user;
  }

  @action
  async handleUploadButtonChange(file) {
    const data = await fileToBase64(file, {
      width: 96,
      height: 96
    });

    const user = this.session.user;

    user.set('avatar', null);
    user.set('avatar_img', data);

    await user.save();

    document.querySelector('nok-account')?.update({
      avatar: null,
      avatarImage: data
    })
  }
}
