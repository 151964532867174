import sortBy from 'babel-app/utils/sort-by';
import { all } from 'rsvp';

export default function getCollectionData(store, collection) {
  const promises = [
    collection.parent,
    collection.children,
    collection.book,
    collection.contenteditor,
    collection.audio,
  ];

  return all(promises).then(([parent, exercises, book, editor]) => {
    if (collection.isTeacherCollection) {
      exercises = sortBy(exercises, 'title_asc');
    }

    return {
      parent,
      exercises,
      book,
      editor,
    };
  });
}
