import Service, { service } from '@ember/service';
import config from 'babel-app/config/environment';

export default class FakeOrdersService extends Service {
  @service store;

  #createTestOrder(id, order) {
    if (this.store.peekRecord('order', id)) {
      return;
    }

    return this.store.createRecord('order', {
      activated: null,
      activatedByUser: null,
      alternativeDeliveryPoint: null,
      attention: 'Beställare Beställarsson',
      created: new Date().toLocaleString('sv'),
      isPrivatePurchase: false,
      orderReference: 'referensen',
      schoolName: 'Fina skolan',
      id: id,
      school: null,
      user: null,
      items: [],
      ...order,
    });
  }

  #createTestOrderItem(orderItem) {
    return this.store.createRecord('order-item', {
      id: crypto.randomUUID(),
      created: new Date().toLocaleString('sv'),
      expired: null,
      isbn: '9789127455597',
      licenseDays: 365,
      publishDate: '2020-01-01',
      quantity: 3,
      title: 'En titel',
      willExpireAt: null,
      ...orderItem,
    });
  }

  get #isTestEnvironment() {
    return [
      'staging',
      'dev',
      'test',
      'development',
      'stage',
      '',
    ].includes(config.environmentName);
  }

  get orders() {
    if (!this.#isTestEnvironment) {
      return [];
    }

    return [
      {
        id: 1,
        title: 'Aktiv order med blandade produkter',
      },
      {
        id: 2,
        title: 'Order som är på väg att gå ut',
      },
      {
        id: 3,
        title: 'Order som bara innehåller produkter som publiceras i framtiden',
      },
      {
        id: 4,
        title: 'Order som har gått ut',
      },
      {
        id: 5,
        title: 'Aktiverad order (privat)',
      },
      {
        id: 6,
        title: 'Aktiverad order (skola)',
      },
      {
        id: 7,
        title: 'Aktiverad order (skickad till skolon)',
      },
      {
        id: 8,
        title: 'Aktiv privatorder',
      },
      {
        id: 9,
        title: 'Aktiv företagsorder',
      },
      {
        id: 10,
        title: 'Delvis utgången order',
      },
    ];
  }

  async generate(user) {
    if (!this.#isTestEnvironment) {
      return;
    }

    const testSchool = await user?.school;

    // Order med väldigt blandade produkter
    this.#createTestOrder(1, {
      items: [
        this.#createTestOrderItem({
          title: 'Vanlig produkt',
        }),
        this.#createTestOrderItem({
          title: 'På väg att gå ut',
          willExpireAt: new Date('2050-01-01').toISOString(),
        }),
        this.#createTestOrderItem({
          title: 'Har gått ut',
          expired: new Date('2020-01-01').toISOString(),
        }),
        this.#createTestOrderItem({
          title: 'Publiceras i framtiden',
          publishDate: new Date('2050-01-01').toISOString(),
        }),
      ],
    });

    // Order som är på väg att gå ut
    this.#createTestOrder(2, {
      items: [
        this.#createTestOrderItem({
          title: 'På väg att gå ut',
          willExpireAt: new Date('2050-01-01').toISOString(),
        }),
      ],
    });

    // Order som bara innehåller produkter som publiceras i framtiden
    this.#createTestOrder(3, {
      items: [
        this.#createTestOrderItem({
          title: 'Publiceras i framtiden',
          publishDate: new Date('2050-01-01').toISOString(),
        }),
      ],
    });

    // Order som har gått ut
    this.#createTestOrder(4, {
      items: [
        this.#createTestOrderItem({
          title: 'Har gått ut',
          expired: new Date('2020-01-01').toISOString(),
        }),
      ],
    });

    // Aktiverad order (privat)
    this.#createTestOrder(5, {
      activated: new Date().toISOString(),
      user,
      schoolName: null,
      isPrivatePurchase: true,
      items: [
        this.#createTestOrderItem({
          title: 'Aktiverad produkt',
        }),
      ],
    });

    // Aktiverad order (skola)
    this.#createTestOrder(6, {
      activated: new Date().toISOString(),
      user,
      school: await testSchool,
      items: [
        this.#createTestOrderItem({
          title: 'Aktiverad produkt',
        }),
      ],
    });

    // Aktiverad order (skickad till skolon)
    this.#createTestOrder(7, {
      alternativeDeliveryPoint: 'skolon',
      activated: new Date().toISOString(),
      user,
      school: await testSchool,
      items: [
        this.#createTestOrderItem({
          title: 'Aktiverad produkt',
        }),
      ],
    });

    // Aktiv privatorder
    this.#createTestOrder(8, {
      isPrivatePurchase: true,
      items: [
        this.#createTestOrderItem({
          title: 'Vanlig produkt',
        }),
      ],
    });

    // Aktiv företagsorder
    this.#createTestOrder(9, {
      items: [
        this.#createTestOrderItem({
          title: 'Vanlig produkt',
        }),
      ],
    });

    // Delvis utgången
    this.#createTestOrder(10, {
      items: [
        this.#createTestOrderItem({
          title: 'Ej utgången',
          willExpireAt: new Date('2050-01-01').toISOString(),
        }),
        this.#createTestOrderItem({
          title: 'Utgången',
          expired: new Date('2020-01-01').toISOString(),
        }),
      ],
    });
  }
}
