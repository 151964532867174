import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
  // PROPERTIES

  selected: null,

  modalSelected: null,

  available: null,

  txt: null,

  modalOpen: false,

  onChange() {},

  // COMPUTED

  sortedSelected: computed('selected.{[],isFulfilled}', function() {
    return this.get('selected').sortBy('showname');
  }),

  // RUNLOOP

  init() {
    this._super(...arguments);

    if (!this.get('txt')) {
      this.set('txt', {
        searchHeading: 'userSelect.searchHeading',
        searchPlaceholder: 'userSelect.searchPlaceholder',
        noUsersMessage: 'userSelect.noUsersMessage',
        userNameHeading: 'userSelect.userNameHeading',
        userEmailHeading: 'userSelect.userEmailHeading',
        usersLabel: 'courses.numStudents'
      });
    }

    if (!this.get('selected')) {
      this.set('selected', []);
    }

    if (!this.get('available')) {
      this.set('available', []);
    }
  },

  // ACTIONS

  actions: {
    openModal() {
      this.set('modalOpen', true);
      this.set('modalSelected', this.get('selected').slice(0));
    },

    closeModal() {
      this.set('modalOpen', false);
    },

    userChanged(users) {
      this.get('selected').addObjects(users);
      this.get('onChange')(this.get('selected'));
    },

    remove(item) {
      this.get('selected').removeObject(item);
      this.get('onChange')(this.get('selected'));
    },

    saveTeachers() {
      this.set('selected', this.get('modalSelected').slice(0));
      this.set('modalOpen', false);
      this.get('onChange')(this.get('selected'));
    }
  }
});
