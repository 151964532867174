import config from 'babel-app/config/environment';

function scaleImage(file, width, height) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.responseType = 'blob';

    xhr.open(
      'POST',
      `${config.imgProcessingEndpoint}?w=${width}&h=${height}&embed=true`,
      true
    );

    xhr.onload = function() {
      var reader = new FileReader();
      reader.readAsDataURL(this.response);
      reader.onloadend = function() {
        if (reader.result) {
          return resolve(reader.result);
        } else {
          return reject();
        }
      };
    };

    xhr.onerror = function() {
      return reject();
    };

    const blob = new Blob([file], { type: file.type });
    xhr.send(blob);
  });
}

export default function(file, { width, height } = {}) {
  return scaleImage(file, width, height);
}
