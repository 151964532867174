import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
  classNames: ['share-link-modal-options'],

  encodedLinkToShare: computed('linkToShare', function() {
    return encodeURIComponent(this.get('linkToShare'));
  })
});
