import { action } from '@ember/object';
import { later } from '@ember/runloop';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import config from 'babel-app/config/environment';

export default class GlobalHeaderComponent extends Component {
  @service imbox;
  @service router;
  @service session;
  @service intl;
  @service navigationHelper;

  @tracked isShowingTeacherGuidance = false;
  @tracked chatIsActive = false;

  accountUrl = config.accountEndpoint;

  get resolvedPageTitle() {
    const pageTitle = this.args.pageTitle;

    if (!pageTitle) return null;

    return {
      title: pageTitle?.title || this.intl.t(pageTitle?.label),
      target: pageTitle?.target,
    };
  }

  get isNotStudent() {
    return this.session.user?.role !== 'student';
  }

  get skolonEnvironment() {
    return [
      'staging',
      'dev',
      'test',
      'development',
      'stage',
      '',
    ].includes(config.environmentName)
      ? 'https://api-test.skolon.com'
      : 'https://api.skolon.com';
  }

  constructor() {
    super(...arguments);

    this._initTracking();
    // later(this, '_initTracking', 500);
  }

  @action
  passToken(nokAccountComponent) {
    nokAccountComponent.accessToken = () => this.session.accessToken
  }

  @action handleOpenGuide() {
    if (window._imbox && typeof window._imbox.push === 'function') {
      window._imbox.push(['toggle']);
    }
  }

  @action scrollToTop() {
    this._scrollToTop();
  }

  @action handlePageTitleClick(target) {
    let url;

    if (target?.route && target?.model) {
      url = this.router.urlFor(target.route, target.model);
    } else if (target?.target) {
      url = this.router.urlFor(target.target);
    }

    if (url === this.router.currentURL) {
      this._scrollToTop();
    } else {
      return this.router.transitionTo(url).catch((err) => console.log(err));
    }
  }

  _scrollToTop() {
    later(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    });
  }

  _initTracking() {
    if (
      !config.matomo.endpoint ||
      !config.matomo.site_id ||
      // Looks like they are always strings
      config.matomo.endpoint === 'null' ||
      config.matomo.site_id === 'null'
    ) {
      return;
    }

    // Initialize Matomo script
    window._paq ??= [];

    _paq.push(['setUserId', this.session.user?.id]);

    (() => {
      window._paq.push(['setDomains', config.matomo.domains]);
      window._paq.push(['enableCrossDomainLinking']);
      window._paq.push([
        'setTrackerUrl',
        `${config.matomo.endpoint}/matomo.php`,
      ]);
      window._paq.push(['setSiteId', config.matomo.site_id]);
      window._paq.push(['setCustomDimension', 1, this.session.user?.role]);
      const firstScript = document.getElementsByTagName('script')[0];

      const matomoScript = document.createElement('script');
      matomoScript.async = true;
      matomoScript.src = `${config.matomo.endpoint}/matomo.js`;

      firstScript.before(matomoScript);
    })();

    // Need a timeout to wait for the title to be updated
    const triggerPageChange = () => setTimeout(() => {
      const _paq = window._paq;
      if (!_paq) return;

      // Track pages
      _paq.push([
        'setCustomUrl',
        `${window.location.origin}${this.router.currentURL}`,
      ]);
      _paq.push(['trackPageView', document.title]);
    }, 100);

    // Trigger on page load
    triggerPageChange();

    // Listen to route changes
    this.router.on('routeDidChange', triggerPageChange);
  }
}
