import Controller from '@ember/controller';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class MasterProfileSettingsController extends Controller {
  queryParams = ['error', 'service', 'identifier'];

  @service session;

  @tracked error = null;
  @tracked service = null;
  @tracked identifier = null;

  @action
  async selectAvatar(avatar) {
    const chosenAvatar = avatar?.value ?? null;

    this.session.user.avatar = chosenAvatar
    this.session.user.avatar_img = null

    await this.session.user.save();

    document.querySelector('nok-account')?.update({
      avatar: this.session.user.avatar,
      avatarImage: null
    })
  }
}
