import { A } from '@ember/array';
import ArrayProxy from '@ember/array/proxy';
import Component from '@ember/component';
import { computed, observer } from '@ember/object';
import PromiseProxyMixin from '@ember/object/promise-proxy-mixin';
import ObjectProxy from '@ember/object/proxy';
import { inject as service } from '@ember/service';
import licenseInfoHelper from 'babel-app/utils/license-info';
import { pluralize } from 'ember-inflector';
import moment from 'moment';
import { resolve } from 'rsvp';
import { trackEvent } from '../../../../utils/matomo-events';

import config from '../../../../config/environment';

const ObjectPromiseProxy = ObjectProxy.extend(PromiseProxyMixin);
const ArrayPromiseProxy = ArrayProxy.extend(PromiseProxyMixin);

export default Component.extend({
  store: service(),
  ajax: service(),
  intl: service(),

  isLoading: false,

  availableProduct: false,
  school: false,
  externalLicenses: false,
  assignDisabled: false,
  trialsDisabled: false,
  actionsDisabled: false,
  agreement: null,

  itemPopover: false,
  showRecycleLicensesModal: false,

  groupMenuIsOpen: false,

  groupMenuChange: observer(
    'groupMenuIsOpen',
    function () {
      if (this.groupMenuIsOpen) {
        trackEvent({ category: 'Hantera licenser', action: 'Hantera licenser - Visa grupper' });
      }
    }
  ),

  sortField: false,
  sortDirection: 'ASC',

  groupedLicenses: computed(
    'availableProduct.user_licenses.{isPending,@each}',
    'groupOrCourse.users.[]',
    function () {
      const groupOrCourse = this.get('groupOrCourse');
      const userLicenses = this.get('availableProduct.user_licenses') || A();
      if (groupOrCourse) {
        return userLicenses.filter((license) =>
          (groupOrCourse.get('users') || A()).find(
            (user) => user.get('id') === license.get('user_id')
          )
        );
      }

      return userLicenses;
    }
  ),

  hasAgreement: computed('agreement', function () {
    const agreement = this.get('agreement');

    if (!agreement?.length) {
      return false;
    }

    return !!agreement.filter((curr) => !curr.is_demo).length;
  }),

  hasDemoAgreement: computed('agreement', function () {
    const agreement = this.get('agreement');

    if (!agreement?.length) {
      return false;
    }

    return !!agreement.filter((curr) => curr.is_demo).length;
  }),

  currentAgreement: computed('agreement', function () {
    const agreement = this.get('agreement');

    if (!agreement?.length) {
      return false;
    }

    const demoAgreements = agreement.filter((curr) => !curr.is_demo);

    if (demoAgreements.length) {
      return demoAgreements.get('firstObject');
    }

    return null;
  }),

  selectedUsersForLicenseRecycling: computed(
    'selectedUser',
    'selectedUsers',
    'usersWithoutLicense',
    function () {
      if (this.get('selectedUser')) {
        return [this.get('selectedUser')];
      }
      return this.get('usersWithoutLicense');
    }
  ),

  demoAgreement: computed('agreement', function () {
    const agreement = this.get('agreement');

    if (!agreement?.length) {
      return false;
    }

    const demoAgreements = agreement.filter((curr) => {
      if (!curr.is_demo) {
        return false;
      }

      const currentIsbn = curr.belongsTo('product').id();

      // If currentIsbn is null, it means it´s a demo campaign going on
      if (!currentIsbn || this.availableProduct?.isbn === currentIsbn) {
        return true;
      }

      return false;
    });

    if (demoAgreements.length) {
      return demoAgreements.get('firstObject');
    }

    return null;
  }),

  canAssignLicense: computed('availableProduct', 'hasAgreement', function () {
    const availableProduct = this.get('availableProduct');
    const hasAgreement = this.get('hasAgreement');

    return (
      availableProduct.unassigned > 0 ||
      availableProduct.recycled > 0 ||
      availableProduct.product.get('isExtraMaterial') ||
      hasAgreement
    );
  }),

  canAddDemoLicenses: computed(
    'availableProduct.product.for_trial',
    'school.metadata.trials_disabled',
    function () {
      if (this.get('school.metadata.trials_disabled')) {
        return false;
      }

      if (this.get('availableProduct.product.for_trial') !== true) return false;
      return true;
    }
  ),

  numberOfUsedDemoLicenses: computed('paginatedLicenses', function () {
    const paginatedLicenses = this.get('paginatedLicenses');

    return paginatedLicenses
      .filter((license) => {
        return license.get('demo_used');
      })
      .filter((license) => {
        return !(
          !license.get('is_expired') ||
          (license.is_expired &&
            moment(license.active_until).isBefore(
              moment().subtract(365, 'days')
            ))
        );
      }).length;
  }),

  searchedLicenses: computed('groupedLicenses.[]', 'search', function () {
    const search = this.get('search');
    const allUsers = this.store.peekAll('user').map(({ username, email }) => ({ username, email }));

    if (search) {
      const searchWithSingleWhitespace = search.replaceAll('  ', ' ');

      const groupedLicenses = this.groupedLicenses;
      const licenseUsernames = groupedLicenses.map(license => license.username);

      const foundLicenses = this.get('groupedLicenses').filter((license) => {
        const name = `${license.get('firstname')} ${license.get('lastname')}`
          .replaceAll('  ', ' ')
          .toLowerCase();

        return (
          name.includes(searchWithSingleWhitespace) ||
          license.get('username').includes(search) ||
          allUsers.find(user => user.email?.includes(search) && user.username === license.username)
        );
      });

      trackEvent({ category: 'Hantera licenser', action: 'Hantera licenser - Söker användare', value: foundLicenses.length });

      return foundLicenses;
    }

    return this.get('groupedLicenses');
  }),

  availableLicenses: computed(
    'searchedLicenses.@each.{license_id,is_external,is_expired,active_until,is_demo,extended,is_private}',
    'availableProduct.product.isExtraMaterial',
    function () {
      return this.get('searchedLicenses');
    }
  ),

  sortedLicenses: computed(
    'availableLicenses.[]',
    'sortField',
    'sortDirection',
    function () {
      let sortedLicenses = this.get('availableLicenses') || [];

      const sortField = this.get('sortField');
      const sortDirection = this.get('sortDirection');

      if (sortField) {
        if (sortField === 'text') {
          sortedLicenses.forEach(
            (license) =>
              (license.text = licenseInfoHelper(license, this.intl).text)
          );
        }
        sortedLicenses = sortedLicenses.sortBy(sortField);
        if (sortDirection === 'DESC') {
          sortedLicenses = sortedLicenses.reverse();
        }
      }

      return sortedLicenses;
    }
  ),

  usersWithoutLicense: computed('groupedLicenses', function () {
    return this.get('groupedLicenses').filter(
      ({ license_id, is_expired, is_demo, extended }) =>
        (!license_id || is_expired || is_demo) && !extended
    );
  }),

  usersWithoutLicenseIncludingTrial: computed('groupedLicenses', function () {
    return this.get('groupedLicenses').filter(
      ({ license_id, is_expired, extended }) =>
        (!license_id || is_expired) && !extended
    );
  }),

  numberOfUsersWithoutLicenses: computed('usersWithoutLicense', function () {
    return this.get('usersWithoutLicense').length;
  }),

  numberOfUsersWithoutLicensesIncludingTrial: computed('usersWithoutLicenseIncludingTrial', function () {
    return this.get('usersWithoutLicenseIncludingTrial').length;
  }),

  pageNumber: 1,

  numberOfPages: computed('availableLicenses.[]', function () {
    return Math.ceil(this.get('availableLicenses').length / 50);
  }),

  paginatedLicenses: computed('sortedLicenses.[]', 'pageNumber', function () {
    const pageNumber = this.get('pageNumber');

    const min = (pageNumber - 1) * 50;

    const max = Math.min(pageNumber * 50, this.get('sortedLicenses').length);

    return this.get('sortedLicenses').slice(min, max);
  }),

  selectableClasses: computed('school.groups.[]', 'groupSearch', function () {
    const groups = this.get('school.groups') || [];
    const groupSearch = this.get('groupSearch');

    return groups
      .reduce((acc, group) => {
        if (
          group.get('type') === 'class' &&
          group.get('userIds').length > 0 &&
          (!groupSearch ||
            (groupSearch &&
              group.get('name').toLowerCase().includes(groupSearch)))
        ) {
          acc.push({
            label: this.get('intl').t(
              'components.availableLicensesModal.nameAndNum',
              {
                name: group.get('name'),
                num: group.get('num_students'),
              }
            ),
            type: 'option',
            value: `group.${group.get('id')}`,
          });
        }
        return acc;
      }, [])
      .sort((a, b) => {
        return a.label.localeCompare(b.label);
      });
  }),

  schoolCoursesForProduct: computed(
    'availableProduct.isbn',
    'school.id',
    function () {
      const schoolId = this.get('school.id');
      const isbn = this.get('availableProduct.isbn');

      let promise;

      if (schoolId && isbn) {
        promise = this.get('store').query('course', {
          filter: {
            school_id: schoolId,
            'products &&': [isbn],
            active: true,
          },
        });
      } else {
        promise = resolve([]);
      }

      return ArrayPromiseProxy.create({ promise });
    }
  ),

  selectableCourses: computed(
    'schoolCoursesForProduct.[]',
    'groupSearch',
    'availableProduct',
    function () {
      const availableProduct = this.get('availableProduct');
      const courses = this.get('schoolCoursesForProduct') || [];
      const groupSearch = this.get('groupSearch');

      let promise;

      if (!availableProduct) {
        promise = resolve([]);
      } else {
        promise = Promise.all(
          courses
            .filter(
              (course) =>
                !groupSearch ||
                (groupSearch &&
                  course.get('name').toLowerCase().includes(groupSearch))
            )
            .map((course) => course.get('teachers').then(() => course))
        ).then((courses) =>
          courses
            .map((course) => {
              let teacherLabel = this.get('intl').t(
                'components.availableLicensesModal.nameAndNum',
                {
                  name: course.get('name'),
                  num: course.get('numUsers'),
                }
              );

              const teacherNames = course
                .get('teachers')
                .map((x) => x.get('showname'));

              if (teacherNames.length > 0 && teacherNames[0]) {
                teacherLabel += ' - ' + teacherNames[0];

                if (teacherNames.length > 1) {
                  teacherLabel +=
                    ' ' +
                    this.get('intl').t(
                      'components.availableLicensesModal.shortMore'
                    );
                }
              }

              return {
                label: teacherLabel,
                type: 'option',
                value: `course.${course.get('id')}`,
              };
            })
            .sort((a, b) => {
              return a.label.localeCompare(b.label);
            })
        );
      }

      return ObjectPromiseProxy.create({ promise });
    }
  ),

  selectableGroups: computed('school.groups.[]', function () {
    const groups = this.get('school.groups') || [];

    return groups
      .reduce((acc, group) => {
        if (
          group.get('type') === 'user_group' &&
          group.get('userIds').length > 0
        ) {
          acc.push({
            label: this.get('intl').t(
              'components.availableLicensesModal.nameAndNum',
              {
                name: group.get('name'),
                num: group.get('num_students'),
              }
            ),
            type: 'option',
            value: `group.${group.get('id')}`,
          });
        }
        return acc;
      }, [])
      .sort((a, b) => {
        return a.label.localeCompare(b.label);
      });
  }),

  searchedSelectableGroups: computed(
    'selectableGroups',
    'groupSearch',
    function () {
      const selectableGroups = this.get('selectableGroups');
      const groupSearch = this.get('groupSearch');

      return selectableGroups.filter(
        (group) =>
          !groupSearch ||
          (groupSearch && group.label.toLowerCase().includes(groupSearch))
      );
    }
  ),

  groupOrCourseId: null,
  groupOrCourse: null,

  groupsAndCourses: computed(
    'selectableClasses',
    'selectableCourses.isPending',
    'searchedSelectableGroups',
    'showUserGroups',
    'school.users.[]',
    function () {
      const selectableClasses = this.get('selectableClasses');

      let selectableCourses = [];

      if (!this.get('selectableCourses.isPending')) {
        selectableCourses = this.get('selectableCourses.content');
      }

      const searchedSelectableGroups = this.get('searchedSelectableGroups');
      const showUserGroups = this.get('showUserGroups');

      let groupsAndCourses = [
        {
          label: this.get('intl').t(
            'components.availableLicensesModal.allUsers',
            {
              num: this.get('school.users.length'),
            }
          ),
          type: 'option',
          value: null,
        },
      ];

      groupsAndCourses.push({
        label: this.get('intl').t('components.availableLicensesModal.courses'),
        type: 'heading',
      });

      if (selectableCourses.length === 0) {
        groupsAndCourses.push({
          label: this.get('intl').t(
            'components.availableLicensesModal.noCourses'
          ),
          type: 'option',
          disabled: true,
        });
      } else {
        groupsAndCourses = groupsAndCourses.concat(selectableCourses);
      }

      groupsAndCourses.push({
        label: this.get('intl').t('components.availableLicensesModal.classes'),
        type: 'heading',
      });

      if (selectableClasses.length === 0) {
        groupsAndCourses.push({
          label: this.get('intl').t(
            'components.availableLicensesModal.noClasses'
          ),
          type: 'option',
          disabled: true,
        });
      } else {
        groupsAndCourses = groupsAndCourses.concat(selectableClasses);
      }

      if (showUserGroups) {
        groupsAndCourses.push({
          label: this.get('intl').t('components.availableLicensesModal.groups'),
          type: 'heading',
        });

        if (searchedSelectableGroups.length === 0) {
          groupsAndCourses.push({
            label: this.get('intl').t(
              'components.availableLicensesModal.noGroups'
            ),
            type: 'option',
            disabled: true,
          });
        } else {
          groupsAndCourses = groupsAndCourses.concat(searchedSelectableGroups);
        }
      }

      return groupsAndCourses;
    }
  ),

  searchVal: '',
  searchArr: [],
  search: null,

  groupSearchVal: '',
  groupSearch: null,

  showUserGroups: false,

  didReceiveAttrs() {
    this._super(...arguments);

    if (this.get('searchVal')) {
      this.send('search');
    }

    const groupOrCourseId = this.get('groupOrCourseId');
    if (groupOrCourseId) {
      const [modelName, modelId] = groupOrCourseId.split('.');

      this.get('store')
        .findRecord(modelName, modelId)
        .then((record) => {
          this.set('groupOrCourse', record);
        });
    }
  },

  actions: {
    search() {
      let search = this.get('searchVal');

      if (search) {
        search = search.trim().toLowerCase();

        this.set('search', search);
        this.set('searchArr', search.split(' '));
        this.set('pageNumber', 1);
      } else {
        if (this.get('search')) this.set('pageNumber', 1);
        this.set('searchArr', []);
        this.set('search', null);
      }
    },

    searchGroups() {
      let groupSearch = this.get('groupSearchVal');

      if (groupSearch && groupSearch.length > 1) {
        groupSearch = groupSearch.trim().toLowerCase();

        trackEvent({ category: 'Hantera licenser', action: 'Hantera licenser - Söker kurs / klass' });

        this.set('groupSearch', groupSearch);
      } else {
        this.set('groupSearch', null);
      }
    },

    groupChange(id) {
      if (id) {
        const [modelName, modelId] = id.split('.');

        this.get('store')
          .findRecord(modelName, modelId)
          .then((record) => {
            if (modelName === 'group') {
              if (record?.type === 'class') {
                trackEvent({ category: 'Hantera licenser', action: 'Hantera licenser - Väljer klass' });
              } else {
                trackEvent({ category: 'Hantera licenser', action: 'Hantera licenser - Väljer grupp' });
              }
            } else {
              trackEvent({ category: 'Hantera licenser', action: 'Hantera licenser - Väljer kurs' });
            }

            this.set('groupOrCourse', record);
          });
      } else {
        this.set('groupOrCourse', null);
      }

      // reset other parameters
      this.set('searchVal', '');
      this.set('search', null);

      this.set('groupSearchVal', '');
      this.set('groupSearch', null);

      this.set('pageNumber', 1);

      // if a group is selected the default sorting should be
      // on name to get a feeling close to a printed class list
      // sorted by name
      if (this.get('groupOrCourseId')) {
        this.set('sortField', 'showname');
      } else {
        this.set('sortField', false);
      }

      this.set('sortDirection', 'ASC');
      this.set('groupOrCourseId', id);
    },

    handlePaginationChange(pageNumber) {
      this.set('pageNumber', pageNumber);
    },

    sort(field) {
      const sortField = this.get('sortField');
      const sortDirection = this.get('sortDirection');

      if (field === sortField) {
        if (sortDirection === 'ASC') {
          trackEvent({ category: 'Hantera licenser', action: 'Hantera licenser - Sortera tabellhuvud', name: `${field} - DESC` });
          this.set('sortDirection', 'DESC');
        } else {
          trackEvent({ category: 'Hantera licenser', action: 'Hantera licenser - Sortera tabellhuvud', name: `${field} - ASC` });
          this.set('sortDirection', 'ASC');
        }
      } else {
        trackEvent({ category: 'Hantera licenser', action: 'Hantera licenser - Sortera tabellhuvud', name: `${field} - ASC` });
        this.set('sortField', field);
        this.set('sortDirection', 'ASC');
      }
    },

    showRecycleLicensesForUsers() {
      this.set('itemPopover', false);
      this.set('showRecycleLicensesModal', true);
    },

    showRecycleLicenseForUser(user) {
      this.set('selectedUser', user);
      this.set('showRecycleLicensesModal', true);
    },

    closeRecycleLicensesModal() {
      this.set('selectedUser', null);
      this.set('showRecycleLicensesModal', false);
    },

    assignMissing(trial) {
      this.set('itemPopover', false);
      this.set('isLoading', true);

      const availableProduct = this.get('availableProduct');
      const id = availableProduct.get('id');
      const isbn = id.split('_')[0];

      const groupOrCourseId = this.get('groupOrCourseId');

      if (groupOrCourseId) {
        const [modelName, modelId] = groupOrCourseId.split('.');

        let value;
        if (availableProduct.get('product').get('teachersOnly')) {
          value = this.numberOfUsersWithoutLicenses
        } else {
          value = this.numberOfUsersWithoutLicensesIncludingTrial
        }

        trackEvent({ category: 'Hantera licenser', action: 'Hantera licenser - Tilldela dessa en licens', value });

        const self = this;
        return this.get('ajax')
          .request(
            `/api/${pluralize(
              modelName
            )}/${modelId}/assign-licenses?isbn=${isbn}&is_demo=${trial}`,
            true,
            { type: 'POST' }
          )
          .then((licenses) => availableProduct.licensesUpdated(licenses))
          .then(() => self.set('isLoading', false))
          .catch(() => self.set('isLoading', false));
      }
    },
  },
});
