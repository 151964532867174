import Helper from '@ember/component/helper';
import { service } from '@ember/service';

export default class LicenseTimeHelper extends Helper {
  @service intl;

  getTranslation(timespan, value) {
    return this.intl.t(`helpers.licenseTime.${timespan}`, { value })
  }

  compute([days]) {
    switch (days) {
      case 3650:
        return this.getTranslation('years', '10');
      case 1825:
        return this.getTranslation('years', '5');
      case 1095:
        return this.getTranslation('years', '3');
      case 548:
        return this.getTranslation('months', '18');
      case 365:
        return this.getTranslation('months', '12');
      case 183:
        return this.getTranslation('months', '6');
      default:
        return this.getTranslation('days', '12');
    }
  }
}
