import Route from '@ember/routing/route';
import { service } from '@ember/service';
import config from 'babel-app/config/environment';
import {
  generateCodeVerifier,
  generatePkceChallenge,
} from 'ember-simple-auth-oidc/utils/pkce';
import uuid from 'uuid';

export default class CourseKeyIndexRoute extends Route {
  @service session;
  @service store;
  @service router;

  async #redirectToIdp({ course_code }, transition) {
    const state = uuid.v4();
    this.session.set('data.state', state);
    this.session.set('data.nextURL', transition.intent.url);

    const searchParams = new URLSearchParams({
      client_id: config['ember-simple-auth-oidc'].clientId,
      redirect_uri: `${location.protocol}//${location.host}/login`,
      response_type: 'code',
      state,
      scope: 'openid profile offline_access',
      prompt: 'consent',
      acr_values: 'simple',
    });

    if (course_code) {
      searchParams.append('login_hint', course_code);
    }

    if (config['ember-simple-auth-oidc'].enablePkce) {
      let verifier = this.session.data.pkceCodeVerifier;

      if (!verifier) {
        verifier = generateCodeVerifier(96);
        this.session.set('data.pkceCodeVerifier', verifier);
      }

      searchParams.append('code_challenge', generatePkceChallenge(verifier));
      searchParams.append('code_challenge_method', 'S256');
    }

    const authHost = config['ember-simple-auth-oidc'].host;

    window.location = `${`${authHost}/auth`}?${searchParams}`;
  }

  async #redirectToCourse({ course_code }) {
    const courseCodeResult = await this.store.query('course-code', {
      code: course_code,
      active: true,
    });

    const courseId = courseCodeResult
      .get('firstObject')
      ?.belongsTo('course')
      .id();

    if (courseId) {
      window.location = `${config.diginokUrl}/courses/${courseId}`;
    } else {
      this.router.transitionTo('404');
    }
  }

  model() {
    return this.session.isAuthenticated
      ? this.#redirectToCourse(...arguments)
      : this.#redirectToIdp(...arguments);
  }
}
