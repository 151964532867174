import ApplicationSerializer from './application';

export default ApplicationSerializer.extend({
  serialize() {
    const json = this._super(...arguments);

    delete json.data.attributes.licenses_added;

    return json;
  }
});
