import { attr, belongsTo } from '@ember-data/model';
import Model from 'babel-app/models/model';

export default class CourseCodeModel extends Model {
  @attr('boolean') active;
  @attr('string') code;
  @attr('date') created;
  @attr('date') inactivated;

  @belongsTo('user') created_by;
  @belongsTo('user') inactivated_by;
  @belongsTo('course') course;
}
