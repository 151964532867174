import EmberObject from '@ember/object';
import { typeOf } from '@ember/utils';
import { camelizeKeys, underscoreKeys } from 'compton/utils/object';
import DS from 'ember-data';

import emberObjectToNativeObject from '../utils/ember-object-to-native-object';
import nativeObjectToEmberObject from '../utils/native-object-to-ember-object';

const { Transform } = DS;

export default Transform.extend({
  deserialize(serialized, { camelizeDeserializedKeys = false }) {
    if (typeOf(serialized) === 'object') {
      if (camelizeDeserializedKeys) {
        serialized = camelizeKeys(serialized);
      }
      return nativeObjectToEmberObject(serialized);
    } else {
      return EmberObject.create();
    }
  },

  serialize(deserialized) {
    if (typeOf(deserialized) === 'instance') {
      return underscoreKeys(emberObjectToNativeObject(deserialized));
    } else {
      return {};
    }
  }
});
