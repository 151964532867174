import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class FileRollItemtComponent extends Component {
  get iconName() {
    switch (this.args.file?.type) {
      case 'video':
        return 'play-square';
      case 'audio':
        return 'audio';
      case 'external':
        return 'link';
      default:
        return 'content';
    }
  }

  get titleName() {
    return this.args.file?.name;
  }

  @action handleRemove() {
    this.args.onRemove?.(this.args.file);
  }
}
