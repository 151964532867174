import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { trackEvent } from '../../../utils/matomo-events';

export default Component.extend({

  intl: service(),
  snackbar: service(),

  // EXTERNAL

  item: null,

  addUsers: null,

  delete: null,

  moveTxt: null,

  classes: null,

  moveUsers() {},

  // INTERNAL

  tagName: '',

  isPopupIndexOpen: false,
  modalOpen: false,

  name: null,

  moveModalOpen: false,
  moveLoading: false,

  selectedStudents: [],
  selectedClasses: [],

  init() {
    this._super(...arguments);

    if (!this.get('moveTxt')) {
      this.set('moveTxt', {
        searchHeading: 'admin.moveUsers.search',
        searchPlaceholder: 'admin.moveUsers.searchStudents',
        groupSelectHeading: 'admin.moveUsers.groups',
        groupSelectAllOption: 'admin.moveUsers.allGroups',
        noUsersMessage: 'admin.moveUsers.noStudents',
        groupNumUsers: 'admin.moveUsers.groupNumUsers',
        groupNoUsers: 'admin.moveUsers.groupNoUsers',
        userNameHeading: 'admin.moveUsers.name',
        userEmailHeading: 'admin.moveUsers.eMail',
        usersLabel: 'admin.moveUsers.numStudents',
      });
    }
  },

  movableClasses: computed('classes', 'item', function () {
    const classes = this.get('classes');
    const item = this.get('item');

    return classes
      .filter((x) => {

        return x.get('id') !== item.get('id');
      });
  }),

  numberOfSelectedUsers: computed('selectedClasses.[]', 'selectedStudents.[]', function () {
    const selectedClasses = this.get('selectedClasses');
    const selectedStudents = this.get('selectedStudents');

    const numberOfUsersInSelectedClasses = selectedClasses
      .reduce((total, group) => total + group.get('num_students'), 0);

    return numberOfUsersInSelectedClasses + selectedStudents.length;
  }),

  // ACTIONS

  actions: {
    handleActionMenuButtonChange(value) {
      switch (value) {
        case 'rename_class':
          this.set('name', this.get('item.name'));
          this.set('modalOpen', true);
          break;
        case 'remove_class':
          this.get('delete')(this.get('item'), this.get('item.users'));
          break;
        case 'move_students_to_class':
          this.set('selectedClasses', []);
          this.set('selectedStudents', []);
          this.set('moveModalOpen', true);
          this.set('isPopupIndexOpen', false);
          break;
      }
    },

    closeModal() {
      trackEvent({
        category: 'Användare',
        action: 'Klass - Ändra namn - Avbryt'
      });

      this.set('modalOpen', false);
    },

    closeMoveModal() {
      trackEvent({
        category: 'Användare',
        action: 'Klass - Flytta elever hit - Avbryt'
      });

      this.set('moveModalOpen', false);
    },

    moveUsers() {
      this.set('moveLoading', true);

      const selectedClasses = this.get('selectedClasses');
      const selectedStudents = this.get('selectedStudents');

      const usersInSelectedClasses = selectedClasses
        .flatMap((classGroup) => {
          // get class users and convert to native JS array by calling map on it
          // so we can use .flatMap()
          return classGroup.get('users')
            .map((user) => user);
        });

      const usersToMove = selectedStudents.concat(usersInSelectedClasses);

      if (usersToMove.length === 0) {
        this.set('moveLoading', false);
        return;
      }

      Promise.all(
        selectedStudents.map((user) =>
          user.get('groups')
            .then((groups) =>
              groups.findBy('type', 'class')
            )
        )
      )
        .then((selectedStudentsClasses) => {
          const nonEmptyStrayClasses = selectedStudentsClasses
            .filter((group) => group);

          const uniqStrayClasses = [...new Set(nonEmptyStrayClasses)];

          const oldGroups = selectedClasses.concat(uniqStrayClasses);

          const newClass = this.get('item');

          this.get('moveUsers')(newClass, usersToMove, oldGroups)
            .then(() => {
              this.set('moveLoading', false);
              this.set('moveModalOpen', false);

              trackEvent({
                category: 'Användare',
                action: 'Klass - Flytta elever hit - Flytta',
                value: usersToMove.length
              });

              this.get('snackbar').enqueue(
                this.get('intl').t('admin.moveUsers.snack', { num_users: usersToMove.length, class_name: newClass.get('name') }), {
                  variant: 'success',
                  autoDismiss: true
                }
              );
            });
        });
    },

    saveName() {
      const group = this.get('item');

      group.set('name', this.get('name'));
      group.save();

      trackEvent({
        category: 'Användare',
        action: 'Klass - Ändra namn - Spara'
      });

      this.set('modalOpen', false);
    }
  }
});
