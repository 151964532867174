import entityFallbackTitles from 'babel-app/rules/entity-fallback-titles';

export default function (type, intl) {
  const typeFallback = entityFallbackTitles[type];

  if (typeFallback) {
    return intl.t(`models.entity.fallbackTitles.${type}`);
  }

  return intl.t(`models.entity.fallbackTitles.missingType`);
}
