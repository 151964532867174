import { computed } from '@ember/object';
import Mixin from '@ember/object/mixin';
import { inject as service } from '@ember/service';
import DS from 'ember-data';

const { belongsTo } = DS;

export default Mixin.create({
  // SETUP

  session: service(),

  // RELATIONSHIPS

  user: belongsTo('user'),

  // PROPERTIES

  userId: computed('user', function () {
    return this.belongsTo('user').id();
  }),

  isOwner: computed('user', 'session.user', function () {
    return this.isUser(this.get('session.user'));
  }),

  // METHODS

  isUser(user) {
    return this.get('userId') === user.get('id');
  },
});
