import ApplicationSerializer from 'babel-app/serializers/application';
import sanitizeHTML from 'babel-app/utils/sanitize-html';

export default ApplicationSerializer.extend({
  normalize(_, hash) {
    if (hash && hash.attributes) {
      const attrs = [
        'examples',
        'explanation',
        'translation',
        'word_class',
        'word_construction'
      ];

      attrs.forEach((name) => {
        if (hash.attributes[name]) {
          hash.attributes[name] = sanitizeHTML(hash.attributes[name]);
        }
      });
    }

    return this._super(...arguments);
  }
});
