import Evented from '@ember/object/evented';
import { cancel, later, throttle } from '@ember/runloop';
import Service from '@ember/service';

export default Service.extend(Evented, {
  scrollTO: null,
  lastVal: 0,
  lastDirection: null,

  init() {
    this._super(...arguments);

    window.addEventListener('scroll', this.event.bind(this), { passive: true });
  },

  event(e) {
    let direction = null;
    let scrollY = this._scrollY();

    if (scrollY > this.lastVal) {
      direction = 'down';
    } else if (scrollY < this.lastVal) {
      direction = 'up';
    }

    if (direction !== null) {
      this.lastVal = scrollY;

      if (direction !== this.lastDirection) {
        this.lastDirection = direction;
        this.trigger('direction', direction);
      }
    }

    throttle(this, this.throttled, e, 200, true);

    cancel(this.scrollTO);

    this.scrollTO = later(this, this.after, e, 200);
  },

  throttled(e) {
    this.trigger('scroll', e);
  },

  after(e) {
    this.trigger('after', e);
  },

  _scrollY() {
    let scrollY = window.pageYOffset;

    if (scrollY < 0) {
      scrollY = 0;
    } else {
      const maxScroll = document.body.scrollHeight - window.innerHeight;

      if (scrollY > maxScroll) {
        scrollY = maxScroll;
      }
    }

    return scrollY;
  }
});
