import Route from '@ember/routing/route';

export default class SkolkodRoute extends Route {
  resetController(controller, isExiting, transition) {
    super.resetController(...arguments);
  
    if (isExiting) {
      controller.reset();
    }
  }
}
