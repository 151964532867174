import EmberObject, { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import DS from 'ember-data';
import { all, resolve } from 'rsvp';

import Userable from '../mixins/userable';
import Model from './model';

const { attr, belongsTo, hasMany } = DS;

export default Model.extend(Userable, {
  // SETUP

  store: service(),

  changeTracker: { except: ['includedEntitySettings'] },

  // RELATIONSHIPS

  course: belongsTo('course'),

  entity: belongsTo('entity'),

  includedEntities: hasMany('entity'),

  includedTeacherAssignments: hasMany('teacher-assignment'),

  includedWordlists: hasMany('wordlist'),

  todos: hasMany('todo'),

  // ATTRIBUTES

  title: attr('string'),

  populated: attr('boolean', { defaultValue: false }),

  sort: attr('number', { defaultValue: 0 }),

  includedEntitySettings: attr('array', {
    defaultValue() {
      return [];
    },
  }),

  titleWithFallback: computed('title', 'entity.title', function () {
    const title = this.title;

    if (isPresent(title)) {
      return title;
    }

    return this.get('entity.title');
  }),

  // METHODS

  populate() {
    if (this.populated) {
      return resolve(this);
    }

    const store = this.store;

    return this.entity.then((entity) => {
      return Promise.all([
        this.includedEntities,
        this.includedTeacherAssignments,
        this.includedWordlists,
        entity.get('customizableDescendents'),
        store
          .findAll('teacher-assignment')
          .then((teacherAssignments) =>
            all(
              teacherAssignments.map((teacherAssignment) =>
                all([
                  teacherAssignment.get('books'),
                  teacherAssignment.get('contents'),
                ])
              )
            ).then(() => teacherAssignments)
          ),
        store
          .findAll('wordlist')
          .then((wordlists) =>
            all(
              wordlists.map((wordlist) =>
                all([wordlist.get('books'), wordlist.get('contents')])
              )
            ).then(() => wordlists)
          ),
      ]).then(
        ([
          includedEntities,
          includedTeacherAssignments,
          includedWordlists,
          descendents,
          teacherAssignments,
          wordlists,
        ]) => {
          includedTeacherAssignments.setObjects(
            teacherAssignments
              .filterBy('isPublished')
              .filter((teacherAssignment) =>
                teacherAssignment.hasContent(entity)
              )
          );

          includedWordlists.setObjects(
            wordlists
              .filterBy('isPublished')
              .filter((wordlist) => wordlist.hasContent(entity))
          );

          includedEntities.setObjects(descendents);

          descendents.forEach((descendent) => {
            this.includedEntitySettings.addObject(
              this._getDefaultSettingsForIncludedEntity(descendent)
            );
          });

          this.set('populated', true);

          return this;
        }
      );
    });
  },

  // PRIVATE

  _getDefaultSettingsForIncludedEntity(entity) {
    const entityId = entity.get('id');

    if (entity.get('isDiagnosis')) {
      return EmberObject.create({
        entityId,
        numberOfTries: null,
        timeAllowed: null,
      });
    }

    return EmberObject.create({
      entityId,
    });
  },
});
