import DS from 'ember-data';

import Model from './model';
const { attr } = DS;

export default Model.extend({
  // ATTRIBUTES

  description: attr('string'),

  address: attr(),

  settings: attr(),

  name: attr('string')
});
