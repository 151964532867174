import DraggableObject from 'ember-drag-drop/components/draggable-object';

const noop = function() {
  return;
};

/**
 * A 'fix' to prevent scroll on touchmove.
 * For further information:
 * https://dockyard.com/blog/2018/07/20/drag-and-drop-on-ios-with-ember
 */

export default DraggableObject.extend({
  init() {
    this._super(...arguments);
    window.addEventListener('touchmove', noop, { passive: false });
  },

  willDestroyElement() {
    this._super(...arguments);
    window.removeEventListener('touchmove', noop);
  }
});
