import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class MasterProfileSettingsRoute extends Route {
  @service store;

  resetController(controller) {
    controller.error = null;
    controller.service = null;
    controller.identifier = null;
  }

  async model() {
    const avatars = await this.store.findAll('avatar');

    return {
      avatars,
    };
  }
}
