import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import config from 'babel-app/config/environment';
import shouldReloadOnUpdate from 'babel-app/utils/should-reload-on-update';

const CONFIG_REGEXP =
  /<meta name="babel-app\/config\/environment" content="(.+)" \/>/gm;

export default class AppUpdateService extends Service {
  @service fetch;

  @service router;

  @tracked initialized = false;

  @tracked hasUpdate = false;

  async checkVersion() {
    try {
      // We check for updates by pinging the index file and comparing the app
      // version found there with the current app version.

      const text = await this.fetch.request('/index.html');
      const matches = CONFIG_REGEXP.exec(text);
      const meta = decodeURIComponent(matches[1]);
      const json = JSON.parse(meta);
      const indexVersion = json.APP.version;
      const currentVersion = config.APP.version;

      if (indexVersion && currentVersion && indexVersion !== currentVersion) {
        this.hasUpdate = true;
      }
    } catch (err) {
      // do nothing
    }
  }

  initialize() {
    if (!this.initialized) {
      this.router.on('routeWillChange', (transition) => {
        if (this.hasUpdate && shouldReloadOnUpdate(transition?.to?.name)) {
          transition.finally(() => location.reload());
        } else {
          this.checkVersion();
        }
      });

      this.initialized = true;
    }
  }
}
