import { A } from '@ember/array';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import DS from 'ember-data';
import { all } from 'rsvp';

import Model from './model';

const { attr, belongsTo, hasMany } = DS;

export default Model.extend({
  session: service(),

  store: service(),

  total: attr('number'),

  is_assigned: attr('number'),

  unassigned: attr('number'),

  recycled: attr('number'),

  demo: attr('number'),

  external: attr('number'),

  numberOfAssignableLicenses: computed('unassigned', 'recycled', function () {
    return this.get('unassigned') + this.get('recycled')
  }),

  onlyExternal: computed('total', 'external', function () {
    return this.get('total') === this.get('external');
  }),

  isbn: computed('id', function () {
    return this.get('id').split('_')[0];
  }),

  recyclableLicenses: computed(
    'isbn',
    'available_licenses.@each.{assignee}',
    function () {
      const isbn = this.get('isbn');
      const now = new Date();
      const productLicenses = this.get('available_licenses').filter(
        (license) =>
          license.isbn == isbn &&
          !license.isb_demo &&
          !license.assignee &&
          license.active_until > now
      );
      return productLicenses.reduce((licenseGroups, license) => {
        const formattedDate = license.active_until.toLocaleDateString();
        (licenseGroups[formattedDate] ??= []).push(license);

        return licenseGroups;
      }, {});
    }
  ),

  total_assigned: computed('total', 'demo', function () {
    return (this.total || 0) + (this.demo || 0);
  }),

  has_agreement: attr('boolean'),

  max_acquire_date: attr('date'),

  school_id: attr('string'),

  // INTERNAL

  licensesHaveChanged: false,

  availableProductIsReloading: false,

  // RELATIONSHIPS

  user_licenses: hasMany('user_license', { inverse: null }),

  available_licenses: hasMany('available_license', { inverse: null }),

  product: belongsTo('product'),

  agreement: belongsTo('agreement'),

  // METHODS

  async licensesUpdated(payload, userId = null) {

    if (!payload) {
      throw new Error('No payload was received');
    }


    if (payload.data && Array.isArray(payload.data)) {
      payload.data = payload.data.filter((item) => item && item.id);
    }

    this.store.pushPayload(payload);

    const licenses = A();

    await Promise.all(
      [].concat(payload.data).map(async (license) => {
        licenses.addObject(await this.store.findRecord('license', license.id));
      })
    );

    const isbn = this.get('product.id');

    const currentUserId = this.get('session.user.id');

    const affectedCourses = this.store.peekAll('course').filter((course) => {
      if (!course.get('active')) return false;

      if (!course.hasMany('products').ids().includes(isbn)) {
        return false;
      }

      if (course.hasMany('missingLicenseProducts').value() === null) {
        return false;
      }

      return true;
    });

    const availableLicenses = await this.available_licenses.reload();
    await Promise.all(
      affectedCourses.map((course) =>
        course.get('missingLicenseProducts').reload()
      )
    );

    await this.get('user_licenses').reload();
    await this.get('session.user').get('licenses').reload();

    if (licenses.findBy('user.id', currentUserId)) {
      await this.get('session').refreshUserLicenses();
    }

    await Promise.all(
      licenses.map(async (license) => {
        const { id } = license;
        const availableLicense = availableLicenses.findBy('id', id);
        availableLicense?.set('assignee', license.belongsTo('user').id());

        const affectedUserId = userId ?? license.belongsTo('user').id();

        const userLicenseId = `${isbn}_${affectedUserId}`;

        await this.store.findRecord('user_license', userLicenseId, {
          reload: true,
          adapterOptions: {
            query: { school_id: this.get('school_id') },
          },
        });

        license.is_new = true;

        if (currentUserId === affectedUserId) {
          await this.get('session').refreshUserLicenses();
        }
      })
    );

    await this.reload();
  },

  fullReload() {
    if (this.get('availableProductIsReloading')) return;

    this.set('availableProductIsReloading', true);

    const promises = [];

    const userLicensesRelation = this.hasMany('user_licenses');

    if (userLicensesRelation.value() !== null) {
      promises.push(
        userLicensesRelation.reload({
          adapterOptions: {
            query: { school_id: this.get('school_id') },
          },
        })
      );
    }

    const availableLicensesRelation = this.hasMany('available_licenses');

    if (availableLicensesRelation.value() !== null) {
      promises.push(availableLicensesRelation.reload());
    }

    return all(promises)
      .then(() => this.reload())
      .then(() => {
        this.set('licensesHaveChanged', false);
        this.set('availableProductIsReloading', false);
      })
      .catch((err) => {
      });
  },
});
