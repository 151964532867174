import RootModel from 'babel-app/models/root-model';
import DS from 'ember-data';

const { attr } = DS;

export default RootModel.extend({
  // ATTRIBUTES

  created: attr('date', {
    defaultValue() {
      return new Date();
    }
  }),

  updated: attr('date'),

  duplicateAttributes() {
    const attributes = {};

    this.eachAttribute((name) => {
      if (!['created', 'updated'].includes(name)) {
        attributes[name] = this.get(name);
      }
    });

    return attributes;
  }
});
