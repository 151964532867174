import { helper as buildHelper } from '@ember/component/helper';

export function imageBuilder(params, hash) {
  if (!hash.url) {
    return null;
  }

  var url = hash.url;

  url += '?';

  if (hash.w || hash.width) {
    url += 'width=' + (hash.w || hash.width) + '&';
  }
  if (hash.h || hash.height) {
    url += 'height=' + (hash.h || hash.height) + '&';
  }
  if (hash.preset) {
    url += 'preset=' + hash.preset + '&';
  }

  return url;
}

export default buildHelper(imageBuilder);
