import { computed } from '@ember/object';
import { notEmpty } from '@ember/object/computed';
import Mixin from '@ember/object/mixin';
import DS from 'ember-data';

const { hasMany } = DS;

export default Mixin.create({
  // RELATIONSHIPS

  books: hasMany('entity'),

  contents: hasMany('entity'),

  // PROPERTIES

  bookIds: computed('books.@each', function () {
    return this.get('books').mapBy('id');
  }),

  contentIds: computed('contents.@each', function () {
    return this.get('contents').mapBy('id');
  }),

  hasBooks: notEmpty('bookIds'),

  hasContents: notEmpty('contentIds'),

  // METHODS

  hasBook(book) {
    return this.get('bookIds').includes(book.get('id'));
  },

  hasContent(content) {
    return this.get('contentIds').includes(content.get('id'));
  },
});
